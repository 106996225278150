import React, {
    Component
    // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import './layout.css';
import Select from '@mui/material/Select';
import { Collapse, Alert, AlertTitle, Toolbar, AppBar, Dialog, Slide, Tooltip, IconButton, Grid, Container, Typography, TextField, Button, FormControl, MenuItem, InputLabel } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import PlusIcon from '@mui/icons-material/Add';


import MUIDataTable from "mui-datatables";

const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: "806px",
        },
    },
};

let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();

const today = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;

const options = {
    filterType: 'multiselect',
    // filterOptions: {fullWidth: true},
    selectableRows: 'none',
    setRowProps: row => {
        if (row[0] === "New") {
            return {
                style: { background: "snow" }
            };
        }
    },
    draggableColumns: { enabled: true },
    pagination: true,
    // resizableColumns: true,
    // responsive: "standard",
    fixedHeader: false,
    downloadOptions: {
        filterOptions: {
            useDisplayedRowsOnly: true,
        }
    }
};



class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {},
            selectedDate: new Date(),
            heading: '',
            unique_states: [],
            stateValue: '',
            unique_clientNames: [],
            clientID: '',
            clientName: '',
            clientNameState: 'newClientAdd',
            clientNameDropDown: '',
            billingName: '',
            address: '',
            gst: '',
            pan: '',
            submitButton: '',
            successText: '',
            alertState: false,
            rowData: [],
            columns: [
                {
                    name: "clientName",
                    label: "Client Name",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "billingName",
                    label: "Billing Name",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "State",
                    label: "State",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "address",
                    label: "Address",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "GST",
                    label: "GST",
                },
                {
                    name: "pan",
                    label: "PAN No.",
                },
                {
                    name: "CreatedDate",
                    label: "Date",
                    options: {
                        filter: false,
                        sort: true,
                        customBodyRenderLite: (dataIndex) => {
                            return (
                                <div >
                                    {this.state.rowData[dataIndex].CreatedDate.slice(0, 10)}
                                </div>
                            )
                        }
                    }
                },

                {
                    name: "clientID",
                    label: "Actions",
                    options: {
                        filter: false,
                        sort: true,
                        customBodyRenderLite: (dataIndex) => {
                            return (
                                <Tooltip title="Update">
                                    <Button
                                        // component={RouterLink}
                                        size="small"
                                        // to={{
                                        //   pathname: `/management/view/order-details/${params.node.data.pTLTripID}`,
                                        //   orders: this.state.orders
                                        // }}
                                        onClick={(e) => this.handleOpenFullModal(dataIndex, e)}

                                        style={{ color: 'black' }}
                                    // variant="outlined"
                                    >
                                        <EditIcon />

                                    </Button>
                                </Tooltip>
                            )

                        }
                    }
                },
            ],


        };
        this.validator = new SimpleReactValidator();
    }

    delay = async (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }


    getNewRequests = async () => {
        const res = await axios.get(
            `${Config.hostName}/billing/getClientList`,
            {
                // ManagerID: 113
            }
        )
            .then(async (res) => {
                // console.log(res.data.data, 'project');
                if (res.data.data.length > 0) {
                    await this.setState({
                        rowData: res.data.data.map(eachItem => {
                            return {
                                clientID: eachItem.clientID,
                                clientName: eachItem.clientName,
                                billingName: eachItem.billingName,
                                State: eachItem.State,
                                address: eachItem.address,
                                GST: eachItem.GST,
                                pan: eachItem.pan,
                                CreatedDate: eachItem.CreatedDate,
                            }
                        })
                    });
                } else {
                    alert('No Recoords Found... !!');

                }
            })
            .catch((error) => {
                alert(error);
            });
    }


    getStates = async () => {
        const res = await axios.get(
            `${Config.hostName}/billing/getStateDD`,
            {
                // ManagerID: 113
            }
        )
            .then(async (res) => {
                console.log(res.data.data, 'project');
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_states: res.data.data
                    });
                } else {
                    alert('No Recoords Found... !!');

                }
            })
            .catch((error) => {
                alert(error);
            });
    }

    getClientNames = async () => {
        const res = await axios.get(
            `${Config.hostName}/billing/getCleintNameDD`,
            {
                // ManagerID: 113
            }
        )
            .then(async (res) => {
                console.log(res.data.data, 'clientName');
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_clientNames: res.data.data
                    });
                } else {
                    alert('No Recoords Found... !!');

                }
            })
            .catch((error) => {
                alert(error);
            });
    }


    async componentDidMount() {
        await this.setState({
            style: {}
        });
        await this.getNewRequests();

        await this.setState({
            style: {
                display: "none"
            }
        });
    }


    changeClientName = async (e) => {
        if (e.target.value == 'add') {
            this.setState({
                clientNameState: '',
                clientNameDropDown: 'add',
                clientName: ''
            })

        } else {
            this.setState({
                clientNameDropDown: e.target.value,
                clientNameState: 'newClientAdd',
                clientName: e.target.value
            });


        }

    }


    handleOpenFullModal = async (data, e) => {
        await this.getStates();
        await this.getClientNames();
        if (data == 'add') {
            await this.setState({
                heading: 'Register New Client',
                submitButton: 'Submit',
                openFullModal: true,
                clientID: '',
                clientName: '',
                billingName: '',
                stateValue: '',
                address: '',
                gst: '',
                pan: '',
            });
        } else {
            console.log(this.state.rowData[data]);
            await this.setState({
                heading: 'Update Client Information',
                submitButton: 'Update',
                openFullModal: true,
                clientID: this.state.rowData[data].clientID,
                clientName: this.state.rowData[data].clientName,
                clientNameDropDown: this.state.rowData[data].clientName,
                billingName: this.state.rowData[data].billingName,
                stateValue: this.state.rowData[data].State.toUpperCase(),
                address: this.state.rowData[data].address,
                gst: this.state.rowData[data].GST,
                pan: this.state.rowData[data].pan,
            });
        }
        await this.setState({
            clientNameState: 'newClientAdd',
            openFullModal: true,
        });
    };

    handleCloseFullModal = async (e) => {
        await this.setState({
            openFullModal: false,
        });

    };

    onSubmitModal = async (e) => {
        e.preventDefault();
        await this.setState({
            submitButton: 'Please wait...',
        });
        console.log(this.state.clientName);
        const res = await axios.post(
            `${Config.hostName}/billing/manageClient`,
            {
                clientID: this.state.clientID,
                clientName: this.state.clientName,
                billingName: this.state.billingName,
                state: this.state.stateValue,
                address: this.state.address,
                GST: this.state.gst,
                pan: this.state.pan,
            }
        )
            .then(async (res) => {
                await this.getNewRequests();
                if (res.data.success != false) {
                    if (this.state.clientID == "") {
                        await this.setState({
                            successText: 'Client Successfully Registered',
                            alertState: true,
                            openFullModal: false,
                        });
                    } else {
                        await this.setState({
                            successText: 'Client Successfully Updated',
                            alertState: true,
                            openFullModal: false,
                        });
                    }
                } else {
                    if (this.state.clientID == "") {
                        await this.setState({
                            submitButton: 'Submit'
                        });
                    } else {
                        await this.setState({
                            submitButton: 'Update'
                        });
                    }
                    alert(res.data.message);
                }


            })
            .catch((error) => {
                alert(error);
            });


    };

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    render() {


        return (
            <div>

                <Container pt={3} style={{ maxWidth: 'inherit', background: 'white', paddingBottom: "12%", marginTop: '7%' }} >
                    <div id='cover-spin' style={this.state.style}></div>

                    <Grid pt={3} container>
                        <Grid item md={12} >
                            <Collapse in={this.state.alertState}>
                                <Alert onClose={() => {
                                    this.setState({
                                        successText: '',
                                        alertState: false,
                                    })
                                }} severity="success">
                                    <AlertTitle>Success</AlertTitle>
                                    {this.state.successText}
                                </Alert>
                            </Collapse>
                        </Grid>



                        <Grid style={{ float: 'right', textAlign: 'right' }} item md={12}>
                            <Button onClick={(e) => this.handleOpenFullModal('add', e)} size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }} endIcon={<PlusIcon />}>
                                Add Client
                            </Button>
                        </Grid>


                    </Grid>

                    <Grid mt={3}>
                        <Grid item md={8}>
                            <MUIDataTable
                                title={"Registered Client"}
                                data={this.state.rowData}
                                columns={this.state.columns}
                                options={options}
                            />
                        </Grid>

                    </Grid>

                </Container>

                <Dialog fullScreen
                    TransitionComponent={this.Transition}
                    onClose={this.handleCloseFullModal} open={this.state.openFullModal}
                >
                    <Container maxWidth="xl" style={{ maxWidth: 'inherit', paddingLeft: '0px', paddingRight: '0px' }}>


                        <AppBar style={{ height: 'auto', backgroundColor: 'white', color: 'black' }} sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={this.handleCloseFullModal}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      Sound
                    </Typography> */}
                                <Button autoFocus color="inherit" onClick={this.handleCloseFullModal}>
                                    Close
                                </Button>
                            </Toolbar>
                        </AppBar>



                        {/* <Header order="rohan" /> */}
                        <Grid style={{ background: '#e0e1e0', }} container>
                            <Grid item md={12} style={{ padding: '0px 12px' }}>
                                <form onSubmit={this.onSubmitModal} method="POST">
                                    <Container pt={3} style={{ maxWidth: 'inherit', background: 'white', paddingBottom: "12%", marginTop: '2%' }} >
                                        <Typography pt={2} style={{ textAlign: 'center', color: '#333333' }} variant="h4"> <b><u> {this.state.heading}</u></b></Typography>


                                        <Typography pt={4} variant="h6"><b> Choose :</b></Typography>
                                        <Grid pt={1} container>


                                            <Grid item md={3}>
                                                <FormControl variant="standard" fullWidth={true} sx={{ m: 1, }} style={{ marginLeft: '0px' }}>
                                                    <InputLabel id="State">State</InputLabel>
                                                    <Select
                                                        labelId="State"
                                                        id="State2"
                                                        value={this.state.stateValue}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                stateValue: e.target.value
                                                            })
                                                        }}
                                                        label="State"
                                                        required
                                                    >
                                                        <MenuItem value="">
                                                            <em>Select State</em>
                                                        </MenuItem>

                                                        {this.state.unique_states.map((eachItem, key) => (
                                                            <MenuItem value={eachItem.State} key={key} >{eachItem.State}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={1}>
                                            </Grid>

                                            <Grid item md={3}>
                                                <FormControl variant="standard" fullWidth={true} sx={{ m: 1, }} style={{ marginLeft: '0px' }}>
                                                    <InputLabel id="department">Client Name</InputLabel>
                                                    <Select
                                                        labelId="department"
                                                        id="department2"
                                                        value={this.state.clientNameDropDown}
                                                        onChange={(e) => { this.changeClientName(e) }}
                                                        label="Department"
                                                    >
                                                        <MenuItem value="">
                                                            <em>Select Client Name</em>
                                                        </MenuItem>

                                                        {this.state.unique_clientNames.map((eachItem, key) => (
                                                            <MenuItem value={eachItem.ClientName} key={key} >{eachItem.ClientName}</MenuItem>
                                                        ))}
                                                        <MenuItem value="add">
                                                            <b> Add New Client </b>
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={1}>
                                            </Grid>
                                            <Grid  item className={this.state.clientNameState} md={3}  >
                                                <TextField   sx={{ m: 1, width: '30ch' }} id="client" label="Client Name"
                                                    onChange={(e) => { this.setState({
                                                        clientName: e.target.value
                                                    }) }}
                                                    value={this.state.clientName}
                                                    variant="standard"
                                                    disabled={this.state.clientNameState}

                                                />

                                            </Grid>





                                        </Grid>

                                        <Typography pt={4} variant="h6"> <b> Basic Information:</b></Typography>

                                        <Grid pt={1} container>

                                            <Grid item md={8} >
                                                <TextField sx={{ m: 1, width: '60ch' }} id="billingName" label="Billing Name"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            billingName: e.target.value,
                                                        });
                                                    }}
                                                    value={this.state.billingName}
                                                    variant="standard"
                                                    required

                                                />

                                            </Grid>

                                            <Grid item md={8} >
                                                <TextField sx={{ m: 1, width: '60ch' }} id="PAN" label="PAN No."
                                                    onChange={(e) => {
                                                        this.setState({
                                                            pan: e.target.value,
                                                        });
                                                    }}
                                                    value={this.state.pan}
                                                    variant="standard"
                                                    required

                                                />

                                            </Grid>

                                            <Grid item md={8} >
                                                <TextField sx={{ m: 1, width: '60ch' }} id="GST" label="GST"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            gst: e.target.value,
                                                        });
                                                    }}
                                                    value={this.state.gst}
                                                    variant="standard"
                                                    required

                                                />

                                            </Grid>


                                            <Grid item md={8} >
                                                <TextField sx={{ mt: 3, width: '60ch' }}
                                                    id="standard-multiline-static"
                                                    label="Address"
                                                    rows={5}
                                                    multiline
                                                    defaultValue={this.state.address}
                                                    variant="outlined"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            address: e.target.value,
                                                        });
                                                    }}
                                                    required
                                                />
                                            </Grid>





                                            <Grid item md={9} >

                                            </Grid>
                                            <Grid item md={6} >

                                            </Grid>

                                            <Grid mt={2} item md={3} >
                                                <Button type="submit" size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }} endIcon={<SendIcon />}>
                                                    {this.state.submitButton}
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </Container>
                                </form>
                            </Grid>

                        </Grid>


                    </Container>

                </Dialog>







            </div>


        );
    }
}

HomePage.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(HomePage);
