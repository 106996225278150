import React, { Component, useState } from "react";
import "../../../../assets/customCssFiles/bmsViewReqPayee.css";
import "../../../../assets/customCssFiles/bmsDashboard.css";
import axios from "axios";
import PropTypes from "prop-types";
import {
  Button,
  FormGroup,
  CustomInput,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Breadcrumb from "../../../common/breadcrumb.component";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import Pagination from "react-js-pagination";
import "../../../../assets/customCssFiles/loader-spinner.css";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import * as XLSX from "xlsx";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import EditIcon from "@material-ui/icons/Edit";
import { AvForm, AvField } from "availity-reactstrap-validation";
import CloseIcon from "@material-ui/icons/Close";
// import HomePage from "../../FTSD/DashBoard/HomePage";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "",
      modal: false,
      locations: [],
      unique_locationids: [],
      unique_segments: [],
      unique_clients: [],
      anotherModal: false,
      index: null,
      AdvpayDate: new Date(),
      description: "",
      startDate: "",
      endDate: "",
      Locationid: null,
      Segment: null,
      ServiceType: "",
      ClientName: null,
      IsVerified: "",
      IsVerifiedValue: "",
      Trip: "",
      selectedArray: [],
      AdvPay: 0,
      activePage: 1,
      itemsCountPerPage: 30,
      totalItemsCount: 5000,
      pageRangeDisplayed: 5,
      columnDefs: [
        // {
        //     headerName: "Update", field: "Update", minWidth: 130,
        //     cellRendererFramework: (params) => {
        //         // console.log(params);

        //         // console.log(params.node.data);

        //         return <Button
        //             color="primary"
        //             value={params.node.data.TripID}
        //             onClick={this.onSubmit}
        //         >
        //             Update
        //               </Button>

        //     }
        // },
        {
          headerName: "BMSID",
          field: "BMSID",
          sortable: true,
          filter: true,
          minWidth: 80,
        },
        // {
        //     headerName: "Payee City", field: "PayeeCity", sortable: true, filter: true
        // },
        {
          headerName: "Input Narration",
          field: "inputNarration",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Project",
          field: "Project",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Category",
          field: "Category",
          sortable: true,
          filter: true,
          minWidth: 110,
        },
        {
          headerName: "Zonal Manager",
          field: "ZonalMgr",
          sortable: true,
          filter: true,
          minWidth: 110,
        },
        {
          headerName: "Payee Type",
          field: "PayeeType",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Beneficiary Name",
          field: "BeneficiaryName",
          sortable: true,
          filter: true,
          minWidth: 110,
        },
        {
          headerName: "Requested Amount",
          field: "Requestedamount",
          sortable: true,
          filter: true,
        },
        // {
        //     headerName: "Payee ID", field: "PayeeID", sortable: true, filter: true
        // },

        {
          headerName: "Paid Amount",
          field: "Paidamount",
          sortable: true,
          filter: true,
          cellRendererFramework: (params) => {
            const paidamount = params.node.data.Paidamount;

            if (paidamount == null) {
              return "--";
            } else {
              return paidamount;
            }
          },
        },
        {
          headerName: "UTR",
          field: "UTR",
          sortable: true,
          filter: true,
          cellRendererFramework: (params) => {
            const utr = params.node.data.UTR;

            if (utr == null) {
              return "--";
            } else {
              return utr;
            }
          },
        },
        {
          headerName: "Payment Date",
          field: "PaymentDate",
          sortable: true,
          filter: true,
          cellRendererFramework: (params) => {
            const PaymentDate = params.node.data.PaymentDate;

            if (PaymentDate == null) {
              return "--";
            } else {
              return PaymentDate;
            }
          },
        },
        {
          headerName: "Narration",
          field: "Narration",
          sortable: true,
          filter: true,
          cellRendererFramework: (params) => {
            const Narration = params.node.data.Narration;

            if (Narration == null) {
              return "--";
            } else {
              return Narration;
            }
          },
        },

        {
          headerName: "Payment Status",
          field: "PaymentStatus",
          sortable: true,
          filter: true,
          minWidth: 130,
          cellRendererFramework: (params) => {
            const PaymentStatus = params.node.data.PaymentStatus;
            let StatusColor = "";
            if (PaymentStatus == "Success") {
              StatusColor = "text-success";
            } else if (PaymentStatus == "Rejected") {
              StatusColor = "text-danger";
            } else {
              StatusColor = "text-success";
            }
            return (
              <span className={StatusColor} style={{ fontWieght: "bold" }}>
                {params.node.data.PaymentStatus
                  ? params.node.data.PaymentStatus
                  : "--"}
              </span>
            );
          },
        },

        {
          headerName: "Ticket Status",
          field: "TicketStatus",
          sortable: true,
          filter: true,
        },

        {
          headerName: "Balance Amount",
          field: "balanceAmount",
          sortable: true,
          filter: true,
          cellRendererFramework: (params) => {
            const BalanceAmount = params.node.data.balanceAmount;

            if (BalanceAmount == null) {
              return "--";
            } else {
              return BalanceAmount;
            }
          },
        },

        {
          headerName: "View",
          field: "View",
          cellRendererFramework: (params) => {
            return (
              <RemoveRedEyeIcon
                onClick={() =>
                  this.onClickOpenViewReqModal(params.node.data.BMSID)
                }
              />
            );
          },
        },
        // {
        //     headerName: "Update", field: "Update",
        //     cellRendererFramework: (params) => {
        //         return <EditIcon onClick={() => this.onClickOpenEditModal(params.node.data.BMSID)} />
        //     }
        // }
      ],

      rowData: [],
      search: false,
      domLayout: "autoHeight",
      uniqueDateRanges: [],
      dateRangeSelected: "",

      tmsExportData: [],
      tripIds: "",
      isEditPageData: false,
      isViewRequestPageData: false,
      fileName: "",
      invalidFile: false,
      file: null,
      setFile: [],
      popupTableData: [],
      popupTableDataKeys: [],
      toggleTripModal: false,
      search_val: "",
      paymentHistory: [],
    };
  }

  componentDidMount = () => {
    axios
      .get(`${Config.hostName}/unique/locations`)
      .then((res) => {
        // console.log(res.data);

        this.setState({ unique_locationids: res.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeLocation = async (event) => {
    this.setState({ style: {}, unique_segments: [], unique_clients: [] });
    event.preventDefault();
    await this.setState({ Locationid: event.target.value });
    let { Locationid } = this.state;
    await axios
      .post(`${Config.hostName}/unique/clients`, {
        locationId: Locationid,
        clientName: "",
      })
      .then((res) => {
        // console.log(res.data);
        this.setState({
          unique_clients: res.data.data,
          style: { display: "none" },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateStatus = async (event, BMSID) => {
    const statusCode = event.target.value;
    const viewReqData = [];
    await this.state.locations.filter((item) => {
      if (item.BMSID == BMSID) {
        return viewReqData.push(item);
      }
    });

    const selectedRow = viewReqData[0];
    await axios
      .post(`${Config.hostName}/tms/updatePaymentDtails`, {
        BMSID: BMSID,
        payeeID: selectedRow.payeeID,
        paidamount: selectedRow.paidamount,
        utr: selectedRow.utr,
        remarks: selectedRow.remarks,
        userid: this.props.auth.user.USERID,
        statusid: statusCode,
      })
      .then((res) => {
        this.handleSearchShipment();
        this.props.setAlert("Status Updated", "success");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handlePageChange = async (pageNumber) => {
    // console.log(`active page is ${pageNumber}`);
    await this.setState({ activePage: pageNumber, style: {} });
    if (this.state.search === false) await this.componentWillMount();
    else await this.handleSearchShipment();
  };

  componentWillMount = async () => {
    let separator = "-";
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    this.props.setAlert("", "");
    const endDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
      }${separator}${date + 1}`;
    const startDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
      }${separator}${date}`;

    await this.setState({ search: false });
    let { activePage } = this.state;
    await this.getUniqueDateRanges();
    const result = await axios.post(
      `${Config.hostName}/tms/getPaymentDetails`,
      {
        startDate: null,
        endDate: null,
        locationID: null,
        BMSID: null,
      }
    );
    // console.log(result.data, "data");
    await this.setState({
      locations: result.data.data,
      tmsExportData: result.data.data,
    });
    await this.setState({
      style: { display: "none" },
      rowData: this.state.locations.map((eachItem) => {
        return {
          BMSID: eachItem.BMSID,
          PayeeCity: eachItem.payeeCity,
          inputNarration: eachItem.inputNarration,
          Project: eachItem.Project,
          Category: eachItem.category,
          ZonalMgr: eachItem.ZonalMgr,
          BeneficiaryName: eachItem.beneficiaryName,
          Requestedamount: eachItem.requestedamount,
          Paidamount: eachItem.paidamount,
          Requestedamount: eachItem.requestedamount,
          UTR: eachItem.utr,
          Modifiedby: eachItem.Modifiedby,
          Narration: eachItem.Narration,
          PayeeID: eachItem.payeeID,
          PayeeType: eachItem.payeeType,
          PaymentStatus: eachItem.paymentStatus,
          TicketStatus: eachItem.ticketStatus,
          balanceAmount: eachItem.balanceAmount,
          paymentHistory: eachItem.PaymentDetails,
        };
      }),
    });
    // console.log(this.state.locations);
  };

  handleCheckVerify = (index) => {
    // console.log(index);

    let selectedArray = this.state.locations;
    selectedArray.forEach((item) => {
      item.selected = false;
    });
    selectedArray[index].selected = !this.state.locations[index].selected;
    this.setState({
      locations: selectedArray,
    });
    // console.log(selectedArray[index]);
    this.setState({
      index: index,
    });
  };

  getUniqueDateRanges = async () => {
    await axios
      .get(
        `${Config.hostName}/shipment/get/data/dashboard/royaloak/unique/date-ranges`
      )
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            uniqueDateRanges: res.data.data,
            startDate: res.data.data.startDate,
            endDate: res.data.data.endDate,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleSearchShipment = async () => {
    try {
      // console.log(this.state.startDate, this.state.endDate)
      await this.setState({ style: {}, search: true });
      let result = await axios.post(
        `${Config.hostName}/tms/getPaymentDetails`,

        {
          startDate: this.state.startDate || null,
          endDate: this.state.endDate || null,
          locationID: this.state.Locationid,
          BMSID: this.state.search_val || null,
        }
      );
      // console.log(result.data);

      if (result.data.success) {
        console.log(result.data.data, "dataaa");
        await this.setState({
          style: { display: "none" },
          locations: result.data.data,
          tmsExportData: result.data.data,
          rowData: result.data.data.map((eachItem) => {
            return {
              BMSID: eachItem.BMSID,
              PayeeCity: eachItem.payeeCity,
              inputNarration: eachItem.inputNarration,
              Project: eachItem.Project,
              Category: eachItem.category,
              ZonalMgr: eachItem.ZonalMgr,
              BeneficiaryName: eachItem.beneficiaryName,
              Requestedamount: eachItem.requestedamount,
              Paidamount: eachItem.paidamount,
              Requestedamount: eachItem.requestedamount,
              UTR: eachItem.utr,
              Modifiedby: eachItem.Modifiedby,
              Narration: eachItem.Narration,
              PayeeID: eachItem.payeeID,
              PayeeType: eachItem.payeeType,
              PaymentStatus: eachItem.paymentStatus,
              TicketStatus: eachItem.ticketStatus,
              balanceAmount: eachItem.balanceAmount,
              paymentHistory: eachItem.PaymentHistory,
            };
          }),
        });
      } else {
        alert(result.data.message);
        await this.setState({
          style: { display: "none" },
          locations: [],
          tmsExportData: [],
        });
      }
    } catch (e) { }
  };

  handleAdvPay = (index, value) => {
    // console.log(index, value);
    let selectedArray = this.state.locations;

    selectedArray[index].AdvPay = value;
    this.setState({
      locations: selectedArray,
    });
    // console.log(selectedArray[index]);
  };

  handleRemarks = (index, value) => {
    // console.log(index, value);
    let selectedArray = this.state.locations;

    selectedArray[index].Remarks = value;
    this.setState({
      locations: selectedArray,
    });
    // console.log(selectedArray[index]);
  };

  onSubmit = async (e) => {
    e.preventDefault();
    // console.log(this.state.index, "index");

    // console.log(e.target.value);
    let data = e.target.value;

    // let result = this.state.locations[this.state.index]

    try {
      await this.setState({ style: {} });
      // eslint-disable-next-line
      const res = await axios
        .post(`${Config.hostName}/tms/verify`, {
          tripId: data,
          user: this.props.auth.user.name,
          isVerified: 1,
          comments: null,
          advPay: null,
          advPayDate: null,
        })
        .then(async (response) => {
          // console.log(response.data, "response");
          if (response.data.success === false) {
            this.props.setAlert(response.data.message, "danger");
          } else {
            await this.componentWillMount();
            await this.props.setAlert(`Approved Trip ${data} `, "success");
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
      }
    }
  };

  handleChangeDateRange = async (e) => {
    // console.log(e.target.value);
    let value = e.target.value;
    // console.log();2222
    if (value == 2222) {
      await this.setState({
        dateRangeSelected: e.target.value,
        startDate: "",
        endDate: "",
      });
      await this.toggle();
    } else {
      this.state.uniqueDateRanges.filter(async (eachDate, i) => {
        if (i == value) {
          // console.log("true", eachDate);
          await this.setState({
            dateRangeSelected: e.target.value,
            startDate: moment(eachDate.startDate).format("YYYY-MM-DD"),
            endDate: moment(eachDate.endDate).format("YYYY-MM-DD"),
          });
        }
      });
    }
  };

  viewReqModalFunction = async (BMSID) => {
    const viewReqData = [];
    await this.state.locations.filter((item) => {
      if (item.BMSID == BMSID) {
        return viewReqData.push(item);
      }
    });
    if (viewReqData.length > 0) {
      const selectedRow = viewReqData[0];
      console.log(selectedRow);
      await this.setState({
        BMSID,
        AccountNumber: selectedRow.AccountNumber,
        BillingMonth: selectedRow.BillingMonth,
        IFSC: selectedRow.IFSC,
        Project: selectedRow.Project,
        Status: selectedRow.Status,
        bankName: selectedRow.bankName,
        beneficiaryName: selectedRow.beneficiaryName,
        category: selectedRow.category,
        createddate: selectedRow.createddate,
        paidamount: selectedRow.paidamount,
        locationid: selectedRow.locationid,
        payeeCity: selectedRow.payeeCity,
        inputNarration: selectedRow.inputNarration,
        payeeID: selectedRow.payeeID,
        payeeType: selectedRow.payeeType,
        requestedamount: selectedRow.requestedamount,
        payeeID: selectedRow.payeeID,
        payeeID: selectedRow.payeeID,
        utr: selectedRow.utr,
        remarks: selectedRow.remarks,
        userid: selectedRow.userid,
        statusCode: selectedRow.statusCode,
        paymentHistory:
          selectedRow.PaymentDetails != null ? selectedRow.PaymentDetails : "",
      });
      if (this.state.paymentHistory != "") {
        let paymentDetails = `[${this.state.paymentHistory}]`;
        paymentDetails = JSON.parse(paymentDetails);
        await this.setState({
          paymentHistory: paymentDetails,
        });
      }
      console.log(this.state.paymentHistory);
      await this.toggleOpenViewRequestsModal();
    } else {
      await this.setState({
        style: { display: "none" },
      });
      await this.toggleOpenViewRequestsModal();
    }
  };

  editModalFunction = async (BMSID) => {
    const viewReqData = [];
    await this.state.locations.filter((item) => {
      if (item.BMSID == BMSID) {
        return viewReqData.push(item);
      }
    });
    if (viewReqData.length > 0) {
      const selectedRow = viewReqData[0];
      // if(selectedRow.Status == "Bill not Uploaded"){
      //     this.setState({oneStatus: "1"})
      // }else if(selectedRow.Status == "Bill Uploaded"){
      //     this.setState({oneStatus: "2"})
      // }else if(selectedRow.Status == "Verified"){
      //     this.setState({oneStatus: "3"})
      // }else if(selectedRow.Status == "Rejected"){
      //     this.setState({oneStatus: "4"})
      // }
      await this.setState({
        BMSID: selectedRow.BMSID,
        payeeID: selectedRow.payeeID,
        paidamount: selectedRow.paidamount,
        utr: selectedRow.utr,
        remarks: selectedRow.remarks,
        userid: selectedRow.userid,
        Status: selectedRow.Status,
      });
      await this.toggleOpenEditsModal();
    } else {
      await this.setState({
        style: { display: "none" },
      });
      await this.toggleOpenEditsModal();
    }
  };

  onSubmitUpdate = async (e) => {
    e.preventDefault();
    let statusss = this.state.Status;
    if (statusss == "Pending Verification") {
      statusss = 1;
    } else if (statusss == "Pending Approval") {
      statusss = 2;
    } else if (statusss == "Submitted to Accounts") {
      statusss = 3;
    } else if (statusss == "Submitted for MD Approval") {
      statusss = 4;
    } else if (statusss == "Submitted for Payment") {
      statusss = 5;
    } else if (statusss == "Need more Info") {
      statusss = 6;
    } else if (statusss == "Rejected") {
      statusss = 7;
    } else if (statusss == "Payment Done") {
      statusss = 8;
    } else if (statusss == "Partial Payment") {
      statusss = 9;
    } else if (statusss == "Resubmit") {
      statusss = 10;
    } else if (statusss == "Submitted For Finance") {
      statusss = 11;
    } else if (statusss == "Payment Processed") {
      statusss = 12;
    } else if (statusss == "RejectPayment Done WO GSTed") {
      statusss = 13;
    }
    try {
      // eslint-disable-next-line
      const res = await axios
        .post(`${Config.hostName}/tms/updatePaymentDtails`, {
          BMSID: this.state.BMSID,
          payeeID: this.state.payeeID,
          paidamount: this.state.paidamount,
          utr: this.state.utr,
          remarks: this.state.remarks,
          userid: this.props.auth.user.USERID,
          statusid: statusss,
        })
        .then((response) => {
          // console.log(response);
          // console.log('rjljlkj');
          if (response.data.name) {
            this.props.setAlert(response.data.parent.message, "danger");
          } else {
            this.setState({
              isEditPageData: !this.state.isEditPageData,
            });
            this.handleSearchShipment();
            this.props.setAlert("Updated", "success");
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });

      // console.log("Route added");
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
      }
    }
  };

  toggleCloseModal = async () => {
    await this.toggle();
    await this.setState({
      dateRangeSelected: "",
    });
  };

  toggleConfirmModal = async () => {
    await this.toggle();
    await this.setState({
      dateRangeSelected: 2222,
    });
  };

  toggleOpenViewRequestsModal = async () => {
    await this.setState({
      isViewRequestPageData: !this.state.isViewRequestPageData,
    });
  };

  toggleOpenEditsModal = async () => {
    // console.log("rohan");
    await this.setState({
      isEditPageData: !this.state.isEditPageData,
    });
  };

  onClickOpenViewReqModal = async (BMSID) => {
    await this.viewReqModalFunction(BMSID);
  };

  onClickOpenEditModal = async (BMSID) => {
    await this.editModalFunction(BMSID);
  };

  handleExportExcel = async (e) => {
    e.preventDefault();
    var workSheet = XLSX.utils.json_to_sheet(this.state.tmsExportData);
    var workBook = XLSX.utils.book_new();
    var wscols = [
      { wch: 6 },
      { wch: 35 },
      { wch: 15 },
      { wch: 15 },
      { wch: 35 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    // workSheet['!cols'] = wscols;
    XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet 1");
    XLSX.writeFile(workBook, "BMS_PAYEE_Report" + ".xlsx");
  };

  async newwwfiles(files) {
    var f = files[0];
    const promise = new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws);
        resolve(dataParse);
      };
      // console.log(readedData);
      reader.readAsBinaryString(f);
    });

    promise.then(async (d) => {
      this.setState(
        {
          popupTableData: d,
        },
        async () => {
          const data = this.state.popupTableData;
          const keys = Object.keys(data[0]);
          this.toggleOpenTripModal();
          const dataExcel = this.state.popupTableData;
          await dataExcel.map(async (e) => {
            const excelSerialNumber = e.Date;
            const paymentDate = e.paymentDate;
            const date = new Date((excelSerialNumber - 25569) * 24 * 60 * 60 * 1000); // Convert Excel serial number to Unix timestamp
            const paymentDate2 = new Date((paymentDate - 25569) * 24 * 60 * 60 * 1000); // Convert Excel serial number to Unix timestamp

            const monthYearString = date.toLocaleDateString("en-US", { month: "2-digit", year: "numeric", day: "2-digit" });
            e.Date = monthYearString.replace(/\//g, '-');

            const monthYearString2 = paymentDate2.toLocaleDateString("en-US", { month: "2-digit", year: "numeric", day: "2-digit" });
            e.paymentDate = monthYearString2.replace(/\//g, '-');
          })
          this.setState({
            popupTableData: dataExcel,
            popupTableDataKeys: keys,
            style: {
              display: "none",
            },
          });

        }
      );
    });
  }

  handleFileChange = this.handleFileChange.bind(this);
  async handleFileChange({ target: { files } }) {
    const cancel = !files.length;
    if (cancel) return;

    const [{ size, name }] = files;
    const maxSize = 50000;
    var lastThree = name.substr(name.length - 3);
    var lastFour = name.substr(name.length - 4);
    if (lastFour == "xlsx" || lastThree == "xls") {
      if (size < maxSize) {
        this.setState({ fileName: name, invalidFile: false });
        this.setState({
          popupTableData: [],
          popupTableDataKeys: [],
          style: {},
        });
        await this.newwwfiles(files);
      } else {
        this.setState({ fileName: "", invalidFile: true });
        // console.log("dddd");
        // console.log(files);
      }
    } else {
      this.setState({ fileName: "", invalidFile: true });
      this.props.setAlert("Please Select Excel File", "danger");
    }
  }

  updateData = async (event) => {
    event.preventDefault();
    // await this.setState({ Locationid: event.target.value });
    // let { setFile } = this.state;
    let records = 0;
    await this.state.popupTableData.map((eachItem) => {
      var BMSID = eachItem.BMSID;
      var accnumber = eachItem.BeneficiaryAccountNo;
      var ifsc = eachItem.Bene_IFSC_Code;
      var paidamount = eachItem.Amount;
      var utr = eachItem.UTR;
      var remarks = eachItem.Remarks;
      var narration = eachItem.CreditNarration;
      var userid = this.props.auth.user.USERID;

      var payeeID = eachItem.payeeID;
      var isAlternateAcct = eachItem.isAlternateAcct;
      var Date = eachItem.Date;
      var BeneficiaryName = eachItem.BeneficiaryName;
      var BeneficiaryAccountNo = eachItem.BeneficiaryAccountNo;
      var Bene_IFSC_Code = eachItem.Bene_IFSC_Code;
      var Amount = eachItem.Amount;
      var CreditNarration = eachItem.CreditNarration;
      var STATUS = eachItem.STATUS;
  

      {
        BMSID == undefined ? (BMSID = "") : (BMSID = BMSID);
      }
      {
        accnumber == undefined ? (accnumber = "") : (accnumber = accnumber);
      }
      {
        ifsc == undefined ? (ifsc = "") : (ifsc = ifsc);
      }
      {
        paidamount == undefined ? (paidamount = "") : (paidamount = paidamount);
      }
      {
        utr == undefined ? (utr = "") : (utr = utr);
      }
      {
        remarks == undefined ? (remarks = "") : (remarks = remarks);
      }
      {
        narration == undefined ? (narration = "") : (narration = narration);
      }
      {
        userid == undefined ? (userid = "") : (userid = userid);
      }

      {
        payeeID == undefined ? (payeeID = "") : (payeeID = payeeID);
      }
      {
        isAlternateAcct == undefined
          ? (isAlternateAcct = "")
          : (isAlternateAcct = isAlternateAcct);
      }

      {
        Date == undefined ? (Date = "") : (Date = Date);
      }
      {
        BeneficiaryName == undefined
          ? (BeneficiaryName = "")
          : (BeneficiaryName = BeneficiaryName);
      }
      {
        BeneficiaryAccountNo == undefined
          ? (accnumber = "")
          : (accnumber = BeneficiaryAccountNo);
      }
      {
        Bene_IFSC_Code == undefined ? (ifsc = "") : (ifsc = Bene_IFSC_Code);
      }
      {
        Amount == undefined ? (paidamount = "") : (paidamount = Amount);
      }
      {
        CreditNarration == undefined
          ? (narration = "")
          : (narration = CreditNarration);
      }
      {
        STATUS == undefined ? (STATUS = "") : (STATUS = STATUS);
      }


      axios
        .post(`${Config.hostName}/tms/updatePaymentDtailsBluckUpload`, {
          BMSID: BMSID,
          accnumber: accnumber,
          ifsc: ifsc,
          paidamount: paidamount,
          utr: utr,
          remarks: remarks,
          narration: narration,
          userid: userid,
          payeeID: payeeID,
          isAlternateAcct: isAlternateAcct,
          paymentDate: Date
        })
        .then((res) => { })
        .catch((err) => {
          console.log(err);
        });
      records = records + 1;
    });
    alert(records + " Records Updated");
    this.toggleCloseTripModal();
    await this.setState({
      fileName: "Upload New File",
      popupTableData: [],
      popupTableDataKeys: [],
      invalidFile: false,
    });
    await this.componentWillMount();

    this.props.setAlert(records + " Records Updated", "success");
  };

  toggleOpenTripModal = async () => {
    await this.setState({
      toggleTripModal: true,
    });
  };

  toggleCloseTripModal = async () => {
    await this.setState({
      toggleTripModal: false,
    });
  };

  uploadErr = async () => {
    this.props.setAlert("Please Select Excel File First", "danger");
  };

  render() {
    let {
      style,
      modal,
      isEditPageData,
      isViewRequestPageData,
      Status,
      invalidFile,
      fileName,
      toggleTripModal,
      popupTableDataKeys,
      popupTableData,
      PaymentHistory,
    } = this.state;
    const defaultColDef = {
      flex: 1,
      minWidth: 100,
      // height: 100,
      resizable: true,
    };

    // const getRowHeight = {
    //     getRowHeight: 50
    // }

    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "user"
    )
      return <Redirect to="/dashboard" />;
    else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.userType === "staff"
    )
      return <Redirect to="/staff/notice" />;
    else if (!this.props.auth.isAuthenticated)
      return <Redirect to="/user/login" />;

    return (
      <div>
        <div id="cover-spin" style={style}></div>
        {/* <Breadcrumb link="ImportantDates" parent="Admin" /> */}
        <div className="container-fluid">
          <div className="edit-profile">
            <div className="row ">
              <div className="col-xl-12">
                <Alert />

                <nav
                  className="navbar navbar-light "
                  style={{ backgroundColor: "none" }}
                >
                  <div
                    className="row"
                    style={{ paddingTop: "8px", width: "100%" }}
                  >
                    <div className="col-sm-3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="dateRange"
                          value={this.state.dateRangeSelected}
                          id="dateRange"
                          onChange={this.handleChangeDateRange}
                        >
                          <option
                            className="bms-dashboaord-date-range"
                            value={""}
                            disabled
                          >
                            Select Date Range
                          </option>

                          {this.state.uniqueDateRanges.map((eachItem, i) => (
                            <option value={i} key={i}>
                              {eachItem.title}
                            </option>
                          ))}
                          <option value={2222}>
                            {/* <Button onClick={this.toggle}> */}
                            Custom Date Range
                            {/* </Button> */}
                          </option>
                          <div>
                            <Modal
                              isOpen={modal}
                            // toggle={this.toggle}
                            >
                              <ModalHeader>
                                Select Start and End date
                              </ModalHeader>
                              <ModalBody>
                                <div
                                  className="row"
                                  style={{ minHeight: "1px" }}
                                >
                                  <div className="col float-left">
                                    <div
                                      className="cal-datepicker"
                                      style={{ height: "100%" }}
                                    >
                                      <div
                                        className="datepicker-here text-center"
                                        style={{ height: "100%" }}
                                        data-language="en"
                                      >
                                        <DatePicker
                                          height="400"
                                          placeholderText="Select Start Date"
                                          id="startDateInput"
                                          inline
                                          selectsRange
                                          selected={this.state.startDate}
                                          startDate={this.state.startDate}
                                          onChange={(date) => {
                                            this.setState({
                                              startDate: date,
                                            });
                                          }}
                                          dateFormat="yyyy-MM-dd"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col float-left">
                                    <div className="cal-datepicker">
                                      <div
                                        className="datepicker-here text-center"
                                        data-language="en"
                                      >
                                        <DatePicker
                                          height="400"
                                          id="endDateInput"
                                          inline
                                          placeholderText="Select End Date"
                                          selected={this.state.endDate}
                                          onChange={(date) => {
                                            this.setState({
                                              endDate: date,
                                            });
                                          }}
                                          dateFormat="yyyy-MM-dd"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="info"
                                  onClick={this.toggleConfirmModal}
                                >
                                  Confirm
                                </Button>{" "}
                                <Button
                                  color="danger"
                                  onClick={this.toggleCloseModal}
                                >
                                  Close
                                </Button>{" "}
                              </ModalFooter>
                            </Modal>
                          </div>
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-3">
                      <FormGroup>
                        {/* <Label>Location ID</Label> */}
                        <Input
                          type="select"
                          name="locationid"
                          value={this.state.Locationid}
                          id="locationid"
                          onChange={this.changeLocation}
                        >
                          <option value={""} disabled selected>
                            Select Location
                          </option>

                          {this.state.unique_locationids.map((locationid) => (
                            <option value={locationid.Locationid}>
                              {locationid.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-3">
                      <FormGroup>
                        <Input
                          type="text"
                          name="search_val"
                          value={this.state.search_val}
                          // style={{ borderColor: "#FF4848" }}
                          style={{
                            fontFamily:
                              "Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif",
                            fontVariantNumber: "tabular-nums",
                          }}
                          id="search_val"
                          placeholder="BMSID"
                          onChange={(e) => {
                            this.setState({ search_val: e.target.value });
                          }}
                        ></Input>
                      </FormGroup>
                    </div>

                    <div className="col-sm-2">
                      <button
                        // type="submit"
                        // className="btn btn-secondary"
                        className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                        onClick={this.handleSearchShipment}
                      >
                        Search
                      </button>
                    </div>

                    <div className="col-sm-5">
                      <FormGroup>
                        <CustomInput
                          type="file"
                          id="exampleCustomFileBrowser"
                          name="customFile"
                          label={fileName || "Upload File"}
                          onChange={this.handleFileChange}
                          invalid={invalidFile}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-sm-2">
                      <button
                        // type="submit"
                        // className="btn btn-secondary"
                        className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                        onClick={this.uploadErr}
                      >
                        Upload Excel File
                      </button>
                    </div>

                    <div className="col-sm-2">
                      <a target="_blank" href="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1687195761987_BMS_Payee_Bulk_Template.xlsx">
                        <button
                          // type="submit"
                          // className="btn btn-secondary"
                          className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                          onClick={this.uploadErr}
                        >
                          Download template
                        </button>
                      </a>
                    </div>

                    <div className="col-sm-2">
                      {this.state.tmsExportData.length > 0 && (
                        // <CSVLink data={this.state.exportData}>
                        <button
                          className={
                            !this.state.tmsExportData.length > 0 &&
                              !this.state.tmsExportData.length > 0
                              ? "btn btn-danger w-100 overflow-hidden mb-3 px-0 disabled"
                              : "btn btn-danger w-100 overflow-hidden mb-3 px-0"
                          }
                          onClick={this.handleExportExcel}
                          disabled={
                            !this.state.tmsExportData &&
                            !this.state.tmsExportData.length > 0
                          }
                        >
                          <i
                            className="fa fa-file-excel-o"
                            style={{ marginRight: "5px" }}
                          />
                          Export
                        </button>
                        // </CSVLink>
                      )}
                    </div>
                  </div>
                </nav>

                <div className="card-body">
                  <div
                    className="ag-theme-balham"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <AgGridReact
                      columnDefs={this.state.columnDefs}
                      rowData={this.state.rowData}
                      defaultColDef={defaultColDef}
                      onGridReady={this.onGridReady}
                      // rowStyle={{ background: 'yellow' }}
                      enableCellTextSelection={true}
                      gridAutoHeight={true}
                      domLayout={this.state.domLayout}
                      rowHeight={45}
                      // enableCellTextSelection={true}
                      suppressAutoSize={true}
                    // getRowHeight={getRowHeight}
                    // getRowStyle={(params) => {
                    //     return {
                    //         background: (params.data.IsVerified === true) ? "#bbf58c" :
                    //             (params.data.IsVerified === false) ? "#f7a681" :
                    //                 "white"
                    //     }
                    // }}
                    ></AgGridReact>
                  </div>
                  <div
                    style={{
                      margin: "9vh auto",
                      marginLeft: "25%",
                      justifyContent: "center",
                    }}
                  >
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.itemsCountPerPage}
                      totalItemsCount={this.state.totalItemsCount}
                      pageRangeDisplayed={this.state.pageRangeDisplayed}
                      onChange={this.handlePageChange.bind(this)}
                      prevPageText="prev"
                      nextPageText="next"
                      firstPageText="first"
                      lastPageText="last"
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>

                <div>
                  <Modal
                    size="lg"
                    style={{ maxWidth: "1600px", width: "80%" }}
                    centered={true}
                    isOpen={isViewRequestPageData}
                  >
                    <ModalFooter>
                      <div className="col-sm-2">
                        <span
                          className="bms-view-req-modal-header-label"
                          style={{
                            fontFamily:
                              "Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif",
                            fontVariantNumber: "tabular-nums",
                          }}
                        >
                          BMS ID : {this.state.BMSID}
                        </span>
                      </div>
                      <div className="col-sm-3">
                        <span
                          className="bms-view-req-modal-header-label"
                          style={{
                            fontFamily:
                              "Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif",
                            fontVariantNumber: "tabular-nums",
                          }}
                        >
                          Project :{this.state.Project}
                        </span>
                      </div>

                      <div className="col-sm-4">
                        <span
                          className="bms-view-req-modal-header-label"
                          style={{
                            fontFamily:
                              "Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif",
                            fontVariantNumber: "tabular-nums",
                          }}
                        >
                          BillingMonth : {this.state.BillingMonth}
                        </span>
                      </div>

                      <div className="col-sm-2">
                        <Button
                          color="danger"
                          onClick={this.toggleOpenViewRequestsModal}
                        >
                          <CloseIcon />
                        </Button>{" "}
                      </div>
                    </ModalFooter>
                    <ModalBody>
                      <div className="bms-view-req-modal-body">
                        <div>
                          <div className="row bmsViewReqNewCardsForStatusMain">
                            <div className="row bmsViewReqNewCardsForStatusFirst">
                              <div className="row">
                                <span className="bms-view-request-attachments">
                                  Payment History:{" "}
                                </span>
                                <span
                                  className="bms-view-request-attachments"
                                  style={{
                                    color:
                                      Status == "Pending Verification" ||
                                        Status == "Rejected"
                                        ? "red"
                                        : "green",
                                  }}
                                >
                                  {this.state.Status}
                                </span>
                                <br />
                              </div>

                              <div className="">
                                <div className="row">
                                  {this.state.paymentHistory != null && (
                                    <Table
                                      responsive
                                      className="table table-striped table-light"
                                    >
                                      <thead>
                                        <tr className="styled-table-header-row">
                                          <th>Beneficary</th>
                                          <th>Account Number</th>
                                          <th>IFSC</th>
                                          <th>Is Alternate</th>
                                          <th>Paid Amount</th>
                                          <th>Utr</th>
                                          <th>Payment Processed date</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.paymentHistory != null &&
                                          Object.values(
                                            this.state.paymentHistory
                                          ).map((note, i) => (
                                            <tr className="styled-table-row">
                                              <td>{note.Beneficiary}</td>
                                              <td>{note.accnumber}</td>
                                              <td>{note.ifsc}</td>
                                              <td>
                                                {note.isAlternateAcct == 0
                                                  ? "No"
                                                  : " Yes "}
                                              </td>
                                              <td>{note.paidamount}</td>
                                              <td>{note.utr}</td>
                                              <td>{note.updatedDate}</td>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </Table>
                                  )}
                                  {this.state.paymentHistory == null ||
                                    (this.state.paymentHistory == "" && (
                                      <h4 style={{ fontFamily: "system-ui" }}>
                                        No Payment Recorded yet
                                      </h4>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>
                </div>

                <div>
                  {toggleTripModal && (
                    <Modal
                      size="lg"
                      style={{ maxWidth: "1600px", width: "80%" }}
                      centered={true}
                      isOpen={toggleTripModal}
                    >
                      <ModalFooter>
                        <div className="col-sm-3">
                          <span className="bms-view-req-modal-header-label">
                            Update Data
                          </span>
                        </div>

                        <div className="col-sm-3 ml-auto">
                          <div className="row">
                            <div className="col-sm-6">
                              {/* <button
                                                            className={
                                                                (!this.state.popupTableData.length > 0 &&
                                                                    !this.state.popupTableData.length > 0) ?
                                                                    'btn btn-danger w-100 overflow-hidden mb-3 px-auto disabled' :
                                                                    'btn btn-danger w-100 overflow-hidden mb-3 px-auto'}
                                                            onClick={this.handleExportExcelTripSearch}
                                                            disabled={!this.state.popupTableData && !this.state.popupTableData.length > 0}
                                                        >
                                                            <i
                                                                className="fa fa-download"
                                                                style={{ marginRight: "5px" }}
                                                            />
                                                            Export
                                                        </button> */}
                              <Button color="danger" onClick={this.updateData}>
                                <i
                                  className="fa fa-upload"
                                  style={{ marginRight: "5px" }}
                                />
                                Update
                              </Button>{" "}
                            </div>

                            <div className="col-sm-6">
                              <Button
                                color="danger"
                                onClick={this.toggleCloseTripModal}
                              >
                                <CloseIcon />
                              </Button>{" "}
                            </div>
                          </div>
                        </div>
                      </ModalFooter>
                      <ModalBody>
                        <div className="bms-view-req-modal-body">
                          <div>
                            <Table responsive className="styled-table">
                              <thead>
                                <tr className="styled-table-header-row">
                                  {popupTableDataKeys.map((headItem) => {
                                    if (headItem == 'Date') {
                                      return <th style={{ width: '20px' }}>{headItem}</th>;
                                    } else {
                                      return <th >{headItem}</th>;
                                    }

                                  })}
                                </tr>
                              </thead>
                              <tbody>
                                {popupTableData.map((item) => (
                                  <tr className="styled-table-row">
                                    {popupTableDataKeys.map((hkey) => {
                                      return (
                                        <td>{item[hkey] ? item[hkey] : "-"}</td>
                                      );
                                    })}
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </ModalBody>
                    </Modal>
                  )}
                </div>

                <div>
                  <Modal
                    size="lg"
                    style={{ maxWidth: "1600px", width: "80%" }}
                    centered={true}
                    isOpen={isEditPageData}
                  >
                    <ModalFooter>
                      <div className="col-sm-2">
                        <Button
                          color="danger"
                          onClick={this.toggleOpenEditsModal}
                        >
                          <CloseIcon />
                        </Button>{" "}
                      </div>
                    </ModalFooter>
                    <ModalBody>
                      <div className="bms-view-req-modal-body">
                        <div>
                          <div className="row bmsViewReqNewCardsForStatusMain">
                            <div className="row bmsViewReqNewCardsForStatusFirst">
                              <div className="row">
                                <span className="bms-view-request-attachments">
                                  Update BMSID: {this.state.BMSID}
                                </span>
                                <br />
                              </div>

                              <div className="row bmsViewReqFourthRowGallery">
                                <form style={{ width: "100%" }}>
                                  <div
                                    className="col-lg-12 text-right"
                                    style={{ marginTop: "0vh" }}
                                  ></div>
                                  <div className="col-lg-12">
                                    <div className="grid">
                                      <div className="grid-body">
                                        <div className="item-wrapper">
                                          <div className="row mb-3">
                                            <div className="col-md-12 mx-auto">
                                              <div className="form-row">
                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>BMSID:</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      type="number"
                                                      name="BMSID"
                                                      className="form-control"
                                                      readOnly
                                                      // valuedefault={this.state.intentno}
                                                      value={this.state.BMSID}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Payee ID *</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter PayeeID"
                                                      type="number"
                                                      name="payeeID"
                                                      min={0}
                                                      className="form-control"
                                                      value={this.state.payeeID}
                                                      onChange={(event) => {
                                                        this.setState({
                                                          payeeID:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="form-row">
                                                <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>
                                                      Paid Amount *{" "}
                                                    </label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter Paid Amount"
                                                      type="number"
                                                      min={0}
                                                      name="paidamount"
                                                      className="form-control"
                                                      value={
                                                        this.state.paidamount
                                                      }
                                                      onChange={(event) => {
                                                        this.setState({
                                                          paidamount:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>UTR *</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <input
                                                      placeholder="Enter UTR."
                                                      type="number"
                                                      min={0}
                                                      name="utr"
                                                      className="form-control"
                                                      value={this.state.utr}
                                                      onChange={(event) => {
                                                        this.setState({
                                                          utr:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Status*</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <Input
                                                      type="select"
                                                      name="Status"
                                                      id="Status"
                                                      onChange={(event) => {
                                                        this.setState({
                                                          Status:
                                                            event.target.value,
                                                        });
                                                      }}
                                                    >
                                                      <option
                                                        value="1"
                                                        selected={
                                                          this.state.Status ==
                                                          "Pending Verification"
                                                        }
                                                      >
                                                        Pending Verification
                                                      </option>

                                                      <option
                                                        value="2"
                                                        selected={
                                                          this.state.Status ==
                                                          "Pending Approval"
                                                        }
                                                      >
                                                        Pending Approval
                                                      </option>

                                                      <option
                                                        value="3"
                                                        selected={
                                                          this.state.Status ==
                                                          "Submitted to Accounts"
                                                        }
                                                      >
                                                        Submitted to Accounts
                                                      </option>

                                                      <option
                                                        value="4"
                                                        selected={
                                                          this.state.Status ==
                                                          "Submitted for MD Approval"
                                                        }
                                                      >
                                                        Submitted for MD
                                                        Approval
                                                      </option>

                                                      <option
                                                        value="5"
                                                        selected={
                                                          this.state.Status ==
                                                          "Submitted for Payment"
                                                        }
                                                      >
                                                        Submitted for Payment
                                                      </option>

                                                      <option
                                                        value="6"
                                                        selected={
                                                          this.state.Status ==
                                                          "Need more Info"
                                                        }
                                                      >
                                                        Need more Info
                                                      </option>

                                                      <option
                                                        value="7"
                                                        selected={
                                                          this.state.Status ==
                                                          "Rejected"
                                                        }
                                                      >
                                                        Rejected
                                                      </option>

                                                      <option
                                                        value="8"
                                                        selected={
                                                          this.state.Status ==
                                                          "Payment Done"
                                                        }
                                                      >
                                                        Payment Done
                                                      </option>

                                                      <option
                                                        value="9"
                                                        selected={
                                                          this.state.Status ==
                                                          "Partial Payment"
                                                        }
                                                      >
                                                        Partial Payment
                                                      </option>

                                                      <option
                                                        value="10"
                                                        selected={
                                                          this.state.Status ==
                                                          "Resubmit"
                                                        }
                                                      >
                                                        Resubmit
                                                      </option>

                                                      <option
                                                        value="11"
                                                        selected={
                                                          this.state.Status ==
                                                          "Submitted For Finance"
                                                        }
                                                      >
                                                        Submitted For Finance
                                                      </option>

                                                      <option
                                                        value="12"
                                                        selected={
                                                          this.state.Status ==
                                                          "Payment Processed"
                                                        }
                                                      >
                                                        Payment Processed
                                                      </option>

                                                      <option
                                                        value="13"
                                                        selected={
                                                          this.state.Status ==
                                                          "Payment Done WO GST"
                                                        }
                                                      >
                                                        Payment Done WO GST
                                                      </option>
                                                    </Input>
                                                  </div>
                                                </div>

                                                <div className="form-group col-md-6 row showcase_row_area">
                                                  <div className="col-md-3 showcase_text_area">
                                                    <label>Remarks*</label>
                                                  </div>
                                                  <div className="col-md-9 showcase_text_area">
                                                    <AvForm>
                                                      {/* <div className="col-md-12"> */}
                                                      <AvField
                                                        name="Remarks"
                                                        value={
                                                          this.state.remarks
                                                        }
                                                        onChange={(e) => {
                                                          this.setState({
                                                            remarks: e.target.value.replace(
                                                              /[^\w\s]/gi,
                                                              ""
                                                            ),
                                                          });
                                                        }}
                                                        type="textarea"
                                                        placeholder="Remarks"
                                                      />
                                                      {/* </div> */}
                                                    </AvForm>
                                                  </div>
                                                </div>
                                              </div>
                                              <button
                                                type="submit"
                                                className="btn btn-outline-primary btn-lg"
                                                style={{ float: "right" }}
                                                onClick={(e) =>
                                                  this.onSubmitUpdate(e)
                                                }
                                              >
                                                Update
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomePage.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(HomePage);
