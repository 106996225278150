import axios from "axios";
import { setAlert } from "./alert.actions";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  // USER_LOADED,
  // AUTH_ERROR,
  LOGIN_SUCCESS,
  CLEAR_DETAILS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ALERTS
} from "../constants/actionTypes";
// import setAuthToken from "../../utils/setAuthToken";
// import {clearApplication} from "./application";
// import {clearAlert} from "./alert.actions";
import Config from "../../config"
// Load User

// Register User
export const register = (
  name,
  email,
  password,
  phone,
  dob,
  userType = "user"
) => dispatch => {
  axios
    .post(`${Config.hostName}/api/user/signup`, {
      name: name,
      email: email,
      password: password,
      phone: phone,
      dob: dob,
      userType
    })
    .then(
      res => {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data
        });
      },
      error => {
        const errors = error.response.data.errors;
        console.log(error);

        if (errors) {
          errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
        }

        dispatch({
          type: REGISTER_FAIL
        });
      }
    );
};

// Login User
export const login = (email, password) => async dispatch => {
  // console.log(email, password);

  // if (email == 'Billing_test@logicarts.in') {
  //   dispatch({
  //     type: LOGIN_FAIL
  //   });
  // } else {
    try {
      const res = await axios.post(`${Config.hostName}/user/login`, {
        email,
        password
      });
      // console.log(res.data, "res.data");
      const payloadData = res.data.data[0];
      if (res.data.success) {
        if (payloadData['isSystemAccess'] == 0) {
          dispatch({
            type: LOGIN_FAIL
          });
          return dispatch({
            type: LOGIN_FAIL,
            isSystemAccess: payloadData['isSystemAccess'],
          });
        } else {
          return dispatch({
            type: LOGIN_SUCCESS,
            payload: payloadData,
            isSystemAccess: payloadData['isSystemAccess']
          });
        }
      }
      else {
        return dispatch({
          type: LOGIN_FAIL
        });
      }


    } catch (err) {

      dispatch({
        type: LOGIN_FAIL
      });
    }
  // }


};



// Logout / Clear Profile
export const logout = () => dispatch => {
  dispatch({ type: CLEAR_DETAILS });
  dispatch({ type: CLEAR_ALERTS });
  dispatch({ type: LOGOUT });
};
