import React, {
  Component,
  // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";
import { FormGroup, Label, Input } from "reactstrap";

// import {Link} from "react-router-dom";
// import { CSVLink } from "react-csv";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumb from "../../../common/breadcrumb.component";
// import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import "./layout.css";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Autocomplete, Snackbar, CardContent, Alert, Toolbar, AppBar, Dialog, Slide, Tooltip, ListItemText, OutlinedInput, FormControlLabel, Backdrop, Fade, Modal, Chip, Stack, Box, Checkbox, IconButton, Grid, Container, Typography, Avatar, Badge, TextField, Card, CardMedia, Button, FormControl, MenuItem, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

import XLSX from "xlsx";
import moment from "moment";

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import GetAppIcon from "@mui/icons-material/GetApp";
import CloseIcon from "@mui/icons-material/Close";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import SendIcon from "@mui/icons-material/Send";
import pdfIcon from "../../../../assets/customImages/pdfIcon.png";

import MUIDataTable from "mui-datatables";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import MuiAlert from "@material-ui/lab/Alert";
import { saveAs } from 'file-saver';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function AlertSnack(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FileSaver = require("file-saver");

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const style6 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "406px",
    },
  },
};

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();

const today = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;

const options = {
  filterType: "multiselect",
  // filterOptions: {fullWidth: true},
  selectableRows: "none",
  setRowProps: (row) => {
    if (row[0] === "New") {
      return {
        style: { background: "snow" },
      };
    }
  },
  draggableColumns: { enabled: true },
  pagination: true,
  // resizableColumns: true,
  // responsive: "standard",
  fixedHeader: false,
  downloadOptions: {
    filterOptions: {
      useDisplayedRowsOnly: true,
    },
  },
};

const options2 = {
  filterType: "multiselect",
  // filterOptions: {fullWidth: true},
  rowsPerPageOptions: [5, 10, 15],
  selectableRows: "none",
  setRowProps: (row) => {
    if (row[0] === "New") {
      return {
        style: { background: "snow" },
      };
    }
  },
  draggableColumns: { enabled: true },
  pagination: true,
  // resizableColumns: true,
  // responsive: "standard",
  fixedHeader: false,
  downloadOptions: {
    filterOptions: {
      useDisplayedRowsOnly: true,
    },
  },
};

class ManageNewRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},

      options: {
        filterType: "multiselect",
        // filterOptions: {fullWidth: true},
        selectableRows: "none",
        setRowProps: (row) => {
          if (row[0] === "New") {
            return {
              style: { background: "snow" },
            };
          }
        },
        draggableColumns: { enabled: true },
        pagination: true,
        // resizableColumns: true,
        // responsive: "standard",
        fixedHeader: false,
        downloadOptions: {
          filterOptions: {
            useDisplayedRowsOnly: true,
          },
        },
        onDownload: (buildHead, buildBody, columns, data) => {

          const columns2 = Object.keys(this.state.rowData[0]);
          const data2 = this.state.rowData.map((row) => {
            return Object.values(row);
          });

          // console.log(columns2);
          // console.log(data2, 'data');

          const result = this.state.rowData.reduce((acc, curr) => {
            return acc;
          }, {});




          const worksheet = XLSX.utils.aoa_to_sheet([columns2, ...data2]);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, `All-${data2.length}`);

          for (const key in result) {
            // console.log(`Key: ${key}`);
            const array = result[key];
            // console.log(result[key]);

            let data3 = result[key].map((row) => {
              return Object.values(row);
            });

            let worksheet1 = XLSX.utils.aoa_to_sheet([columns2, ...data3]);
            XLSX.utils.book_append_sheet(workbook, worksheet1, `${key}-${array.length}`);
          }

          const objectDate = new Date();
          const day = objectDate.getDate();
          const year = objectDate.getFullYear();
          const dateObj = new Date();
          const month = moment().subtract(0, "month").startOf("month").format("MMMM");

          XLSX.writeFile(workbook, `EMS-Export-${day} ${month} ${year}.xlsx`);
          return false;
        },
      },
      selectedDate: new Date(),
      unique_cities: [],
      snackBar: false,
      vertical: "top",
      horizontal: "center",
      locationId: "",
      unique_project: [],
      projectName: [],
      projectID: [],
      unique_reportTo: [],
      reportTo: [],
      unique_departments: [],
      department: "",
      unique_designations: [],
      designation: "",
      unique_status: [],
      status: "",
      useridDashboard: "",
      userType: "",
      unique_access: [],
      access: [],
      unique_geofence: [],
      geofence: [],
      index: "",
      profile: "",
      fullName: "",
      fullNameValidate: false,
      mobile: "",
      mobileValidate: false,
      email: "",
      permanentDisable: false,
      doj: today,
      employeeType: "",
      personName: [],
      employmentType: "",
      bankName: "",
      bankNameValidate: false,
      aadhar_num: "",
      aadhar_num_validate: false,
      pan_num: "",
      pan_num_validate: false,
      accountNo: "",
      accountNoValidate: false,
      confirmAccountNo: "",
      confirmAccountNoValidate: "",
      accountNoColor: "primary",
      accFocused: false,
      ifsc: "",
      ifscValidate: false,
      panNo: "",
      panNoValidate: false,
      pancardImage: "",
      addPancardImage: [],
      open: false,
      dob: today,
      dobValidate: false,
      panImageText: "Change",
      color: "addColorBlue",
      addProfileAttachments: [],
      monthlyCTC: "",
      inHand: false,
      pfNo: "",
      uanNo: "",
      esicNo: "",
      bloodGroup: "",
      aadharFrontText: "Change",
      aadharFrontcolor: "addColorBlue",
      aadharFrontImage: "",
      aadharBackText: "Change",
      aadharBackcolor: "addColorBlue",
      aadharBackImage: "",
      chequeText: "Change",
      chequecolor: "addColorBlue",
      chequeImage: "",
      modalImage: "",
      idCard: "",
      passbookURL: "",
      cnlChequeURL: "",
      openAlert: false,
      terminateDateUI: false,
      terminateDate: "",
      OtherDeductions: "",
      GrossSalary: "",
      geofenceAlertDisplay: "none",
      geofenceAlertBorder: "2px solid #fff",
      openPayslipModal: false,
      reportingManager: 0,
      rowData: [],
      rowPayslipData: [],
      rmModal: false,
      rmCount: 0,
      rmNames: '',
      columns: [
        {
          name: "FullName",
          label: "Profile Pic / Full Name",
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>
                  <div className="user-pic">
                    <Avatar
                      onClick={(e) => {
                        this.handleOpenModal(
                          this.state.rowData[dataIndex].Picture,
                          e
                        );
                      }}
                      className="profilePic"
                      src={this.state.rowData[dataIndex].Picture}
                      style={{ float: "left" }}
                      alt="E"
                      sx={{ width: 38, height: 38 }}
                    />
                    <div style={{ marginLeft: "51px", paddingTop: "8px" }}>
                      {this.state.rowData[dataIndex].FullName}
                    </div>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "EmployeeID",
          label: "Employee ID",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "Designation",
          label: "Designation",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "WorkingBranch",
          label: "Working Branch",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "isOnroll",
          label: "Onrole",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              var newsss = this.state.rowData[dataIndex].isOnroll;
              var onRole = "No";
              var colorNew = "primary";
              if (newsss == "1") {
                colorNew = "primary";
                onRole = "Yes";
              }
              return (
                <Chip
                  style={{ borderRadius: "4px", height: "25px" }}
                  label={onRole}
                />
              );
            },
          },
        },
        {
          name: "DoB",
          label: "Date of Birth",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "DoJ",
          label: "Date of Joining",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "Status",
          label: "Status",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const statusName = this.state.rowData[dataIndex].Status;
              var colorNew = "";
              if (statusName === "Verified") {
                return (
                  <Chip
                    color="success"
                    style={{
                      backgroundColor: "#3c9b41",
                      borderRadius: "4px",
                      height: "25px",
                    }}
                    label={this.state.rowData[dataIndex].Status}
                  />
                );
              } else if (statusName === "Profile Updated") {
                return (
                  <Chip
                    color="primary"
                    style={{
                      backgroundColor: "#ffa500",
                      borderRadius: "4px",
                      height: "25px",
                    }}
                    label={this.state.rowData[dataIndex].Status}
                  />
                );
              } else if (statusName === "Issued Offer letter") {
                return (
                  <Chip
                    color="primary"
                    style={{
                      backgroundColor: "#fff900",
                      borderRadius: "4px",
                      height: "25px",
                    }}
                    label={this.state.rowData[dataIndex].Status}
                  />
                );
              } else {
                return (
                  <Chip
                    style={{
                      borderRadius: "4px",
                      height: "25px",
                      color: "black",
                    }}
                    label={this.state.rowData[dataIndex].Status}
                  />
                );
              }
            },
          },
        },
        {
          name: "Projects",
          label: "Projects",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const pro = this.state.rowData[dataIndex];
              return (
                <div style={{ textAlign: "center" }}>{pro.ProjectCnt}</div>
              );
            },
          },
        },
        {
          name: "ReportToCount",
          label: "Report To",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <>
                  <Tooltip title="View Reporting Managers">
                    <div onClick={(e) => this.handleOpenRMModal(this.state.rowData[dataIndex].Reportto, this.state.rowData[dataIndex].ReportToCount)} className="reportToCss">
                      <u>{this.state.rowData[dataIndex].ReportToCount}</u>
                    </div>
                  </Tooltip>
                </>
              );
            },
          },
        },
        {
          name: "BankAccountNo",
          label: "Bank Account No",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "BankBeneficiaryName",
          label: "Bank Beneficiary Name",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "pan_num",
          label: "Pan Number",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "aadhar_num",
          label: "Aadhar Number",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "IFSC",
          label: "IFSC",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "EnrollmentDate",
          label: "Enrollment Date",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "OldEmpID",
          label: "Old EMP ID",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "salarySlipHistory",
          label: "Salary Slips",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <>
                  <Tooltip title="View Payslips">
                    <Button
                      // component={RouterLink}
                      size="small"
                      onClick={(e) =>
                        this.handlePayslipModal(
                          dataIndex
                        )
                      }
                      style={{ color: "black", minWidth: "40px" }}
                    // variant="outlined"
                    >
                      <AccountBalanceWalletIcon />
                    </Button>
                  </Tooltip>
                </>
              );
            },
          },
        },
        {
          name: "IDCard",
          label: "Id Card",
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>
                  <Tooltip title="View ID Card">
                    <Button
                      // component={RouterLink}
                      size="small"
                      onClick={(e) =>
                        this.handleOpenModal(
                          this.state.rowData[dataIndex].IDCard
                        )
                      }
                      style={{ color: "black", minWidth: "40px" }}
                    // variant="outlined"
                    >
                      <RemoveRedEyeIcon />
                    </Button>
                  </Tooltip>

                  <Tooltip title="Download ID Card">
                    <Button
                      // component={RouterLink}
                      size="small"
                      onClick={(e) =>
                        this.handleDownloadIdCard(
                          this.state.rowData[dataIndex].IDCard,
                          this.state.rowData[dataIndex].userid,
                          this.state.rowData[dataIndex].FullName
                        )
                      }
                      style={{ color: "black", minWidth: "40px" }}
                    // variant="outlined"
                    >
                      <GetAppIcon />
                    </Button>
                  </Tooltip>
                </div>
              );
            },
          },
        },
        {
          name: "userid",
          label: "View More",
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <Tooltip title="View">
                  <Button
                    // component={RouterLink}
                    size="small"
                    // to={{
                    //   pathname: `/management/view/order-details/${params.node.data.pTLTripID}`,
                    //   orders: this.state.orders
                    // }}
                    onClick={(e) => this.handleOpenFullModal(dataIndex, e)}
                    style={{ color: "black" }}
                  // variant="outlined"
                  >
                    <ViewComfyIcon />
                  </Button>
                </Tooltip>
              );
            },
          },
        },
      ],
      unique_newRequests: [],
      openFullModal: false,
      Basic: "",
      HRA: "",
      CA: "",
      MA: "",
      LTA: "",
      SA: "",
      GE: "",
      CTC: "",
      NSP: "",
      statusText: "",
      submitModal: "",
      loggedInUserId: '',
      unique_quickReports: [
        { reportID: 1, reportName: "Head Office", variant: "outlined" },
        { reportID: 2, reportName: "Reliance", variant: "outlined" },
        { reportID: 3, reportName: "Off Role", variant: "outlined" },
        { reportID: 4, reportName: "Zone 1", variant: "outlined" },
        { reportID: 5, reportName: "Zone 2", variant: "outlined" },
        { reportID: 6, reportName: "Zone 3", variant: "outlined" },
        {
          reportID: 7,
          reportName: "New/Modified Registrations",
          variant: "outlined",
        },
      ],
      rowData2: [],
      columns2: [
        {
          name: "FullName",
          label: "Profile Pic / Full Name",
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>
                  <div className="user-pic">
                    <Avatar
                      onClick={(e) => {
                        this.handleOpenModal(
                          this.state.rowData2[dataIndex].Picture,
                          e
                        );
                      }}
                      className="profilePic"
                      src={this.state.rowData2[dataIndex].Picture}
                      style={{ float: "left" }}
                      alt="E"
                      sx={{ width: 38, height: 38 }}
                    />
                    <div style={{ marginLeft: "51px", paddingTop: "8px" }}>
                      {this.state.rowData2[dataIndex].FullName}
                    </div>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "EmployeeID",
          label: "Employee ID",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "Designation",
          label: "Designation",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "WorkingBranch",
          label: "Working Branch",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "isOnroll",
          label: "Onrole",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              var newsss = this.state.rowData2[dataIndex].isOnroll;
              var onRole = "No";
              var colorNew = "primary";
              if (newsss == "1") {
                colorNew = "primary";
                onRole = "Yes";
              }
              return (
                <Chip
                  style={{ borderRadius: "4px", height: "25px" }}
                  label={onRole}
                />
              );
            },
          },
        },
        {
          name: "DoB",
          label: "Date of Birth",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "DoJ",
          label: "Date of Joining",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "Status",
          label: "Status",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const statusName = this.state.rowData2[dataIndex].Status;
              var colorNew = "";
              if (statusName === "Verified") {
                return (
                  <Chip
                    color="success"
                    style={{
                      backgroundColor: "#3c9b41",
                      borderRadius: "4px",
                      height: "25px",
                    }}
                    label={this.state.rowData2[dataIndex].Status}
                  />
                );
              } else if (statusName === "Profile Updated") {
                return (
                  <Chip
                    color="primary"
                    style={{
                      backgroundColor: "#ffa500",
                      borderRadius: "4px",
                      height: "25px",
                    }}
                    label={this.state.rowData2[dataIndex].Status}
                  />
                );
              } else if (statusName === "Issued Offer letter") {
                return (
                  <Chip
                    color="primary"
                    style={{
                      backgroundColor: "#fff900",
                      borderRadius: "4px",
                      height: "25px",
                    }}
                    label={this.state.rowData2[dataIndex].Status}
                  />
                );
              } else {
                return (
                  <Chip
                    style={{
                      borderRadius: "4px",
                      height: "25px",
                      color: "black",
                    }}
                    label={this.state.rowData2[dataIndex].Status}
                  />
                );
              }
            },
          },
        },
        {
          name: "Projects",
          label: "Projects",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const pro = this.state.rowData2[dataIndex];
              return (
                <div style={{ textAlign: "center" }}>{pro.ProjectCnt}</div>
              );
            },
          },
        },
        {
          name: "Reportto",
          label: "Report To",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "BankAccountNo",
          label: "Bank Account No",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "BankBeneficiaryName",
          label: "Bank Beneficiary Name",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "IFSC",
          label: "IFSC",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "EnrollmentDate",
          label: "Enrollment Date",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "OldEmpID",
          label: "Old EMP ID",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Isactive",
          label: "Is Active",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "lastWorkingDate",
          label: "Last Working Date",
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const trimDate = this.state.rowData2[dataIndex].lastWorkingDate;
              if (trimDate != null) {
                const newDate = trimDate.slice(0, 10);
                return <>{newDate}</>;
              } else {
                return <>N/A</>;
              }
            },
          },
        },
        {
          name: "IDCard",
          label: "Id Card",
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>
                  <Tooltip title="View ID Card">
                    <Button
                      // component={RouterLink}
                      size="small"
                      onClick={(e) =>
                        this.handleOpenModal(
                          this.state.rowData2[dataIndex].IDCard
                        )
                      }
                      style={{ color: "black", minWidth: "40px" }}
                    // variant="outlined"
                    >
                      <RemoveRedEyeIcon />
                    </Button>
                  </Tooltip>

                  <Tooltip title="Download ID Card">
                    <Button
                      // component={RouterLink}
                      size="small"
                      onClick={(e) =>
                        this.handleDownloadIdCard(
                          this.state.rowData2[dataIndex].IDCard,
                          this.state.rowData2[dataIndex].userid,
                          this.state.rowData2[dataIndex].FullName
                        )
                      }
                      style={{ color: "black", minWidth: "40px" }}
                    // variant="outlined"
                    >
                      <GetAppIcon />
                    </Button>
                  </Tooltip>
                </div>
              );
            },
          },
        },
        {
          name: "userid",
          label: "View More",
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <Tooltip title="View">
                  <Button
                    // component={RouterLink}
                    size="small"
                    // to={{
                    //   pathname: `/management/view/order-details/${params.node.data.pTLTripID}`,
                    //   orders: this.state.orders
                    // }}
                    onClick={(e) => this.handleOpenQuickFullModal(dataIndex, e)}
                    style={{ color: "black" }}
                  // variant="outlined"
                  >
                    <ViewComfyIcon />
                  </Button>
                </Tooltip>
              );
            },
          },
        },
      ],
      columnsPayslip: [
        {
          name: "salaryMonth",
          label: "Salary Month",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "url",
          label: "View / Download",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <>
                  <Grid className="margin0" item m={1} md={3}>
                    <img onClick={(event) => { window.open(value) }} style={{ width: '100%' }} className="bms-view-request-img" src={pdfIcon} alt="No Image" />
                  </Grid>
                </>
              )
            }

          }
        },
      ],
      quickReportsModal: false,
    };
    this.validator = new SimpleReactValidator();
  }

  delay = async (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  getUniqueCities = async () => {
    await axios
      .get(`${Config.hostName}/unique/locations`)
      .then((res) => {
        if (res.data.data.length > 0) {
          this.setState({
            unique_cities: res.data.data,
          });
        } else {
          this.setState({
            unique_cities: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  departmentDropDown = async () => {
    await axios
      .get(`${Config.hostName}/ems/departmentDropDown`)
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            unique_departments: res.data.data,
          });
        } else {
          this.setState({
            unique_departments: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  designation = async () => {
    await axios
      .get(`${Config.hostName}/ems/designation`)
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            unique_designations: res.data.data,
          });
        } else {
          this.setState({
            unique_designations: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  accessDropDown = async () => {
    await axios
      .get(`${Config.hostName}/ems/accessDropDown`)
      .then(async (res) => {
        const newArray2 = res.data.data.map((it) => ({
          accessTypeid: it.accessTypeid,
          AccessType: it.AccessType,
          isChecked: false,
        }));
        if (res.data.data.length > 0) {
          await this.setState({
            unique_access: newArray2,
          });

          await this.state.unique_access.filter(async (eachItem, key) => {
            if (
              eachItem.accessTypeid == 1 ||
              eachItem.accessTypeid == 29 ||
              eachItem.accessTypeid == 4 ||
              eachItem.accessTypeid == 19 ||
              eachItem.accessTypeid == 47 ||
              eachItem.accessTypeid == 50
            ) {
              let items = [...this.state.unique_access];
              let item = { ...items[key] };
              item.isChecked = true;
              items[key] = item;

              await this.setState({
                unique_access: items,
              });
            }
          });
        } else {
          this.setState({
            unique_access: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  reportTODropDown = async () => {
    await axios
      .get(`${Config.hostName}/ems/reportTODropDown`)
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            unique_reportTo: res.data.data,
          });
        } else {
          this.setState({
            unique_reportTo: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  projectDropDown = async () => {
    const res = await axios
      .post(`${Config.hostName}/ems/projectDropDown`, {
        locationID: this.state.locationId,
      })
      .then(async (res) => {
        // console.log(res.data.data, 'project');
        if (res.data.data.length > 0) {
          await this.setState({
            unique_project: res.data.data,
          });
        } else {
          this.setState({
            unique_project: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  getUniqueStatus = async () => {
    const res = await axios
      .post(`${Config.hostName}/ems/statusDropDown`, {})
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            unique_status: res.data.data,
          });
        } else {
          this.setState({
            unique_status: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  getNewRequests = async () => {
    const res = await axios
      .post(`${Config.hostName}/ems/hrGetNewRequests`, {
        // ManagerID: "113"
      })
      .then(async (res) => {
        if (res.data.data.length > 0) {
          let result = res.data.data;
          await result.map(async (e, key) => {
            const reportTo = e.Reportto;
            if (reportTo) {
              const reportToids = await reportTo.split(",");
              result[key].ReportToCount = await reportToids.length;
              let rmData = [];
              if (reportToids.length > 0) {
                await reportToids.map(async (id) => {
                  const filteredArray = await this.state.unique_reportTo.filter(item => item.UserID == id);
                  if (filteredArray.length > 0) {
                    await rmData.push(filteredArray[0].Firstname);
                  }
                })
                result[key].Reportto = await rmData.join(",");
              }
            } else {
              result[key].ReportToCount = 0;
            }
          })


          await this.setState({
            rowData: result,
          });
        } else {
          alert("No Recoords Found... !!");
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  handleOpenRMModal = async (e, count) => {

    await this.setState({
      rmModal: true,
      rmNames: e,
      rmCount: e.split(',').length
    });
  };

  handleCloseRMModal = async () => {
    await this.setState({
      rmModal: false,
    });
  };



  handleCloseQuickReports = async () => {
    await this.setState({
      quickReportsModal: false,
    });
  };

  quickReports = async (reportID) => {
    await this.setState({
      style: {},
    });

    console.log(reportID);
    const res = await axios
      .post(`${Config.hostName}/ems/hrQuickReports`, {
        reportID: reportID,
      })
      .then(async (ress) => {
        // console.log(res.data.data.length, 'project');
        // console.log(ress.data.data, 'project');

        // res.data.data.map(eachItem => {
        //  console.log(eachItem);
        // })

        await this.setState({
          quickReportsModal: true,
          rowData2: ress.data.data
        });
        // console.log(this.state.rowData[1].ProjectCnt);
        if (ress.data.data.length > 0) {
        } else {
          alert("No Recoords Found... !!");
        }
      })
      .catch((error) => {
        alert(error);
      });

    await this.setState({
      style: {
        display: "none",
      },
    });
  };

  async componentDidMount() {
    await this.setState({
      style: {},
    });
    const userData = localStorage.getItem('persist:root');
    const auth = JSON.parse(userData).auth;
    const userID = JSON.parse(auth).USERID;
    await this.setState({
      loggedInUserId: userID
    })
    await this.reportTODropDown();
    await this.getNewRequests();
    this.departmentDropDown();
    this.designation();
    this.accessDropDown();
    this.getUniqueCities();
    this.getUniqueStatus();
    await this.setState({
      style: {
        display: "none",
      },
    });
  }

  handlePayslipModal = async (id) => {
    const data = JSON.parse(this.state.rowData[id].salarySlipHistory);

    if (data.length > 0) {
      console.log(data);
      this.setState({
        rowPayslipData: data,
        openPayslipModal: true,
      })
    } else {
      alert('No Records Found');
    }
  }

  handleClosePayslipModal = async (id) => {
    this.setState({
      rowPayslipData: [],
      openPayslipModal: false,
    })
  }

  handleAccessChange = async (event) => {
    console.log(event);
    if (event == 1 || event == 29 || event == 4 || event == 19 || event == 47 || event == 50) {
      alert("This option is Mandatory !!");
    } else {
      await this.state.unique_access.filter(async (eachItem, key) => {
        if (eachItem.accessTypeid == event) {
          let items = [...this.state.unique_access];
          let item = { ...items[key] };
          item.isChecked = !eachItem.isChecked;
          items[key] = item;

          await this.setState({
            unique_access: items,
          });
        }
      });
    }

    console.log(this.state.unique_access);
  };

  handleDownloadIdCard = async (url, userID, fullName) => {
    let extension = "";
    if (url) {
      extension = url;
      extension = extension.substr(extension.length - 3);
    }

    const response = await axios({
      method: "GET",
      url: url,
      responseType: "blob",
    });
    const pdfBlob = new Blob([response.data], { type: `image/${extension}` });
    const fileName = `${userID}_${fullName}.${extension}`;

    FileSaver.saveAs(pdfBlob, fileName);
  };

  getUrlToAttachments = async (url, name) => {
    const { addProfileAttachments } = this.state;
    addProfileAttachments.filter((addProfileAttachments) => {
      if (addProfileAttachments.name == name) {
        return (addProfileAttachments.AttachmentUrl = url);
      }
    });
    await this.setState({
      addProfileAttachments: addProfileAttachments,
      profile: addProfileAttachments[0].AttachmentUrl,
    });
    // console.log(this.state.addProfileAttachments);
  };

  uploadFileAndGetUrl = async (formData) => {
    // console.log("uploading formdata")
    const fileData = await axios({
      method: "POST",
      url: `${Config.hostName}/unique/upload/any-file`,
      timeout: 3 * 60 * 1000,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      data: formData,
    });
    return fileData;
  };

  singleUploadFile = async (file) => {
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = async (e) => {
      await this.setState({
        selectedFile: [reader.result],
      });
    };
    // console.log(url); // Would see a path?
    const fileType = file.type.substring(0, 5);
    await this.setState({ selectedFile: file, selectedFileType: fileType });
    const formData = new FormData();
    formData.append(
      "media",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
    // console.log(formData, "formData", fileType);
    const res = await this.uploadFileAndGetUrl(formData);
    const urlFromAws = res.data.url;

    // const urlFromAws = "https://logicarts.s3.ap-south-1.amazonaws.com/clientlogos/capp_truck.png";
    await this.getUrlToAttachments(urlFromAws, file.name);
    await this.setState({
      profile: urlFromAws,
    });
    const { addProfileAttachments } = this.state;
    setTimeout(async () => {
      addProfileAttachments.filter((att) => {
        if (att.name == file.name && att.isUploaded == false) {
          return (att.isUploaded = true);
        }
      });
      await this.setState({
        addProfileAttachments: addProfileAttachments,
      });
    }, 4000);
    // console.log(this.state.attachments, "data")
  };

  onFileUpload = async (event) => {
    // console.log(event.target.files, "event.target.files");
    const filesObj = event.target.files;
    const filesObjKeys = Object.keys(filesObj);
    const { addProfileAttachments } = this.state;
    const multiMapFileNames = filesObjKeys.map(async (fileKey, index) => {
      addProfileAttachments.push({
        name: filesObj[fileKey].name,
        AttachmentUrl: "",
        isUploaded: false,
      });
    });
    const multiPromiseFileNames = await Promise.all(multiMapFileNames);
    await this.setState({
      style: {},
    });
    await this.setState({
      addProfileAttachments: addProfileAttachments,
    });
    for (let i = 0; i < filesObjKeys.length; i++) {
      // console.log(i, "i")
      const item = filesObj[i];
      await this.singleUploadFile(item);
    }
    await this.delay(2000);
    await this.setState({
      style: {
        display: "none",
      },
    });
  };

  getPanCardUrl = async (url, name) => {
    const { addPancardImage } = this.state;
    addPancardImage.filter((addPancardImage) => {
      if (addPancardImage.name == name) {
        return (addPancardImage.AttachmentUrl = url);
      }
    });
    await this.setState({
      addPancardImage: addPancardImage,
      pancardImage: addPancardImage[0].AttachmentUrl,
    });
  };

  pancardUploadFile = async (file) => {
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = async (e) => {
      await this.setState({
        selectedFile: [reader.result],
      });
    };
    // console.log(url); // Would see a path?
    const fileType = file.type.substring(0, 5);
    await this.setState({ selectedFile: file, selectedFileType: fileType });
    const formData = new FormData();
    formData.append(
      "media",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
    // console.log(formData, "formData", fileType);
    const res = await this.uploadFileAndGetUrl(formData);
    const urlFromAws = res.data.url;

    // const urlFromAws = "https://logicarts.s3.ap-south-1.amazonaws.com/clientlogos/capp_truck.png";
    await this.getPanCardUrl(urlFromAws, file.name);
    await this.setState({
      pancardImage: urlFromAws,
    });

    const { addPancardImage } = this.state;
    setTimeout(async () => {
      addPancardImage.filter((att) => {
        if (att.name == file.name && att.isUploaded == false) {
          return (att.isUploaded = true);
        }
      });
      await this.setState({
        addPancardImage: addPancardImage,
      });
    }, 4000);
    // console.log(this.state.attachments, "data")
  };

  onPanUpload = async (event) => {
    // console.log(event.target.files, "event.target.files");
    const filesObj = event.target.files;
    const filesObjKeys = Object.keys(filesObj);
    const { addPancardImage } = this.state;
    const multiMapFileNames = filesObjKeys.map(async (fileKey, index) => {
      addPancardImage.push({
        name: filesObj[fileKey].name,
        AttachmentUrl: "",
        isUploaded: false,
      });
    });
    const multiPromiseFileNames = await Promise.all(multiMapFileNames);
    await this.setState({
      panImageText: "Uploading..",
    });
    await this.setState({
      addPancardImage: addPancardImage,
    });
    for (let i = 0; i < filesObjKeys.length; i++) {
      // console.log(i, "i")
      const item = filesObj[i];
      await this.pancardUploadFile(item);
    }
    await this.delay(2000);
    await this.setState({
      panImageText: "Change",
      color: "addColorBlue",
    });
  };

  // getPanCardUrl = async (url, name) => {
  //   const { addPancardImage } = this.state;
  //   addPancardImage.filter(addPancardImage => {
  //     if (addPancardImage.name == name) {
  //       return addPancardImage.AttachmentUrl = url
  //     }
  //   })
  //   await this.setState({
  //     addPancardImage: addPancardImage,
  //     pancardImage: addPancardImage[0].AttachmentUrl
  //   })
  // }

  aadharUploadFile = async (adharPos, file) => {
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = async (e) => {
      await this.setState({
        selectedFile: [reader.result],
      });
    };
    // console.log(url); // Would see a path?
    const fileType = file.type.substring(0, 5);
    await this.setState({ selectedFile: file, selectedFileType: fileType });
    const formData = new FormData();
    formData.append(
      "media",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    const res = await this.uploadFileAndGetUrl(formData);
    const urlFromAws = res.data.url;

    if (adharPos == 1) {
      await this.setState({
        aadharFrontImage: urlFromAws,
      });
    } else {
      await this.setState({
        aadharBackImage: urlFromAws,
      });
    }
  };



  onAadharUpload = async (res, event) => {
    // console.log(event.target.files, "event.target.files");
    const filesObj = event.target.files;
    const filesObjKeys = Object.keys(filesObj);

    if (res == 1) {
      await this.setState({
        aadharFrontText: "Uploading..",
      });
    } else {
      await this.setState({
        aadharBackText: "Uploading..",
      });
    }

    for (let i = 0; i < filesObjKeys.length; i++) {
      // console.log(i, "i")
      const item = filesObj[i];
      await this.aadharUploadFile(res, item);
    }
    await this.delay(1000);

    if (res == 1) {
      await this.setState({
        aadharFrontText: "Change",
        aadharFrontcolor: "addColorBlue",
      });
    } else {
      await this.setState({
        aadharBackText: "Change",
        aadharBackcolor: "addColorBlue",
      });
    }
  };

  chequeUploadFile = async (adharPos, file) => {
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = async (e) => {
      await this.setState({
        selectedFile: [reader.result],
      });
    };
    // console.log(url); // Would see a path?
    const fileType = file.type.substring(0, 5);
    await this.setState({ selectedFile: file, selectedFileType: fileType });
    const formData = new FormData();
    formData.append(
      "media",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    const res = await this.uploadFileAndGetUrl(formData);
    const urlFromAws = res.data.url;

    if (adharPos == 1) {
      await this.setState({
        chequeImage: urlFromAws,
      });
    }
  };

  onChequeUpload = async (res, event) => {
    // console.log(event.target.files, "event.target.files");
    const filesObj = event.target.files;
    const filesObjKeys = Object.keys(filesObj);

    if (res == 1) {
      await this.setState({
        chequeText: "Uploading..",
      });
    }

    for (let i = 0; i < filesObjKeys.length; i++) {
      // console.log(i, "i")
      const item = filesObj[i];
      await this.chequeUploadFile(res, item);
    }
    await this.delay(1000);

    if (res == 1) {
      await this.setState({
        chequeText: "Change",
        chequecolor: "addColorBlue",
      });
    }
  };

  handleLocationChange = async (e) => {
    e.preventDefault();
    await this.setState({
      locationId: e.target.value,
      projectID: [],
      projectName: [],
    });
    await this.projectDropDown();
    await this.geofenceDropDown();
  };

  handleReportingManagerChange = async (e) => {
    e.preventDefault();
    await this.setState({
      reportingManager: e.target.value,
    });
  };

  handleDepartmentChange = async (e) => {
    e.preventDefault();
    await this.setState({
      department: e.target.value,
    });
  };

  handleStatusChange = async (e) => {
    e.preventDefault();
    let statusText = "";
    await this.state.unique_status.filter((item) => {
      if (item.StatusID == e.target.value) {
        statusText = item.Status;
      }
    });
    if (e.target.value == 6) {
      this.setState({
        terminateDateUI: true,
      });
    } else if (e.target.value == 25) {
      this.setState({
        terminateDateUI: true,
      });
    } else {
      this.setState({
        terminateDateUI: false,
        terminateDate: "",
      });
    }
    await this.setState({
      status: e.target.value,
      statusText: statusText,
    });
  };

  handleReportToChange = async (e, newValue) => {
    const value = newValue;
    console.log(value);
    await this.setState({
      reportTo: typeof value === "string" ? value.split(",") : value,
    });
  };

  handleProjectChange = async (e) => {
    const value = e.target.value;
    // console.log(data);
    await this.setState({
      projectName: typeof value === "string" ? value.split(",") : value,
    });
  };

  handleReportTo = async (e) => {
    e.preventDefault();
    await this.setState({ reportTo: e.target.value });
  };

  handleDesignationChange = async (e) => {
    e.preventDefault();
    await this.setState({ designation: e.target.value });
  };

  handleTypeChange = async (e) => {
    e.preventDefault();
    await this.setState({ employeeType: e.target.value });
  };

  handleEmploymentChange = async (e) => {
    e.preventDefault();
    await this.setState({ employmentType: e.target.value });
  };

  handleOpenModal = async (url, e) => {
    await this.setState({ modalImage: url });

    await this.setState({ open: true });
  };

  handleCloseModal = async (e) => {
    await this.setState({ open: false });
  };

  handleClose = async () => {
    await this.setState({
      openAlert: false,
    });
  };

  onSubmitModal = async (e) => {
    e.preventDefault();
    if (this.state.bankName == "") {
      alert("Bank Name Required..!!!");
      return;
    }
    // else if (this.state.aadhar_num == "") {
    //   alert("Aadhar Number Required..!!!");
    //   return;
    // } else if (this.state.pan_num == "") {
    //   alert("Pan Number Required..!!!");
    //   return;
    // }
    else if (this.state.profile == "") {
      alert("Profile Pic Required..!!!");
      return;
    } else if (this.state.accountNo == "") {
      alert("Account NO. Required..!!!");
      return;
    } else if (this.state.accountNo != this.state.confirmAccountNo) {
      alert("Account NO. not matched..!!!");
      return;
    } else if (this.state.ifsc == "") {
      alert("IFSC code Required..!!!");
      return;
    } else if (this.state.fullName == "") {
      alert("Name cannot be empty..!!!");
      return;
    } else if (this.state.mobile == "") {
      alert("Mobile Number Required..!!!");
      return;
    }
    if (this.state.status == 6 && this.state.terminateDate == "") {
      alert("Termination Date Required..!!!");
      return;
    }


    if (this.state.status == 25 && this.state.terminateDate == "") {
      alert("Termination Date Required..!!!");
      return;
    }


    let geoID = [];
    this.state.unique_geofence.map((item) => {
      if (item.isChecked == true) {
        geoID.push(item.GeoID);
      }
    });

    if (geoID.toString() == "") {
      // alert("Geo Location Required..!!!");

      await this.setState({
        openAlert: true,
      });

      await this.setState({
        geofenceAlertBorder: "2px solid red",
        geofenceAlertDisplay: "block",
      });

      await this.setState({
        style: {
          display: "none",
        },
      });
      return;
    }

    await this.setState({ submitModal: true });
  };

  handleCloseSubmitModal = async (e) => {
    await this.setState({ submitModal: false });
  };


  terminateRemoveAccess = async (userID) => {
    const res = await axios

      .post(`${Config.hostName}/ems/removeReportingMangerAccess`, {
        userID: userID
      })
      .then(async (res) => {
        console.log('deleted');
      })
      .catch((error) => {
        alert(error);
      });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    if (this.state.bankName == "") {
      alert("Bank Name Required..!!!");
      return;
    }
    //  else if (this.state.aadhar_num == "") {
    //   alert("Aadhar Number Required..!!!");
    //   return;
    // } else if (this.state.pan_num == "") {
    //   alert("Pan Number Required..!!!");
    //   return;
    // } 
    else if (this.state.accountNo == "") {
      alert("Account NO. Required..!!!");
      return;
    } else if (this.state.accountNo != this.state.confirmAccountNo) {
      alert("Account NO. not matched..!!!");
      return;
    } else if (this.state.ifsc == "") {
      alert("IFSC code Required..!!!");
      return;
    } else if (this.state.fullName == "") {
      alert("Name cannot be empty..!!!");
      return;
    } else if (this.state.mobile == "") {
      alert("Mobile Number Required..!!!");
      return;
    }

    const designation = this.state.designation.split("&&");

    let access = [];
    await this.state.unique_access.map((item) => {
      if (item.isChecked == true) {
        access.push(item.accessTypeid);
      }
    });

    let geoID = [];
    this.state.unique_geofence.map((item) => {
      if (item.isChecked == true) {
        geoID.push(item.GeoID);
      }
    });

    // if (geoID.toString() == "") {

    //   await this.setState({
    //     geofenceAlertBorder: "2px solid red",
    //     geofenceAlertDisplay: "block",
    //   });
    //   window.scrollTo({
    //     top: 400,
    //     behavior: "smooth",
    //   });
    //   await this.setState({
    //     style: {
    //       display: "none",
    //     },
    //   });
    //   return;
    // }
    // console.log(geoID.toString(),'geoID');

    let inHand = 0;
    if (this.state.inHand) {
      inHand = 1;
    }
    await this.setState({
      style: {},
    });

    let reportToFinal = [];
    const testReportTo = await this.state.reportTo.map(
      async (filterReportTo) => {
        const filterNew = filterReportTo;
        const reportTo = await this.state.unique_reportTo.filter(
          async (value) => {
            if (value.Firstname == filterNew) {
              reportToFinal.push(value.UserID);
            }
          }
        );
      }
    );

    const userData = localStorage.getItem('persist:root');
    const auth = JSON.parse(userData).auth;
    const userID = JSON.parse(auth).USERID;

    const projectCnt = this.state.projectName.length;
    const acc = `{"BeneficiaryName":"${this.state.bankName}","AccountNo":"${this.state.accountNo}","IFSC":"${this.state.ifsc}" }`;
    const jdata = `{"modifiedBy":"${userID}","aadhar_num":"${this.state.aadhar_num}","pan_num":"${this.state.pan_num}","cnlChequeURL":"${this.state.chequeImage}","isMGR":"${this.state.reportingManager}","terminationDate":"${this.state.terminateDate}","OldEmpID":"${this.state.OldEmpID}","geoID":"${geoID.toString()}","StatusID":"${this.state.status}","email":"${this.state.email}","EmploymentType":"${this.state.employmentType}","Department":"${this.state.department}","ProjectCnt":"${projectCnt}","DoB":"${this.state.dob}"}`;
    const SalaryDetails = `{"GrossSalary":"${this.state.GrossSalary}","OtherDeductions":"${this.state.OtherDeductions}","MonthlyCTC":"${this.state.monthlyCTC}","InHand":"${inHand}","PFno":"${this.state.pfNo}","UANno":"${this.state.uanNo}","ESICno":"${this.state.esicNo}","Basic":"${this.state.Basic}","HRA":"${this.state.HRA}","CA":"${this.state.CA}","MA":"${this.state.MA}","LTA":"${this.state.LTA}","SA":"${this.state.SA}","GE":"${this.state.GE}","CTC":"${this.state.CTC}","NSP":"${this.state.NSP}"}`;
    const res = await axios
      .post(`${Config.hostName}/ems/registerEmployee`, {
        locationid: this.state.locationId,
        name: this.state.fullName,
        mobile: this.state.mobile,
        access: access.toString(),
        designation: designation[0],
        startDate: this.state.doj,
        userType: designation[1],
        isattendance: "0",
        adharfront: this.state.aadharFrontImage,
        adharback: this.state.aadharBackImage,
        pan: this.state.pancardImage,
        isOnroll: this.state.employeeType,
        Projects: this.state.projectName.toString(),
        userID: this.state.useridDashboard,
        reportto: reportToFinal.toString(),
        bloodGroup: this.state.bloodGroup,
        BankAccountDeatils: acc,
        EmployeePicture: this.state.profile,
        jdata: jdata,
        SalaryDetails: SalaryDetails,
      })
      .then(async (res) => {
        if (res.data.success) {
          this.getNewRequests();
          if (this.state.terminateDateUI == true) {
            this.terminateRemoveAccess(this.state.useridDashboard);
          }
          alert("Status Updated");
          await this.setState({
            openFullModal: false,
            submitModal: false,
            terminateDateUI: false,
            style: {
              display: "none",
            },
          });
        } else {
          alert(res.data.message);
          await this.setState({
            style: {
              display: "none",
            },
          });
        }
      })
      .catch(async (error) => {
        alert(error);
        await this.setState({
          style: {
            display: "none",
          },
        });
      });
  };

  handleOpenQuickFullModal = async (dataIndex, e) => {
    const data = this.state.rowData2[dataIndex];
    const BankDetails = JSON.parse(this.state.rowData2[dataIndex].BankDetails);
    const SalaryDetails = JSON.parse(
      this.state.rowData2[dataIndex].SalaryDetails
    );

    let MonthlyCTC = "";
    let InHand = "";
    let PFno = "";
    let UANno = "";
    let ESICno = "";

    let BeneficiaryName = "";
    let AccountNo = "";
    let IFSC = "";
    let GrossSalary = "";
    let OtherDeductions = "";
    if (SalaryDetails != null) {
      if (SalaryDetails.MonthlyCTC != null) {
        MonthlyCTC = SalaryDetails.MonthlyCTC;
      }

      if (SalaryDetails.InHand != null) {
        InHand = SalaryDetails.InHand;
      }

      if (SalaryDetails.PFno != null) {
        PFno = SalaryDetails.PFno;
      }

      if (SalaryDetails.UANno != null) {
        UANno = SalaryDetails.UANno;
      }

      if (SalaryDetails.ESICno != null) {
        ESICno = SalaryDetails.ESICno;
      }

      if (SalaryDetails.GrossSalary != null) {
        GrossSalary = SalaryDetails.GrossSalary;
        if (GrossSalary == '') {
          GrossSalary = SalaryDetails.MonthlyCTC;
        }
      } else {
        GrossSalary = SalaryDetails.MonthlyCTC;
      }
      if (SalaryDetails.OtherDeductions != null) {
        OtherDeductions = SalaryDetails.OtherDeductions;
      }
    }

    if (BankDetails != null) {
      if (BankDetails.BeneficiaryName != null) {
        BeneficiaryName = BankDetails.BeneficiaryName;
      }

      if (BankDetails.AccountNo != null) {
        AccountNo = BankDetails.AccountNo;
      }

      if (BankDetails.IFSC != null) {
        IFSC = BankDetails.IFSC;
      }
    }

    let designation = "";
    let reportTo = [];
    let statusNew = "";

    await this.state.unique_designations.filter((item) => {
      if (item.Designation == data.Designation) {
        designation = item.Designation + "&&" + item.userType;
      }
    });

    if (data.Reportto) {
      reportTo = data.Reportto.split(",");
    }

    // await this.state.unique_reportTo.filter(item => {
    //   if (item.Firstname === data.Reportto) {
    //     reportTo = item.UserID;
    //   }
    // })

    let statusText = "";
    await this.state.unique_status.filter((item) => {
      if (item.Status === data.Status) {
        statusNew = item.StatusID;
        statusText = item.Status;
      }
    });

    if (data.Status == "Terminated") {
      this.setState({
        terminateDateUI: true,
        permanentDisable: true,
      });
    } else if (data.Status == "Exit") {
      this.setState({
        terminateDateUI: true,
        permanentDisable: true,
      });
    } else {
      this.setState({
        terminateDateUI: false,
      });
    }


    let projectName = [];
    let access = [];

    if (data.Projects) {
      projectName = data.Projects.split(",");
    }
    if (data.Access) {
      access = data.Access.split(",");
    }
    // let accessNews = Object.values(access);


    const uniqueAccess = [];
    const numericAccess = access.map(Number);

    await this.state.unique_access.forEach(async (item) => {
      if (numericAccess.includes(item.accessTypeid)) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
      await uniqueAccess.push(item);
    });
    await this.setState({ unique_access: uniqueAccess });

    await this.setState({
      aadharBackImage: data.AdharBack,
      aadharFrontImage: data.AdharFront,
      fullName: data.FullName,
      mobile: data.Mobile,
      email: data.email,
      locationId: data.Locationid,
      designation: designation,
      doj: data.DoJ,
      dob: data.DoB,
      department: data.Department,
      isattendance: "0",
      pancardImage: data.pan,
      employeeType: data.isOnroll,
      employmentType: data.EmploymentType,
      monthlyCTC: MonthlyCTC,
      GrossSalary: GrossSalary,
      OtherDeductions: OtherDeductions,
      inHand: InHand,
      pfNo: PFno,
      uanNo: UANno,
      esicNo: ESICno,
      reportTo: reportTo,
      bloodGroup: data.bloodGroup,
      profile: data.Picture,
      index: dataIndex,
      idCard: data.IDCard,
      passbookURL: data.passbookURL,
      cnlChequeURL: data.cnlChequeURL,
      aadhar_num: data.aadhar_num,
      pan_num: data.pan_num,
      pan_num_validate: false,
      aadhar_num_validate: false,
      projectName: projectName,
      bankName: BeneficiaryName,
      accountNo: AccountNo,
      confirmAccountNo: AccountNo,
      ifsc: IFSC,
      openFullModal: true,
      accountNoColor: "success",
      accFocused: true,
      status: statusNew,
      statusText: statusText,
      useridDashboard: data.userid,
      EmployeeID: data.EmployeeID,
      geoID: data.geoID,
      OldEmpID: data.OldEmpID,
      terminateDate: data.terminationDate != null ? data.terminationDate.slice(0, 10) : "",
    });

    if (data.terminationDate == null) {
      this.setState({
        terminateDateUI: false,
        terminateDate: "",
      });
    }

    await this.geofenceDropDown();
    await this.projectDropDown();

    let geoID = [];
    if (data.geoID) {
      geoID = data.geoID.split(",");
    }

    await this.state.unique_geofence.filter(async (eachItem, key) => {
      let items = [...this.state.unique_geofence];
      let item = { ...items[key] };
      item.isChecked = true;
      if (geoID.includes(`${eachItem.GeoID}`)) {
        items[key] = item;
        await this.setState({
          unique_geofence: items,
        });
        await this.delay(600);
      }
    });
    // console.log();
  };

  handleOpenFullModal = async (dataIndex, e) => {
    const data = this.state.rowData[dataIndex];
    const BankDetails = JSON.parse(this.state.rowData[dataIndex].BankDetails);
    const SalaryDetails = JSON.parse(
      this.state.rowData[dataIndex].SalaryDetails
    );

    let MonthlyCTC = "";
    let InHand = "";
    let PFno = "";
    let UANno = "";
    let ESICno = "";

    let BeneficiaryName = "";
    let AccountNo = "";
    let IFSC = "";
    let GrossSalary = "";
    let OtherDeductions = "";
    if (SalaryDetails != null) {
      if (SalaryDetails.MonthlyCTC != null) {
        MonthlyCTC = SalaryDetails.MonthlyCTC;
      }

      if (SalaryDetails.InHand != null) {
        InHand = SalaryDetails.InHand;
      }

      if (SalaryDetails.PFno != null) {
        PFno = SalaryDetails.PFno;
      }

      if (SalaryDetails.UANno != null) {
        UANno = SalaryDetails.UANno;
      }

      if (SalaryDetails.ESICno != null) {
        ESICno = SalaryDetails.ESICno;
      }

      if (SalaryDetails.GrossSalary != null) {
        GrossSalary = SalaryDetails.GrossSalary;
        if (GrossSalary == '') {
          GrossSalary = SalaryDetails.MonthlyCTC;
        }
      } else {
        GrossSalary = SalaryDetails.MonthlyCTC;
      }

      if (SalaryDetails.OtherDeductions != null) {
        OtherDeductions = SalaryDetails.OtherDeductions;
      }
    }

    if (BankDetails != null) {
      if (BankDetails.BeneficiaryName != null) {
        BeneficiaryName = BankDetails.BeneficiaryName;
      }

      if (BankDetails.AccountNo != null) {
        AccountNo = BankDetails.AccountNo;
      }

      if (BankDetails.IFSC != null) {
        IFSC = BankDetails.IFSC;
      }
    }

    let designation = "";
    let reportTo = [];
    let statusNew = "";

    await this.state.unique_designations.filter((item) => {
      if (item.Designation == data.Designation) {
        designation = item.Designation + "&&" + item.userType;
      }
    });


    if (data.Reportto) {
      reportTo = data.Reportto.split(",");
    }


    let statusText = "";
    await this.state.unique_status.filter((item) => {
      if (item.Status === data.Status) {
        statusNew = item.StatusID;
        statusText = item.Status;
      }
    });

    if (data.Status == "Terminated") {
      this.setState({
        terminateDateUI: true,
        permanentDisable: true,
      });
    } else if (data.Status == "Exit") {
      this.setState({
        terminateDateUI: true,
        permanentDisable: true,
      });
    } else {
      this.setState({
        terminateDateUI: false,
      });
    }

    let projectName = [];
    let access = [];

    if (data.Projects) {
      projectName = data.Projects.split(",");
    }
    if (data.Access) {
      access = data.Access.split(",");
    }
    // let accessNews = Object.values(access);

    const uniqueAccess = [];
    const numericAccess = access.map(Number);

    await this.state.unique_access.forEach(async (item) => {
      if (numericAccess.includes(item.accessTypeid)) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
      await uniqueAccess.push(item);
    });
    await this.setState({ unique_access: uniqueAccess });



    await this.setState({
      aadharBackImage: data.AdharBack,
      chequeImage: data.cnlChequeURL,
      aadharFrontImage: data.AdharFront,
      fullName: data.FullName,
      mobile: data.Mobile,
      email: data.email,
      locationId: data.Locationid,
      designation: designation,
      doj: data.DoJ,
      dob: data.DoB,
      department: data.Department,
      isattendance: "0",
      pancardImage: data.pan,
      reportingManager: data.isMGR == true ? 1 : 0,
      employeeType: data.isOnroll,
      employmentType: data.EmploymentType,
      monthlyCTC: MonthlyCTC,
      GrossSalary: GrossSalary,
      OtherDeductions: OtherDeductions,
      inHand: InHand == 1 ? true : false,
      pfNo: PFno,
      uanNo: UANno,
      esicNo: ESICno,
      reportTo: reportTo,
      bloodGroup: data.bloodGroup,
      profile: data.Picture,
      index: dataIndex,
      idCard: data.IDCard,
      passbookURL: data.passbookURL,
      cnlChequeURL: data.cnlChequeURL,
      aadhar_num: data.aadhar_num,
      pan_num: data.pan_num,
      pan_num_validate: false,
      aadhar_num_validate: false,
      projectName: projectName,
      bankName: BeneficiaryName,
      accountNo: AccountNo,
      confirmAccountNo: AccountNo,
      ifsc: IFSC,
      openFullModal: true,
      accountNoColor: "success",
      accFocused: true,
      status: statusNew,
      statusText: statusText,
      useridDashboard: data.userid,
      EmployeeID: data.EmployeeID,
      geoID: data.geoID,
      OldEmpID: data.OldEmpID,
      terminateDate: data.terminationDate != null ? data.terminationDate.slice(0, 10) : "",
    });

    if (data.terminationDate == null) {
      this.setState({
        terminateDateUI: false,
        terminateDate: "",
      });
    }

    await this.geofenceDropDown();
    await this.projectDropDown();

    let geoID = [];
    if (data.geoID) {
      geoID = data.geoID.split(",");
    }

    await this.state.unique_geofence.filter(async (eachItem, key) => {
      let items = [...this.state.unique_geofence];
      let item = { ...items[key] };
      item.isChecked = true;
      if (geoID.includes(`${eachItem.GeoID}`)) {
        items[key] = item;
        await this.setState({
          unique_geofence: items,
        });
        await this.delay(600);
      }
    });


    // console.log();
  };

  submitGeofence = async () => {
    let geoID = [];
    this.state.unique_geofence.map((item) => {
      if (item.isChecked == true) {
        geoID.push(item.GeoID);
      }
    });
    console.log(geoID.toString());
    if (geoID.length != 0) {
      const res = await axios
        .post(`${Config.hostName}/ems/hrModifyEmployeeinfo`, {
          employeeID: this.state.useridDashboard,
          contentID: "4",
          Content: `${geoID.toString()}`,
        })
        .then(async (res) => {
          // console.log(res.data.data, 'project');
          if (res.data.success) {
            await this.getNewRequests();
            alert("Geofence Updated");
            await this.setState({
              openFullModal: false,
              submitModal: false,
            });
          } else {
            alert(res.data.message);
          }
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      alert(" Please Select At least 1 Geofence Location");
    }
  };

  geofenceDropDown = async () => {
    const res = await axios
      .post(`${Config.hostName}/geofencing/getGeoFence`, {
        locationID: this.state.locationId,
        geoID: "",
      })
      .then(async (res) => {
        console.log(res.data.data, "geofence");
        if (res.data.data.length > 0) {
          const newArray2 = res.data.data.map((it) => ({
            GeoID: it.GeoID,
            Hubname: it.Hubname,
            isChecked: false,
          }));

          await this.setState({
            unique_geofence: newArray2,
          });
        } else {
          this.setState({
            unique_geofence: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  handleGeofenceChange = async (event) => {
    await this.state.unique_geofence.filter(async (eachItem, key) => {
      if (eachItem.GeoID == event) {
        let items = [...this.state.unique_geofence];
        let item = { ...items[key] };
        item.isChecked = !eachItem.isChecked;
        items[key] = item;

        await this.setState({
          unique_geofence: items,
        });
        await this.setState({
          geofenceAlertDisplay: "none",
          geofenceAlertBorder: "2px solid #fff",
        });
      }
    });
  };

  handleCloseFullModal = async (e) => {
    await this.setState({
      openFullModal: false,
    });
  };

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  relianceSalarySheet = async () => {
    await this.setState({
      style: {},
    });

    const month = moment()
      .subtract(1, "month")
      .startOf("month")
      .format("MMMM");
    console.log(month);

    const res = await axios
      .post(`${Config.hostName}/ems/relianceSalarySheet`)
      .then(async (res) => {
        if (res.data.success === true) {
          var workSheet = XLSX.utils.json_to_sheet(res.data.data);
          var workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet 1");
          XLSX.writeFile(workBook, `${month} Reliance Salaries` + ".xlsx");
          console.log(res.data, "relianceSalarySheet");
        }
      })
      .catch((error) => {
        alert(error);
      });

    await this.setState({
      style: {
        display: "none",
      },
    });
  };

  clientBillingData = async () => {
    await this.setState({
      style: {},
    });

    const month = moment()
      .subtract(1, "month")
      .startOf("month")
      .format("MMMM");
    console.log(month);

    const res = await axios
      .post(`${Config.hostName}/ems/clientBillingData`)
      .then(async (res) => {
        if (res.data.success === true) {
          var workSheet = XLSX.utils.json_to_sheet(res.data.data);
          var workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet 1");
          XLSX.writeFile(
            workBook,
            `${month} Reliance Client Billing` + ".xlsx"
          );
          console.log(res.data, "clientBillingData");
        }
      })
      .catch((error) => {
        alert(error);
      });

    await this.setState({
      style: {
        display: "none",
      },
    });
  };

  offRollSalarySheet = async () => {
    await this.setState({
      style: {},
    });

    const month = moment()
      .subtract(1, "month")
      .startOf("month")
      .format("MMMM");
    console.log(month);

    const res = await axios
      .post(`${Config.hostName}/ems/offRollSalarySheet`)
      .then(async (res) => {
        if (res.data.success === true) {
          var workSheet = XLSX.utils.json_to_sheet(res.data.data);
          var workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet 1");
          XLSX.writeFile(workBook, `${month} OffRoll Salaries` + ".xlsx");
          console.log(res.data, "clientBillingData");
        }
      })
      .catch((error) => {
        alert(error);
      });

    await this.setState({
      style: {
        display: "none",
      },
    });
  };

  onRollSalarySheet = async () => {
    await this.setState({
      style: {},
    });

    const month = moment()
      .subtract(1, "month")
      .startOf("month")
      .format("MMMM");
    console.log(month);

    const res = await axios
      .post(`${Config.hostName}/ems/onRollSalarySheet`)
      .then(async (res) => {
        if (res.data.success === true) {
          var workSheet = XLSX.utils.json_to_sheet(res.data.data);
          var workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet 1");
          XLSX.writeFile(workBook, `${month} OnRoll Salaries` + ".xlsx");
          console.log(res.data, "clientBillingData");
        }
      })
      .catch((error) => {
        alert(error);
      });

    await this.setState({
      style: {
        display: "none",
      },
    });
  };

  render() {
    const { vertical, horizontal } = this.state;

    return (
      <div>
        <Container
          pt={3}
          style={{
            maxWidth: "inherit",
            background: "white",
            paddingBottom: "12%",
            marginTop: "7%",
          }}
        >
          <div id="cover-spin" style={this.state.style}></div>

          {/* <Grid pt={3} container>
            <Grid item md={2}>
              <Typography pt={1} style={{ color: "#333333" }} variant="h6">
                {" "}
                <b> Salary Sheets:</b>
              </Typography>
            </Grid> */}

          {/* <Grid item md={10}>
              <Grid container>
                <Grid style={{ textAlign: "center" }} mt={1} item md={2}>
                  <Button
                    sx={{
                      borderRadius: 28,
                      border: "1px solid #d2194b80",
                      color: "#f5506f",
                    }}
                    className="hoverChip"
                    variant="outlined"
                    onClick={() => this.relianceSalarySheet()}
                  >
                    RS
                  </Button>
                </Grid>

                <Grid style={{ textAlign: "center" }} mt={1} item md={2}>
                  <Button
                    sx={{
                      borderRadius: 28,
                      border: "1px solid #d2194b80",
                      color: "#f5506f",
                    }}
                    variant="outlined"
                    className="hoverChip"
                    onClick={() => this.clientBillingData()}
                  >
                    RB
                  </Button>
                </Grid>

                <Grid style={{ textAlign: "center" }} mt={1} item md={2}>
                  <Button
                    sx={{
                      borderRadius: 28,
                      border: "1px solid #d2194b80",
                      color: "#f5506f",
                    }}
                    variant="outlined"
                    className="hoverChip"
                    onClick={() => this.onRollSalarySheet()}
                  >
                    OnS
                  </Button>
                </Grid>

                <Grid style={{ textAlign: "center" }} mt={1} item md={2}>
                  <Button
                    sx={{
                      borderRadius: 28,
                      border: "1px solid #d2194b80",
                      color: "#f5506f",
                    }}
                    className="hoverChip"
                    variant="outlined"
                    onClick={() => this.offRollSalarySheet()}
                  >
                    OfS
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid pt={3} container>
            <Grid item md={2}>
              <Typography pt={1} style={{ color: "#333333" }} variant="h6">
                {" "}
                <b> Quick Reports:</b>
              </Typography>
            </Grid>

            <Grid item md={10}>
              <Grid container>
                {this.state.unique_quickReports.map((item, key) => (
                  <Grid
                    style={{ textAlign: "center" }}
                    key={key}
                    mt={1}
                    item
                    md={item.reportID == 7 ? 4 : 2}
                  >
                    <Button
                      sx={{ borderRadius: 28 }}
                      onClick={(e) => {
                        this.quickReports(item.reportID);
                      }}
                      variant={item.variant}
                    >
                      {item.reportName}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid mt={3}>
            <Grid item md={12}>
              <MUIDataTable
                title={"Employee List"}
                data={this.state.rowData}
                columns={this.state.columns}
                options={this.state.options}
              />
            </Grid>
          </Grid>
        </Container>

        <Dialog
          fullScreen
          scroll="paper"
          TransitionComponent={this.Transition}
          onClose={this.handleCloseFullModal}
          open={this.state.openFullModal}
        >
          <Container
            maxWidth="xl"
            style={{
              maxWidth: "inherit",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <AppBar
              style={{
                height: "auto",
                backgroundColor: "white",
                color: "black",
              }}
              sx={{ position: "relative" }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseFullModal}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Sound
                  </Typography> */}
                <Button
                  autoFocus
                  color="inherit"
                  onClick={this.handleCloseFullModal}
                >
                  Close
                </Button>
              </Toolbar>
            </AppBar>

            {/* <Header order="rohan" /> */}
            <Grid style={{ background: "#e0e1e0" }} container>
              <Grid item md={9} style={{ padding: "0px 12px" }}>
                <form onSubmit={this.onSubmitModal} action="/" method="POST">
                  <Container
                    pt={3}
                    style={{
                      maxWidth: "fit-content",
                      background: "white",
                      paddingBottom: "12%",
                      marginTop: "2%",
                    }}
                  >
                    <Typography
                      pt={2}
                      style={{ textAlign: "center", color: "#333333" }}
                      variant="h4"
                    >
                      {" "}
                      <b>
                        <u> Employee Profile</u>
                      </b>
                    </Typography>

                    <Typography
                      pt={2}
                      style={{ display: "inline" }}
                      variant="h6"
                    >
                      {" "}
                      <b> Personal Info</b>
                    </Typography>
                    <Typography
                      pt={2}
                      style={{
                        display: "inline",
                        float: "right",
                        color: "#333333",
                      }}
                      variant="h6"
                    >
                      EmployeeID :{" "}
                      <b style={{ color: "red" }}>{this.state.EmployeeID}</b>
                    </Typography>

                    <Grid pt={1} container>
                      <Grid item md={4} sm={12}>
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 120 }}
                        >
                          <label htmlFor="icon-button-file">
                            <Input
                              accept="image/*"
                              id="icon-button-file"
                              onChange={this.onFileUpload}
                              type="file"
                            />
                            <IconButton aria-label={100} component="span">
                              <Badge
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                badgeContent={"+"}
                                color="primary"
                                style={{ borderRadius: "0px" }}
                              >
                                {this.state.profile == "" ? (
                                  <Avatar
                                    src={require("./user-icon2.png")}
                                    alt="E"
                                    sx={{ width: 100, height: 100 }}
                                  />
                                ) : (
                                  <Avatar
                                    src={this.state.profile}
                                    style={{ borderRadius: "0px" }}
                                    alt="E"
                                    sx={{ width: 100, height: 100 }}
                                  />
                                )}
                              </Badge>
                            </IconButton>
                          </label>
                        </FormControl>
                      </Grid>

                      <Grid item md={3}>
                        <TextField
                          id="fullName"
                          label="Full Name"
                          required
                          variant="standard"
                          placeholder="As per on Aadhar Card"
                          focused
                          value={this.state.fullName}
                          onChange={(e) => {
                            if (e.target.value != "") {
                              this.setState({
                                fullName: e.target.value,
                                fullNameValidate: false,
                              });
                            } else {
                              this.setState({
                                fullNameValidate: true,
                              });
                            }
                          }}
                          error={this.state.fullNameValidate}
                          style={{ marginTop: "11%" }}
                        />
                      </Grid>

                      <Grid item md={3}>
                        <TextField
                          id="mobile"
                          label="Mobile"
                          required
                          variant="standard"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          value={this.state.mobile}
                          onChange={(e) => {
                            if (e.target.value != "") {
                              this.setState({
                                mobile: e.target.value,
                                mobileValidate: false,
                              });
                            } else {
                              this.setState({
                                mobileValidate: true,
                              });
                            }
                          }}
                          error={this.state.mobileValidate}
                          style={{ marginTop: "11%" }}
                        />
                      </Grid>

                      <Grid item md={3}>
                        <TextField
                          sx={{ m: 1 }}
                          id="email"
                          label="Email"
                          required
                          variant="standard"
                          type="email"
                          value={this.state.email}
                          onChange={(e) => {
                            this.setState({
                              email: e.target.value,
                            });
                          }}
                        />
                      </Grid>

                      <Grid item md={3}>
                        <TextField
                          sx={{ m: 1 }}
                          name="someDate"
                          variant="standard"
                          label="Date of Joining"
                          InputLabelProps={{ shrink: true, required: true }}
                          type="date"
                          defaultValue={this.state.doj}
                          onChange={(e) => {
                            if (e.target.value != "") {
                              this.setState({
                                doj: e.target.value,
                              });
                            } else {
                            }
                          }}
                        />
                      </Grid>

                      <Grid item md={3}>
                        <TextField
                          sx={{ m: 1 }}
                          name="dob"
                          variant="standard"
                          label="Date of Birth"
                          InputLabelProps={{ shrink: true, required: true }}
                          type="date"
                          defaultValue={this.state.dob}
                          onChange={(e) => {
                            if (e.target.value != "") {
                              this.setState({
                                dob: e.target.value,
                                dobValidate: false,
                              });
                            } else {
                              this.setState({
                                dobValidate: true,
                              });
                            }
                          }}
                        />
                      </Grid>

                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 165 }}
                          style={{ marginLeft: "0px" }}
                        >
                          <InputLabel id="Employment-Type">
                            Employment Type
                          </InputLabel>
                          <Select
                            labelId="Employment-Type"
                            id="Employment2"
                            value={this.state.employmentType}
                            onChange={this.handleEmploymentChange}
                            label="Employment Type"
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="Full Time">Full Time</MenuItem>
                            <MenuItem value="Probation">Probation</MenuItem>
                            <MenuItem value="OffRole">OffRole</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 165 }}
                          style={{ marginLeft: "0px" }}
                        >
                          <InputLabel id="department">Department</InputLabel>
                          <Select
                            labelId="department"
                            id="department2"
                            value={this.state.department}
                            onChange={this.handleDepartmentChange}
                            label="Department"
                            required
                          >
                            <MenuItem value="">
                              <em>Select Department</em>
                            </MenuItem>

                            {this.state.unique_departments.map(
                              (eachItem, key) => (
                                <MenuItem value={eachItem.Department} key={key}>
                                  {eachItem.Department}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 165 }}
                          style={{ marginLeft: "0px" }}
                        >
                          <InputLabel id="designation">Designation</InputLabel>
                          <Select
                            labelId="designation"
                            id="designation2"
                            value={this.state.designation}
                            onChange={this.handleDesignationChange}
                            label="Designation"
                            required
                          >
                            <MenuItem value="">
                              <em>Select Designation</em>
                            </MenuItem>

                            {this.state.unique_designations.map(
                              (eachItem, key) => (
                                <MenuItem
                                  value={
                                    eachItem.Designation +
                                    "&&" +
                                    eachItem.userType
                                  }
                                  key={key}
                                >
                                  {eachItem.Designation}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 165 }}
                          style={{ marginLeft: "0px" }}
                        >
                          <InputLabel id="employeetype">
                            Employee Type
                          </InputLabel>
                          <Select
                            labelId="employeetype"
                            id="employeeType"
                            value={this.state.employeeType}
                            onChange={this.handleTypeChange}
                            label="Employee Type"
                          >
                            <MenuItem value="0">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="1">OnRole</MenuItem>
                            <MenuItem value="0">OffRole</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 165 }}
                          style={{ marginLeft: "0px" }}
                        >
                          <InputLabel id="bloodGroup">Blood Group</InputLabel>
                          <Select
                            labelId="bloodGroup"
                            id="bloodGroup2"
                            value={this.state.bloodGroup}
                            onChange={(e) => {
                              this.setState({
                                bloodGroup: e.target.value,
                              });
                            }}
                            label="Blood Group"
                            required
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="A+">A-positive (A+)</MenuItem>
                            <MenuItem value="A-">A-negative (A-)</MenuItem>
                            <MenuItem value="B+">B-positive (B+)</MenuItem>
                            <MenuItem value="B-">B-negative (B-)</MenuItem>
                            <MenuItem value="AB+">AB-positive (AB+)</MenuItem>
                            <MenuItem value="AB-">AB-negative (AB-)</MenuItem>
                            <MenuItem value="O+">O-positive (O+)</MenuItem>
                            <MenuItem value="O-">O-negative (O-)</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 165 }}
                          style={{ marginLeft: "0px" }}
                        >
                          <InputLabel id="location">Work Location</InputLabel>
                          <Select
                            labelId="location"
                            id="location2"
                            value={this.state.locationId}
                            onChange={this.handleLocationChange}
                            label="Work Location"
                            required
                          >
                            <MenuItem value="">
                              <em>Select Location</em>
                            </MenuItem>

                            {this.state.unique_cities.map((eachItem, key) => (
                              <MenuItem value={eachItem.Locationid} key={key}>
                                {eachItem.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 165 }}
                          style={{ marginLeft: "0px" }}
                        >
                          <InputLabel id="location">Reporting Manager</InputLabel>
                          <Select
                            labelId="location"
                            id="location2"
                            value={this.state.reportingManager}
                            onChange={this.handleReportingManagerChange}
                            label="Reporting Manager"
                            required
                          >
                            <MenuItem value="1">
                              Yes
                            </MenuItem>
                            <MenuItem value="0">
                              No
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={5}>
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 165 }}
                          style={{ marginLeft: "0px", display: "flex" }}
                        >
                          <InputLabel id="demo-simple-select-standard-label">
                            Project
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            multiple
                            value={this.state.projectName}
                            onChange={(e) => {
                              this.handleProjectChange(e);
                            }}
                            // input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                            label="Project"
                          >
                            {this.state.unique_project.map((name, key) => (
                              <MenuItem key={key} value={name.projectName}>
                                <Checkbox
                                  checked={
                                    this.state.projectName.indexOf(
                                      name.projectName
                                    ) > -1
                                  }
                                />
                                <ListItemText primary={name.projectName} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      {/* <Grid item md={4}></Grid> */}

                      <Grid item md={5}>
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 165 }}
                          style={{ marginLeft: "0px", display: "flex" }}
                        >
                          {/* <InputLabel id="reportTo">Report To</InputLabel>
                          <Select
                            labelId="reportTo"
                            id="unique_reportTo2"
                            value={this.state.reportTo}
                            onChange={this.handleReportTo}
                            label="Report To"
                          >
                            <MenuItem value="">
                              <em>Select Report To</em>
                            </MenuItem>

                            {this.state.unique_reportTo.map((eachItem, key) => (
                              <MenuItem value={eachItem.UserID} key={key} >{eachItem.Firstname}</MenuItem>
                            ))}
                          </Select> */}

                          <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            value={this.state.reportTo}
                            options={this.state.unique_reportTo.map(
                              (option) => option.Firstname
                            )}
                            disableCloseOnSelect
                            onChange={(e, newValue) => {
                              this.handleReportToChange(e, newValue);
                            }}
                            renderOption={(props, options, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {options}
                              </li>
                            )}
                            required
                            style={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Report To"
                                variant="standard"
                                placeholder="Select Report To..."
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>



                      <Grid pt={4} item md={12}>
                        <Typography pt={1} pb={2} variant="h6">
                          <b>Projects</b>
                        </Typography>

                        <Stack
                          direction="row"
                          style={{ display: "block" }}
                          spacing={1}
                        >
                          {this.state.projectName.map((eachItem, key) => {
                            return (
                              <>
                                <Chip
                                  key={eachItem}
                                  color="primary"
                                  label={eachItem}
                                  style={{
                                    borderRadius: "0px",
                                    margin: "10px",
                                  }}
                                />
                              </>
                            );
                          })}
                        </Stack>
                      </Grid>

                      <Grid pt={4} item md={12}>
                        <Typography pt={1} pb={2} variant="h6">
                          <b>Access</b>
                        </Typography>

                        <Stack
                          direction="row"
                          style={{ display: "block" }}
                          spacing={1}
                        >
                          {this.state.unique_access.map((eachItem, key) => {
                            return eachItem.isChecked == false ? (
                              <Chip
                                key={eachItem.accessTypeid}
                                label={eachItem.AccessType}
                                onClick={(e) => {
                                  this.handleAccessChange(
                                    eachItem.accessTypeid
                                  );
                                }}
                                style={{ borderRadius: "0px", margin: "10px" }}
                              />
                            ) : (
                              <Chip
                                key={eachItem.accessTypeid}
                                color="primary"
                                label={eachItem.AccessType}
                                onClick={(e) => {
                                  this.handleAccessChange(
                                    eachItem.accessTypeid
                                  );
                                }}
                                style={{ borderRadius: "0px", margin: "10px" }}
                              />
                            );
                          })}
                        </Stack>
                      </Grid>

                      <Grid pt={4} item md={12}>
                        <div
                          style={{
                            border: `${this.state.geofenceAlertBorder}`,
                            padding: "10px",
                          }}
                        >
                          <Typography pt={1} pb={2} variant="h6">
                            <b>Geofence</b>
                          </Typography>

                          <Stack
                            direction="row"
                            style={{ display: "block" }}
                            spacing={1}
                          >
                            {this.state.unique_geofence.map((eachItem, key) => {
                              return eachItem.isChecked == false ? (
                                <Chip
                                  key={eachItem.GeoID}
                                  label={eachItem.Hubname}
                                  onClick={(e) => {
                                    this.handleGeofenceChange(eachItem.GeoID);
                                  }}
                                  style={{
                                    borderRadius: "0px",
                                    margin: "10px",
                                  }}
                                />
                              ) : (
                                <Chip
                                  key={eachItem.GeoID}
                                  color="primary"
                                  label={eachItem.Hubname}
                                  onClick={(e) => {
                                    this.handleGeofenceChange(eachItem.GeoID);
                                  }}
                                  style={{
                                    borderRadius: "0px",
                                    margin: "10px",
                                  }}
                                />
                              );
                            })}
                          </Stack>
                          <p
                            style={{
                              color: "red",
                              fontWeight: 500,
                              display: `${this.state.geofenceAlertDisplay}`,
                            }}
                          >
                            Geofence selection is required
                          </p>
                        </div>
                      </Grid>

                      {this.state.geoID == null && <Grid item md={9}></Grid>}

                      {this.state.geoID == null && (
                        <Grid mt={2} item md={3}>
                          <Button
                            onClick={this.submitGeofence}
                            type="button"
                            size="large"
                            variant="contained"
                            style={{
                              marginTop: "18px",
                              background: "rgb(234 67 68)",
                              color: "white",
                            }}
                            endIcon={<SendIcon />}
                          >
                            Update Geofence
                          </Button>
                        </Grid>
                      )}
                    </Grid>

                    <Typography pt={4} variant="h6">
                      <b> Bank Details</b>
                    </Typography>
                    <Grid pt={1} container>
                      <Grid item md={4}>
                        <TextField
                          sx={{ m: 1, width: "25ch" }}
                          id="bankName"
                          label="Beneficiary Name"
                          variant="standard"
                          required
                          value={this.state.bankName}
                          onChange={(e) => {
                            if (e.target.value != "") {
                              this.setState({
                                bankName: e.target.value,
                                bankNameValidate: false,
                              });
                            } else {
                              this.setState({
                                bankNameValidate: true,
                              });
                            }
                          }}
                          error={this.state.bankNameValidate}
                        />
                      </Grid>

                      <Grid item md={4}>
                        <TextField
                          sx={{ m: 1, width: "25ch" }}
                          id="accNo"
                          label="Account No."
                          variant="standard"
                          required
                          autoComplete="new-password"
                          defaultValue={this.state.accountNo}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          type="password"
                          onChange={(e) => {
                            if (e.target.value != "") {
                              this.setState({
                                accountNo: e.target.value,
                                accountNoValidate: false,
                                accFocused: true,
                              });
                            } else {
                              this.setState({
                                accountNoValidate: true,
                                confirmAccountNoValidate: true,
                              });
                            }
                          }}
                          color={this.state.accountNoColor}
                          focused={this.state.accFocused}
                          error={this.state.accountNoValidate}
                        />
                      </Grid>

                      <Grid item md={4}>
                        <TextField
                          sx={{ m: 1, width: "25ch" }}
                          id="confirmAccNo"
                          label="Confirm Account No"
                          variant="standard"
                          required
                          defaultValue={this.state.confirmAccountNo}
                          onChange={(e) => {
                            if (e.target.value != "") {
                              if (e.target.value != this.state.accountNo) {
                                this.setState({
                                  accountNoColor: "primary",
                                  accountNoValidate: true,
                                  confirmAccountNoValidate: true,
                                });
                              } else {
                                this.setState({
                                  accountNoColor: "success",
                                  accountNoValidate: false,
                                  confirmAccountNoValidate: false,
                                });
                              }
                              this.setState({
                                confirmAccountNo: e.target.value,
                                accFocused: true,
                              });
                            } else {
                              this.setState({
                                accountNoColor: "primary",
                                confirmAccountNoValidate: true,
                              });
                            }
                          }}
                          focused={this.state.accFocused}
                          color={this.state.accountNoColor}
                          error={this.state.confirmAccountNoValidate}
                        />
                      </Grid>

                      <Grid item md={4}>
                        <TextField
                          sx={{ m: 1, width: "25ch" }}
                          id="ifsc"
                          label="IFSC"
                          variant="standard"
                          required
                          defaultValue={this.state.ifsc}
                          onChange={(e) => {
                            if (e.target.value != "") {
                              this.setState({
                                ifsc: e.target.value,
                                ifscValidate: false,
                              });
                            } else {
                              this.setState({
                                ifscValidate: true,
                              });
                            }
                          }}
                          error={this.state.ifscValidate}
                        />
                      </Grid>

                      <Grid item md={4}>
                        <TextField
                          sx={{ m: 1, width: "25ch" }}
                          id="aadhar_num"
                          label="Aadhar Number"
                          variant="standard"
                          // required
                          value={this.state.aadhar_num}
                          onChange={(e) => {
                            if (e.target.value != "") {
                              this.setState({
                                aadhar_num: e.target.value,
                                aadhar_num_validate: false,
                              });
                            } else {
                              this.setState({
                                aadhar_num_validate: true,
                              });
                            }
                          }}
                          error={this.state.aadhar_num_validate}
                        />
                      </Grid>

                      <Grid item md={4}>
                        <TextField
                          sx={{ m: 1, width: "25ch" }}
                          id="pan_num"
                          label="Pan Number"
                          variant="standard"
                          // required
                          value={this.state.pan_num}
                          onChange={(e) => {
                            if (e.target.value != "") {
                              this.setState({
                                pan_num: e.target.value,
                                pan_num_validate: false,
                              });
                            } else {
                              this.setState({
                                pan_num_validate: true,
                              });
                            }
                          }}
                          error={this.state.pan_num_validate}
                        />
                      </Grid>

                      <Grid item md={4}></Grid>

                      <Grid item md={3}></Grid>

                      <Grid pt={2} item md={6}>
                        <label style={{ marginLeft: "2%" }}>
                          <b>PanCard </b>
                          <span style={{ color: "red" }}>*</span>
                        </label>

                        <Button
                          color="primary"
                          variant="contained"
                          component="label"
                          onChange={this.onPanUpload}
                          style={{
                            marginLeft: "115px",
                            background: "rgb(234 67 68)",
                            color: "white",
                          }}
                          className={this.state.color}
                        >
                          {this.state.panImageText}
                          <input type="file" hidden accept="image/*" />
                        </Button>
                      </Grid>

                      <Grid pt={2} item md={6}>
                        {this.state.pancardImage != "" && (
                          <Card style={{ width: "80px" }} className="pan-image">
                            {/* <div style={{width:'200px'}}>
             <img src="https://images.unsplash.com/photo-1617854818583-09e7f077a156?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940" style={{width:"100%"}} />
           </div> */}

                            <CardMedia
                              component="img"
                              height="auto"
                              image={this.state.pancardImage}
                              onClick={(e) => {
                                this.handleOpenModal(
                                  this.state.pancardImage,
                                  e
                                );
                              }}
                              alt="Error"
                            />
                          </Card>
                        )}
                      </Grid>

                      <Grid pt={2} item md={6}>
                        <label style={{ marginLeft: "2%" }}>
                          <b>Aadhar Front </b>
                          <span style={{ color: "red" }}>*</span>
                        </label>

                        <Button
                          color="primary"
                          variant="contained"
                          component="label"
                          onChange={(e) => {
                            this.onAadharUpload("1", e);
                          }}
                          style={{
                            marginLeft: "86px",
                            background: "rgb(234 67 68)",
                            color: "white",
                          }}
                          className={this.state.aadharFrontcolor}
                        >
                          {this.state.aadharFrontText}
                          <input type="file" hidden accept="image/*" />
                        </Button>
                      </Grid>

                      <Grid pt={2} item md={6}>
                        {this.state.aadharFrontImage != "" && (
                          <Card style={{ width: "80px" }} className="pan-image">
                            {/* <div style={{width:'200px'}}>
             <img src="https://images.unsplash.com/photo-1617854818583-09e7f077a156?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940" style={{width:"100%"}} />
           </div> */}
                            <CardMedia
                              component="img"
                              height="auto"
                              image={this.state.aadharFrontImage}
                              onClick={(e) => {
                                this.handleOpenModal(
                                  this.state.aadharFrontImage,
                                  e
                                );
                              }}
                              alt="Error"
                            />
                          </Card>
                        )}
                      </Grid>

                      <Grid pt={2} item md={6}>
                        <label style={{ marginLeft: "2%" }}>
                          <b>Aadhar Back &nbsp;</b>
                          <span style={{ color: "red" }}>*</span>
                        </label>

                        <Button
                          color="primary"
                          variant="contained"
                          component="label"
                          onChange={(e) => {
                            this.onAadharUpload("2", e);
                          }}
                          style={{
                            marginLeft: "86px",
                            background: "rgb(234 67 68)",
                            color: "white",
                          }}
                          className={this.state.aadharBackcolor}
                        >
                          {this.state.aadharBackText}
                          <input type="file" hidden accept="image/*" />
                        </Button>
                      </Grid>



                      <Grid pt={2} item md={6}>
                        {this.state.aadharBackImage != "" && (
                          <Card style={{ width: "80px" }} className="pan-image">
                            {/* <div style={{width:'200px'}}>
             <img src="https://images.unsplash.com/photo-1617854818583-09e7f077a156?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940" style={{width:"100%"}} />
           </div> */}

                            <CardMedia
                              component="img"
                              height="auto"
                              image={this.state.aadharBackImage}
                              onClick={(e) => {
                                this.handleOpenModal(
                                  this.state.aadharBackImage,
                                  e
                                );
                              }}
                              alt="Error"
                            />
                          </Card>
                        )}
                      </Grid>


                      <Grid pt={2} item md={6}>
                        <label style={{ marginLeft: "2%" }}>
                          <b>Canceled Cheque &nbsp;</b>
                          <span style={{ color: "red" }}>*</span>
                        </label>

                        <Button
                          color="primary"
                          variant="contained"
                          component="label"
                          onChange={(e) => {
                            this.onChequeUpload("1", e);
                          }}
                          style={{
                            marginLeft: "57px",
                            background: "rgb(234 67 68)",
                            color: "white",
                          }}
                          className={this.state.chequecolor}
                        >
                          {this.state.chequeText}
                          <input type="file" hidden accept="image/*" />
                        </Button>
                      </Grid>

                      <Grid pt={2} item md={6}>
                        {this.state.chequeImage != "" && (
                          <Card style={{ width: "80px" }} className="pan-image">
                            {/* <div style={{width:'200px'}}>
             <img src="https://images.unsplash.com/photo-1617854818583-09e7f077a156?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940" style={{width:"100%"}} />
           </div> */}

                            <CardMedia
                              component="img"
                              height="auto"
                              image={this.state.chequeImage}
                              onClick={(e) => {
                                this.handleOpenModal(
                                  this.state.chequeImage,
                                  e
                                );
                              }}
                              alt="Error"
                            />
                          </Card>
                        )}
                      </Grid>
                    </Grid>



                    <Typography pt={4} variant="h6">
                      {" "}
                      <b> Salary Details</b>
                    </Typography>

                    <Grid pt={1} container>
                      <Grid item md={12}>
                        <TextField
                          sx={{ m: 1, width: "25ch" }}
                          id="monthlyCTC"
                          required
                          label="Fixed Gross Earnings"
                          value={this.state.monthlyCTC}
                          onChange={(e) => {
                            this.setState({
                              monthlyCTC: e.target.value,
                              GrossSalary: e.target.value
                            });
                          }}
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          variant="standard"
                        />
                        <FormControlLabel
                          sx={{ m: 1 }}
                          control={
                            <Checkbox
                              defaultChecked={this.state.inHand}
                              onChange={(e) => {
                                this.setState({
                                  inHand: !this.state.inHand,
                                });
                              }}
                            />
                          }
                          label="In Hand"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          sx={{ m: 1 }}
                          id="pfNo"
                          label="PF No."
                          value={this.state.pfNo}
                          onChange={(e) => {
                            this.setState({
                              pfNo: e.target.value,
                            });
                          }}
                          variant="standard"
                        />
                      </Grid>

                      <Grid item md={4}>
                        <TextField
                          sx={{ m: 1, width: "25ch" }}
                          id="uanNo"
                          label="UAN No."
                          onChange={(e) => {
                            this.setState({
                              uanNo: e.target.value,
                            });
                          }}
                          value={this.state.uanNo}
                          variant="standard"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          sx={{ m: 1, width: "25ch" }}
                          id="esicNo"
                          label="ESIC No."
                          onChange={(e) => {
                            this.setState({
                              esicNo: e.target.value,
                            });
                          }}
                          value={this.state.esicNo}
                          variant="standard"
                        />
                      </Grid>

                      {/* <Grid item md={3}>
                        <TextField sx={{ m: 1, width: '25ch' }} id="Basic" label="Basic"
                          onChange={(e) => {
                            this.setState({
                              Basic: e.target.value,
                            });
                          }}
                          value={this.state.Basic}
                          variant="standard" />

                      </Grid>

                      <Grid item md={3}>
                        <TextField sx={{ m: 1, width: '25ch' }} id="HRA" label="HRA"
                          onChange={(e) => {
                            this.setState({
                              HRA: e.target.value,
                            });
                          }}
                          value={this.state.HRA}
                          variant="standard" />

                      </Grid>

                      <Grid item md={3}>
                        <TextField sx={{ m: 1, width: '25ch' }} id="CA" label="Conveyance Allowance"
                          onChange={(e) => {
                            this.setState({
                              CA: e.target.value,
                            });
                          }}
                          value={this.state.CA}
                          variant="standard" />

                      </Grid>

                      <Grid item md={3}>
                        <TextField sx={{ m: 1, width: '25ch' }} id="MA" label="Medical Allowance"
                          onChange={(e) => {
                            this.setState({
                              MA: e.target.value,
                            });
                          }}
                          value={this.state.MA}
                          variant="standard" />

                      </Grid> */}

                      {/* <Grid item md={3}>
                        <TextField sx={{ m: 1, width: '25ch' }} id="LTA" label="LTA"
                          onChange={(e) => {
                            this.setState({
                              LTA: e.target.value,
                            });
                          }}
                          value={this.state.LTA}
                          variant="standard" />

                      </Grid>

                      <Grid item md={3}>
                        <TextField sx={{ m: 1, width: '25ch' }} id="SA" label="Special Allowance"
                          onChange={(e) => {
                            this.setState({
                              SA: e.target.value,
                            });
                          }}
                          value={this.state.SA}
                          variant="standard" />

                      </Grid>

                      <Grid item md={3}>
                        <TextField sx={{ m: 1, width: '25ch' }} id="GE" label="Gross Earning"
                          onChange={(e) => {
                            this.setState({
                              GE: e.target.value,
                            });
                          }}
                          value={this.state.GE}
                          variant="standard" />

                      </Grid>

                      <Grid item md={3}>
                        <TextField sx={{ m: 1, width: '25ch' }} id="CTC" label="Cost to Company"
                          onChange={(e) => {
                            this.setState({
                              CTC: e.target.value,
                            });
                          }}
                          value={this.state.CTC}
                          variant="standard" />

                      </Grid> */}

                      {/* <Grid item md={3}>
                        <TextField sx={{ m: 1, width: '25ch' }} id="NSP" label="Net Salary Payable"
                          onChange={(e) => {
                            this.setState({
                              NSP: e.target.value,
                            });
                          }}
                          value={this.state.NSP}
                          variant="standard" />

                      </Grid> */}

                      <Grid item md={3}>
                        <TextField
                          sx={{ m: 1, width: "25ch" }}
                          id="NSP"
                          label="Fixed Gross Earnings"
                          required
                          onChange={(e) => {
                            this.setState({
                              monthlyCTC: e.target.value,
                              GrossSalary: e.target.value
                            });
                          }}
                          value={this.state.GrossSalary}
                          variant="standard"
                        />
                      </Grid>

                      <Grid item md={3}>
                        <TextField
                          sx={{ m: 1, width: "25ch" }}
                          id="NSP"
                          label="Other Deductions"
                          onChange={(e) => {
                            this.setState({
                              OtherDeductions: e.target.value,
                            });
                          }}
                          value={this.state.OtherDeductions}
                          variant="standard"
                        />
                      </Grid>

                      <Grid item md={6}></Grid>
                      {this.state.terminateDateUI == true ? (
                        <Grid item md={3}></Grid>
                      ) : (
                        <Grid item md={6}></Grid>
                      )}

                      <Grid
                        mt={2}
                        item
                        md={3}
                        style={{ paddingRight: "2%", textAlign: "right" }}
                      >
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 165 }}
                          style={{ marginLeft: "0px" }}
                        >
                          <InputLabel id="designation">
                            Update Status
                          </InputLabel>
                          <Select
                            labelId="designation"
                            id="designation2"
                            value={this.state.status}
                            onChange={this.handleStatusChange}
                            label="Designation"
                            required
                          >
                            <MenuItem value="">
                              <em>Select Status</em>
                            </MenuItem>

                            {this.state.unique_status.map((eachItem, key) => (
                              <MenuItem value={eachItem.StatusID} key={key}>
                                {eachItem.Status}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {this.state.terminateDateUI == true && (
                        <Grid
                          mt={3}
                          item
                          md={3}
                          style={{ paddingRight: "4%", textAlign: "center" }}
                        >
                          <TextField
                            sx={{ m: 1 }}
                            style={{ margin: "0px" }}
                            name="someDate"
                            variant="standard"
                            label="Date of Termination"
                            InputLabelProps={{ shrink: true, required: true }}
                            type="date"
                            required
                            defaultValue={this.state.terminateDate}
                            onChange={(e) => {
                              if (e.target.value != "") {
                                this.setState({
                                  terminateDate: e.target.value,
                                });
                              } else {
                              }
                            }}
                          />
                        </Grid>
                      )}

                      {this.state.permanentDisable == false && (
                        <Grid mt={2} item md={3}>
                          <Button
                            type="submit"
                            size="large"
                            variant="contained"
                            style={{
                              marginTop: "18px",
                              background: "rgb(234 67 68)",
                              color: "white",
                            }}
                            endIcon={<SendIcon />}
                          >
                            Update
                          </Button>
                        </Grid>
                      )}

                    </Grid>
                  </Container>
                </form>
              </Grid>
              {this.state.loggedInUserId === 2582 &&
                <Grid
                  item
                  md={3}
                  style={{ paddingRight: "8px", paddingTop: "18px" }}
                >
                  <Grid item md={12}>
                    <Card>
                      <Typography
                        style={{ marginLeft: "12px", marginTop: "3px" }}
                        variant="h6"
                      >
                        {" "}
                        Pan :
                      </Typography>
                      <div style={{ padding: "4px 22px", marginBottom: "14px" }}>
                        <CardMedia
                          component="img"
                          className="pan-image"
                          height="194"
                          image={this.state.pancardImage}
                          onClick={(e) => {
                            this.handleOpenModal(this.state.pancardImage, e);
                          }}
                          alt="Pan"
                          style={{
                            border: "1px solid black",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    </Card>
                    <Card style={{ marginTop: "12px" }}>
                      <Typography
                        style={{ marginLeft: "12px", marginTop: "3px" }}
                        variant="h6"
                      >
                        {" "}
                        Aadhar Front :
                      </Typography>
                      <div style={{ padding: "4px 22px", marginBottom: "14px" }}>
                        <CardMedia
                          component="img"
                          className="pan-image"
                          height="194"
                          image={this.state.aadharFrontImage}
                          onClick={(e) => {
                            this.handleOpenModal(this.state.aadharFrontImage, e);
                          }}
                          alt="Aadhar Front"
                          style={{
                            border: "1px solid black",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    </Card>
                    <Card style={{ marginTop: "12px" }}>
                      <Typography
                        style={{ marginLeft: "12px", marginTop: "3px" }}
                        variant="h6"
                      >
                        {" "}
                        Aadhar Back :
                      </Typography>
                      <div style={{ padding: "4px 22px", marginBottom: "14px" }}>
                        <CardMedia
                          component="img"
                          className="pan-image"
                          height="194"
                          image={this.state.aadharBackImage}
                          onClick={(e) => {
                            this.handleOpenModal(this.state.aadharBackImage, e);
                          }}
                          alt="Aadhar Back"
                          style={{
                            border: "1px solid black",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    </Card>
                    <Card style={{ marginTop: "12px" }}>
                      <Typography
                        style={{ marginLeft: "12px", marginTop: "3px" }}
                        variant="h6"
                      >
                        {" "}
                        ID Card :
                      </Typography>
                      <div style={{ padding: "4px 22px", marginBottom: "14px" }}>
                        <CardMedia
                          component="img"
                          className="pan-image"
                          height="194"
                          image={this.state.idCard}
                          onClick={(e) => {
                            this.handleOpenModal(this.state.idCard, e);
                          }}
                          alt="ID Card"
                          style={{
                            border: "1px solid black",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    </Card>
                    {this.state.cnlChequeURL &&
                      <Card style={{ marginTop: "12px" }}>
                        <Typography
                          style={{ marginLeft: "12px", marginTop: "3px" }}
                          variant="h6"
                        >
                          {" "}
                          Canceled Cheque :
                        </Typography>
                        <div style={{ padding: "4px 22px", marginBottom: "14px" }}>
                          <CardMedia
                            component="img"
                            className="pan-image"
                            height="194"
                            image={this.state.cnlChequeURL}
                            onClick={(e) => {
                              this.handleOpenModal(this.state.cnlChequeURL, e);
                            }}
                            alt="ID Card"
                            style={{
                              border: "1px solid black",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </Card>
                    }


                    {this.state.passbookURL &&
                      <Card style={{ marginTop: "12px" }}>
                        <Typography
                          style={{ marginLeft: "12px", marginTop: "3px" }}
                          variant="h6"
                        >
                          {" "}
                          Passbook :
                        </Typography>
                        <div style={{ padding: "4px 22px", marginBottom: "14px" }}>
                          <CardMedia
                            component="img"
                            className="pan-image"
                            height="194"
                            image={this.state.passbookURL}
                            onClick={(e) => {
                              this.handleOpenModal(this.state.passbookURL, e);
                            }}
                            alt="ID Card"
                            style={{
                              border: "1px solid black",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </Card>
                    }
                  </Grid>
                </Grid>
              }

            </Grid>
          </Container>
        </Dialog>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Box sx={style2}>
              <Card style={{}} className="pan-image">
                {/* <div style={{width:'200px'}}>
             <img src="https://images.unsplash.com/photo-1617854818583-09e7f077a156?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940" style={{width:"100%"}} />
           </div> */}

                <CardMedia
                  component="img"
                  height="auto"
                  image={this.state.modalImage}
                  alt="Error"
                />
              </Card>
            </Box>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.rmModal}
          onClose={this.handleCloseRMModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.rmModal}>
            <Box sx={style6}>
              <Grid container>
                <Grid item md={6}>
                  <h3>Reporting Managers - {this.state.rmCount}</h3>
                </Grid>
              </Grid>

              <Card className="pan-image">

                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{this.state.rmNames}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Box>
          </Fade>
        </Modal>

        <Modal
          open={this.state.openPayslipModal}
          onClose={this.handleClosePayslipModal}
          closeAfterTransition
          style={{
            left: "-72%",
            width: "250%",
          }}
        >
          <Fade in={this.state.openPayslipModal}>
            <Box sx={style2}>
              <Card >
                <CardContent style={{ maxHeight: "70vh", overflow: 'scroll' }}>
                  <MUIDataTable
                    title={"Payslip History"}
                    data={this.state.rowPayslipData}
                    columns={this.state.columnsPayslip}
                    options={options2}
                  />
                </CardContent>
              </Card>
            </Box>
          </Fade>
        </Modal>

        <Modal
          open={this.state.submitModal}
          onClose={this.handleCloseSubmitModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Update Status To: <b>{this.state.statusText}</b>
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
            ></Typography>
            <Button
              style={{
                float: "right",
                background: "#fb5f79",
                marginLeft: "3%",
                color: "white",
              }}
              onClick={this.onSubmit}
              size="large"
              variant="contained"
            >
              <span> Confirm</span>
            </Button>
          </Box>
        </Modal>

        <Dialog
          fullScreen
          TransitionComponent={this.Transition}
          onClose={this.handleCloseQuickReports}
          open={this.state.quickReportsModal}
        >
          <Container
            maxWidth="xl"
            style={{
              maxWidth: "fit-content",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <AppBar
              style={{
                height: "auto",
                backgroundColor: "white",
                color: "black",
              }}
              sx={{ position: "relative" }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseQuickReports}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Sound
                  </Typography> */}
                <Button
                  autoFocus
                  color="inherit"
                  onClick={this.handleCloseQuickReports}
                >
                  Close
                </Button>
              </Toolbar>
            </AppBar>

            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
              Update Status To: <b>{this.state.statusText}</b>
            </Typography> */}
            <MUIDataTable
              title={"Employee List"}
              data={this.state.rowData2}
              columns={this.state.columns2}
              options={options}
            />
          </Container>
        </Dialog>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={this.state.openAlert}
          autoHideDuration={5000}
          onClose={this.handleClose}
          key={vertical + horizontal}
        >
          <Alert
            onClose={this.handleClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            Geofence is not selected!
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

ManageNewRegistration.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(ManageNewRegistration);
