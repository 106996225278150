/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert.actions";
import UploadFileMain from "./uploadMain";
import { Grid } from "@material-ui/core";
class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      loading: false,
      progressLoader: 0,
      style: {},
      date: "",
    };
  }

  render() {
    return (
      <div>
        <UploadFileMain />
      </div>
    );
  }
}

UploadFile.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  application: state.application,
});

export default connect(mapStateToProps, { setAlert })(UploadFile);
