import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";

import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import "../ManageNewRegistration/layout.css";
import {
  Grid,
  Container,
  Typography,
  Button,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardActionArea,
  Chip,
  Avatar,
  AppBar,
  Dialog,
  Slide,
  IconButton,
  Toolbar,
  Paper,
  TextField,
  Box, Modal,
  Checkbox,
  Tooltip
} from "@mui/material";
import XLSX from "xlsx";
import moment from "moment";
import CardContent from "@material-ui/core/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable from "mui-datatables";
import DownloadIcon from '@mui/icons-material/Download';

const style5 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  height: "auto",
};

const options = {
  filterType: "multiselect",
  // filterOptions: {fullWidth: true},
  selectableRows: "none",
  setRowProps: (row) => {
    if (row[0] === "New") {
      return {
        style: { background: "snow" },
      };
    }
  },
  draggableColumns: { enabled: true },
  pagination: true,
  // resizableColumns: true,
  // responsive: "standard",
  fixedHeader: false,
};



export class EmployeeStats extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();

    this.state = {
      style: {},
      selectedRows: [],
      attnFreeze: 1,

      options4: {
        filterType: "multiselect",
        selectableRows: "multiple",
        setRowProps: (row) => {
          if (row[0] === "New") {
            return {
              style: { background: "snow" },
            };
          }
        },
        setCellProps: (cellValue, rowIndex, columnIndex) => {
          if (columnIndex === 0) { // Assuming the select rows column is the first column (index 0)
            return {
              style: {
                width: "120px", // Adjust the width as needed
                textAlign: "center",
              },
            };
          }
          return {};
        },
        draggableColumns: { enabled: true },
        pagination: true,
        rowsPerPage: 100, // Set default number of rows per page to 50
        rowsPerPageOptions: [100, 200, 300], // Show 50, 100, 200 records per page options
        fixedHeader: false,
        customToolbarSelect: () => {
          // const handleGetSelectedRows = () => {
          //   console.log(this.state.selectedRows); // Perform your desired action with the selected rows here
          // };

          return (
            <Button
              color="primary"
              size="large"
              variant="contained"
              component="label"
              onClick={this.processPayslips}
              style={{ marginRight: '15px', color: "white", background: '#ea4345' }}
            >
              Process Payslips
            </Button>
          );
        },
        onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
          this.setState({
            selectedRows: rowsSelected
          })
        },
      },

      vertical: "top",
      horizontal: "center",
      alertSnackbar: false,
      unique_cities: [],
      locationID: "",
      uniqueDateRanges: [],
      dateID: "",
      startDate: "",
      endDate: "",
      barCardDisplay: "none",
      statsDisplay1: "",
      statsDisplay2: "none",
      unique_project: [],
      projectName: [],
      userid: "",
      rowData: [],
      reportID: 1,
      StatsCategoryDropdown: [],
      rowDataStats: [],
      statsOverAll: [],
      statsOther: [],
      generatePalyslipModal: false,
      columns: [
        {
          name: "FullName",
          label: "Profile Pic / Full Name",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>
                  <div className="user-pic">
                    <Avatar
                      className="profilePic"
                      src={this.state.rowData[dataIndex].EmployeeUrl}
                      style={{ float: "left" }}
                      alt="E"
                      sx={{ width: 38, height: 38 }}
                    />
                    <div style={{ marginLeft: "51px", paddingTop: "8px" }}>
                      {this.state.rowData[dataIndex].FullName}
                    </div>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "city",
          label: "City",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Designation",
          label: "Designation",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "Department",
          label: "Department",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "AttnDate",
          label: "Date",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "timing",
          label: "Timing",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "WorkShift",
          label: "Work Shift",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "AttnStatus",
          label: "Status",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const statusReal = this.state.rowData[dataIndex].AttnStatus;
              if (statusReal == 1) {
                return (
                  <div>
                    <Chip
                      color="success"
                      style={{
                        backgroundColor: "#2EB85C",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Present"
                    />
                  </div>
                );
              } else if (statusReal == 2) {
                return (
                  <div>
                    <Chip
                      color="warning"
                      style={{
                        backgroundColor: "#3399fe",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Half Day"
                    />
                  </div>
                );
              } else if (statusReal == 3) {
                return (
                  <div>
                    <Chip
                      color="secondary"
                      style={{
                        backgroundColor: "f9b114",
                        color: "white",
                        backgroundColor: "#a8af1d",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Sick Leave"
                    />
                  </div>
                );
              } else if (statusReal == 9) {
                return (
                  <div>
                    <Chip
                      color="info"
                      style={{
                        backgroundColor: "#4e5d72",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Yet To Get Attendance"
                    />
                  </div>
                );
              } else if (statusReal == 0) {
                return (
                  <div>
                    <Chip
                      color="error"
                      style={{
                        backgroundColor: "#e55353",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Absent"
                    />
                  </div>
                );
              } else {
                return (
                  <div>
                    <Chip
                      color="error"
                      style={{ borderRadius: "4px", height: "25px" }}
                      label="Absent"
                    />
                  </div>
                );
              }
            },
          },
        },

        {
          name: "projects",
          label: "Projects",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const pro = this.state.rowData[dataIndex];
              return (
                <div style={{ textAlign: "center" }}>{pro.ProjectCnt}</div>
              );
            },
          },
        },
      ],
      series: null,
      options: {
        title: {
          text: "Attendance View",
        },
        chart: {
          width: 380,
          type: "donut",
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          formatter: function (val, opt) {
            console.log(opt);
            return Math.trunc(val) + "%";
            // return opt.w.config.series[opt.seriesIndex];
          },
          offsetX: 0,
        },
        colors: ["#2EB85C", "#e55353", "#3399fe"],
        labels: ["Present", "Absent", "Half Day"],

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      series2: null,
      options2: {
        title: {
          text: "Sick Leaves",
        },
        chart: {
          width: 380,
          type: "donut",
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          formatter: function (val, opt) {
            // console.log(opt);
            return Math.trunc(val) + "%";
            // return opt.w.config.series[opt.seriesIndex];
          },
          offsetX: 0,
        },
        colors: ["#f9b114", "#4e5d72"],
        labels: ["Sick Leave", "Yet To Get Attendance"],

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartData: null,

      options3: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "Empolyees Attendance",
        },
        xaxis: {
          type: "datetime",
          categories: [
            "01-01-2011 GMT",
            "01/02/2011 GMT",
            "01/03/2011 GMT",
            "01/04/2011 GMT",
            "01/05/2011 GMT",
            "01/06/2011 GMT",
          ],
          labels: {
            formatter: function (val) {
              return val + "K";
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "K";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      series3: [
        {
          name: "Day",
          data: [44, 55, 41, 67, 22, 43],
        },
        {
          name: "Night",
          data: [13, 23, 20, 8, 13, 27],
        },
      ],
      barChartModal: false,
      firstModal: false,
      barChartData: [],
      barChartColumns: [
        {
          name: "FullName",
          label: "Profile Pic / Full Name",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>
                  <div className="user-pic">
                    <Avatar
                      className="profilePic"
                      src={this.state.rowData[dataIndex].EmployeeUrl}
                      style={{ float: "left" }}
                      alt="E"
                      sx={{ width: 38, height: 38 }}
                    />
                    <div style={{ marginLeft: "51px", paddingTop: "8px" }}>
                      {this.state.rowData[dataIndex].FullName}
                    </div>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "city",
          label: "City",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Designation",
          label: "Designation",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "Department",
          label: "Department",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "AttnDate",
          label: "Date",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "timing",
          label: "Timing",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "WorkShift",
          label: "Work Shift",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "AttnStatus",
          label: "Status",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const statusReal = this.state.rowData[dataIndex].AttnStatus;
              if (statusReal == 1) {
                return (
                  <div>
                    <Chip
                      color="success"
                      style={{
                        backgroundColor: "#2EB85C",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Present"
                    />
                  </div>
                );
              } else if (statusReal == 2) {
                return (
                  <div>
                    <Chip
                      color="warning"
                      style={{
                        backgroundColor: "#3399fe",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Half Day"
                    />
                  </div>
                );
              } else if (statusReal == 3) {
                return (
                  <div>
                    <Chip
                      color="secondary"
                      style={{
                        backgroundColor: "f9b114",
                        color: "white",
                        backgroundColor: "#a8af1d",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Sick Leave"
                    />
                  </div>
                );
              } else if (statusReal == 9) {
                return (
                  <div>
                    <Chip
                      color="info"
                      style={{
                        backgroundColor: "#4e5d72",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Yet To Get Attendance"
                    />
                  </div>
                );
              } else if (statusReal == 0) {
                return (
                  <div>
                    <Chip
                      color="error"
                      style={{
                        backgroundColor: "#e55353",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Absent"
                    />
                  </div>
                );
              } else {
                return (
                  <div>
                    <Chip
                      color="error"
                      style={{ borderRadius: "4px", height: "25px" }}
                      label="Absent"
                    />
                  </div>
                );
              }
            },
          },
        },

        {
          name: "projects",
          label: "Projects",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const pro = this.state.rowData[dataIndex];
              return (
                <div style={{ textAlign: "center" }}>{pro.ProjectCnt}</div>
              );
            },
          },
        },
      ],
      statsModal: false,
      statsData: [],
      statsColumn: [
        {
          name: "FullName",
          label: "Profile Pic / Full Name",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>
                  <div className="user-pic">
                    <Avatar
                      className="profilePic"
                      src={this.state.rowDataStats[dataIndex].Picture}
                      style={{ float: "left" }}
                      alt="E"
                      sx={{ width: 38, height: 38 }}
                    />
                    <div style={{ marginLeft: "51px", paddingTop: "8px" }}>
                      {this.state.rowDataStats[dataIndex].FullName}
                    </div>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "EmployeeID",
          label: "EmployeeID",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "City",
          label: "City",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Designation",
          label: "Designation",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Department",
          label: "Department",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Status",
          label: "Status",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "terminationDate",
          label: "Termination Date",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return this.state.rowDataStats[dataIndex].terminationDate == null
                ? 0
                : moment(
                  this.state.rowDataStats[dataIndex].terminationDate
                ).format("MMM, DD, YYYY");
            },
          },
        },
        {
          name: "Salary",
          label: "Salary",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "AttnBasedSalary",
          label: "Attandance Based Salary",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "TotalDays",
          label: "Total Days",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "daysPresent",
          label: "Days Present",
          options: {
            filter: true,
            sort: true,
          },
        },
      ],
      rb: [],
      rb2: [],
      billingMonthPayslips: '',
      uniqueBillingMonthPayslips: [],
      UploadPayslipError: false,
      UploadPayslipErrorData: [],
      empsalarytypeID: '',
      uniqueEmpsalarytypeID: [],
      rowDataSalarySlipGetStatsDeatils: [],
      columnsDataSalarySlipGetStatsDeatils: [
        {
          name: "EmployeeID",
          label: "Employee ID",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "EmpName",
          label: "Employee Name",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "email",
          label: "Email",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "userid",
          label: "User ID",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "SalaryMonth",
          label: "Salary Month",
          options: {
            filter: true,
            sort: true,
          },
        },
      ],
      showDownloadPayslipModal: false,
      selectedSheet: 1,

    };
    this.validator = new SimpleReactValidator();
  }

  delay = async (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  handleOpenDownloadTemplateModal = async () => {
    await this.setState({
      showDownloadPayslipModal: true,
      selectedSheet: 1,
    })
  }

  handleCloseDownloadTemplateModal = async () => {
    await this.setState({
      showDownloadPayslipModal: false,
    })
  }

  getUniqueDateRanges = async () => {
    await axios
      .get(
        `${Config.hostName}/ems/months`
      )
      .then(async (res) => {
        // console.log(res.data.data[0].startDate);
        if (res.data.data.length > 0) {
          await this.setState({
            uniqueDateRanges: res.data.data,
            startDate: res.data.data[0].startDate,
            endDate: res.data.data[0].endDate,
            dateID: res.data.data[0].title
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getUniqueBillingMonthPayslips = async () => {
    await axios
      .post(
        `${Config.hostName}/bms/dateRangeDropDownOnlyMonth`, {
        ccxref: 384
      }
      )
      .then(async (res) => {
        console.log(res.data)
        if (res.data.data.length > 0) {
          await this.setState({
            uniqueBillingMonthPayslips: res.data.data,
            empsalarytypeID: '',
            uniqueEmpsalarytypeID: [],
            rowDataSalarySlipGetStatsDeatils: [],
          });
        }
      }).catch((err) => {
        alert(err);
      });
  };

  handleChangeBillingMonth = async (e) => {
    const value = e.target.value;
    await this.setState({
      billingMonthPayslips: value,
    })
    await axios
      .post(
        `${Config.hostName}/ems/monthlySalarySlipGetStats`, {
        salaryMonth: e.target.value.split(" ").join(""),
      }
      )
      .then(async (res) => {
        console.log(res.data);
        if (res.data.data.length > 0) {
          await this.setState({
            uniqueEmpsalarytypeID: res.data.data,
          });
        } else {
          alert('No, data found');
        }
      }).catch((err) => {
        alert(err);
      });

  }

  handleChangeEmpsalarytypeID = async (e) => {
    const value = e.target.value;
    await this.setState({
      empsalarytypeID: value,
    })
    await axios
      .post(
        `${Config.hostName}/ems/monthlySalarySlipGetStatsDetails`, {
        salaryMonth: this.state.billingMonthPayslips.split(" ").join(""),
        empsalarytypeID: e.target.value,
      }
      )
      .then(async (res) => {
        console.log(res.data);
        if (res.data.data.length > 0) {
          await this.setState({
            rowDataSalarySlipGetStatsDeatils: res.data.data,
          });
        } else {
          alert('No, data found');
        }
      }).catch((err) => {
        alert(err);
      });

  }




  freezeAttn = async (e) => {
    await this.setState({
      style: {},
    });
    let content = { "attnUnFreeze": "1" };
    if (this.state.attnFreeze == 0) {
      content = { "attnUnFreeze": "0" };
    }
    await axios
      .post(
        `${Config.hostName}/ems/hrUpdateContent`, {
        userid: this.props.auth.user.USERID,
        contentID: 1,
        content: JSON.stringify(content)
      }
      )
      .then(async (res) => {
        console.log(res.data);
        this.setState({
          attnFreeze: !this.state.attnFreeze
        });
      }).catch((err) => {
        alert(err);
      });
    await this.setState({
      style: {
        display: "none",
      },
    });

  }


  freezeAttn2 = async (e) => {
    await this.setState({
      style: {},
    });
    const content = {};
    await axios
      .post(
        `${Config.hostName}/ems/hrUpdateContent`, {
        userid: this.props.auth.user.USERID,
        contentID: 0,
        content: ""
      }
      )
      .then(async (res) => {
        const data = res.data.data[0];
        console.log(data.attnUnFreeze, 'rohan');
        if (data.attnUnFreeze == 0) {
          this.setState({
            attnFreeze: 1
          });
        } else {
          this.setState({
            attnFreeze: 0
          });
        }

      }).catch((err) => {
        alert(err);
      });
    await this.setState({
      style: {
        display: "none",
      },
    });

  }


  async componentDidMount() {
    await this.setState({
      style: {},
    });
    this.getUniqueBillingMonthPayslips();
    await this.getUniqueDateRanges();
    console.log(this.props.auth.user.user_type);
    await this.dashboardEmployeeStatsCategoryDropdown();
    await this.dashboardEmployeeStats();
    await this.freezeAttn2();
    await this.setState({
      style: {
        display: "none",
      },
    });
  }

  last3Salary = async (name, index) => {
    await this.setState({
      style: {},
    });

    const month = this.state.dateID;
    console.log(month.split(" ").join(""));
    console.log(index);

    const res = await axios
      .post(`${Config.hostName}/ems/onrollSalaryStats`, {
        SalaryMonth: month.split(" ").join(""),
        ReportID: index,
      })
      .then(async (res) => {
        if (res.data.success === true) {
          var workSheet = XLSX.utils.json_to_sheet(res.data.data);
          var workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet 1");
          XLSX.writeFile(workBook, `${month}_${name}` + ".xlsx");
        }
      })
      .catch((error) => {
        alert(error);
      });

    await this.setState({
      style: {
        display: "none",
      },
    });
  }

  handleStatsCategoryDropdownCostCenterWithSalary = async () => {
    await this.setState({
      style: {},
    });

    await axios
      .post(`${Config.hostName}/ems/dashboardEmployeeStats`, {
        userid: this.state.userid,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        locationID: this.state.locationID,
        reportID: 3,
      })
      .then(async (res) => {
        // console.log(res.data.data);
        if (res.data.data.length > 0) {
          await this.setState({
            statsOverAll: res.data.data[0].category1,
            statsOther: res.data.data[0].category2,
          });
          await this.setState({
            style: {
              display: "none",
            },
          });
        } else {
          alert("No Records Found... !!");
        }
        await this.setState({
          style: {
            display: "none",
          },
        });
        let listArr = [];
        let orderListArr = [];


        res.data.data[0].category1.map((value, key) => {
          let upperOffArr = 0;
          let lowerOffArr = 0;

          const newVal = value.OffRoll.split("-");

          console.log(newVal[0]);

          upperOffArr = newVal[0];
          lowerOffArr = newVal[1];

          listArr[key] = value;
          listArr[key]["offUpper"] = upperOffArr;
          listArr[key]["offLower"] = lowerOffArr;
        });

        res.data.data[0].category1.map((value, key) => {
          let upperOnArr = 0;
          let lowerOnArr = 0;
          const newVal = value.OnRoll.split("-");

          upperOnArr = newVal[0];
          lowerOnArr = newVal[1];

          listArr[key] = value;
          listArr[key]["onUpper"] = upperOnArr;
          listArr[key]["onLower"] = lowerOnArr;
        });

        res.data.data[0].category2.map((value, key) => {
          let upperOffArr = 0;
          let lowerOffArr = 0;

          const newVal = value.OffRoll.split("-");

          console.log(newVal[0]);

          upperOffArr = newVal[0];
          lowerOffArr = newVal[1];

          listArr[key] = value;
          listArr[key]["offUpper"] = upperOffArr;
          listArr[key]["offLower"] = lowerOffArr;
        });
        res.data.data[0].category2.map((value, key) => {
          let upperOnArr = 0;
          let lowerOnArr = 0;
          const newVal = value.OnRoll.split("-");

          upperOnArr = newVal[0];
          lowerOnArr = newVal[1];

          listArr[key] = value;
          listArr[key]["onUpper"] = upperOnArr;
          listArr[key]["onLower"] = lowerOnArr;
        });
        await this.setState({
          statsOther: listArr,
        });
        console.log(this.state.statsOverAll);
      })
      .catch((error) => {
        alert(error);
      });


  };

  handleChangeDate = async (e) => {
    await this.setState({
      style: {},
    });

    const id = e.target.value;
    // console.log(e.target.value, "id");
    if (e.target.value != 0) {
      let value = e.target.value;
      if (value == 2222) {
        await this.setState({
          dateID: e.target.value,
          startDate: "",
          endDate: "",
        });
        await this.toggle();
      } else {
        this.state.uniqueDateRanges.filter(async (eachDate, i) => {
          if (eachDate.title == id) {
            console.log(eachDate);
            await this.setState({
              dateID: eachDate.title,
              startDate: eachDate.startDate,
              endDate: eachDate.endDate,
            });
          }
        });
      }

      if (this.state.reportID == 3) {
        await this.handleStatsCategoryDropdownCostCenterWithSalary()
      }
      else {
        await this.dashboardEmployeeStats()
      }
      await this.setState({
        style: {
          display: "none",
        },
      });
    }
  };


  offRollSalarySheet = async () => {
    await this.setState({
      style: {},
    });

    // const month = moment()
    //   .subtract(1, "month")
    //   .startOf("month")
    //   .format("MMMM");
    const month = this.state.dateID;
    console.log(month);

    const res = await axios
      .post(`${Config.hostName}/ems/offRollSalarySheet`, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then(async (res) => {
        if (res.data.success === true) {
          var workSheet = XLSX.utils.json_to_sheet(res.data.data);
          var workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet 1");
          XLSX.writeFile(workBook, `${month} OffRoll Salaries` + ".xlsx");
          console.log(res.data, "clientBillingData");
        }
      })
      .catch((error) => {
        alert(error);
      });

    await this.setState({
      style: {
        display: "none",
      },
    });
  };

  onRollSalarySheet = async () => {
    await this.setState({
      style: {},
    });

    // const month = moment()
    //   .subtract(1, "month")
    //   .startOf("month")
    //   .format("MMMM");
    // console.log(month);
    const month = this.state.dateID;

    const res = await axios
      .post(`${Config.hostName}/ems/onRollSalarySheet`, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then(async (res) => {
        if (res.data.success === true) {
          var workSheet = XLSX.utils.json_to_sheet(res.data.data);
          var workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet 1");
          XLSX.writeFile(workBook, `${month} OnRoll Salaries` + ".xlsx");
          console.log(res.data, "clientBillingData");
        }
      })
      .catch((error) => {
        alert(error);
      });

    await this.setState({
      style: {
        display: "none",
      },
    });
  };

  relianceSalarySheet = async () => {
    await this.setState({
      style: {},
    });

    // const month = moment()
    //   .subtract(1, "month")
    //   .startOf("month")
    //   .format("MMMM");
    // console.log(month);

    const month = this.state.dateID;

    const res = await axios
      .post(`${Config.hostName}/ems/relianceSalarySheet`, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then(async (res) => {
        if (res.data.success === true) {
          var workSheet = XLSX.utils.json_to_sheet(res.data.data);
          var workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet 1");
          XLSX.writeFile(workBook, `${month}_Reliance_Salaries` + ".xlsx");

          const result = res.data.data;
          let payslipData = []
          await result.map((e)=>{
            let final= {};
            final.SalaryMonth =e.SalaryMonth!= '' ?e.SalaryMonth : '-';
            final.FirstName = e.FirstName!= '' ?e.FirstName : '-';
            final.EmployeeID = e.EmployeeID!= '' ?e.EmployeeID : '-';
            final.Designation = e.designation!= '' ?e.designation : '-';
            final.City =e.city!= '' ?e.city : '-';
            final.DaysinTotal = e.DaysinTotal!= '' ?e.DaysinTotal : '-';
            final.DaysPresent = e.DaysPresent!= '' ?e.DaysPresent : '-';

            final.BeneficiaryName  = e.BeneficiaryName != '' ?e.BeneficiaryName : '-';
            final.AccountNo  = e.AccountNo!= '' ?e.AccountNo : '-';
            final.IFSC  = e.IFSC!= '' ?e.IFSC : '-';
            final.PFno  = e.PFno!= '' ?e.PFno : '-';
            final.ESICno  = e.ESICno  != '' ?e.ESICno : 0;
            final.UANno  = e.UANno!= '' ?e.UANno : '-';

            final.Basic = e.Basic!= '' ?e.Basic : '-';
            final.HRA = e.HRA!= '' ?e.HRA : '-';
            final.adv_bonus = e.adv_bonus!= '' ?e.adv_bonus : '-';
            final.adv_leaveEncash = e.adv_leaveEncash!= '' ?e.adv_leaveEncash : '-';
            final.OT = e.OT!= '' ?e.OT : '-';
            final.EE_PF = e.EE_PF!= '' ?e.EE_PF : '-';
            final.eR_LWF = e.ER_LWF!= '' ?e.ER_LWF : '-';
            final.otherallowance = e.otherallowance!= '' ?e.otherallowance : '-';
            final.PT = e.PT!= '' ?e.PT : '-';
            final.eE_ESIC = e.EE_ESIC!= '' ?e.EE_ESIC : '-';
            final.TotalDeductions = e.TotalDeducations != '' ?e.TotalDeducations : 0;
            final.GrossSalary = e.AttnBasedSalary != '' ?e.AttnBasedSalary : 0;
            final.AttnBasedSalary = e.AttnBasedSalary!= '' ?e.AttnBasedSalary : '-';
            final.NetPayout = e.GrandNetPayout != '' ?e.GrandNetPayout : '-';
            final.Remarks  = 'N/A';
            final.SalaryAdvance  = e.SalaryAdvance != '' ?e.SalaryAdvance : 0;
            
            final.Lop  = '-';
            final.Bonus  = e.YearlyBonus!= '' ?e.YearlyBonus : '-';

            
            payslipData.push(final);
          })
          var workSheet = XLSX.utils.json_to_sheet(payslipData);
          var workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook, workSheet, "Reliance");
          XLSX.writeFile(workBook, `${month}_Payslips_Data_Reliance_Salaries` + ".xlsx");
        }
      })
      .catch((error) => {
        alert(error);
      });

    await this.setState({
      style: {
        display: "none",
      },
    });
  };

  clientBillingData = async () => {
    await this.setState({
      style: {},
    });

    // const month = moment()
    //   .subtract(1, "month")
    //   .startOf("month")
    //   .format("MMMM");
    // console.log(month);

    const month = this.state.dateID;

    const res = await axios
      .post(`${Config.hostName}/ems/clientBillingData`, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then(async (res) => {
        if (res.data.success === true) {
          await this.setState({
            rb: res.data.data,
          });
        } else {
          alert("No Data Found");
        }
      })
      .catch((error) => {
        alert(error);
      });

    const result = await axios
      .post(`${Config.hostName}/ems/relianceBillingAttendance`, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
      .then(async (res) => {
        if (res.data.success === true) {
          await this.setState({
            rb2: res.data.data,
          });
        } else {
          alert("No Data Found");
        }
      })
      .catch((error) => {
        alert(error);
      });

    // console.log(this.state.rb,this.state.rb2);

    if (this.state.rb != [] && this.state.rb2 != []) {
      var workSheet = XLSX.utils.json_to_sheet(this.state.rb);
      var workSheet2 = XLSX.utils.json_to_sheet(this.state.rb2);
      var workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Billing");
      XLSX.utils.book_append_sheet(workBook, workSheet2, "Attendance");
      XLSX.writeFile(workBook, `${month} Reliance Client Billing` + ".xlsx");
    }

    await this.setState({
      style: {
        display: "none",
      },
    });
  };

  dashboardEmployeeStats = async () => {
    const res = await axios
      .post(`${Config.hostName}/ems/dashboardEmployeeStats`, {
        userid: this.state.userid,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        locationID: this.state.locationID,
        reportID: 1,
      })
      .then(async (res) => {
        // console.log(res.data.data);
        if (res.data.data.length > 0) {
          await this.setState({
            statsOverAll: res.data.data[0].category1,
            statsOther: res.data.data[0].category2,
          });
        } else {
          alert("No Records Found... !!");
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  dashboardEmployeeStatsOverallDetails = async () => {
    await this.setState({
      style: {},
    });
    const res = await axios
      .post(`${Config.hostName}/ems/dashboardEmployeeStatsDetails`, {
        reportID: 4,
        statusID: "",
        segment: '',
        isOnroll: '',
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.data.length > 0) {

          await this.setState({
            rowDataStats: res.data.data,
            statsData: res.data.data,
            statsModal: true,
          });
        } else {
          alert("No Records Found... !!");
        }
        await this.setState({
          style: {
            display: "none",
          },
        });
      })
      .catch((error) => {
        alert(error);
        this.setState({
          style: {
            display: "none",
          },
        });
      });
  };


  dashboardEmployeeStatsDetails = async (statusID, segment, roll) => {
    await this.setState({
      style: {},
    });
    const res = await axios
      .post(`${Config.hostName}/ems/dashboardEmployeeStatsDetails`, {
        reportID: this.state.reportID,
        statusID: this.state.reportID != 2 ? statusID : "",
        segment: segment,
        isOnroll: roll,
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.data.length > 0) {
          await this.setState({
            rowDataStats: res.data.data,
            statsData: res.data.data,
            statsModal: true,
          });
        } else {
          alert("No Records Found... !!");
        }
        await this.setState({
          style: {
            display: "none",
          },
        });
      })
      .catch((error) => {
        alert(error);
        this.setState({
          style: {
            display: "none",
          },
        });
      });
  };

  dashboardEmployeeStatsCategoryDropdown = async () => {
    await axios
      .get(`${Config.hostName}/ems/dashboardEmployeeStatsCategoryDropdown`)
      .then((res) => {
        console.log(res.data.data);
        this.setState({
          StatsCategoryDropdown: res.data.data,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  handleStatsCategoryDropdown = async (e) => {
    await this.setState({
      style: {},
    });

    if (e.target.value != "") {
      await this.setState({
        reportID: e.target.value,
      });

      await axios
        .post(`${Config.hostName}/ems/dashboardEmployeeStats`, {
          userid: this.state.userid,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          locationID: this.state.locationID,
          reportID: e.target.value,
        })
        .then(async (res) => {
          // console.log(res.data.data);
          if (res.data.data.length > 0) {
            await this.setState({
              statsOverAll: res.data.data[0].category1,
              statsOther: res.data.data[0].category2,
            });
            await this.setState({
              style: {
                display: "none",
              },
            });
          } else {
            alert("No Records Found... !!");
          }

          let listArr = [];
          let orderListArr = [];

          if (e.target.value === 3) {
            res.data.data[0].category1.map((value, key) => {
              let upperOffArr = 0;
              let lowerOffArr = 0;

              const newVal = value.OffRoll.split("-");

              console.log(newVal[0]);

              upperOffArr = newVal[0];
              lowerOffArr = newVal[1];

              listArr[key] = value;
              listArr[key]["offUpper"] = upperOffArr;
              listArr[key]["offLower"] = lowerOffArr;
            });

            res.data.data[0].category1.map((value, key) => {
              let upperOnArr = 0;
              let lowerOnArr = 0;
              const newVal = value.OnRoll.split("-");

              upperOnArr = newVal[0];
              lowerOnArr = newVal[1];

              listArr[key] = value;
              listArr[key]["onUpper"] = upperOnArr;
              listArr[key]["onLower"] = lowerOnArr;
            });

            res.data.data[0].category2.map((value, key) => {
              let upperOffArr = 0;
              let lowerOffArr = 0;

              const newVal = value.OffRoll.split("-");

              console.log(newVal[0]);

              upperOffArr = newVal[0];
              lowerOffArr = newVal[1];

              listArr[key] = value;
              listArr[key]["offUpper"] = upperOffArr;
              listArr[key]["offLower"] = lowerOffArr;
            });
            res.data.data[0].category2.map((value, key) => {
              let upperOnArr = 0;
              let lowerOnArr = 0;
              const newVal = value.OnRoll.split("-");

              upperOnArr = newVal[0];
              lowerOnArr = newVal[1];

              listArr[key] = value;
              listArr[key]["onUpper"] = upperOnArr;
              listArr[key]["onLower"] = lowerOnArr;
            });
            await this.setState({
              statsOther: listArr,
            });
          } else {
            console.log("no split");
          }

          console.log(this.state.statsOverAll);
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  // handleFilter = async (e) => {

  //   const res = await axios
  //     .post(`${Config.hostName}/ems/generatePayslip`, {
  //       userID: "",
  //       startDate: this.state.startDate,
  //       endDate: this.state.endDate,
  //       dateID: this.state.dateID,
  //       // startDate: "2022-11-01",
  //       // endDate: "2022-11-30",
  //     })
  //     .then(async (res) => {
  //       console.log(res);
  //       if (res.data.length > 0) {
  //         console.log(res.data)
  //         await this.setState({
  //           UploadPayslipError: true,
  //           UploadPayslipErrorData: res.data.data,
  //         })
  //       } else {
  //         console.log(res);
  //         alert('Payslips Uploaded!!');
  //       }

  //     })
  //     .catch(async (err) => {
  //       alert(err.message);
  //     });
  // }

  handleCloseUploadPayslipError = async () => {
    await this.setState({
      UploadPayslipError: false,
      UploadPayslipErrorData: [],
    })
    window.location.reload();
  }

  removeSpacesFromKeys = async (data) => {
    return data.map(obj => {
      const newObj = {};
      for (const key in obj) {
        newObj[key.trim()] = `${obj[key]}`;
      }
      return newObj;
    });
  };


  checkTemplateFileOnroll = async (data) => {
    const arr1 = [
      {
        "SalaryMonth": "Jan",
        "FirstName": "John",
        "EmployeeID": "123",
        "DOJ": "2022-01-01",
        "Designation": "Manager",
        "City": "New York",

        "DaysinTotal": "31",
        "DaysPresent": "27",

        'BeneficiaryAcctNo': 30,
        'BeneficiaryIFSC': 30,

        "Basic": "2000",
        "HRA": "1000",
        "Convience": "500",
        "Medical": "200",
        "LTA": "0",
        "DriverSalary": "0",
        "Petrol": "0",
        "Books": "0",
        "SpecialAllowance": "1100",
        "AdditionalAllowance": "100",
        "GrossSalary": "5000",
        "PT": "200",
        "EE_PF": "240",
        "ER_PF": "240",
        "GHI": "100",
        "TDS": "0",
        "TotalDeductions": "780",
        "NetPayout": "4020",


        AttnBasedSalary: "4800",
        'Remarks': 30,
        eE_ESIC: '3',
        eR_ESIC: '3',
        SalaryAdvance: '3',
        PFno: '3',
        ESICno: '3',
        UANno: '3',
        BeneficiaryName: '3',
        AccountNo: '3',
        IFSC: '3',
        Lop: '3',
        VariablePay: '3',
        Bonus: '3',

      }];
    const newData = await this.removeSpacesFromKeys(data);

    const arr1Keys = await Object.keys(arr1[0]);
    const arr2Keys = await Object.keys(newData[0]);
    console.log(arr2Keys, 'arr2keys');
    console.log(arr1Keys, 'arr1Keys');
    let result = true;

    let arr2 = [];
    await arr2Keys.map(async (e) => {
      let n = e.replace(/\s/g, '');
      arr2.push(n);
    })

    await arr1Keys.map(async (e) => {
      if (!arr2.includes(e)) {
        result = false;
      }
    });
    return result;

  }

  checkTemplateFileOffroll = async (data) => {
    const arr1 = [
      {
        SalaryMonth: "January",
        FirstName: "John",
        EmployeeID: "12345",

        Designation: "Manager",
        City: "New York",

        DaysinTotal: 31,
        DaysPresent: 26,
        'BeneficiaryAcctNo': 30,
        'BeneficiaryIFSC': 30,
        GrossSalary: 5000,
        AttnBasedSalary: 4500,
        NetPayout: 3500,

        'Remarks': 30,
        eE_ESIC: '3',
        eR_ESIC: '3',
        SalaryAdvance: '3',
        BeneficiaryName: '3',
        AccountNo: '3',
        IFSC: '3',
        Lop: '3',
        Bonus: '3',
      }];
    const arr1Keys = await Object.keys(arr1[0]);
    const arr2Keys = await Object.keys(data[0]);
    console.log(arr1Keys)
    console.log(arr2Keys)
    let result = true;

    let arr2 = [];
    await arr2Keys.map(async (e) => {
      let n = e.replace(/\s/g, '');
      arr2.push(n);
    })
    console.log(arr2);
    await arr1Keys.map(async (e) => {
      if (!arr2.includes(e)) {
        console.log(e);
        result = false;
      }
    });
    return result;

  }


  checkTemplateFileRils = async (data) => {
    const arr1 = [
      {
        'SalaryMonth': "January",
        'FirstName': "John",
        'EmployeeID': "123",
        'Designation': "Manager",
        'City': "New York",
        'DaysinTotal': 31,
        'DaysPresent': 25,
        'BeneficiaryName': 30,
        'AccountNo': 30,
        'IFSC': 30,
        'PFno': 30,
        'ESICno': 30,
        'UANno': 30,
        'Basic': 25000,
        'HRA': 10000,
        'adv_bonus': 5000,
        'adv_leaveEncash': 0,
        'OT': 0,
        'EE_PF': 2000,
        'eR_LWF': 1000,
        'otherallowance': 5000,
        'PT': 200,
        'eE_ESIC': 0,
        'TotalDeductions': 4000,
        'GrossSalary': 50000,
        'AttnBasedSalary': 50000,
        'NetPayout': 46000,
        'Remarks': 30,
        SalaryAdvance: '3',
        Lop: '3',
        Bonus: '3',

      }];
    const newData = await this.removeSpacesFromKeys(data);

    const arr1Keys = await Object.keys(arr1[0]);
    const arr2Keys = await Object.keys(newData[0]);
    console.log(arr1Keys, 'arr1')
    console.log(arr2Keys, 'arr2')
    let result = true;


    await arr1Keys.map(async (e) => {
      if (!arr2Keys.includes(e)) {
        console.log(e);
        result = false;
      }
    });
    return result;

  }





  generatePaySlip = async (data, typeID, sheetName) => {
    const salaryMonth = this.state.dateID;
    const formattedMonth = salaryMonth.replace(/\s/g, '');
    const newData = await this.removeSpacesFromKeys(data);

    await newData.map(async (e) => {
      const excelSerialNumber = e.SalaryMonth;
      const date = new Date((excelSerialNumber - 25569) * 24 * 60 * 60 * 1000); // Convert Excel serial number to Unix timestamp
      const monthYearString = date.toLocaleDateString("en-US", { month: "short", year: "numeric" }).toUpperCase().replace("/", "");
      e.SalaryMonth = monthYearString.replace(" ", "");
    })

    const res = await axios
      .post(`${Config.hostName}/ems/monthlyBulkUPload`, {
        billingMonth: formattedMonth,
        sheetName: sheetName,
        jdata: JSON.stringify(newData),
      })
      .then(async (res) => {
        console.log(res.data);

        if (res.data.data.length > 0) {
          await this.setState({
            UploadPayslipError: true,
            UploadPayslipErrorData: res.data.data,
          })
        } else {
          alert('Payslips Uploaded!!');
          // window.location.reload();
        }

      })
      .catch((error) => {
        alert(error.message);
      });

    return 1;
  }

  handleChangeCheckbox = async (e, userid) => {

    console.log(userid);
  }

  handleChangeAttachment = async (e) => {

    const file = e.target.files[0];
    this.fileInputRef.current.value = '';


    await this.setState({
      style: {},
    });
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      console.log(sheetName, 'shetname');

      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet);
      if (sheetName === 'OnRoll') {
        const res = await this.checkTemplateFileOnroll(excelData);
        if (res == true) {
          await this.generatePaySlip(excelData, 1, 'OnRoll');
        } else {
          alert("Invalid template");
        }
      } else if (sheetName === 'Reliance') {
        const res = await this.checkTemplateFileRils(excelData);
        if (res == true) {
          await this.generatePaySlip(excelData, 2, 'Reliance');
        } else {
          alert("Invalid template");
        }
      } else if (sheetName === 'OffRoll') {
        const res = await this.checkTemplateFileOffroll(excelData);
        if (res == true) {
          await this.generatePaySlip(excelData, 3, 'OffRoll');
        } else {
          alert("Invalid template");
        }
      } else {
        alert("Invalid sheet name");

      }

    };
    reader.readAsArrayBuffer(file);
    await this.setState({
      style: {
        display: "none",
      },
    });




  }

  handleOpenGeneratePalyslip = async () => {
    await this.setState({
      generatePalyslipModal: true,
    });
  }

  processPayslips = async () => {
    await this.setState({
      style: {},
    });
    let userID = [];
    await this.state.selectedRows.map((e) => {
      userID.push(this.state.rowDataSalarySlipGetStatsDeatils[e]['userid']);
    })


    await axios
      .post(
        `${Config.hostName}/ems/monthlySalarySlipGenerateGetUsers`, {
        userid: userID,
        billingMonth: this.state.billingMonthPayslips.split(" ").join(""),
        empsalarytypeID: this.state.empsalarytypeID
      }
      )
      .then(async (res) => {
        console.log(res.data);
        alert('Payslips generated successfully');
        // this.handleCloseGeneratePalyslip();
      }).catch((err) => {
        alert(err);
      });

    console.log(userID)

    await this.setState({
      style: {
        display: "none",
      },
    });
  }

  handleCloseGeneratePalyslip = async () => {
    await this.setState({
      generatePalyslipModal: false,
      rowsSelected: [],
      rowDataSalarySlipGetStatsDeatils: [],
      billingMonthPayslips: '',
      UploadPayslipError: false,
      UploadPayslipErrorData: [],
      empsalarytypeID: '',
      uniqueEmpsalarytypeID: [],
    });
  }


  handleCloseStatsReport = async () => {
    await this.setState({
      statsModal: false,
    });
  };

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  render() {
    const { vertical, horizontal } = this.state;

    return (
      <div>
        <Container
          pt={3}
          style={{
            maxWidth: "inherit",
            background: "white",
            paddingBottom: "12%",
            marginTop: "7%",
          }}
        >
          <div id="cover-spin" style={this.state.style}></div>

          <Grid pt={3} container >

            <Grid item md={2} style={{ marginBottom: "5vh" }}>
              <FormControl
                variant="standard"
                sx={{ width: "19vh" }}
                style={{ marginLeft: "0px" }}
              >
                <InputLabel id="viewInvoiceBilling">Month</InputLabel>
                <Select
                  labelId="viewInvoiceBilling"
                  id="viewInvoiceBilling2"
                  value={this.state.dateID}
                  onChange={(e) => this.handleChangeDate(e)}
                  label="Month"
                  variant="standard"
                >
                  <MenuItem value="">
                    <em>Months</em>
                  </MenuItem>

                  {this.state.uniqueDateRanges.map((eachItem, key) => (
                    <MenuItem value={eachItem.title} key={key}>
                      {eachItem.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>


            </Grid>
            <Grid item md={4} className="mt-1" style={{ marginBottom: "5vh" }}>

              <Button
                color="primary"
                size="large"
                variant="contained"
                component="label"
                onChange={(e) => { this.handleChangeAttachment(e) }}
                style={{ color: "white", background: '#ea4345' }}

              >
                Upload Final Salary Sheets
                <input
                  type="file"
                  hidden
                  id="fileUploadFinal"
                  accept=".xls,.xlsx"
                  ref={this.fileInputRef}

                />
              </Button>
            </Grid>



            <Grid item md={3} className="mt-1" style={{ marginBottom: "5vh" }}>

              <Button
                color="primary"
                size="large"
                variant="contained"
                component="label"
                onClick={this.handleOpenGeneratePalyslip}
                style={{ color: "white", background: '#ea4345' }}

              >
                Generate Payslips
              </Button>
            </Grid>

            <Grid item md={3} className="mt-1" style={{ marginBottom: "5vh", textAlign: 'right' }}>

              <Tooltip title="Download">
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  component="label"
                  onClick={(e) => { this.handleOpenDownloadTemplateModal() }}
                  style={{ color: "white", background: '#ea4345' }}
                // variant="outlined"
                >
                  <DownloadIcon />
                </Button>
              </Tooltip>
            </Grid>




            <Grid item md={2}>
              <Grid container>

                <Grid mt={1} item md={12}>
                  <Typography pt={1} style={{ color: "#333333" }} variant="h6">
                    {" "}
                    <b> Salary Sheets:</b>
                  </Typography>
                </Grid>

                {
                  this.props.auth.user.USERID == 2582 &&

                  <Grid style={{ textAlign: "center" }} mt={1} item md={12}>
                    {this.state.attnFreeze == 1 &&
                      <Button
                        sx={{
                          borderRadius: 28,
                          border: "1px solid #d2194b80",
                          color: "#f5506f",
                        }}
                        className="hoverChip"
                        variant="outlined"
                        onClick={() => this.freezeAttn()}
                      >
                        Attn Unfreeze
                      </Button>
                    }

                    {this.state.attnFreeze == 0 &&
                      <Button
                        sx={{
                          borderRadius: 28,
                          border: "1px solid #1390ea",
                          color: "#1390ea",
                        }}
                        className="hoverChip2"
                        variant="outlined"
                        onClick={() => this.freezeAttn()}
                      >
                        Attn Freeze
                      </Button>
                    }

                  </Grid>

                }


              </Grid>
            </Grid>

            <Grid item md={10}>
              <Grid container>

                <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                  <Button
                    sx={{
                      borderRadius: 28,
                      border: "1px solid #d2194b80",
                      color: "#f5506f",
                    }}
                    className="hoverChip"
                    variant="outlined"
                    onClick={() => this.relianceSalarySheet()}
                  >
                    RS
                  </Button>
                </Grid>
                <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                  <Button
                    sx={{
                      borderRadius: 28,
                      border: "1px solid #d2194b80",
                      color: "#f5506f",
                    }}
                    variant="outlined"
                    className="hoverChip"
                    onClick={() => this.clientBillingData()}
                  >
                    RB
                  </Button>
                </Grid>

                <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                  <Button
                    sx={{
                      borderRadius: 28,
                      border: "1px solid #d2194b80",
                      color: "#f5506f",
                    }}
                    variant="outlined"
                    className="hoverChip"
                    onClick={() => this.onRollSalarySheet()}
                  >
                    OnS
                  </Button>
                </Grid>

                <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                  <Button
                    sx={{
                      borderRadius: 28,
                      border: "1px solid #d2194b80",
                      color: "#f5506f",
                    }}
                    className="hoverChip"
                    variant="outlined"
                    onClick={() => this.offRollSalarySheet()}
                  >
                    OfS
                  </Button>
                </Grid>




                {/* {this.props.auth.user.USERID == 1376  &&
                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_Onroll', 1)}
                    >
                      L3M_Onroll
                    </Button>
                  </Grid>
                } */}




                {this.props.auth.user.user_type == 'hr' &&
                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_Reliance', 2)}
                    >
                      L3M_Reliance
                    </Button>
                  </Grid>
                }



                {this.props.auth.user.user_type == 'hr' &&
                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_OVERALL', 4)}
                    >
                      L3M_OVERALL
                    </Button>
                  </Grid>
                }

                {this.props.auth.user.user_type == 'hr' &&

                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_Onroll', 1)}
                    >
                      L3M_Onroll
                    </Button>
                  </Grid>
                }

                {this.props.auth.user.user_type == 'hr' &&
                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_OFFroll', 3)}
                    >
                      L3M_OFFroll
                    </Button>
                  </Grid>
                }


                {this.props.auth.user.USERID == 1376 &&
                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_Reliance', 2)}
                    >
                      L3M_Reliance
                    </Button>
                  </Grid>
                }



                {this.props.auth.user.USERID == 1376 &&
                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_OVERALL', 4)}
                    >
                      L3M_OVERALL
                    </Button>
                  </Grid>
                }

                {this.props.auth.user.USERID == 1376 &&

                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_Onroll', 1)}
                    >
                      L3M_Onroll
                    </Button>
                  </Grid>
                }

                {this.props.auth.user.USERID == 1376 &&
                  <Grid style={{ textAlign: "center" }} mt={1} item md={3}>
                    <Button
                      sx={{
                        borderRadius: 28,
                        border: "1px solid #1390ea",
                        color: "#1390ea",
                      }}
                      className="hoverChip2"
                      variant="outlined"
                      onClick={() => this.last3Salary('L3M_OFFroll', 3)}
                    >
                      L3M_OFFroll
                    </Button>
                  </Grid>
                }





              </Grid>
            </Grid>
          </Grid>

          <Grid mt={3}>
            <Grid item md={12}>
              <Card
                style={{
                  marginTop: "20px",
                }}
              >
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Typography
                        component="h5"
                        style={{
                          lineHeight: 3.5,
                        }}
                      >
                        Employee Stats
                      </Typography>
                    </div>
                    <div>
                      <FormControl
                        variant="standard"
                        sx={{ width: "30vh" }}
                        style={{ marginLeft: "0px" }}
                      >
                        <InputLabel id="viewStatsCategory">
                          Stats Category
                        </InputLabel>
                        <Select
                          labelId="viewStatsCategory"
                          id="viewStatsCategory1"
                          value={this.state.reportID}
                          onChange={(e) => this.handleStatsCategoryDropdown(e)}
                          label="Stats Category"
                          variant="standard"
                        >
                          <MenuItem value="">
                            <em>Stats Category</em>
                          </MenuItem>

                          {this.state.StatsCategoryDropdown.map(
                            (eachItem, key) => (
                              <MenuItem value={eachItem.reportID} key={key}>
                                {eachItem.reporttype}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      {this.state.statsOverAll.map((overall, index) => (
                        <Card key={index}>
                          <CardActionArea
                            style={{
                              cursor: "default",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "antiquewhite",
                              }}
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                  fontWeight: "600",
                                  padding: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.dashboardEmployeeStatsOverallDetails()
                                }
                              >
                                {overall.Segment}
                              </p>
                            </div>
                          </CardActionArea>
                          <CardContent>
                            {this.state.reportID !== 3 ? (
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <h1
                                      style={{
                                        fontSize: `${this.state.reportID === 3
                                          ? "2rem"
                                          : "2rem"
                                          }`,
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          overall.StatusID,
                                          overall.Segment,
                                          1
                                        )
                                      }
                                    >
                                      {overall.OnRoll}
                                    </h1>
                                  </div>
                                </div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    color: "grey",
                                  }}
                                >
                                  On Roll
                                </p>

                                <div
                                  style={{
                                    backgroundColor: "black",
                                    height: "1px",
                                    marginBottom: "20px",
                                  }}
                                ></div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <h1
                                      style={{
                                        fontSize: `${this.state.reportID === 3
                                          ? "2rem"
                                          : "2rem"
                                          }`,
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          overall.StatusID,
                                          overall.Segment,
                                          0
                                        )
                                      }
                                    >
                                      {overall.OffRoll}
                                    </h1>
                                  </div>
                                </div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    color: "grey",
                                  }}
                                >
                                  Off Roll
                                </p>
                              </div>
                            ) : (
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <h1
                                      style={{
                                        fontSize: `${this.state.reportID === 3
                                          ? "2rem"
                                          : "2rem"
                                          }`,
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          overall.StatusID,
                                          overall.Segment,
                                          1
                                        )
                                      }
                                    >
                                      {overall.onUpper}
                                    </h1>
                                  </div>
                                </div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    color: "grey",
                                  }}
                                >
                                  On Roll
                                </p>

                                <h1
                                  style={{
                                    fontSize: `${this.state.reportID === 3
                                      ? "1rem"
                                      : "1rem"
                                      }`,
                                    textAlign: "center",
                                    color: "grey",

                                    fontFamily:
                                      "Roboto,Helvetica,Arial,sans-serif",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.dashboardEmployeeStatsDetails(
                                      overall.StatusID,
                                      overall.Segment,
                                      1
                                    )
                                  }
                                >
                                  ₹ {overall.onLower}
                                </h1>

                                <div
                                  style={{
                                    backgroundColor: "black",
                                    height: "1px",
                                    marginBottom: "20px",
                                  }}
                                ></div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <h1
                                      style={{
                                        fontSize: `${this.state.reportID === 3
                                          ? "2rem"
                                          : "2rem"
                                          }`,
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          overall.StatusID,
                                          overall.Segment,
                                          0
                                        )
                                      }
                                    >
                                      {overall.offUpper}
                                    </h1>
                                  </div>
                                </div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    color: "grey",
                                  }}
                                >
                                  Off Roll
                                </p>

                                <h1
                                  style={{
                                    fontSize: `${this.state.reportID === 3
                                      ? "1rem"
                                      : "1rem"
                                      }`,
                                    textAlign: "center",
                                    color: "grey",

                                    fontFamily:
                                      "Roboto,Helvetica,Arial,sans-serif",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.dashboardEmployeeStatsDetails(
                                      overall.StatusID,
                                      overall.Segment,
                                      0
                                    )
                                  }
                                >
                                  ₹ {overall.offLower}
                                </h1>
                              </div>
                            )}
                          </CardContent>
                        </Card>
                      ))}
                    </Grid>
                    <Grid item xs={9}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          flexWrap: "wrap",
                        }}
                      >
                        {this.state.statsOther.map((stats, index) => (
                          <Card
                            style={{
                              width: "30%",
                              marginBottom: "10px",
                            }}
                            key={index}
                          >
                            <CardActionArea
                              style={{
                                cursor: "default",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#e1effb",
                                }}
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "600",
                                    padding: "5px",
                                  }}
                                >
                                  {stats.Segment}
                                </p>
                              </div>
                            </CardActionArea>
                            <CardContent>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                }}
                              >
                                {this.state.reportID !== 3 ? (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <h3
                                          style={{
                                            fontSize: `${this.state.reportID === 3
                                              ? "12px"
                                              : "1.75rem"
                                              }`,
                                            paddingTop: "0.5rem",
                                            fontFamily:
                                              "Roboto,Helvetica,Arial,sans-serif",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.dashboardEmployeeStatsDetails(
                                              stats.StatusID,
                                              stats.Segment,
                                              1
                                            )
                                          }
                                        >
                                          {stats.OnRoll}
                                        </h3>
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        color: "grey",
                                        fontSize: "11px",
                                      }}
                                    >
                                      On Roll
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <h3
                                          style={{
                                            paddingTop: "0.5rem",
                                            fontFamily:
                                              "Roboto,Helvetica,Arial,sans-serif",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.dashboardEmployeeStatsDetails(
                                              stats.StatusID,
                                              stats.Segment,
                                              1
                                            )
                                          }
                                        >
                                          {stats.onUpper}
                                        </h3>
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        color: "grey",
                                        fontSize: "11px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      On Roll
                                    </p>
                                    <h3
                                      style={{
                                        fontSize: `${this.state.reportID === 3
                                          ? "12px"
                                          : "1.75rem"
                                          }`,
                                        color: "grey",
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          stats.StatusID,
                                          stats.Segment,
                                          1
                                        )
                                      }
                                    >
                                      ₹ {stats.onLower}
                                    </h3>
                                  </div>
                                )}

                                <div>
                                  <p
                                    style={{
                                      fontSize: "2rem",
                                    }}
                                  >
                                    |
                                  </p>
                                </div>

                                {this.state.reportID !== 3 ? (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <h3
                                          style={{
                                            fontSize: `${this.state.reportID === 3
                                              ? "12px"
                                              : "1.75rem"
                                              }`,
                                            paddingTop: "0.5rem",
                                            fontFamily:
                                              "Roboto,Helvetica,Arial,sans-serif",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.dashboardEmployeeStatsDetails(
                                              stats.StatusID,
                                              stats.Segment,
                                              0
                                            )
                                          }
                                        >
                                          {stats.OffRoll}
                                        </h3>
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        color: "grey",
                                        fontSize: "11px",
                                      }}
                                    >
                                      Off Roll
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <h3
                                          style={{
                                            paddingTop: "0.5rem",
                                            fontFamily:
                                              "Roboto,Helvetica,Arial,sans-serif",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.dashboardEmployeeStatsDetails(
                                              stats.StatusID,
                                              stats.Segment,
                                              0
                                            )
                                          }
                                        >
                                          {stats.offUpper}
                                        </h3>
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        color: "grey",
                                        fontSize: "11px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      Off Roll
                                    </p>
                                    <h3
                                      style={{
                                        fontSize: `${this.state.reportID === 3
                                          ? "12px"
                                          : "1.75rem"
                                          }`,
                                        color: "grey",
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          stats.StatusID,
                                          stats.Segment,
                                          0
                                        )
                                      }
                                    >
                                      ₹ {stats.offLower}
                                    </h3>
                                  </div>
                                )}
                              </div>
                            </CardContent>
                          </Card>
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <Dialog
          fullScreen
          TransitionComponent={this.Transition}
          onClose={this.handleCloseStatsReport}
          open={this.state.statsModal}
        >
          <Container
            maxWidth="xl"
            style={{
              maxWidth: "initial",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <AppBar
              style={{
                height: "auto",
                backgroundColor: "white",
                color: "black",
              }}
              sx={{ position: "relative" }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseStatsReport}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      Sound
                    </Typography> */}
                <Button
                  autoFocus
                  color="inherit"
                  onClick={this.handleCloseStatsReport}
                >
                  Close
                </Button>
              </Toolbar>
            </AppBar>

            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                Update Status To: <b>{this.state.statusText}</b>
              </Typography> */}
            <MUIDataTable
              title={"Employee List"}
              data={this.state.statsData}
              columns={this.state.statsColumn}
              options={options}
            />
          </Container>
        </Dialog>



        <Modal
          open={this.state.UploadPayslipError}
          onClose={this.handleCloseUploadPayslipError}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style5}>
            <div className="row" style={{ marginBottom: "18px" }}>
              <div className="col-sm-12">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  An error occurred while uploading the payslips.
                </Typography>
                <small style={{ color: 'red' }}> Please fix these employees data and re-upload the file.</small>
                <div>
                </div>

              </div>
              <div className="col-sm-12 mt-3" style={{ maxHeight: '420px', minHeight: '300px' }}>
                <div style={{ height: '89%' }} className="table-responsive" >
                  <table className="tableInvoice table">
                    <tr>
                      <th >
                        City
                      </th>
                      <th>
                        EmployeeID
                      </th>
                      <th>
                        FirstName
                      </th>
                      <th>
                        Remarks
                      </th>
                    </tr>
                    {this.state.UploadPayslipErrorData != null &&
                      this.state.UploadPayslipErrorData.map((e, key) => (
                        <tr>
                          <td>
                            {e.City}
                          </td>
                          <td>
                            {e.EmployeeID}
                          </td>
                          <td>
                            {e.FirstName}
                          </td>
                          <td>
                            <span style={{ color: 'red' }}>
                              {e.Remarks}
                            </span>
                          </td>
                        </tr>
                      ))
                    }
                  </table>
                </div>
              </div>
              <div className="col-sm-12" style={{ textAlign: 'right' }}>
                <Button
                  onClick={this.handleCloseUploadPayslipError}
                  size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }} >
                  Close
                </Button>
              </div>

            </div>
          </Box>
        </Modal>


        <Modal
          open={this.state.showDownloadPayslipModal}
          onClose={this.handleCloseDownloadTemplateModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style5}>
            <div className="row" style={{ marginBottom: "18px" }}>
              <div className="col-sm-12">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Download Salary Sheets Template
                </Typography>
                {/* <small style={{ color: 'red' }}> Please modify the downloaded template with employees data and re-upload the file.</small> */}

                <div>
                  <Grid pt={2} container >

                    <Grid item md={3} style={{ marginBottom: "5vh" }}>
                      <FormControl
                        variant="standard"
                        sx={{ width: "30vh" }}
                        style={{ marginLeft: "0px" }}
                      >
                        <InputLabel id="viewInvoiceBilling">Payslip Type</InputLabel>
                        <Select
                          labelId="viewInvoiceBilling"
                          id="viewInvoiceBilling2"
                          value={this.state.selectedSheet}
                          onChange={(e) => this.setState({
                            selectedSheet: e.target.value
                          })}
                          label="Month"
                          variant="standard"
                        >
                          <MenuItem value="1">
                            OnRoll Template
                          </MenuItem>

                          <MenuItem value="2">
                            Reliance Template
                          </MenuItem>

                          <MenuItem value="3">
                            OffRoll Template
                          </MenuItem>


                        </Select>
                      </FormControl>


                    </Grid>

                    <Grid item md={2} style={{ marginBottom: "5vh" }}>
                    </Grid>


                    <Grid item md={4} style={{ marginBottom: "5vh" }}>
                      <Tooltip title="Download Template File">
                        <Button size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }}>
                          {this.state.selectedSheet == 1 &&
                            <a style={{ color: 'white' }} href="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1708507508308_onrollsalarysheet.xlsx" target="_blank"><DownloadIcon /> Download</a>
                          }

                          {this.state.selectedSheet == 2 &&
                            <a style={{ color: 'white' }} href="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1699604983407_reliancesalarysheet.xlsx" target="_blank"><DownloadIcon /> Download</a>
                          }

                          {this.state.selectedSheet == 3 &&
                            <a style={{ color: 'white' }} href="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1695820380081_Offroll.xlsx" target="_blank"><DownloadIcon /> Download</a>
                          }
                        </Button>
                      </Tooltip>
                    </Grid>

                  </Grid>
                </div>

              </div>



              <div className="col-sm-12" style={{ textAlign: 'right' }}>
                <Button
                  onClick={this.handleCloseDownloadTemplateModal}
                  size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }} >
                  Close
                </Button>

              </div>

            </div>
          </Box>
        </Modal>


        <Dialog
          fullScreen
          TransitionComponent={this.Transition}
          onClose={this.handleCloseGeneratePalyslip}
          open={this.state.generatePalyslipModal}
        >
          <Container
            maxWidth="xl"
            style={{
              maxWidth: "initial",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <AppBar
              style={{
                height: "auto",
                backgroundColor: "white",
                color: "black",
              }}
              sx={{ position: "relative" }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseGeneratePalyslip}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      Sound
                    </Typography> */}
                <Button
                  autoFocus
                  color="inherit"
                  onClick={this.handleCloseGeneratePalyslip}
                >
                  Close
                </Button>
              </Toolbar>
            </AppBar>


            <div style={{ margin: "26px" }}>
              <Card>
                <CardContent>
                  <Typography variant="h5" color="inherit" component="div" style={{ textAlign: 'center' }}>
                    <b> Generate Payslips</b>
                  </Typography>
                  <br />

                  <Grid pt={1} container spacing={1}>
                    <Grid item md={3}>
                      <FormControl variant="standard" sx={{ width: '30ch' }} style={{ marginLeft: '0px' }}>
                        <InputLabel id="demo-simple-select-standard2">Billing Month </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard2"
                          value={this.state.billingMonthPayslips}
                          onChange={(e) => { this.handleChangeBillingMonth(e) }}
                          variant="standard"
                        >
                          {this.state.uniqueBillingMonthPayslips.map((name, key) => (
                            <MenuItem key={key} value={name.billingMonth}>
                              {name.billingMonth}
                            </MenuItem>
                          ))}

                        </Select>
                      </FormControl>
                    </Grid>

                    {this.state.uniqueEmpsalarytypeID.length > 0 &&
                      <Grid item md={3}>
                        <FormControl variant="standard" sx={{ width: '30ch' }} style={{ marginLeft: '0px' }}>
                          <InputLabel id="demo-simple-select-standard2">Salary Type</InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard2"
                            value={this.state.empsalarytypeID}
                            onChange={(e) => { this.handleChangeEmpsalarytypeID(e) }}
                            variant="standard"
                          >
                            <MenuItem >
                              Select Salary Type
                            </MenuItem>
                            {this.state.uniqueEmpsalarytypeID.map((name, key) => (
                              <MenuItem key={key} value={name.empsalarytypeID}>
                                {name.SalaryType}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    }


                    <Grid item md={6} style={{ marginTop: '12px' }}>
                      {/* <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        component="label"
                        onClick={this.handleOpenGeneratePalyslip}
                        style={{ color: "white", background: '#ea4345' }}

                      >
                        Process Payslips
                      </Button> */}
                    </Grid>


                    <Grid item md={12} style={{ marginTop: '4vh', marginBottom: '10vh' }}>
                      <Paper elevation={6}>
                        {
                          this.state.rowDataSalarySlipGetStatsDeatils.length > 0 &&
                          <MUIDataTable
                            title='Select Employees'
                            data={this.state.rowDataSalarySlipGetStatsDeatils}
                            columns={this.state.columnsDataSalarySlipGetStatsDeatils}
                            options={this.state.options4}
                            style={{ width: "100%" }}
                          />
                        }


                      </Paper>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
            {/* <MUIDataTable
              title={"Employee List"}
              data={this.state.statsData}
              columns={this.state.statsColumn}
              options={options}
            /> */}
          </Container>
        </Dialog>
      </div >
    );
  }
}

EmployeeStats.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(EmployeeStats);
