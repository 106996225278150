import React, {
    Component
    // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import './layout.css';
import Select from '@mui/material/Select';
import { Switch, Modal, Box, Checkbox, FormControlLabel, Chip, Collapse, Alert, AlertTitle, Toolbar, AppBar, Dialog, Slide, Tooltip, IconButton, Grid, Container, Typography, TextField, Button, FormControl, MenuItem, InputLabel } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import PlusIcon from '@mui/icons-material/Add';
import pdfIcon from "../../../../assets/customImages/pdfIcon.png";
import excelIcon from "./excel.jpg";
import DeleteIcon from '@mui/icons-material/Delete';
import MUIDataTable from "mui-datatables";
import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const style4 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                },
            },
        },
    },
    palette: {
        primary: {
            light: '#757ce8',
            main: '#f44336',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: "806px",
        },
    },
};

let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();

const today = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;

const options = {
    filterType: 'multiselect',
    // filterOptions: {fullWidth: true},
    selectableRows: 'none',
    setRowProps: row => {
        if (row[0] === "New") {
            return {
                style: { background: "snow" }
            };
        }
    },
    draggableColumns: { enabled: true },
    pagination: true,
    // resizableColumns: true,
    // responsive: "standard",
    fixedHeader: false,
    downloadOptions: {
        filterOptions: {
            useDisplayedRowsOnly: true,
        }
    }
};



class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {},
            uniqueDateRanges: [],
            dateRangeSelected: '',
            startDate: '',
            endDate: '',
            successText: '',
            alertState: false,
            headingAdd: 'Add Client',
            submitButton: 'Submit',
            uniqueLocationsDropDown: [],
            locationid: '',
            uniqueClientsDropDown: [],
            ClientName: '',
            clientNameDropDown: '',
            uniqueSegmentsDropDown: [],
            segmentNameDropDown: '',
            segment: '',
            ClientCode: '',
            uniqueZonalManagerDropDown: [],
            zmID: '',
            isshipmentcnt: false,
            isactive: false,
            isbillable: false,
            isVu: false,
            attachements: [{ sdate: '', edate: '', url: '', Description: '' }],
            openFullModal: false,
            contentID: 1,
            CCxrefID: '',
            downloadFiles: [],
            openDownloadFilesModal: false,
            rowData: [],
            columns: [
                {
                    name: "ProjectName",
                    label: "Project Name",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "zmName",
                    label: "Zonal Manager",
                },
                {
                    name: "ClientName",
                    label: "Client Name",
                },
                {
                    name: "ClientCode",
                    label: "Client Code",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },

                {
                    name: "IsShipmentcnt",
                    label: "Is Shipment",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <div style={{ minWidth: "12vh" }}>
                                <Switch checked={value} onChange={(e) => this.handleChangeCheckbox(tableMeta, 'IsShipmentcnt', e)} />
                            </div>
                            )
                        }
                    }
                },
                {
                    name: "isactive",
                    label: "Is Active",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {

                            return (
                                <div style={{ minWidth: "12vh" }}>
                                <Switch checked={value} onChange={(e) => this.handleChangeCheckbox(tableMeta, 'isactive', e)} />
                            </div>
                            )
                        }
                    }
                },
                {
                    name: "isbillable",
                    label: "Is Billable",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {

                            return (
                                <div style={{ minWidth: "12vh" }}>
                                    <Switch checked={value} onChange={(e) => this.handleChangeCheckbox(tableMeta, 'isbillable', e)} />
                                </div>
                            )


                        }
                    }
                },
                {
                    name: "agreementHistory",
                    label: "Agreement History",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            let attachementsParse = [];
                            if (value != undefined || value != null) {
                                attachementsParse = JSON.parse(value);
                            }
                            return (
                                <div
                                    style={{
                                        marginLeft: "10px",
                                    }}
                                >
                                    {value != '' && value != null && attachementsParse[0].url != '' ?
                                        <Tooltip title="Agreement History">
                                            <Button
                                                size="small"
                                                onClick={(e) => this.handleOpenAttachmentsModal(value, e)}

                                                style={{ color: 'black' }}
                                            // variant="outlined"
                                            >
                                                <FileCopyIcon />
                                            </Button>
                                        </Tooltip> :

                                        <Tooltip title="Download">
                                            <Button
                                                size="small"

                                                style={{ color: 'black' }}
                                            >
                                                <FolderOffOutlinedIcon />
                                            </Button>
                                        </Tooltip>
                                    }
                                </div>
                            )


                        }
                    }
                },

                {
                    name: "CCxrefID",
                    label: "Actions",
                    options: {
                        filter: false,
                        sort: true,
                        customBodyRenderLite: (dataIndex) => {
                            return (
                                <Tooltip title="Update">
                                    <Button
                                        size="small"
                                        onClick={(e) => this.handleOpenFullModal(dataIndex, e)}
                                        style={{ color: 'black' }}
                                    >
                                        <EditIcon />

                                    </Button>
                                </Tooltip>
                            )

                        }
                    }
                },
            ],


        };
        this.validator = new SimpleReactValidator();
    }

    delay = async (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }


    getUniqueDateRanges = async () => {
        await axios
            .post(
                `${Config.hostName}/bms/dateRangeDropDownOnlyMonth`, {
                ccxref: 384
            }
            )
            .then(async (res) => {
                // console.log(res.data.data[0].billingMonth);
                if (res.data.data.length > 0) {
                    await this.setState({
                        uniqueDateRanges: res.data.data,
                        dateRangeSelected: res.data.data[0].billingMonth
                    });
                }
            }).catch((err) => {
                alert(err);
            });
    };

    getNewRequests = async () => {
        const res = await axios.post(
            `${Config.hostName}/billing/ccxrefGetdetails`,
            {

            }
        )
            .then(async (res) => {
                console.log(res.data.data, 'project');
                if (res.data.data.length > 0) {
                    let data = res.data.data;
                    await data.map(async (e, key) => {
                        await this.state.uniqueZonalManagerDropDown.map(async (zm) => {
                            if (e.zmID == zm.Userid) {
                                data[key]['zmName'] = zm.Name
                            }
                        })
                    })
                    console.log(data);
                    await this.setState({
                        rowData: data,

                    });
                } else {
                    alert('No Recoords Found... !!');

                }
            })
            .catch((error) => {
                alert(error);
            });
    }



    async componentDidMount() {
        await this.setState({
            style: {}
        });
        // await this.getUniqueDateRanges();
        await this.getUniqueZonalManagerDropDown();
        await this.getNewRequests();

        this.getUniqueLocationsDropDown();

        await this.setState({
            style: {
                display: "none"
            }
        });
    }

    handleChangeDate = async (e) => {
        if (e.target.value != 0) {
            let value = e.target.value;
            if (value == 2222) {
                await this.setState({
                    dateRangeSelected: e.target.value,
                    startDate: "",
                    endDate: "",
                });
                await this.toggle();
            } else {
                this.state.uniqueDateRanges.filter(async (eachDate, i) => {
                    if (eachDate.billingMonth === value) {
                        await this.setState({
                            dateRangeSelected: eachDate.billingMonth,
                            startDate: eachDate.startDate,
                            endDate: eachDate.endDate,
                        });
                        await this.getNewRequests();
                    }
                });
            }
        }
    }

    getUniqueLocationsDropDown = async () => {
        await axios
            .get(`${Config.hostName}/unique/locations`)
            .then(async (res) => {
                await this.setState({
                    uniqueLocationsDropDown: res.data.data,
                });
            })
            .catch((error) => {
                alert("getUniqueLocationsDropDown : ", error);
            });
    };


    getUniqueClientsDropDown = async () => {
        await axios
            .post(`${Config.hostName}/unique/clients`, {
                locationId: this.state.locationid,
                clientName: "",
            })
            .then(async (res) => {
                // console.log(res.data);
                await this.setState({
                    uniqueClientsDropDown: res.data.data,
                });
            })
            .catch((err) => {
                alert(err);
            });
    };

    getUniqueZonalManagerDropDown = async () => {
        await axios
            .post(`${Config.hostName}/billing/zonalManagerDD`, {

            })
            .then(async (res) => {
                // console.log(res.data);
                await this.setState({
                    uniqueZonalManagerDropDown: res.data.data,
                });
            })
            .catch((err) => {
                alert(err);
            });
    };

    getUniqueSegmentsDropDown = async () => {
        await axios
            .post(`${Config.hostName}/unique/segments`, {
                locationId: this.state.locationid,
                clientName: this.state.clientNameDropDown,
            })
            .then((res) => {
                this.setState({
                    uniqueSegmentsDropDown: res.data.data,
                });
            })
            .catch((err) => {
                alert(err);
            });
    };


    handleChageLocation = async (e) => {
        if (e.target.value != '') {
            await this.setState({
                locationid: e.target.value,
                uniqueClientsDropDown: [],
                clientName: '',
                clientNameDropDown: '',
                segment: '',
                uniqueSegmentsDropDown: [],
                segmentNameDropDown: '',

            })
            await this.getUniqueClientsDropDown();
        }

    }

    handleChageClientName = async (e) => {
        if (e.target.value == 'add') {
            this.setState({
                clientNameDropDown: 'add',
                ClientName: '',
                segment: '',
                uniqueSegmentsDropDown: [],
                segmentNameDropDown: '',
            })
        } else if (e.target.value == '') {

        } else {
            await this.setState({
                clientNameDropDown: e.target.value,
                ClientName: e.target.value,
                segment: '',
                uniqueSegmentsDropDown: [],
                segmentNameDropDown: '',
            })
            await this.getUniqueSegmentsDropDown();
        }
    }

    handleChageSegment = async (e) => {
        if (e.target.value == 'add') {
            this.setState({
                segment: '',
                uniqueSegmentsDropDown: [],
                segmentNameDropDown: 'add',
            })
        } else if (e.target.value == '') {

        } else {
            await this.setState({
                segment: e.target.value,
                segmentNameDropDown: e.target.value,
            })
        }
    }




    handleOpenFullModal = async (data, e) => {



        if (data == 'add') {
            // add 
            this.setState({
                CCxrefID: '',
                contentID: 1,
                headingAdd: 'Add Client',
                submitButton: 'Submit'
            })
        } else {
            // update
            const dataUpdate = this.state.rowData[data];
            console.log(dataUpdate['agreementHistory']);
            await this.setState({
                locationid: dataUpdate['LocationID'],
                isshipmentcnt: dataUpdate['IsShipmentcnt'],
                isactive: dataUpdate['isactive'],
                isbillable: dataUpdate['isbillable'],
                zmID: dataUpdate['zmID'],
                ClientCode: dataUpdate['ClientCode'],
                ClientName: dataUpdate['ClientName'],
                clientNameDropDown: dataUpdate['ClientName'],
                attachements: dataUpdate['agreementHistory'] != null ? JSON.parse(dataUpdate['agreementHistory']) : [{ sdate: '', edate: '', url: '', Description: '' }],

                CCxrefID: dataUpdate['CCxrefID'],
                contentID: 2,
                headingAdd: 'Update Client',
                submitButton: 'Update'
            })

            await this.getUniqueClientsDropDown();
            await this.getUniqueSegmentsDropDown();

            this.setState({
                segment: dataUpdate['Segment'],
                segmentNameDropDown: dataUpdate['Segment'],
            })


        }

        // after add + update
        await this.setState({
            openFullModal: true,
        });

    };

    handleCloseFullModal = async (e) => {
        await this.setState({
            openFullModal: false,
            headingAdd: 'Add Client',
            submitButton: 'Submit',
            locationid: '',
            uniqueClientsDropDown: [],
            ClientName: '',
            clientNameDropDown: '',
            uniqueSegmentsDropDown: [],
            segmentNameDropDown: '',
            segment: '',
            ClientCode: '',
            zmID: '',
            isshipmentcnt: false,
            isactive: false,
            isbillable: false,
            isVu: false,
            attachements: [{ sdate: '', edate: '', url: '', Description: '' }],
            openFullModal: false
        });
    };


    onSubmitModal = async (e) => {
        e.preventDefault();
        await this.setState({
            submitButton: 'Please wait...',
        });

        const res = await axios.post(
            `${Config.hostName}/billing/clientManagement`,
            {
                locationid: this.state.locationid,
                ClientName: this.state.ClientName,
                segment: this.state.segment,
                ClientCode: this.state.ClientCode,
                isshipmentcnt: this.state.isshipmentcnt ? 1 : 0,
                attachments: this.state.attachements,
                isactive: this.state.isactive ? 1 : 0,
                isbillable: this.state.isbillable ? 1 : 0,
                zmID: this.state.zmID,
                userID: this.props.auth.user.USERID,
                contentID: this.state.contentID,
                ccxrefID: this.state.CCxrefID,
            }
        )
            .then(async (res) => {
                console.log(res);
                if (res.data.success != false) {
                    await this.setState({
                        headingAdd: 'Add Client',
                        submitButton: 'Submit',
                        locationid: '',
                        uniqueClientsDropDown: [],
                        ClientName: '',
                        clientNameDropDown: '',
                        uniqueSegmentsDropDown: [],
                        segmentNameDropDown: '',
                        segment: '',
                        ClientCode: '',
                        zmID: '',
                        isshipmentcnt: false,
                        isactive: false,
                        isbillable: false,
                        isVu: false,
                        attachements: [{ sdate: '', edate: '', url: '', Description: '' }],
                        openFullModal: false,
                        alertState: true,
                    });
                    if (this.state.contentID == 1) {
                        this.setState({
                            successText: 'Client Added Successfully!!'
                        })
                    } else if (this.state.contentID == 2) {
                        this.setState({
                            successText: 'Client Updated Successfully!!'
                        })
                    }
                } else {
                    alert(res.data.message)
                    await this.setState({
                        submitButton: 'Submit'
                    });
                }
                await this.getNewRequests();
            })
            .catch((error) => {
                alert(error);
            });


    };


    uploadFileAndGetUrl = async (formData) => {
        // console.log("uploading formdata")
        const fileData = await axios({
            method: "POST",
            url: `${Config.hostName}/unique/upload/any-file-name`,
            timeout: 3 * 60 * 1000,
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*"
            },
            data: formData,
        });
        return fileData;
    }


    billLinkUploadFile = async (file, name) => {
        const reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = async (e) => {
            await this.setState({
                selectedFile: [reader.result],
            });
        }
        // console.log(url); // Would see a path?
        const fileType = file.type.substring(0, 5);
        await this.setState({ selectedFile: file, selectedFileType: fileType });
        const formData = new FormData();
        formData.append(
            "media",
            this.state.selectedFile,
            `Client_${name}.${this.state.selectedFile.name.split('.').pop().toLowerCase()}`
        );
        // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
        // console.log(formData, "formData", fileType);
        const res = await this.uploadFileAndGetUrl(formData);
        const urlFromAws = res.data.url;
        return urlFromAws;
        // const urlFromAws = "https://logicarts.s3.ap-south-1.amazonaws.com/clientlogos/capp_truck.png";
        // await this.getPanCardUrl(urlFromAws, file.name);


        // console.log(this.state.attachments, "data")
    }

    handleChangeAttachment = async (index, e) => {

        const list = this.state.attachements;
        console.log(list);
        if (e.target.name == 'Description') {
            list[index][e.target.name] = e.target.value;
        } else if (e.target.name == 'sdate') {
            list[index][e.target.name] = e.target.value;
        } else if (e.target.name == 'edate') {
            list[index][e.target.name] = e.target.value;
        } else {
            if (list[index]['Description'] != '') {
                const filesObj = e.target.files;
                const filesObjKeys = Object.keys(filesObj);
                const res = await this.billLinkUploadFile(filesObj[0], list[index]['Description']);
                list[index]['url'] = res;
            } else {
                alert("Enter Document Name First");
            }
        }
        await this.setState({
            attachements: list,
        })
    }


    removeNewAttachment = async (index, e) => {
        if (index != 0) {
            const list = this.state.attachements;
            list.splice(index, 1);
            await this.setState({
                attachements: list,
            })
        } else {
            const list = this.state.attachements;
            list[index]['sdate'] = '';
            list[index]['edate'] = '';
            list[index]['url'] = '';
            list[index]['Description'] = '';
            await this.setState({
                attachements: list,
            })
        }
    }


    addNewAttachment = async (e) => {

        if (this.state.attachements.length < 3) {
            await this.setState({
                attachements: [...this.state.attachements, { sdate: '', edate: '', url: '', Description: '' }]
            })
        }

    }

    handleOpenAttachmentsModal = async (value, e) => {


        await this.setState({
            downloadFiles: JSON.parse(value),
            openDownloadFilesModal: true
        })
    }


    handleCloseAttachmentsModal = async () => {
        await this.setState({
            downloadFiles: [],
            openDownloadFilesModal: false
        })
    }

    handleChangeCheckbox = async (value, name, e) => {
        const row = value.rowIndex;
        const data = this.state.rowData[row];
        console.log(data);

        if (window.confirm("Are you sure you want to update the status?")) {

            if (name == 'isbillable') {
                await this.setState({
                    CCxrefID: data.CCxrefID,
                    isbillable: !data.isbillable == true ? 1 : 0,
                    contentID: 4
                })

                const res = await axios.post(
                    `${Config.hostName}/billing/clientManagement`,
                    {
                        locationid: '',
                        ClientName: '',
                        segment: '',
                        ClientCode: '',
                        isshipmentcnt: '',
                        attachments: '',
                        isactive: '',
                        isbillable: this.state.isbillable ? 1 : 0,
                        zmID: '',
                        userID: this.props.auth.user.USERID,
                        contentID: this.state.contentID,
                        ccxrefID: this.state.CCxrefID,
                    }
                )
                    .then(async (res) => {
                        if (res.data.success != false) {
                            if (this.state.contentID == 4) {
                                await this.setState({
                                    alertState: true,
                                    successText: 'Is Billable Status Changed!!',
                                    CCxrefID: '',
                                    isbillable: 0,
                                    contentID: 1
                                })
                            }
                        } else {
                            alert(res.data.message)
                        }
                        await this.getNewRequests();
                    })
                    .catch((error) => {
                        alert(error);
                    });
            } else if (name == 'isactive') {
                await this.setState({
                    CCxrefID: data.CCxrefID,
                    isactive: !data.isactive == true ? 1 : 0,
                    contentID: 3
                })

                const res = await axios.post(
                    `${Config.hostName}/billing/clientManagement`,
                    {
                        locationid: '',
                        ClientName: '',
                        segment: '',
                        ClientCode: '',
                        isshipmentcnt: '',
                        attachments: '',
                        isactive: this.state.isactive ? 1 : 0,
                        isbillable: '',
                        zmID: '',
                        userID: this.props.auth.user.USERID,
                        contentID: this.state.contentID,
                        ccxrefID: this.state.CCxrefID,
                    }
                )
                    .then(async (res) => {
                        if (res.data.success != false) {
                            if (this.state.contentID == 3) {
                                await this.setState({
                                    alertState: true,
                                    successText: 'Is Active Status Changed!!',
                                    CCxrefID: '',
                                    isactive: 0,
                                    contentID: 1
                                })
                            }
                        } else {
                            alert(res.data.message)
                        }
                        await this.getNewRequests();
                    })
                    .catch((error) => {
                        alert(error);
                    });
            }else if(name == 'IsShipmentcnt'){
                await this.setState({
                    CCxrefID: data.CCxrefID,
                    isshipmentcnt: !data.IsShipmentcnt == true ? 1 : 0,
                    contentID: 5
                })

                const res = await axios.post(
                    `${Config.hostName}/billing/clientManagement`,
                    {
                        locationid: '',
                        ClientName: '',
                        segment: '',
                        ClientCode: '',
                        isshipmentcnt: this.state.isshipmentcnt ? 1 : 0,
                        attachments: '',
                        isactive: '',
                        isbillable: '',
                        zmID: '',
                        userID: this.props.auth.user.USERID,
                        contentID: this.state.contentID,
                        ccxrefID: this.state.CCxrefID,
                    }
                )
                    .then(async (res) => {
                        if (res.data.success != false) {
                            if (this.state.contentID == 5) {
                                await this.setState({
                                    alertState: true,
                                    successText: 'Is ShipmentCnt Status Changed!!',
                                    CCxrefID: '',
                                    isshipmentcnt: 0,
                                    contentID: 1
                                })
                            }
                        } else {
                            alert(res.data.message)
                        }
                        await this.getNewRequests();
                    })
                    .catch((error) => {
                        alert(error);
                    });
            }

        }





    }



    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    render() {


        return (
            <div>
                <ThemeProvider theme={theme}>
                    <Container pt={3} style={{ maxWidth: 'inherit', background: 'white', paddingBottom: "12%", marginTop: '7%' }} >
                        <div id='cover-spin' style={this.state.style}></div>

                        <Grid pt={3} container>
                            <Grid item md={12} >
                                <Collapse in={this.state.alertState}>
                                    <Alert onClose={() => {
                                        this.setState({
                                            successText: '',
                                            alertState: false,
                                        })
                                    }} severity="success">
                                        <AlertTitle>Success</AlertTitle>
                                        {this.state.successText}
                                    </Alert>
                                </Collapse>
                            </Grid>


                            {/* <Grid style={{ marginTop: '10px', float: 'left', textAlign: 'left' }} item md={12}>
                            <FormControl variant="standard" sx={{ width: '30ch' }} style={{ marginLeft: '0px' }}>
                                <InputLabel id="viewInvoiceBilling">Billing Month</InputLabel>
                                <Select
                                    labelId="viewInvoiceBilling"
                                    id="viewInvoiceBilling2"
                                    value={this.state.dateRangeSelected}
                                    onChange={(e) => this.handleChangeDate(e)}
                                    label="Billing Month"
                                    variant="standard"
                                >
                                    <MenuItem value="">
                                        <em>Select Billing Month</em>
                                    </MenuItem>

                                    {this.state.uniqueDateRanges.map((eachItem, key) => (
                                        <MenuItem value={eachItem.billingMonth} key={key} >{eachItem.billingMonth}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid> */}
                            <Grid style={{ float: 'left', textAlign: 'left' }} item md={6}>
                            </Grid>


                            <Grid style={{ float: 'right', textAlign: 'right' }} item md={6}>
                                <Button
                                    onClick={(e) => this.handleOpenFullModal('add', e)}
                                    size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }} endIcon={<PlusIcon />}>
                                    Add Client
                                </Button>
                            </Grid>



                        </Grid>

                        <Grid mt={3}>
                            <Grid item md={8}>
                                <MUIDataTable
                                    title={"Client Management"}
                                    data={this.state.rowData}
                                    columns={this.state.columns}
                                    options={options}
                                />
                            </Grid>

                        </Grid>

                    </Container>

                    <Dialog fullScreen
                        TransitionComponent={this.Transition}
                        onClose={this.handleCloseFullModal}
                        open={this.state.openFullModal}
                    // open={true}
                    >
                        <Container maxWidth="xl" style={{ maxWidth: 'inherit', paddingLeft: '0px', paddingRight: '0px' }}>


                            <AppBar style={{ height: 'auto', backgroundColor: 'white', color: 'black' }} sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={this.handleCloseFullModal}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <Button autoFocus color="inherit" onClick={this.handleCloseFullModal}>
                                        Close
                                    </Button>
                                </Toolbar>
                            </AppBar>



                            {/* <Header order="rohan" /> */}
                            <Grid style={{ background: '#e0e1e0', }} container>
                                <Grid item md={12} style={{ padding: '0px 12px' }}>
                                    <form onSubmit={this.onSubmitModal} method="POST" >
                                        <Container pt={3} style={{ maxWidth: 'inherit', background: 'white', paddingBottom: "12%", marginTop: '2%' }} >
                                            <Typography pt={2} style={{ textAlign: 'center', color: '#333333' }} variant="h4"> <b><u> {this.state.headingAdd}</u></b></Typography>


                                            <Typography pt={4} variant="h6"><b> Choose :</b></Typography>
                                            <Grid pt={1} container>
                                                <Grid item md={3}>
                                                    <FormControl variant="standard" fullWidth={true} sx={{ m: 1, }} style={{ marginLeft: '0px', width: '30ch' }}>
                                                        <InputLabel id="location">Location</InputLabel>
                                                        <Select
                                                            labelId="Location"
                                                            id="State2"
                                                            value={this.state.locationid}
                                                            onChange={(e) => {
                                                                this.handleChageLocation(e)
                                                            }}
                                                            label="Location"
                                                            required
                                                        >
                                                            <MenuItem value="">
                                                                <em>Select Location</em>
                                                            </MenuItem>

                                                            {this.state.uniqueLocationsDropDown.map((eachItem, key) => (
                                                                <MenuItem value={eachItem.Locationid} key={key} >{eachItem.name}</MenuItem>
                                                            ))}

                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item md={3}>
                                                    <FormControl variant="standard" fullWidth={true} sx={{ m: 1, }} style={{ marginLeft: '0px', width: '30ch' }}>
                                                        <InputLabel id="client">Client Name</InputLabel>
                                                        <Select
                                                            labelId="Client"
                                                            id="client"
                                                            value={this.state.clientNameDropDown}
                                                            onChange={(e) => {
                                                                this.handleChageClientName(e);
                                                            }}
                                                            label="Client"
                                                            required
                                                        >
                                                            <MenuItem value="">
                                                                <em>Select Client</em>
                                                            </MenuItem>


                                                            {this.state.uniqueClientsDropDown.map((eachItem, key) => (
                                                                <MenuItem value={eachItem.ClientName} key={key} >{eachItem.ClientName}</MenuItem>
                                                            ))}

                                                            {this.state.uniqueClientsDropDown != [] &&
                                                                <MenuItem value="add">
                                                                    <b> Add New Client </b>
                                                                </MenuItem>
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>



                                                {
                                                    this.state.clientNameDropDown == 'add' &&
                                                    <Grid item md={3}>
                                                        <TextField sx={{ m: 1, width: '30ch' }} id="client" label="Client Name"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    ClientName: e.target.value
                                                                })
                                                            }}
                                                            value={this.state.ClientName}
                                                            variant="standard"
                                                            required
                                                        />
                                                    </Grid>
                                                }

                                                {this.state.clientNameDropDown != 'add' && this.state.clientNameDropDown != '' &&
                                                    <Grid item md={3}>
                                                        <FormControl variant="standard" fullWidth={true} sx={{ m: 1, }} style={{ marginLeft: '0px', width: '30ch' }}>
                                                            <InputLabel id="client">Segment Name</InputLabel>
                                                            <Select
                                                                labelId="Segment Name"
                                                                id="segmentDropDown"
                                                                value={this.state.segmentNameDropDown}
                                                                onChange={(e) => {
                                                                    this.handleChageSegment(e);
                                                                }}
                                                                label="Segment Name"
                                                                required
                                                            >
                                                                <MenuItem value="">
                                                                    <em>Select Segment</em>
                                                                </MenuItem>


                                                                {this.state.uniqueSegmentsDropDown.map((eachItem, key) => (
                                                                    <MenuItem value={eachItem.Segment} key={key} >{eachItem.Segment}</MenuItem>
                                                                ))}

                                                                {this.state.uniqueSegmentsDropDown != [] &&
                                                                    <MenuItem value="add">
                                                                        <b> Add New Segment </b>
                                                                    </MenuItem>
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>}


                                                {
                                                    this.state.clientNameDropDown == 'add' &&
                                                    <Grid item md={3}>
                                                        <TextField sx={{ m: 1, width: '30ch' }} id="segment" label="Segment Name"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    segment: e.target.value
                                                                })
                                                            }}
                                                            value={this.state.segment}
                                                            variant="standard"
                                                            required
                                                        />
                                                    </Grid>
                                                }
                                                {
                                                    this.state.segmentNameDropDown == 'add' &&
                                                    <Grid item md={2}>
                                                        <TextField sx={{ m: 1, width: '30ch' }} id="segment" label="Segment Name"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    segment: e.target.value
                                                                })
                                                            }}
                                                            value={this.state.segment}
                                                            variant="standard"
                                                            required
                                                        />
                                                    </Grid>
                                                }


                                            </Grid>



                                            <Typography pt={4} variant="h6"> <b> Basic Information:</b></Typography>

                                            <Grid pt={1} container>


                                                <Grid item md={3}>
                                                    <TextField sx={{ m: 1, width: '30ch' }} id="clientCode" label="Client Code"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                ClientCode: e.target.value
                                                            })
                                                        }}
                                                        value={this.state.ClientCode}
                                                        variant="standard"
                                                        required
                                                    />
                                                </Grid>

                                                <Grid item md={3}>
                                                    <FormControl variant="standard" fullWidth={true} sx={{ m: 1, }} style={{ marginLeft: '0px', width: '30ch' }}>
                                                        <InputLabel id="manager">Zonal Manager</InputLabel>
                                                        <Select
                                                            labelId="Zonal Manager"
                                                            id="segmentDropDown"
                                                            value={this.state.zmID}
                                                            onChange={(e) => {
                                                                this.setState({ zmID: e.target.value })
                                                            }}
                                                            label="Zonal Manager"
                                                            required
                                                        >
                                                            <MenuItem value="">
                                                                <em>Select Zonal Manager</em>
                                                            </MenuItem>


                                                            {this.state.uniqueZonalManagerDropDown.map((eachItem, key) => (
                                                                <MenuItem value={eachItem.Userid} key={key} >{eachItem.Name}</MenuItem>
                                                            ))}

                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item md={6} >
                                                </Grid>


                                                <Grid item md={3} >
                                                    <FormControl sx={{ m: 1, }}>
                                                        <FormControlLabel control={<Checkbox checked={this.state.isshipmentcnt} onChange={(e) => { this.setState({ isshipmentcnt: !this.state.isshipmentcnt }) }} />} label="Is Shipment" />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item md={3} >
                                                    <FormControl sx={{ m: 1, }}>
                                                        <FormControlLabel control={<Checkbox checked={this.state.isactive} onChange={(e) => { this.setState({ isactive: !this.state.isactive }) }} />} label="Is Active" />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item md={6} >
                                                </Grid>

                                                <Grid item md={3} >
                                                    <FormControl sx={{ m: 1, }}>
                                                        <FormControlLabel control={<Checkbox checked={this.state.isbillable} onChange={(e) => { this.setState({ isbillable: !this.state.isbillable }) }} />} label="Is Billable" />
                                                    </FormControl>
                                                </Grid>




                                            </Grid>

                                            <Typography pt={4} variant="h6"> <b> Attachments:</b></Typography>

                                            <Grid pt={1} container>

                                                <Grid item md={12} >
                                                    <div className="table-responsive">
                                                        <table className="tableInvoice table">
                                                            <tr>
                                                                <th>
                                                                    SR No
                                                                </th>
                                                                <th>
                                                                    Description
                                                                    <span style={{
                                                                        color: "red"
                                                                    }}>*</span>
                                                                </th>
                                                                <th>
                                                                    Start Date
                                                                </th>
                                                                <th>
                                                                    End Date
                                                                </th>
                                                                <th>
                                                                    Attach / View
                                                                </th>
                                                                <th>
                                                                    Action
                                                                </th>
                                                            </tr>

                                                            {this.state.attachements.map((e, key) => (
                                                                <tr>
                                                                    <td style={{ width: '6%', textAlign: 'center' }}>
                                                                        {key + 1}.
                                                                    </td>
                                                                    <td style={{ width: '26%' }}>
                                                                        <TextField sx={{ m: 1, width: '38ch' }} id="clientCode" label="Description"
                                                                            onChange={(e) => { this.handleChangeAttachment(key, e) }}
                                                                            name='Description'
                                                                            value={e.Description}
                                                                            variant="standard"
                                                                            required
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: '20%' }}>
                                                                        <TextField sx={{ m: 1, width: '30ch' }}
                                                                            onChange={(e) => { this.handleChangeAttachment(key, e) }}
                                                                            name='sdate'
                                                                            value={e.sdate}
                                                                            variant="standard"
                                                                            type="date"
                                                                            required
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: '20%' }}>
                                                                        <TextField sx={{ m: 1, width: '30ch' }}
                                                                            onChange={(e) => { this.handleChangeAttachment(key, e) }}
                                                                            name='edate'
                                                                            value={e.edate}
                                                                            variant="standard"
                                                                            type="date"
                                                                            required
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <>
                                                                            {e.Description == '' ?
                                                                                <Button
                                                                                    color="primary"
                                                                                    size="large"
                                                                                    variant="contained"
                                                                                    component="label"
                                                                                    onClick={(e) => { alert('Please Enter Description First!!') }}
                                                                                    style={{ color: "white" }}
                                                                                >
                                                                                    Upload
                                                                                </Button> :
                                                                                <>

                                                                                    {e.url == '' ?
                                                                                        <Button
                                                                                            color="primary"
                                                                                            size="large"
                                                                                            variant="contained"
                                                                                            component="label"
                                                                                            onChange={(e) => { this.handleChangeAttachment(key, e) }}
                                                                                            style={{ color: "white" }}
                                                                                        // className={this.state.color}
                                                                                        >
                                                                                            Upload
                                                                                            <input
                                                                                                type="file"
                                                                                                hidden
                                                                                                accept="image/*,.pdf,.xls,.xlsx"
                                                                                            />
                                                                                        </Button>
                                                                                        :
                                                                                        <>
                                                                                            {e.url.split('.').pop().toLowerCase() != "pdf" && e.url.split('.').pop().toLowerCase() != "xlsx" &&
                                                                                                <div style={{ width: '152px', marginLeft: 'auto', marginRight: 'auto' }}>
                                                                                                    <img style={{ width: '100%' }} src={e.url} />
                                                                                                </div>
                                                                                            }

                                                                                            {e.url.split('.').pop().toLowerCase() == "pdf" &&
                                                                                                <img className="bms-view-request-img" onClick={(e) => { window.open(e.url) }} src={pdfIcon} alt="No Image" key={key} />
                                                                                            }

                                                                                            {e.url.split('.').pop().toLowerCase() == "xlsx" &&
                                                                                                <img className="bms-view-request-img" onClick={(e) => { window.open(e.url) }} src={excelIcon} alt="No Image" key={key} />
                                                                                            }

                                                                                        </>
                                                                                    }

                                                                                </>

                                                                            }


                                                                        </>
                                                                    </td>
                                                                    <td>
                                                                        <DeleteIcon
                                                                            onClick={(e) => this.removeNewAttachment(key, e)}
                                                                            className="deleteIcon" />
                                                                    </td>
                                                                </tr>
                                                            ))}


                                                        </table>
                                                    </div>
                                                </Grid>

                                                <Grid item md={9}>

                                                </Grid>

                                                <Grid item md={3}>
                                                    {/* onClick={(e) => this.addNewItem(key, e)} */}
                                                    <div style={{ textAlign: "right", marginRight: '8px' }}>

                                                        <Button size="large" onClick={(e) => this.addNewAttachment(e)} variant="contained" style={{ marginRight: '26px', marginBottom: '20px', marginTop: "18px", color: "white" }} >
                                                            <PlusIcon />
                                                        </Button>
                                                    </div>
                                                </Grid>

                                                <Grid item md={12} >
                                                </Grid>
                                                <Grid item md={12} >
                                                </Grid>
                                                <Grid item md={12} >
                                                </Grid>

                                                <Grid item md={9} >

                                                </Grid>
                                                <Grid item md={6} >

                                                </Grid>

                                                <Grid mt={2} item md={3} >
                                                    <Button type="submit" size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }} endIcon={<SendIcon />}>
                                                        {this.state.submitButton}
                                                    </Button>
                                                </Grid>

                                            </Grid>



                                        </Container>
                                    </form>
                                </Grid>

                            </Grid>


                        </Container>

                    </Dialog>

                    <Modal
                        open={this.state.openDownloadFilesModal}
                        onClose={this.handleCloseAttachmentsModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style4}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Download Aggrement Attachments
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <Grid container>
                                    {
                                        this.state.downloadFiles.map((e, key) => (
                                            <>
                                                {e.url.split('.').pop().toLowerCase() != "pdf" && e.url.split('.').pop().toLowerCase() != "xlsx" &&
                                                    <Tooltip title={e.url.slice(54)} placement="top">
                                                        <Grid item m={1} md={3}>
                                                            <img className="hoverImage" style={{ width: '100%' }} onClick={(event) => { window.open(e.url) }} src={e.url} />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                                {e.url.split('.').pop().toLowerCase() == "pdf" &&
                                                    <Tooltip title={e.url.slice(54)} placement="top">
                                                        <Grid className="margin0" item m={1} md={3}>
                                                            <img onClick={(event) => { window.open(e.url) }} style={{ width: '100%' }} className="bms-view-request-img" src={pdfIcon} alt="No Image" />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                                {e.url.split('.').pop().toLowerCase() == "xlsx" &&
                                                    <Tooltip title={e.url.slice(54)} placement="top">
                                                        <Grid item m={1} className="margin0" md={3}>
                                                            <img className="bms-view-request-img" onClick={(event) => { window.open(e.url) }} src={excelIcon} alt="No Image" key={key} />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                            </>
                                        ))
                                    }

                                </Grid>


                            </Typography>
                        </Box>
                    </Modal>

                </ThemeProvider>
            </div >


        );
    }
}

HomePage.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(HomePage);
