/* eslint-disable eqeqeq */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Form } from "reactstrap";
import { login } from "../../redux/actions/user.actions";
import Alert from "../common/Alert";
import SimpleReactValidator from "simple-react-validator";
import { setAlert } from "../../redux/actions/alert.actions";
import "../../assets/customCssFiles/loader-spinner.css";
import { Link } from "react-router-dom";

class Samplepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},
      loading: false,
    };
    this.validator = new SimpleReactValidator();

    this.handleLoginButtonClick = this.handleLoginButtonClick.bind(this);
  }

  componentDidMount() {
    this.setState({
      style: { display: "none" },
    });
  }

  async handleLoginButtonClick(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      await this.setState({ loading: true });
      let { email, password } = this.state;
      const loginData = await this.props.login(email, password);
      //  console.log(loginData.isSystemAccess);

      if (!this.props.auth.isAuthenticated) {
        await this.setState({ loading: false });
        if (loginData) {
          if (loginData.isSystemAccess != 0) {
            return this.props.setAlert(
              "You dont have access to login.",
              "danger"
            );
          }
        } else {
          return this.props.setAlert("Invalid Credentials", "danger");
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    if (
      this.props.auth.isAuthenticated &&
      (this.props.auth.user.user_type == "ROAdmin" ||
        this.props.auth.user.user_type == "ROSuperAdmin" ||
        this.props.auth.user.user_type == "ROSupervisor")
    )
      return <Redirect to="/admin/home/dashboard" />;
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type == "cms"
    )
      return <Redirect to="/admin/cms/tracking" />;
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type == "accounts"
    )
      return <Redirect to="/admin/bms/dashboard" />;
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type == "Employee"
    )
      return <Redirect to="/admin/tms/trip-count" />;
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type == "Manager"
    )
      return <Redirect to="/admin/tms/trip-count" />;
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type == "hr"
    )
      return <Redirect to="/admin/ems/map-attendance" />;
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.user_type == "supervisor"
    )
      return <Redirect to="/admin/tms/trip-count" />;
    if (this.props.auth.isAuthenticated)
      return <Redirect to="/admin/dashboard" />;

    const { loading } = this.state;
    let style = this.state.style;
    const background = require("../../assets/images/auth-layer.png");

    return (
      <div>
        <div id="cover-spin" style={style}></div>

        <div className="page-wrapper">
          <div className="container-fluid">
            {/*login page start*/}
            <div className="authentication-main">
              <div className="row">
                <div className="col-md-4 p-0">
                  <div
                    className="auth-innerleft"
                    style={{ backgroundImage: "url(" + background + ")" }}
                  >
                    <div className="text-center">
                      <img
                        src={require("../../assets/images/logicartslogo.jpeg")}
                        className="logo-login"
                        alt=""
                      />

                      <hr />
                      <div className="social-media">
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a
                              href="https://www.facebook.com/logicarts.in"
                              target="_blank"
                            >
                              <i
                                className="fa fa-facebook txt-fb"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <i class="fa-brands fa-instagram"></i>
                            <i
                              className="fa fa-google-plus txt-google-plus"
                              aria-hidden="true"
                            ></i>
                          </li>
                          <li className="list-inline-item">
                            <a
                              href="https://twitter.com/Logicarts_in/status/1651140925173768193"
                              target="_blank"
                            >
                              <i
                                className="fa fa-twitter txt-twitter"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a
                              href="https://www.linkedin.com/company/logicarts/"
                              target="_blank"
                            >
                              <i
                                className="fa fa-linkedin txt-linkedin"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 p-0">
                  <div className="auth-innerright">
                    <div className="authentication-box">
                      <h4 className="txt-primary">LOGIN</h4>
                      <h6 className="txt-danger">
                        Enter your Username and Password For Login
                      </h6>
                      <Alert />

                      <div className="card mt-4 p-4 mb-0">
                        <Form className="theme-form">
                          <div className="form-group">
                            <label className="col-form-label pt-0">
                              Email/Mobile
                            </label>
                            <input
                              required
                              type="text"
                              className="form-control form-control-lg"
                              onChange={(event) =>
                                this.setState({ email: event.target.value })
                              }
                            />
                            {/* <div className="text-danger">
                              {this.validator.message(
                                "email",
                                this.state.email,
                                "required|email"
                              )}
                            </div> */}
                          </div>

                          <div className="form-group">
                            <label className="col-form-label">Password</label>
                            <input
                              required
                              type="password"
                              className="form-control form-control-lg"
                              onChange={(event) =>
                                this.setState({ password: event.target.value })
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "password",
                                this.state.password,
                                "required|min:6"
                              )}
                            </div>
                          </div>

                          {/* <div className="checkbox p-0">
                            <input id="checkbox1" type="checkbox" />
                            <label htmlFor="checkbox1">Remember me</label>
                          </div> */}

                          <div className="form-group form-row mt-3">
                            <div className="col-md-3">
                              <button
                                className="btn btn-secondary"
                                onClick={this.handleLoginButtonClick}
                                disabled={loading}
                              >
                                {loading && (
                                  <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                  />
                                )}
                                {loading && <span>Please Wait ...</span>}
                                {!loading && <span>Login</span>}
                              </button>
                            </div>
                            <div className="col-md-3 ml-10">
                              <Link to={`/reset-password/${this.state.email}`}>
                                <button className="btn btn-danger">
                                  <span>Reset</span>
                                </button>
                              </Link>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*login page end*/}
          </div>
        </div>
      </div>
    );
  }
}

Samplepage.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login, setAlert })(Samplepage);
