import React, { Component } from 'react';

import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import Config from "../../config";
import axios from "axios";


class Samplepage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            style: {},
            password: "",
            newPassword: "",
            confirmPassword: "",
            userName: "",
            currentPassword: "",
            loading: false

        };
    }

    componentDidMount = () => {
        this.setState({
            style: { display: 'none' },
            userName: this.props.match.params.userName,
        });
    }

    resetPasswordApiCall = async () => {
        await axios.post(`${Config.hostName}/user/reset-password`, {
            "userName": this.state.userName,
            "password": this.state.currentPassword,
            "newPassword": this.state.confirmPassword
        }).then(res => {
            console.log(res);

        }).catch(async err => {
            await this.setState({
                loading: false
            })
            alert(err);
        })
    }

    resetPassword = async () => {
        await this.setState({
            loading: true
        })
        console.log(this.state.newPassword, this.state.confirmPassword, this.state.currentPassword)
        const { newPassword, confirmPassword } = this.state;
        if (confirmPassword == newPassword) {
            await axios.post(`${Config.hostName}/user/reset-password`, {
                "userName": this.state.userName,
                "password": this.state.currentPassword,
                "newPassword": this.state.confirmPassword
            }).then(async res => {
                console.log(res);
                if (res.data.success == false) {
                    await this.setState({
                        loading: false
                    })
                    alert(res.data.message);
                }
                else {
                    await this.setState({
                        loading: false
                    })
                    alert("Password is reset. Please login again")
                    await this.props.history.push("/login");
                }


            }).catch(async err => {
                await this.setState({
                    loading: false
                })
                alert(err);
            })

        }
        else {
            await this.setState({
                loading: false
            })
            alert("Passwords mismatch")
        }

    }


    render() {
        let style = this.state.style;
        const { loading } = this.state;
        const background = require('../../assets/images/auth-layer.png')

        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">

                    <div className="container-fluid">

                        {/*sign up page start*/}

                        <div className="authentication-main">
                            <div className="row">
                                <div className="col-md-4 p-0">
                                    <div className="auth-innerleft" style={{ backgroundImage: "url(" + background + ")" }}>
                                        <div className="text-center">
                                            <img src={require('../../assets/images/key.png')} className="img-fluid security-icon" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            <h3>RESET YOUR PASSWORD</h3>
                                            <div className="card mt-4 p-4">
                                                <Form className="theme-form">
                                                    {/* <h5 className="f-16 mb-3">CREATE YOUR PASSWORD</h5> */}
                                                    <div className="form-group">
                                                        <label className="col-form-label">UserName</label>
                                                        <input type="text" className="form-control" name="userName" placeholder="Enter Your Email or Mobile"
                                                            value={this.state.userName}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    userName: e.target.value
                                                                })
                                                            }
                                                            } />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Current Password</label>
                                                        <input type="password" className="form-control" name="currentPassword" placeholder="*****" value={this.state.currentPassword}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    currentPassword: e.target.value
                                                                })
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">New Password</label>
                                                        <input type="password" className="form-control" name="newPassword" placeholder="*****" value={this.state.newPassword}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    newPassword: e.target.value
                                                                })
                                                            }
                                                            } />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Confirm Password</label>
                                                        <input type="password" className="form-control" name="confirmPassword" placeholder="*****" value={this.state.confirmPassword}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    confirmPassword: e.target.value
                                                                })
                                                            }
                                                            } />
                                                    </div>

                                                </Form>
                                                <div className="mb-0">
                                                    <div className="col-md-2">
                                                        <button
                                                            className="btn btn-secondary"
                                                            onClick={this.resetPassword}
                                                            disabled={loading}>
                                                            {loading && (
                                                                <i
                                                                    className="fa fa-refresh fa-spin"
                                                                    style={{ marginRight: "5px" }}
                                                                />
                                                            )}
                                                            {loading && <span>Please Wait ...</span>}
                                                            {!loading && <span>Reset</span>}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*Reset Password page end*/}


                    </div>
                </div>
            </div>
        )
    }
}


export default Samplepage;
