import React, {
    Component
    // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import XLSX from "xlsx";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import './layout.css';
import Select from '@mui/material/Select';
import { CardContent, Fade, Card, CardMedia, Autocomplete, Snackbar, Chip, FormLabel, FormControlLabel, FormGroup, Switch, Checkbox, ListItemText, Box, Modal, Paper, Collapse, Alert, AlertTitle, Toolbar, AppBar, Dialog, Slide, Tooltip, IconButton, Grid, Container, Typography, TextField, Button, FormControl, MenuItem, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    BarSeries,
    Title,
    Legend,
} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation } from '@devexpress/dx-react-chart';
import * as d3Format from 'd3-format';
import CloseIcon from '@mui/icons-material/Close';
import ViewIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import PlusIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import BackupIcon from '@mui/icons-material/Backup';
import MUIDataTable from "mui-datatables";
import pdfIcon from "../../../../assets/customImages/pdfIcon.png";
import excelIcon from "./excel.jpg";
import historyIcon from "./history.png";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ArrowForwardIosOutlined } from "@mui/icons-material";
import FileDownloadOffTwoToneIcon from "@mui/icons-material/FileDownloadOffTwoTone";
import GetAppIcon from "@mui/icons-material/GetApp";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined';
import InfoIcon from "@material-ui/icons/Info";
import SearchIcon from "@material-ui/icons/Search";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const style6 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '71%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: '87vh'
};
const style4 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const style5 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: "auto",
};
const Input = styled('input')({
    display: 'none',
});
const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: "806px",
        },
    },
};
const MenuProps2 = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
            width: "46vh",
            left: '42vh',
        },
    },
};
let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
const today = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;
const options = {
    filterType: 'multiselect',
    // filterOptions: {fullWidth: true},
    selectableRows: 'none',
    setRowProps: row => {
        if (row[0] === "New") {
            return {
                style: { background: "snow" }
            };
        }
    },
    draggableColumns: { enabled: true },
    pagination: true,
    // resizableColumns: true,
    // responsive: "standard",
    fixedHeader: false,
    downloadOptions: {
        filterOptions: {
            useDisplayedRowsOnly: true,
        }
    }
};
const Root = props => (
    <Legend.Root {...props} sx={{ display: 'flex', margin: 'auto', flexDirection: 'row' }} />
);
const Label = props => (
    <Legend.Label {...props} sx={{ whiteSpace: 'nowrap' }} />
);
class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {},
            selectedDate: new Date(),
            heading: '',
            overViewDetals: [],
            options2: {
                filterType: 'multiselect',
                // filterOptions: {fullWidth: true},
                selectableRows: 'none',
                setRowProps: row => {
                    if (row[0] === "New") {
                        return {
                            style: { background: "snow" }
                        };
                    }
                },
                draggableColumns: { enabled: true },
                pagination: true,
                // resizableColumns: true,
                // responsive: "standard",
                fixedHeader: false,
                downloadOptions: {
                    filterOptions: {
                        useDisplayedRowsOnly: true,
                    }
                },
                onDownload: (buildHead, buildBody, columns, data) => {
                    const columns2 = Object.keys(this.state.overViewDetals[0]);
                    const data2 = this.state.overViewDetals.map((row) => {
                        return Object.values(row);
                    });

                    // console.log(columns2);
                    // console.log(data2, 'data');

                    const result = this.state.overViewDetals.reduce((acc, curr) => {
                        const BillingFrom = curr.BillingFrom;
                        if (!acc[BillingFrom]) {
                            acc[BillingFrom] = [];
                        }
                        acc[BillingFrom].push(curr);
                        return acc;
                    }, {});




                    const worksheet = XLSX.utils.aoa_to_sheet([columns2, ...data2]);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, `All-${data2.length}`);

                    for (const key in result) {
                        // console.log(`Key: ${key}`);
                        const array = result[key];
                        // console.log(result[key]);

                        let data3 = result[key].map((row) => {
                            return Object.values(row);
                        });

                        let worksheet1 = XLSX.utils.aoa_to_sheet([columns2, ...data3]);
                        XLSX.utils.book_append_sheet(workbook, worksheet1, `${key}-${array.length}`);
                    }

                    XLSX.writeFile(workbook, "data.xlsx");
                    return false;
                },
            },
            chartData: [
                {
                    "billingmonth": "JAN2022",
                    "TotalBillingcnt": 378,
                    "BilledCnt": 250
                },
                {
                    "billingmonth": "JUL2022",
                    "TotalBillingcnt": 378,
                    "BilledCnt": 102
                },
                {
                    "billingmonth": "JUN2022",
                    "TotalBillingcnt": 378,
                    "BilledCnt": 260
                },
                {
                    "billingmonth": "MAY2022",
                    "TotalBillingcnt": 378,
                    "BilledCnt": 378
                }
            ],
            isManager: 0,
            downloadFiles: [],
            openDownloadFilesModal: false,
            unique_statesFrom: [],
            stateFrom: '',
            unique_statesTo: [],
            stateTo: '',
            billFromDetails: {},
            billToDetails: {},
            unique_clientNamesTo: [],
            clientNameTo: '',
            unique_bankDetailsDD: [],
            bankDetailsDD: [],
            bankDetails: {},
            reqEmail: '',
            reqName: '',
            po: '',
            vertical: 'top',
            horizontal: 'center',
            vendorCode: '',
            placeofService: '',
            uniqueDateRanges: [],
            dateRangeSelected: '',
            startDate: '',
            endDate: '',
            billingMonth: '',
            lastUpdatedStatus: '',
            viewInvoiceBilling: [],
            // newInvoiceBilling: [],
            unique_sac: [],
            sacDetails: {},
            itemDetails: [{ Description: '', SAC: '', grandTotal: 0, sGST: '', sGSTAmount: 0, cGST: '', cGSTAmount: 0, iGST: '', iGSTAmount: 0 }],
            attachements: [{ Description: '', DocLink: '' }],
            totalAmount: '',
            totalGST: '',
            grandTotal: '',
            submitButton: 'Generate Invoice',
            headingGenerate: 'Generate Invoice',
            successText: '',
            alertState: false,
            invoiceUrl: '',
            invoiceModal: false,
            clientNameProject: '',
            unique_clientNamesProject: [],
            unique_project: [],
            projectName: [],
            uploadBillLinkImage: [],
            statusModal: false,
            unique_statusDD: [],
            StatusID: '',
            invoiceDate: '',
            misAttachments: [],
            invAttachments: [],
            rowData: [],
            rowData2: [],
            comments: '',
            updateID: '',
            openCommentModal: false,
            comment: '',
            commentColor: '',
            invxrefID: '',
            snackBar: false,
            openInvMondal: false,
            invID: '',
            newStartDate: '',
            newEndDate: '',
            invoiceName: '',
            updateInvID: '',
            CommentHistory: [],
            costCenter: '',
            clientSpocName: '',
            Invoiceno: '',
            modalData: '',
            viewImageModal: false,
            viewPdfModal: false,
            viewExcelModal: false,
            totalPaymentReceived: 0,
            openPaymentHistoryModal: false,
            paymentHistoryData: [],
            invoiceno: '',
            eagleViewStatsTotal: 0,
            eagleViewStats: [],
            openEagleViewModal: false,
            invoiceDetailsTotalEagle: 0,
            rowDataEagleView: [],
            invoiceStatusDD: [],
            errorText: '',
            errorState: false,
            clickedStatus: '',
            openDownloadFilesModal2: false,
            downloadFiles2: [],
            rowItemDetailData: [],
            openInvStatsItemDetails: false,
            BalanceAmount: 0,
            tds: 0,
            totalAmount: 0,
            grandTotal: 0,
            totalGST: 0,
            invBillingMonth: '',
            columnsInvDetailsEagle: [
                {
                    name: "createdOn",
                    label: "Created Date",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "projectName",
                    label: "Project Name",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "invoiceno",
                    label: "Invoice No.",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "createdBy",
                    label: "Created By",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "ZonalManager",
                    label: "Zonal Manager",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "billingmonth",
                    label: "Billing Month",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "grandTotal",
                    label: "Grand Total",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <span>  ₹{" "}{new Intl.NumberFormat("en-IN").format(value)}</span>
                            )
                        }
                    },
                },
                {
                    name: "status",
                    label: "Status",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            console.log(value);
                            return (
                                <>
                                    <FormControl sx={{ m: 1 }} style={{ width: '25ch' }} fullWidth>
                                        <InputLabel id="viewInvoiceMonth">Select Status</InputLabel>
                                        <Select
                                            labelId="viewInvoiceMonth"
                                            id="viewInvoiceMonth2"
                                            value={value.toLowerCase()}
                                            onChange={(e) =>
                                                this.handleChangeBillingStatus(
                                                    tableMeta.rowIndex, e.target.value
                                                )
                                            }
                                            label="Select Status"
                                            displayEmpty
                                        >
                                            {this.state.invoiceStatusDD.map((eachItem, key) => (
                                                <MenuItem value={eachItem.Status.toLowerCase()} key={key}>
                                                    {eachItem.Status}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </>
                            )
                        }
                    },
                },
                {
                    name: "attachements",
                    label: "Action",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            let attachementsParse = [];
                            if (value != undefined || value != null) {
                                attachementsParse = JSON.parse(
                                    value
                                );
                            }
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                    }}
                                >
                                    {this.state.rowDataEagleView[tableMeta.rowIndex].invURL == "" || this.state.rowDataEagleView[tableMeta.rowIndex].invURL == null || this.state.rowDataEagleView[tableMeta.rowIndex].invURL == undefined ? (
                                        <div>
                                            <Tooltip title=" No Invoice">
                                                <FileDownloadOffTwoToneIcon />
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <div>
                                            <Tooltip title="Download Invoice">
                                                <a
                                                    // component={RouterLink}
                                                    size="small"
                                                    target="_blank"
                                                    href={this.state.rowDataEagleView[tableMeta.rowIndex].invURL}
                                                    style={{ color: "black", minWidth: "40px" }}
                                                // variant="outlined"
                                                >
                                                    <GetAppIcon />
                                                </a>
                                            </Tooltip>
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            marginLeft: "10px",
                                        }}
                                    >
                                        {value != '' && value != null && attachementsParse[0].DocLink != '' ?
                                            <Tooltip title="Download">
                                                <Button
                                                    // component={RouterLink}
                                                    size="small"
                                                    // to={{
                                                    //   pathname: `/management/view/order-details/${params.node.data.pTLTripID}`,
                                                    //   orders: this.state.orders
                                                    // }}
                                                    onClick={(e) => this.handleOpenAttachmentsModal(value, e)}
                                                    style={{ color: 'black' }}
                                                // variant="outlined"
                                                >
                                                    <FileCopyIcon />
                                                </Button>
                                            </Tooltip> :
                                            <Tooltip title="Download">
                                                <Button
                                                    size="small"
                                                    style={{ color: 'black' }}
                                                >
                                                    <FolderOffOutlinedIcon />
                                                </Button>
                                            </Tooltip>
                                        }
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: "10px",
                                        }}
                                    >
                                        <Tooltip title="Item Details">
                                            <InfoIcon
                                                onClick={() =>
                                                    this.handleOpenInvStatsItemDetailsModal(
                                                        tableMeta.rowIndex
                                                    )
                                                }
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            );
                        },
                    },
                },
            ],
            columns: [
                {
                    name: "BillingMonth",
                    label: "Billing Month",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "clientName",
                    label: "Client Name",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "invoices",
                    label: "Invoice Number",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            let arr = [];
                            if (value != null) {
                                arr = value.split(",");
                            }
                            return (
                                <div style={{ textAlign: 'center' }}>
                                    <u onClick={(e) => { this.handleOpenInvMondal(tableMeta.rowIndex, e) }} className="invCount"> {arr.map((e) => (
                                        <>
                                            {e} <br />
                                        </>
                                    ))}</u>
                                </div>
                            )
                        }
                    },
                },
                {
                    name: "projectName",
                    label: "Project Name",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const projectName = value;
                            const newArray = projectName.split(',');
                            return (
                                <>
                                    {
                                        newArray.map((e) => (
                                            <>
                                                {e} <br />                                    </>
                                        ))
                                    }
                                </>
                            )
                        }
                    }
                },

                {
                    name: "totalAmount",
                    label: "Total Amount",
                    options: {
                        filter: true,
                        sort: true,
                        customHeadLabelRender: (dataIndex, rowIndex) => {
                            return (
                                <>
                                    Total Amount  <br />
                                    <div className="row">
                                        <div className="col-12 adjustWidthTotal">
                                            (₹ {new Intl.NumberFormat("en-IN").format(this.state.totalAmount)})
                                        </div>
                                    </div>
                                </>
                            )
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <div style={{ minWidth: "12vh" }}>
                                    ₹ {new Intl.NumberFormat("en-IN").format(value)}
                                </div>
                            )
                        }
                    }
                },
                {
                    name: "totalGST",
                    label: "GST",
                    options: {
                        filter: true,
                        sort: true,
                        customHeadLabelRender: (dataIndex, rowIndex) => {
                            return (
                                <>
                                    GST  <br />
                                    <div className="row">
                                        <div className="col-12 adjustWidthTotal">
                                            (₹ {new Intl.NumberFormat("en-IN").format(this.state.totalGST)})
                                        </div>
                                    </div>
                                </>
                            )
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <div style={{ minWidth: "12vh" }}>
                                    ₹ {new Intl.NumberFormat("en-IN").format(value)}
                                </div>
                            )
                        }
                    }
                },
                {
                    name: "grandTotal",
                    label: "Grand Total",
                    options: {
                        filter: true,
                        sort: true,
                        customHeadLabelRender: (dataIndex, rowIndex) => {
                            return (
                                <>
                                    Grand Total  <br />
                                    <div className="row">
                                        <div className="col-12 adjustWidthTotal">
                                            (₹ {new Intl.NumberFormat("en-IN").format(this.state.grandTotal)})
                                        </div>
                                    </div>
                                </>
                            )
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <div style={{ minWidth: "12vh" }}>
                                    ₹ {new Intl.NumberFormat("en-IN").format(value)}
                                </div>
                            )
                        }
                    }
                },
                {
                    name: "status",
                    label: "Status",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "TotalPaymentReceived",
                    label: "Total Payment Received",
                    options: {
                        filter: true,
                        sort: true,
                        customHeadLabelRender: (dataIndex, rowIndex) => {
                            return (
                                <>
                                    Total Payment Received  <br />
                                    <div className="row">
                                        <div className="col-12">
                                            (₹ {new Intl.NumberFormat("en-IN").format(this.state.totalPaymentReceived)})
                                        </div>
                                    </div>
                                </>
                            )
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {

                            if (value != '' && value != 0 && value != null) {
                                return (
                                    <div style={{ minWidth: '16vh', display: 'flex', justifyContent: "center" }}>
                                        <div style={{ margin: 'auto' }}>
                                            ₹ {new Intl.NumberFormat("en-IN").format(value)}
                                        </div>
                                        <div>
                                            <Button
                                                onClick={(e) => { this.handleOpenPaymentHistoryModal(tableMeta.rowIndex) }}
                                                size="small" sx={{ borderRadius: '50%', padding: '15px' }}>
                                                <img src={historyIcon} style={{ width: '30px', height: '30px' }} />
                                            </Button>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (<>
                                    ₹ {new Intl.NumberFormat("en-IN").format(value)}
                                </>)
                            }
                        }
                    }
                },
                {
                    name: "TDS",
                    label: "",
                    options: {
                        filter: true,
                        sort: true,
                        customHeadLabelRender: (dataIndex, rowIndex) => {
                            return (
                                <>
                                    TDS  <br />
                                    <div className="row">
                                        <div className="col-12 adjustWidth" >
                                            (₹ {new Intl.NumberFormat("en-IN").format(this.state.tds)})
                                        </div>
                                    </div>
                                </>
                            )
                        },
                    }
                },
                {
                    name: "BalanceAmount",
                    label: "Balance Amount",
                    options: {
                        filter: true,
                        sort: true,
                        customHeadLabelRender: (dataIndex, rowIndex) => {
                            return (
                                <>
                                    Balance Amount  <br /> (₹ {new Intl.NumberFormat("en-IN").format(this.state.BalanceAmount)})
                                </>
                            )
                        },
                    }
                },
                {
                    name: "pendency",
                    label: "Pendency",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "otherDeductions",
                    label: "Other Deductions",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "ClientGST",
                    label: "Client GST",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "LC_GST",
                    label: "Logicarts GST",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
            ],
            columns2: [
                {
                    name: "Invoiceno",
                    label: "Invoice Number",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "clientName",
                    label: "Client Name",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "jdata",
                    label: "Project Name",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const projectName = JSON.parse(value);
                            const newArray = projectName.Projects.split(',');
                            return (
                                <>
                                    {
                                        newArray.map((e) => (
                                            <>
                                                {e} <br />                                    </>
                                        ))
                                    }
                                </>
                            )
                        }
                    }
                },
                {
                    name: "totalAmount",
                    label: "Total Amount",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <div style={{ minWidth: "12vh" }}>
                                    ₹ {new Intl.NumberFormat("en-IN").format(value)}
                                </div>
                            )
                        }
                    }
                },
                {
                    name: "totalGST",
                    label: "GST",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <div style={{ minWidth: "12vh" }}>
                                    ₹ {new Intl.NumberFormat("en-IN").format(value)}
                                </div>
                            )
                        }
                    }
                },
                {
                    name: "grandTotal",
                    label: "Grand Total",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <div style={{ minWidth: "12vh" }}>
                                    ₹ {new Intl.NumberFormat("en-IN").format(value)}
                                </div>
                            )
                        }
                    }
                },
                {
                    name: "GeneratedDate",
                    label: "Generated Date",
                },
                {
                    name: "status",
                    label: "Status",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const statusName = value;
                            var colorNew = '';
                            if (statusName === "Billed") {
                                return (
                                    <Chip color="success" style={{ backgroundColor: 'rgb(243, 129, 39)', borderRadius: '4px', height: '25px' }} label={statusName} />
                                )
                            } else if (statusName === "Yet To Bill") {
                                return (
                                    <Chip color="primary" style={{ borderRadius: '4px', height: '25px' }} label={statusName} />
                                )
                            } else if (statusName === "Issued Offer letter") {
                                return (
                                    <Chip color="primary" style={{ backgroundColor: "#fff900", borderRadius: '4px', height: '25px' }} label={statusName} />
                                )
                            } else {
                                return (
                                    <Chip color="primary" style={{ borderRadius: '4px', height: '25px' }} label={statusName != null ? statusName : "N/A"} />
                                )
                            }
                        }
                    }
                },
                {
                    name: "InvURL",
                    label: "Attachments",
                    options: {
                        filter: false,
                        sort: true,
                        customBodyRenderLite: (dataIndex) => {
                            return (
                                <Tooltip title="Download">
                                    <Button
                                        // component={RouterLink}
                                        size="small"
                                        // to={{
                                        //   pathname: `/management/view/order-details/${params.node.data.pTLTripID}`,
                                        //   orders: this.state.orders
                                        // }}
                                        onClick={(e) => this.handleDownloadAttachments(dataIndex, e)}
                                        style={{ color: 'black' }}
                                    // variant="outlined"
                                    >
                                        <DownloadIcon />
                                    </Button>
                                </Tooltip>
                            )
                        }
                    }
                },
                {
                    name: "invID",
                    label: "Action",
                    options: {
                        filter: false,
                        sort: true,
                        customBodyRenderLite: (dataIndex) => {
                            return (
                                <div style={{ display: 'flex' }}>
                                    <Tooltip title="Comments">
                                        <Button
                                            size="small"
                                            onClick={(e) => this.handleOpenComentModal(dataIndex, e)}
                                            style={{ color: 'black', minWidth: '50px' }}
                                        >
                                            <MapsUgcIcon />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Update Status">
                                        <Button
                                            size="small"
                                            onClick={(e) => this.handleOpenStatuslModal(dataIndex, e)}
                                            style={{ color: 'black', minWidth: '50px' }}
                                        >
                                            <EditIcon />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Modify">
                                        <Button
                                            size="small"
                                            onClick={(e) => this.handleOpenFullModal(dataIndex, e)}
                                            style={{ color: 'black', minWidth: '50px' }}
                                        >
                                            <AppRegistrationIcon />
                                        </Button>
                                    </Tooltip>
                                </div>
                            )
                        }
                    }
                },
            ],
            columnsInvItemDetails: [
                {
                    name: "Description",
                    label: "Description",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "SAC",
                    label: "SAC",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "iGST",
                    label: "iGST",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "iGSTAmount",
                    label: "iGST Amount",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "cGST",
                    label: "cGST",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "cGSTAmount",
                    label: "cGST Amount",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "sGST",
                    label: "sGST",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "sGSTAmount",
                    label: "sGST Amount",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "grandTotal",
                    label: "Grand Total",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
            ],
            transportationText: '',
            showTransportationText: false,

        };
        this.validator = new SimpleReactValidator();
    }
    delay = async (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }
    getPath = (x, width, y, y1) => `M ${x} ${y1}
   L ${width + x} ${y1}
   L ${width + x} ${y + 30}
   L ${x + width / 2} ${y + 30}
   L ${x} ${y + 30}
   Z`;
    BarWithLabel = ({
        arg, barWidth, maxBarWidth, val, startVal, color, value, style,
    }) => {
        const width = maxBarWidth * barWidth;
        return (
            <React.Fragment>
                <path d={this.getPath(arg - width / 2, width, val, startVal)} fill={color} style={style} />
                <Chart.Label
                    x={arg}
                    y={(val + startVal) / 2}
                    dominantBaseline="middle"
                    textAnchor="middle"
                    style={{ fontSize: '17px', fontWeight: '600', fill: '#FFFFFF' }}
                >
                    {value}
                </Chart.Label>
            </React.Fragment>
        );
    };
    getPath2 = (x, width, y, y1) => `M ${x} ${y1}
   L ${width + x} ${y1}
   L ${width + x} ${y}
   L ${x + width / 2} ${y}
   L ${x} ${y}
   Z`;
    BarWithLabel2 = ({
        arg, barWidth, maxBarWidth, val, startVal, color, value, style,
    }) => {
        const width = maxBarWidth * barWidth;
        return (
            <React.Fragment>
                <path d={this.getPath2(arg - width / 2, width, val, startVal)} fill={color} style={style} />
                <Chart.Label
                    x={arg}
                    y={(val + startVal) / 2}
                    dominantBaseline="middle"
                    textAnchor="middle"
                    style={{ fontSize: '17px', fontWeight: '600', fill: '#FFFFFF' }}
                >
                    {value}
                </Chart.Label>
            </React.Fragment>
        );
    };
    getUniqueDateRanges = async () => {
        await axios
            .post(
                `${Config.hostName}/bms/dateRangeDropDownOnlyMonth`, {
                ccxref: 384
            }
            )
            .then(async (res) => {
                console.log(res.data)
                if (res.data.data.length > 0) {
                    await this.setState({
                        uniqueDateRanges: res.data.data,
                    });
                }
            }).catch((err) => {
                alert(err);
            });
    };
    handleChangeDateRange = async (e) => {
        if (e.target.value != 0) {
            let value = e.target.value;
            console.log(value);
            // console.log();2222
            if (value == 2222) {
                await this.setState({
                    dateRangeSelected: e.target.value,
                    startDate: "",
                    endDate: "",
                });
                await this.toggle();
            } else {
                this.state.uniqueDateRanges.filter(async (eachDate, i) => {
                    if (eachDate.billingMonth === value) {
                        // console.log("true", eachDate);
                        await this.setState({
                            dateRangeSelected: e.target.value,
                            startDate: eachDate.startDate,
                            endDate: eachDate.endDate,
                            billingMonth: eachDate.billingMonth
                        });
                    }
                });
            }
        }
    };
    handleOpenPaymentHistoryModal = async (rowIndex) => {
        await this.setState({
            style: {}
        });
        const invoiceno = this.state.rowData[rowIndex].invoices;
        const res = await axios.post(
            `${Config.hostName}/billing/invoicePaymentHistory`,
            {
                invoiceno: invoiceno,
            }
        )
            .then(async (res) => {
                this.setState({
                    invoiceno: invoiceno,
                    paymentHistoryData: res.data.data,
                    openPaymentHistoryModal: true
                })
            })
            .catch((error) => {
                alert(error);
            });
        await this.setState({
            style: {
                display: "none"
            }
        });
    }
    getEagelViewStats = async () => {
        await this.setState({
            // style: {},
        });
        let billingMonth = '';
        await this.state.viewInvoiceBilling.map((e) => {
            billingMonth = billingMonth + ',' + e.replace(' ', '');
        });
        billingMonth = billingMonth.substr(1, billingMonth.length - 1);
        const res = await axios
            .post(`${Config.hostName}/bs/dashboard/eagleViewStats`, {
                billingMonth: billingMonth,
            })
            .then(async (res) => {
                // console.log(res.data.data);
                if (res.data.data.length > 0) {
                    let eagleViewStatsTotal = 0;
                    await res.data.data.map(async (e, key) => {
                        eagleViewStatsTotal += e.grandTotal;
                    })
                    await this.setState({
                        eagleViewStats: res.data.data,
                        eagleViewStatsTotal: eagleViewStatsTotal
                    })
                } else {
                    await this.setState({
                        alertSnackbar: true,
                    })
                }
                await this.setState({
                    style: { display: "none" },
                });
            })
            .catch(async (err) => {
                await this.setState({
                    alertSnackbar: true,
                    style: { display: "none" },
                })
            });
    }
    handleOpenAttachmentsModal = async (value, e) => {
        await this.setState({
            downloadFiles2: JSON.parse(value),
            openDownloadFilesModal2: true
        })
    }
    handleCloseAttachmentsModal = async () => {
        await this.setState({
            downloadFiles2: [],
            openDownloadFilesModal2: false
        })
    }
    handleOpenInvStatsItemDetailsModal = async (i) => {
        if (this.state.rowDataEagleView[i].itemDetails != undefined || this.state.rowDataEagleView[i].itemDetails != null) {
            let itemDetails = this.state.rowDataEagleView[i].itemDetails;
            const itemDetailParsed = JSON.parse(itemDetails);
            await this.setState({
                openInvStatsItemDetails: true,
                rowItemDetailData: itemDetailParsed,
            })
        } else {
            alert('No records found');
            await this.setState({
                alertSnackbar: true,
            })
        }
    }
    handleCloseInvStatsItemDetailsModal = async () => {
        await this.setState({
            openInvStatsItemDetails: false,
            rowItemDetailData: [],
            transportationText: '',
            showTransportationText: false,
        })
    }
    handleClosePaymentHistoryModal = async () => {
        this.setState({
            invoiceno: '',
            openPaymentHistoryModal: false
        })
    }
    getOverview_Details = async () => {
        const res = await axios.post(
            `${Config.hostName}/billing/getInvoiceDetails_Overview_download`,
            {
                billingMonth: this.state.viewInvoiceBilling.join(',')
            }
        )
            .then(async (res) => {
                // console.log(res.data,'overViewDetals');

                let newArray = res.data.data.map(obj => {
                    delete obj.totalGST;
                    return obj;
                });

                console.log(newArray, 'overviewDetals');

                await this.setState({
                    overViewDetals: newArray
                })
            })
            .catch((error) => {
                alert(error);
                this.setState({
                    style: {
                        display: "none"
                    }
                });
            });
    }
    handleChangeBillingMonth = async (e) => {
        const value = e.target.value;
        await this.setState({
            viewInvoiceBilling: typeof value === 'string' ? value.split(',') : value,
        })
    }
    handleChangeViewInvoice = async (val, e) => {
        await this.setState({
            style: {}
        });
        // const billingMonth = this.state.viewInvoiceBilling;
        if (val != 'm' && val != 'u') {
            if (e.target.value != '') {
                // await this.setState({
                //     viewInvoiceBilling: e.target.value
                // })
                const value = e.target.value;
                await this.setState({
                    viewInvoiceBilling: typeof value === 'string' ? value.split(',') : value,
                })
                // let billingMonth = this.state.viewInvoiceBilling.join(',');
            }
        } else if (val == 'm') {
            if (this.state.isManager == 0) {
                await this.setState({
                    isManager: 1
                })
            } else {
                await this.setState({
                    isManager: 0
                })
            }
        }
        const res = await axios.post(
            `${Config.hostName}/billing/getInvoiceDetailsNew`,
            {
                billingMonth: this.state.viewInvoiceBilling.join(','),
                isManagerView: `${this.state.isManager}`,
            }
        )
            .then(async (res) => {
                await this.setState({
                    style: {
                        display: "none"
                    }
                });
                if (res.data.data.length > 0) {
                    let count = 0;
                    let totalAmount = 0;
                    let tds = 0;
                    let totalGST = 0;
                    let BalanceAmount = 0;
                    let grandTotal = 0;
                    await res.data.data.map((e) => {
                        count += e.TotalPaymentReceived;
                        totalAmount += e.totalAmount;
                        tds += e.TDS;
                        totalGST += e.totalGST;
                        BalanceAmount += e.BalanceAmount;
                        grandTotal += e.grandTotal;
                    })

                    await this.setState({
                        totalPaymentReceived: count,
                        totalAmount: totalAmount,
                        tds: tds,
                        BalanceAmount: BalanceAmount,
                        totalGST: totalGST,
                        grandTotal: grandTotal,
                        rowData: res.data.data,
                    });
                     this.getEagelViewStats();
                    this.getOverview_Details();
                } else {
                    alert('No Recoords Found... !!');
                    await this.setState({
                        style: {
                            display: "none"
                        },
                        viewInvoiceBilling: this.state.viewInvoiceBilling.join(','),
                    });
                }
            })
            .catch(async (error) => {
                alert(error);
                await this.setState({
                    style: {
                        display: "none"
                    }
                });
            });
    }
    handleDownloadAttachments = async (index) => {
        // console.log(this.state.rowData2[index].invURL);
        let linkArray = this.state.rowData2[index].invURL;
        const urls = linkArray.split(',');
        await this.setState({
            misAttachments: this.state.rowData2[index].misAttachments != "" ? JSON.parse(this.state.rowData2[index].misAttachments) : [],
            invAttachments: JSON.parse(this.state.rowData2[index].attachments),
            downloadFiles: urls,
            openDownloadFilesModal: true
        })
    }
    handleCloseDownloadFilesModal = async () => {
        await this.setState({
            downloadFiles: [],
            openDownloadFilesModal: false
        })
    }
    getStatusDD = async () => {
        const res = await axios.get(
            `${Config.hostName}/billing/getInvoiceStatusDD`,
            {
                // ManagerID: 113
            }
        )
            .then(async (res) => {
                // console.log(res.data.data, 'project');
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_statusDD: res.data.data
                    });
                } else {
                    alert('No Recoords Found... !!');
                }
            })
            .catch((error) => {
                alert(error);
            });
    }
    handleSubmitUpdate = async (e) => {
        await this.setState({
            style: {}
        });
        const res = await axios.post(
            `${Config.hostName}/billing/updateContent`,
            {
                contentID: 2,
                content: `{"statusID": "${this.state.StatusID}"}`,
                invID: this.state.updateID,
            }
        )
            .then(async (res) => {
                // console.log(res, 'project');
                await this.handleChangeViewInvoice('u', '');
                await this.delay(100);
                await this.setState({
                    statusModal: false,
                })
                await this.setState({
                    style: {
                        display: "none"
                    }
                });
                // if (res.data.data > 0) {
                //     await this.setState({
                //        unique_statusDD: res.data.data
                //     });
                // } else {
                //     alert('No Recoords Found... !!');
                // }
            })
            .catch(async (error) => {
                alert(error);
            });
    }
    handleOpenStatuslModal = async (index, e) => {
        await this.getStatusDD();
        // console.log(this.state.rowData[index].invID);
        await this.setState({
            updateID: this.state.rowData[index].invID,
            statusModal: true
        })
    }
    handleCloseStatuslModal = async () => {
        await this.setState({
            updateID: "",
            statusModal: false
        })
    }
    handleOpenComentModal = async (index, e) => {
        await this.setState({
            CommentHistory: JSON.parse(this.state.rowData2[index].CommentHistory),
            openCommentModal: true,
            commentColor: "",
            invxrefID: this.state.rowData2[index].invxrefID
        })
    }
    handleCloseComentModal = async () => {
        await this.setState({
            openCommentModal: false
        })
    }
    handleCloseSnackBar = async () => {
        await this.setState({
            snackBar: false,
        })
    }
    changeComments = async (e) => {
        e.preventDefault();
        let final = e.target.value;
        final = await final.replace(/[\W_]+/g, " ");
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/;
        let error = format.test(final);
        if (error) {
            this.setState({
                commentColor: 'red'
            })
        }
        await this.setState({
            comment: final
        })
    }
    handleSubmitComment = async (e) => {
        e.preventDefault();
        await this.setState({
            snackBar: true,
        })
        const res = await axios.post(
            `${Config.hostName}/billing/updateContent`,
            {
                contentID: "9",
                content: `{"invxrefID":${this.state.invxrefID},"userID":"${this.props.auth.user.USERID}","notes":"${this.state.comment}"}`,
                invID: "",
            }
        )
            .then(async (res) => {
                // console.log(res.data, 'Status');
                this.getInvoiceByInvID();
                await this.delay(300);
                this.setState({
                    comment: "",
                    openCommentModal: false,
                    invxrefID: '',
                })
            })
            .catch((error) => {
                alert(error);
            });
    }
    getNewRequests = async () => {
        const res = await axios.get(
            `${Config.hostName}/billing/getClientList`,
            {
                // ManagerID: 113
            }
        )
            .then(async (res) => {
                // console.log(res.data.data, 'project');
                if (res.data.data.length > 0) {
                    await this.setState({
                        rowData: res.data.data.map(eachItem => {
                            return {
                                clientID: eachItem.clientID,
                                clientName: eachItem.clientName,
                                billingName: eachItem.billingName,
                                State: eachItem.State,
                                address: eachItem.address,
                                GST: eachItem.GST,
                                CreatedDate: eachItem.CreatedDate,
                            }
                        })
                    });
                } else {
                    alert('No Recoords Found... !!');
                }
            })
            .catch((error) => {
                alert(error);
            });
    }
    getStates = async () => {
        const res = await axios.get(
            `${Config.hostName}/billing/getStateDD`,
            {
                // ManagerID: 113
            }
        )
            .then(async (res) => {
                // console.log(res.data.data, 'project');
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_states: res.data.data
                    });
                } else {
                    alert('No Recoords Found... !!');
                }
            })
            .catch((error) => {
                alert(error);
            });
    }
    getClientNames = async () => {
        const res = await axios.get(
            `${Config.hostName}/billing/getCleintNameDD`,
            {
                // ManagerID: 113
            }
        )
            .then(async (res) => {
                // console.log(res.data.data, 'clientName');
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_clientNames: res.data.data
                    });
                } else {
                    alert('No Recoords Found... !!');
                }
            })
            .catch((error) => {
                alert(error);
            });
    }
    getStatesBillFrom = async () => {
        const res = await axios.post(
            `${Config.hostName}/billing/getStateDDbyCLient`,
            {
                clientName: "logicarts"
            }
        )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_statesFrom: res.data.data
                    });
                } else {
                    alert('No Recoords Found... !!');
                }
            })
            .catch((error) => {
                alert(error);
            });
    }
    getSac = async () => {
        const res = await axios.post(
            `${Config.hostName}/billing/bsSACD`,
            {
                LGST: this.state.billFromDetails.GST,
                ClGST: this.state.billToDetails.GST,
            }
        )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_sac: res.data.data
                    });
                } else {
                    alert('No Recoords Found... !!');
                }
            })
            .catch((error) => {
                alert(error);
            });
    }
    handleFromStateChange = async (clientName, i, e) => {
        if (e.target.value != 0) {
            if (i == 1) {
                await this.setState({
                    stateFrom: e.target.value
                })
            } else {
                await this.setState({
                    stateTo: e.target.value
                })
            }
            const res = await axios.post(
                `${Config.hostName}/billing/getClientDetailsbyState`,
                {
                    clientName: clientName,
                    state: e.target.value
                }
            )
                .then(async (res) => {
                    // console.log(res.data);
                    if (i == 1) {
                        await this.setState({
                            billFromDetails: res.data.data[0]
                        })
                    } else {
                        await this.setState({
                            billToDetails: res.data.data[0]
                        })
                        await this.getSac();
                    }
                    if (this.state.billFromDetails.GST != null && this.state.billToDetails.GST != null) {
                        await this.getSac();
                    }
                    await this.setState({
                        itemDetails: [{ Description: '', SAC: '', grandTotal: 0, sGST: '', sGSTAmount: 0, cGST: '', cGSTAmount: 0, iGST: '', iGSTAmount: 0 }],
                        totalAmount: '',
                        totalGST: '',
                        grandTotal: '',
                    })
                })
                .catch((error) => {
                    alert(error);
                });
        }
    }
    getClientsBillTo = async () => {
        const res = await axios.get(
            `${Config.hostName}/billing/getCleintNameDD`,
            {
            }
        )
            .then(async (res) => {
                // console.log(res.data, 'unique_clientNamesTo');
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_clientNamesTo: res.data.data
                    });
                } else {
                    alert('No Recoords Found... !!');
                }
            })
            .catch((error) => {
                alert(error);
            });
    }
    handleChangeClientTo = async (newValue, e) => {

        if (e != 0 || e != null) {
            await this.setState({
                clientNameTo: e,
                stateTo: '',
                billToDetails: {}
            })

            if (e == 'Loram') {
                this.setState({
                    transportationText: '',
                    showTransportationText: true
                });
            } else {
                this.setState({
                    transportationText: '',
                    showTransportationText: false
                });
            }
            const res = await axios.post(
                `${Config.hostName}/billing/getStateDDbyCLient`,
                {
                    clientName: this.state.clientNameTo
                }
            )
                .then(async (res) => {
                    if (res.data.data.length > 0) {
                        await this.setState({
                            unique_statesTo: res.data.data
                        });
                    } else {
                        alert('No Recoords Found... !!');
                    }
                })
                .catch((error) => {
                    alert(error);
                });
        }
    }
    getBankNames = async () => {
        const res = await axios.get(
            `${Config.hostName}/billing/getLCBankDD`,
            {
            }
        )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_bankDetailsDD: res.data.data
                    });
                } else {
                    alert('No Recoords Found... !!');
                }
            })
            .catch((error) => {
                alert(error);
            });
    }
    handleChangeBankName = async (e) => {
        if (e.target.value == 0) {
        } else {
            await this.setState({
                bankDetailsDD: e.target.value
            })
            const res = await axios.post(
                `${Config.hostName}/billing/getLCBankDetails`,
                {
                    bankID: e.target.value
                }
            )
                .then(async (res) => {
                    // console.log(res.data.data);
                    if (res.data.data.length > 0) {
                        await this.setState({
                            bankDetails: res.data.data[0]
                        });
                    } else {
                        alert('No Recoords Found... !!');
                    }
                })
                .catch((error) => {
                    alert(error);
                });
        }
    }
    getClientNameProject = async () => {
        const res = await axios.get(
            `${Config.hostName}/billing/getCleintNameDD`,
            {
            }
        )
            .then(async (res) => {
                // console.log(res.data);
                if (res.data.data.length > 0) {
                    // console.log(res.data.data, 'unique_clientNamesProject')
                    await this.setState({
                        unique_clientNamesProject: res.data.data
                    });
                } else {
                    alert('No Recoords Found... !!');
                }
            })
            .catch((error) => {
                alert(error);
            });
    }
    handleChangeClientProject = async (e, newValue) => {
        // console.log(e, 'rohansss');
        // console.log(newValue, 'rohansss');
        if (newValue != "") {
            await this.setState({
                clientNameProject: newValue,
                projectName: [],
            })
        }
        const res = await axios.post(
            `${Config.hostName}/billing/getProjectDropDown`,
            {
                clientName: this.state.clientNameProject
            }
        )
            .then(async (res) => {
                // console.log(res.data.data);
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_project: res.data.data,
                    });
                } else {
                    alert('No Recoords Found... !!');
                }
            })
            .catch((error) => {
                alert(error);
            });
    }
    handleProjectChange = async (e, newValue) => {
        const value = newValue;
        await this.setState({
            projectName: typeof value === 'string' ? value.split(',') : value,
        })
        // console.log(this.state.projectName);
    }
    getLastUpdatedStatus = async (e, newValue) => {
        const res = await axios.post(
            `${Config.hostName}/bs/getLastUpdatedDate`,
            {
            }
        )
            .then(async (res) => {
                this.setState({
                    lastUpdatedStatus: res.data.data
                })
            })
            .catch((error) => {
                alert(error);
            });
    }
    async componentDidMount() {
        await this.setState({
            style: {}
        });
        if (this.props.auth.user.ismgr == 1) {
            await this.setState({
                isManager: 1
            })
        }
        await this.getUniqueDateRanges();
        await this.setState({
            viewInvoiceBilling: [this.state.uniqueDateRanges[1].billingMonth]
        })
         this.getEagelViewStats();
        await this.handleChangeViewInvoice("", { target: { value: this.state.viewInvoiceBilling } });
         this.getStatesBillFrom();
         this.getClientsBillTo();
         this.getBankNames();
         this.getClientNameProject();
         this.getLastUpdatedStatus();
        await this.setState({
            style: {
                display: "none"
            }
        });
    }
    changeClientName = async (e) => {
        if (e.target.value == 'add') {
            this.setState({
                clientNameState: '',
                clientNameDropDown: 'add',
                clientName: ''
            })
        } else {
            this.setState({
                clientNameDropDown: e.target.value,
                clientNameState: 'newClientAdd',
                clientName: e.target.value
            });
        }
    }
    getStatusEagleView = async () => {
        const res = await axios
            .post(`${Config.hostName}/billing/invoiceStatusDD`, {
            })
            .then(async (res) => {
                await this.setState({
                    invoiceStatusDD: res.data.data
                })
            })
            .catch(async (err) => {
                await this.setState({
                    alertSnackbar: true,
                    style: { display: "none" },
                })
            });
    }
    handleChangeBillingStatus = async (data, status) => {
        await this.setState({
            style: {},
        });
        if (window.confirm("Are you sure you want to update the status?")) {
            // Save it!
            const userData = localStorage.getItem('persist:root');
            const auth = JSON.parse(userData).auth;
            let StatusID = '';
            await this.state.invoiceStatusDD.map((each) => {
                if (each.Status.toLowerCase() == status.toLowerCase()) {
                    StatusID = each.StatusID;
                    return 1;
                }
            })
            const res = await axios
                .post(`${Config.hostName}/billing/updatecontentDashboard`, {
                    invID: this.state.rowDataEagleView[data].invID,
                    jdata: `{"billingmonth":"${this.state.rowDataEagleView[data].billingmonth}","contentID":"1","statusID":"${StatusID}","userID":"${JSON.parse(auth).USERID}","ccxrefID":"${this.state.rowDataEagleView[data].ccxrefid}"}`
                })
                .then(async (res) => {
                    if (res.data.success == true) {
                        console.log(res);
                        alert('Status Updated');
                    } else {
                        alert(res.data.message);
                    }
                     this.getEagelViewStats();
                    await this.handleOpenEagleViewModal(this.state.clickedStatus);
                })
                .catch(async (err) => {
                    await this.setState({
                        alertSnackbar: true,
                    })
                    alert('Error');
                });
        } else {
            // Do nothing!
            console.log('cancel');
        }
        await this.setState({
            style: { display: "none" },
        })
    }
    handleOpenEagleViewModal = async (status) => {
        await this.setState({
            style: {},
            clickedStatus: status
        });
        await this.getStatusEagleView();
        let billingMonth = '';
        await this.state.viewInvoiceBilling.map((e) => {
            billingMonth = billingMonth + ',' + e.replace(' ', '');
        });
        const res = await axios
            .post(`${Config.hostName}/bs/dashboard/eagleView`, {
                billingMonth: billingMonth.substr(1, billingMonth.length - 1),
                status: status,
            })
            .then(async (res) => {
                console.log(res.data.data);
                if (res.data.data.length > 0) {
                    let grandTotal = 0;
                    await res.data.data.map(async (e, key) => {
                        grandTotal += e.grandTotal;
                    })
                    await this.setState({
                        rowDataEagleView: res.data.data,
                        invoiceDetailsTotalEagle: grandTotal,
                        openEagleViewModal: true,
                    })
                } else {
                    await this.setState({
                        errorState: true,
                        errorText: 'No records found'
                    })
                }
                await this.setState({
                    style: { display: "none" },
                });
            })
            .catch(async (err) => {
                await this.setState({
                    errorState: true,
                    errorText: 'No records found',
                    style: { display: "none" },
                })
            });
    }
    handleCloseEagleViewModal = async (status) => {
        this.setState({
            openEagleViewModal: false,
        })
    }
    handleOpenFullModal = async (data, e) => {
        // await this.getStates();
        // await this.getClientNames();
        if (data == 'add') {
            await this.setState({
                billFromDetails: {},
                billToDetails: {},
                vendorCode: '',
                itemDetails: [{ Description: '', SAC: '', grandTotal: 0, sGST: '', sGSTAmount: 0, cGST: '', cGSTAmount: 0, iGST: '', iGSTAmount: 0 }],
                attachements: [{ Description: '', DocLink: '' }],
                grandTotal: '',
                placeofService: '',
                po: '',
                totalAmount: '',
                totalGST: '',
                stateFrom: '',
                stateTo: '',
                bankDetailsDD: '',
                clientNameProject: '',
                projectName: [],
                unique_project: [],
                updateInvID: '',
                submitButton: 'Generate Invoice',
                headingGenerate: "Generate Invoice",
                clientSpocName: '',
                costCenter: '',
                Invoiceno: '',
                updateInvID: '',
                transportationText: "",
                showTransportationText: false,
            });
        } else {
            await this.setState({
                style: {
                    display: "none"
                },
            });
            // console.log(this.state.rowData2[data]);
            var data = this.state.rowData2[data];
            var parseData = JSON.parse(data.jdata);
            await this.setState({
                clientNameTo: parseData.cClientName,
                stateTo: parseData.cState,
                projectName: parseData.Projects.split(','),
                billFromDetails: { clientID: data.fromClientID, billingName: data.lName, address: data.laddress, GST: data.lGST, PAN: data.lpan },
                billToDetails: { clientID: data.toClientID, billingName: data.clientName, address: data.cAddress, GST: data.cGST, PAN: data.cpan },
            })

            if (parseData.cClientName == 'Loram') {
                this.setState({
                    showTransportationText: true,
                    transportationText: parseData.transportationText
                })
            }
            // console.log(this.state.billFromDetails, 'rohan');
            await this.setState({
                vendorCode: data.vendorCode,
                itemDetails: JSON.parse(data.itemDetails),
                attachements: JSON.parse(data.attachments),
                grandTotal: data.grandTotal,
                placeofService: data.placeofService,
                po: data.po,
                totalAmount: data.totalAmount,
                totalGST: data.totalGST,
                newEndDate: parseData.billingEndDate.slice(0, 10),
                newStartDate: parseData.billingStartDate.slice(0, 10),
                bankDetails: { beneficiaryName: data.beneficiaryName, vendorName: data.VName, acctno: data.acctno, IFSC: data.IFSC, bankName: data.bankName },
                reqName: parseData.requestorName,
                reqEmail: parseData.requestorEmail,
                invoiceDate: parseData.invoiceDate,
                billingMonth: parseData.billingMonthNew,
                stateFrom: parseData.lState,
                bankDetailsDD: parseData.lBankID,
                clientNameProject: parseData.selectedClientName,
                updateInvID: data.invID,
                invoiceName: parseData.invoiceName,
                headingGenerate: "Update Invoice",
                submitButton: "Update Invoice",
                clientSpocName: data.clientSpocName,
                costCenter: data.costCenter,
                Invoiceno: data.Invoiceno
            });
            const ee = {}
            await this.handleChangeClientProject(ee, "");
            await this.getSac();
            const res = await axios.post(
                `${Config.hostName}/billing/getStateDDbyCLient`,
                {
                    clientName: this.state.clientNameTo
                }
            )
                .then(async (res) => {
                    if (res.data.data.length > 0) {
                        await this.setState({
                            unique_statesTo: res.data.data
                        });
                    } else {
                        // alert('No Recoords Found... !!');
                    }
                })
                .catch((error) => {
                    // alert(error);
                });
        }
        await this.setState({
            openFullModal: true,
            style: {
                display: "none"
            },
        });
    };
    handleCloseFullModal = async (e) => {
        await this.setState({
            openFullModal: false,
        });
    };
    getInvoiceByInvID = async () => {
        const res = await axios.post(
            `${Config.hostName}/billing/getInvoiceDetailByInvID`,
            {
                billingMonth: this.state.invBillingMonth,
                invID: this.state.invID
            }
        )
            .then(async (res) => {
                // console.log(res.data);
                if (res.data.data.length > 0) {
                    await this.setState({
                        openInvMondal: true,
                        rowData2: res.data.data
                    });
                } else {
                    alert('No Recoords Found... !!');
                }
            })
            .catch((error) => {
                alert(error);
            });
    }
    handleOpenInvMondal = async (index, e) => {
        await this.setState({
            invID: this.state.rowData[index].invID,
            invBillingMonth: this.state.rowData[index].BillingMonth,
        })
        const res = await axios.post(
            `${Config.hostName}/billing/getInvoiceDetailByInvID`,
            {
                billingMonth: this.state.invBillingMonth,
                invID: this.state.invID
            }
        )
            .then(async (res) => {
                // console.log(res.data);
                if (res.data.data.length > 0) {
                    await this.setState({
                        openInvMondal: true,
                        rowData2: res.data.data
                    });
                } else {
                    alert('No Recoords Found... !!');
                }
            })
            .catch((error) => {
                alert(error);
            });
    };
    handleCloseInvMondal = async (e) => {
        await this.setState({
            openInvMondal: false,
        });
    };
    addNewItem = async (e) => {
        if (this.state.itemDetails.length < 3) {
            await this.setState({
                itemDetails: [...this.state.itemDetails, { Description: '', SAC: '', grandTotal: '0', sGST: '', sGSTAmount: '0', cGST: '', cGSTAmount: '0', iGST: '', iGSTAmount: '0' }]
            })
            // console.log(this.state.itemDetails);
        }
    }
    removeNewItem = async (index, e) => {
        if (index != 0) {
            const list = this.state.itemDetails;
            list.splice(index, 1);
            await this.setState({
                itemDetails: list,
            })
        }
    }
    handleChangeItemDetails = async (index, e) => {
        const name = e.target.name;
        const list = this.state.itemDetails;
        list[index][name] = e.target.value;
        if (name == "SAC" && e.target.value != "") {
            await this.state.unique_sac.map((eachItem, key) => {
                if (eachItem.SAC == e.target.value) {
                    list[index]["sGST"] = eachItem.sGST;
                    list[index]["iGST"] = eachItem.iGST;
                    list[index]["cGST"] = eachItem.cGST;
                }
            });
        }
        let totalAmount = 0;
        let sGSTAmount = 0;
        let iGSTAmount = 0;
        let cGSTAmount = 0;
        let totalGST = 0;
        await this.state.itemDetails.map(async (eachItem, key) => {
            totalAmount = Number(totalAmount) + Number(eachItem.grandTotal);
            if (index == key) {
                sGSTAmount = (Number(eachItem.grandTotal) * Number(eachItem.sGST)) / 100;
                cGSTAmount = (Number(eachItem.grandTotal) * Number(eachItem.cGST)) / 100;
                iGSTAmount = (Number(eachItem.grandTotal) * Number(eachItem.iGST)) / 100;
                list[index]["sGSTAmount"] = sGSTAmount.toFixed(2);
                list[index]["cGSTAmount"] = cGSTAmount.toFixed(2);
                list[index]["iGSTAmount"] = iGSTAmount.toFixed(2);
                await this.setState({
                    itemDetails: list
                })
            }
            totalGST = Number(totalGST) + Number(eachItem.sGSTAmount) + Number(eachItem.cGSTAmount) + Number(eachItem.iGSTAmount);
        })
        const GrandTot = totalAmount + totalGST;
        // console.log(totalGST);
        await this.setState({
            totalAmount: Number(totalAmount).toFixed(2),
            totalGST: Number(totalGST).toFixed(2),
            grandTotal: Number(GrandTot).toFixed(2)
        })
        // list[index]["sGSTAmount"] = sGSTAmount;
        // list[index]["cGSTAmount"] = cGSTAmount;
        // list[index]["iGSTAmount"] = iGSTAmount;
        // await this.setState({
        //     itemDetails: list
        // })
    }
    addNewAttachment = async (e) => {
        if (this.state.attachements.length < 3) {
            await this.setState({
                attachements: [...this.state.attachements, { Description: '', DocLink: '' }]
            })
        }
    }
    handleOpenModal = async (link, type) => {
        if (type == 'image') {
            await this.setState({
                modalData: link,
                viewImageModal: true
            })
        } else if (type == 'pdf') {
            await this.setState({
                modalData: link,
                viewPdfModal: true
            })
        }
    }
    handleCloseModal = async () => {
        this.setState({
            viewImageModal: false,
            viewPdfModal: false,
        })
    }
    removeNewAttachment = async (index, e) => {
        if (index != 0) {
            const list = this.state.attachements;
            list.splice(index, 1);
            await this.setState({
                attachements: list,
            })
        } else {
            const list = this.state.attachements;
            list[index]['Description'] = '';
            list[index]['DocLink'] = '';
            await this.setState({
                attachements: list,
            })
        }
    }
    uploadFileAndGetUrl = async (formData) => {
        // console.log("uploading formdata")
        const fileData = await axios({
            method: "POST",
            url: `${Config.hostName}/unique/upload/any-file-name`,
            timeout: 3 * 60 * 1000,
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*"
            },
            data: formData,
        });
        return fileData;
    }
    billLinkUploadFile = async (file, name) => {
        const reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = async (e) => {
            await this.setState({
                selectedFile: [reader.result],
            });
        }
        // console.log(url); // Would see a path?
        const fileType = file.type.substring(0, 5);
        await this.setState({ selectedFile: file, selectedFileType: fileType });
        const formData = new FormData();
        formData.append(
            "media",
            this.state.selectedFile,
            `Inv_${this.state.billingMonth.replaceAll(' ', '')}_Supporting_${name}.${this.state.selectedFile.name.split('.').pop().toLowerCase()}`
        );
        // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
        // console.log(formData, "formData", fileType);
        const res = await this.uploadFileAndGetUrl(formData);
        const urlFromAws = res.data.url;
        return urlFromAws;
        // const urlFromAws = "https://logicarts.s3.ap-south-1.amazonaws.com/clientlogos/capp_truck.png";
        // await this.getPanCardUrl(urlFromAws, file.name);
        // console.log(this.state.attachments, "data")
    }
    handleChangeAttachment = async (index, e) => {
        const list = this.state.attachements;
        // console.log(list);
        if (e.target.name == 'Description') {
            if (list[index]['DocLink'] != '') {
                alert('First Delete the Uploaded Attachment!!');
            } else {
                list[index][e.target.name] = e.target.value;
            }
        } else {
            if (list[index]['Description'] != '') {
                const filesObj = e.target.files;
                const filesObjKeys = Object.keys(filesObj);
                const res = await this.billLinkUploadFile(filesObj[0], list[index]['Description']);
                list[index]['DocLink'] = res;
            } else {
                alert("Enter Document Name First");
            }
        }
        await this.setState({
            attachements: list,
        })
    }
    handleBulkStatusUpdate = async (e) => {
        const filesObj = e.target.files;
        const filesObjKeys = Object.keys(filesObj);
        const url = await this.billLinkUploadFile(filesObj[0], 'bulk_update');
        console.log(url);
        await this.setState({
            style: {}
        });
        const res = await axios.post(
            `${Config.hostName}/bs/dashboard/bulkStatusUpdate`,
            {
                url: url,
                userID: this.props.auth.user.USERID
            }
        )
            .then(async (res) => {
                console.log(res.data);
                await this.setState({
                    style: {
                        display: "none"
                    },
                    successText: 'Data Loaded',
                    alertState: true,
                });
                await this.getLastUpdatedStatus();
                await this.handleChangeViewInvoice("", { target: { value: this.state.viewInvoiceBilling } });
            })
            .catch((error) => {
                alert(error);
                this.setState({
                    style: {
                        display: "none"
                    }
                });
            });
    }
    handleCloseInvoiceModal = async () => {
        await this.setState({
            invoiceModal: false,
        })
    }
    onSubmitModal = async (e) => {
        e.preventDefault();

        if (window.confirm('Are you sure you want to generate invoice?')) {
            await this.setState({
                submitButton: 'Please wait...',
            });
            let ccxrefID = [];
            await this.state.projectName.map(async (name, key) => {
                console.log(name);
                await this.state.unique_project.map(async (project, i) => {
                    if (name == project.projectName) {
                        ccxrefID.push(project.ccxrefID);
                    }
                })
            })
            console.log(this.state.newStartDate);

            var dateParts = this.state.newStartDate.split("-");
            var formattedDate = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
            console.log(formattedDate);


            var dateParts2 = this.state.newEndDate.split("-");
            var formattedDate2 = dateParts2[2] + "-" + dateParts2[1] + "-" + dateParts2[0];


            var text = `Transportation of Goods for the period ${formattedDate} to ${formattedDate2} as per the data sheet attached.`;
            if (this.state.clientNameTo == 'Loram') {
                text = this.state.transportationText;
            }
            const res = await axios.post(
                `${Config.hostName}/billing/generateInvoice`,
                {
                    FromClientID: this.state.billFromDetails.clientID,
                    toClientID: this.state.billToDetails.clientID,
                    vendorCode: this.state.vendorCode,
                    itemDetails: this.state.itemDetails,
                    grandTotal: this.state.grandTotal,
                    placeofService: this.state.placeofService,
                    attachements: this.state.attachements,
                    jdata: `{"transportationText": "${text}","Invoiceno":"${this.state.Invoiceno}", "costCenter":"${this.state.costCenter}","clientSpocName":"${this.state.clientSpocName}","lBankID":"${this.state.bankDetailsDD}","cClientName":"${this.state.clientNameTo}","cState":"${this.state.stateTo}","lState":"${this.state.stateFrom}","selectedClientName":"${this.state.clientNameProject}", "Projects":"${this.state.projectName}","invID": "${this.state.updateInvID}", "invoiceName": "${this.state.invoiceName}","invoiceDate": "${this.state.invoiceDate}","userID": "${this.props.auth.user.USERID}","billingMonthNew":"${this.state.billingMonth}","billingMonth":"${this.state.billingMonth.replaceAll(' ', '')}","ccxrefID":"${ccxrefID.toString()}","billingStartDate":"${this.state.newStartDate}","billingEndDate":"${this.state.newEndDate}","po":"${this.state.po}", "totalAmount": "${this.state.totalAmount}", "totalGST":"${this.state.totalGST}","requestorName":"${this.state.reqName}","requestorEmail":"${this.state.reqEmail}"}`,
                }
            )
                .then(async (res) => {
                    console.log(res);
                    if (res.data.success != false) {
                        await this.setState({
                            submitButton: 'Generate Invoice',
                            invoiceUrl: res.data.data.url,
                            invoiceModal: true
                        });
                        console.log(this.state.invoiceUrl);
                    } else {
                        if (this.state.clientID == "") {
                            await this.setState({
                                submitButton: 'Generate Invoice'
                            });
                        } else {
                            await this.setState({
                                submitButton: 'Generate Invoice'
                            });
                        }
                    }
                })
                .catch((error) => {
                    alert(error);
                });
        } else {

        }


    };
    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    render() {
        const { vertical, horizontal } = this.state;
        return (
            <div>
                <Container pt={3} style={{ maxWidth: 'inherit', background: 'white', paddingBottom: "12%", marginTop: '7%' }} >
                    <div id='cover-spin' style={this.state.style}></div>
                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={this.state.snackBar}
                        autoHideDuration={2000}
                        onClose={this.handleCloseSnackBar}
                        key={vertical + horizontal}
                    >
                        <Alert onClose={this.handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                            Comment Added Succesfully!
                        </Alert>
                    </Snackbar>
                    <Grid pt={3} container>
                        <Grid item md={12} >
                            <Collapse in={this.state.alertState}>
                                <Alert onClose={() => {
                                    this.setState({
                                        successText: '',
                                        alertState: false,
                                    })
                                }} severity="success">
                                    <AlertTitle>Success</AlertTitle>
                                    {this.state.successText}
                                </Alert>
                            </Collapse>
                        </Grid>
                        <Grid item md={12} className="mb-3" >
                            <Collapse in={this.state.errorState}>
                                <Alert onClose={() => {
                                    this.setState({
                                        errorText: '',
                                        alertState: false,
                                    })
                                }} severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {this.state.errorText}
                                </Alert>
                            </Collapse>
                        </Grid>
                        <Grid style={{ float: 'right', textAlign: 'right' }} item md={12}>
                            <Typography style={{ float: 'right', fontSize: '17px' }}>
                                Invoice status updated on:<span style={{ color: 'red' }}>{this.state.lastUpdatedStatus}</span>
                            </Typography>
                        </Grid>

                        <Grid style={{ float: 'left', textAlign: 'left' }} item md={7}>
                            <FormControl variant="standard" sx={{ width: '30ch' }} style={{ marginLeft: '0px' }}>
                                <InputLabel id="demo-simple-select-standard2">Billing Month </InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard2"
                                    value={this.state.viewInvoiceBilling}

                                    onChange={(e) => { this.handleChangeBillingMonth(e) }}
                                    variant="standard"
                                    renderValue={(selected) => selected.join(',')}
                                    multiple
                                    MenuProps={MenuProps2}
                                >
                                    {this.state.uniqueDateRanges.map((name, key) => (
                                        <MenuItem key={key} value={name.billingMonth}>
                                            <Checkbox checked={this.state.viewInvoiceBilling.indexOf(name.billingMonth) > -1} />
                                            <ListItemText primary={name.billingMonth} />
                                        </MenuItem>
                                    ))}
                                    {/* {this.state.uniqueDateRanges.map((eachItem, key) => (
                                        <MenuItem value={eachItem.billingMonth} key={key} >{eachItem.billingMonth}</MenuItem>
                                    ))} */}
                                </Select>
                            </FormControl>
                            <FormControl component="fieldset">
                                {/* <FormLabel component="legend">Label placement</FormLabel> */}
                                <FormGroup style={{ marginTop: '13px' }} aria-label="position" row>
                                    <FormControlLabel
                                        value="start"
                                        control={<Switch checked={this.state.isManager ? true : false} color="primary" />}
                                        label="Manager View"
                                        labelPlacement="start"
                                        onClick={(e) => { this.handleChangeViewInvoice("m", '') }}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl component="fieldset">

                                <Tooltip title="Apply Filter">
                                    <Button
                                        onClick={(e) => this.handleChangeViewInvoice('', { target: { value: this.state.viewInvoiceBilling } })}
                                        size="large" variant="contained" style={{ marginLeft: '17px', marginTop: "14px", background: "rgb(234 67 68)", color: "white" }}>
                                        <SearchIcon /> Search
                                    </Button>
                                </Tooltip>

                            </FormControl>
                        </Grid>
                        <Grid style={{ float: 'right', textAlign: 'right' }} item md={2}>
                        </Grid>
                        <Grid style={{ float: 'right', textAlign: 'right' }} item md={1}>
                            <Tooltip title="Download Template File">
                                <Button size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }}>
                                    <a style={{ color: 'white' }} href="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1682013306244_Template.xlsx" target="_blank"><DownloadIcon /></a>
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid style={{ float: 'right', textAlign: 'right' }} item md={1}>
                            <Tooltip title="Bulk Status Update">
                                {/* <Button   size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }}>
                            <Input accept="image/*" id="contained-button-file"  type="file" />
                                    <BackupIcon />
                                </Button> */}
                                <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    component="label"
                                    onChange={(e) => { this.handleBulkStatusUpdate(e) }}
                                    style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }}
                                    className={this.state.color}
                                >
                                    <BackupIcon />
                                    <input
                                        type="file"
                                        hidden
                                        accept=".xls,.xlsx"
                                    />
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid style={{ float: 'right', textAlign: 'right' }} item md={1}>
                            <Tooltip title="Generate Invoice">
                                <Button onClick={(e) => this.handleOpenFullModal('add', e)} size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }}>
                                    <PlusIcon />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    {/* <Grid m={4} item md={12}>
                    </Grid>
                    <Grid item md={6}>
                        <Paper elevation={8}>
                            <Chart
                                data={this.state.chartData}
                                textAnchor="middle"
                            >
                                <ArgumentAxis />
                                <ValueAxis />
                                <BarSeries
                                    name="Total Billingcnt"
                                    valueField="TotalBillingcnt"
                                    argumentField="billingmonth"
                                    color="#ffa500"
                                    pointComponent={this.BarWithLabel}
                                />
                                <BarSeries
                                    name="Billed Cnt"
                                    valueField="BilledCnt"
                                    argumentField="billingmonth"
                                    color="#6db6ff"
                                    pointComponent={this.BarWithLabel2}
                                />
                                <Animation />
                                <Legend position="bottom" style={{ display: 'flex' }} />
                                <Title text="Invoice Stats" rootComponent={Root} labelComponent={Label} />
                                <Stack />
                            </Chart>
                        </Paper>
                    </Grid> */}
                    <Grid mt={3}>
                        {this.state.eagleViewStats != [] &&
                            <Grid item md={12} className="mt-2">
                                <Typography variant="h6" color="inherit" component="div" style={{ textAlign: 'center', fontSize: '29px' }}>
                                    {this.state.eagleViewStatsTotal != 0 && <>
                                        Grand Total :- <b style={{ color: 'red' }}> ₹ {new Intl.NumberFormat("en-IN").format(this.state.eagleViewStatsTotal)}</b>
                                    </>
                                    }
                                </Typography>
                            </Grid>
                        }
                        <Grid item md={10} className="mt-2">
                            <Grid pt={1} container spacing={1}>
                                {/* here */}
                                {this.state.eagleViewStats != [] && this.state.eagleViewStats.map((eachItem, key) => (
                                    <Grid item md={3} className="mt-2 mb-2 ">
                                        <Paper elevation={6}>
                                            <Card
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                className="hoverBoder"
                                                onClick={(e) => { this.handleOpenEagleViewModal(eachItem.status) }}
                                            >
                                                <CardContent
                                                    style={{
                                                        textAlign: "center",
                                                        maxHeight: "135px"
                                                    }}
                                                >
                                                    <Typography
                                                        mb={2}
                                                        style={{
                                                            fontSize: "12px",
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {eachItem.status}
                                                    </Typography>
                                                    <Grid container spacing={2} mb={2}>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                style={{
                                                                    fontWeight: 600,
                                                                    color: "#03039e9e",
                                                                    fontSize: "14px",
                                                                }}
                                                            >
                                                                {eachItem.ProjectCnt}
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    fontSize: "12px",
                                                                    fontWeight: 300,
                                                                }}
                                                            >
                                                                Project Count
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                style={{
                                                                    fontWeight: 600,
                                                                    color: "#ff4700c9",
                                                                    fontSize: "14px",
                                                                }}
                                                            >
                                                                {" "}
                                                                ₹ {new Intl.NumberFormat("en-IN").format(eachItem.grandTotal)}
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    fontSize: "12px",
                                                                    fontWeight: 300,
                                                                }}
                                                            >
                                                                Grand Total
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item md={8} className="mt-4">
                            <MUIDataTable
                                title={""}
                                data={this.state.rowData}
                                columns={this.state.columns}
                                options={this.state.options2}
                            />
                        </Grid>
                    </Grid>
                    <Modal
                        open={this.state.openPaymentHistoryModal}
                        onClose={this.handleClosePaymentHistoryModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style5}>
                            <div className="row" style={{ marginBottom: "18px" }}>
                                <div className="col-sm-12">
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Payment History : {this.state.invoiceno}
                                    </Typography>
                                </div>
                                <div className="col-sm-12 mt-3" style={{ maxHeight: '420px', minHeight: '300px' }}>
                                    <div style={{ height: '100%' }} className="table-responsive" >
                                        <table className="tableInvoice table">
                                            <tr>
                                                <th style={{ textAlign: 'center' }} >
                                                    Received Amount
                                                </th>
                                                <th style={{ textAlign: 'center' }} >
                                                    Received Date
                                                </th>
                                                <th style={{ textAlign: 'center' }} >
                                                    TDS
                                                </th>
                                                <th style={{ textAlign: 'center' }} >
                                                    Updated By
                                                </th>
                                            </tr>
                                            {this.state.paymentHistoryData != null &&
                                                this.state.paymentHistoryData.map((e, key) => (
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }} >
                                                            ₹ {e.ReceivedAmount != '' ? e.ReceivedAmount : 0}
                                                        </td>
                                                        <td style={{ textAlign: 'center' }} >
                                                            {e.ReceivedDate}
                                                        </td>
                                                        <td style={{ textAlign: 'center' }} >
                                                            ₹ {e.TDS != '' ? e.TDS : 0}
                                                        </td>
                                                        <td style={{ textAlign: 'center' }} >
                                                            {e.updatedBY}
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                    <Modal
                        open={this.state.openDownloadFilesModal}
                        onClose={this.handleCloseDownloadFilesModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style4}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Download Attachments
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <Grid container>
                                    {
                                        this.state.misAttachments != null && this.state.misAttachments.map((e, key) => (
                                            <>
                                                {e.DocLink.split('.').pop().toLowerCase() != "pdf" && e.DocLink.split('.').pop().toLowerCase() != "xlsx" &&
                                                    <Tooltip title={e.DocLink.slice(54)} placement="top">
                                                        <Grid item m={1} md={3}>
                                                            <img className="hoverImage" style={{ width: '100%' }} onClick={(event) => { window.open(e.DocLink) }} src={e.DocLink} />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                                {e.DocLink.split('.').pop().toLowerCase() == "pdf" &&
                                                    <Tooltip title={e.DocLink.slice(54)} placement="top">
                                                        <Grid className="margin0" item m={1} md={3}>
                                                            <img onClick={(event) => { window.open(e.DocLink) }} style={{ width: '100%' }} className="bms-view-request-img" src={pdfIcon} alt="No Image" />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                                {e.DocLink.split('.').pop().toLowerCase() == "xlsx" &&
                                                    <Tooltip title={e.DocLink.slice(54)} placement="top">
                                                        <Grid item m={1} className="margin0" md={3}>
                                                            <img className="bms-view-request-img" onClick={(event) => { window.open(e.DocLink) }} src={excelIcon} alt="No Image" key={key} />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                            </>
                                        ))
                                    }
                                    {
                                        this.state.downloadFiles.map((e, key) => (
                                            <>
                                                {e.split('.').pop().toLowerCase() != "pdf" && e.split('.').pop().toLowerCase() != "xlsx" &&
                                                    <Tooltip title={e.slice(54)} placement="top">
                                                        <Grid item m={1} md={3}>
                                                            <img className="hoverImage" style={{ width: '100%' }} onClick={(event) => { window.open(e) }} src={e} />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                                {e.split('.').pop().toLowerCase() == "pdf" &&
                                                    <Tooltip title={e.slice(58)} placement="top">
                                                        <Grid className="margin0" item m={1} md={3}>
                                                            <img onClick={(event) => { window.open(e) }} style={{ width: '100%' }} className="bms-view-request-img" src={pdfIcon} alt="No Image" />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                                {e.split('.').pop().toLowerCase() == "xlsx" &&
                                                    <Tooltip title={e.slice(54)} placement="top">
                                                        <Grid item m={1} className="margin0" md={3}>
                                                            <img className="bms-view-request-img" onClick={(event) => { window.open(e) }} src={excelIcon} alt="No Image" key={key} />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                            </>
                                        ))
                                    }
                                    {
                                        this.state.invAttachments.map((e, key) => (
                                            <>
                                                {e.DocLink.split('.').pop().toLowerCase() != "pdf" && e.DocLink.split('.').pop().toLowerCase() != "xlsx" &&
                                                    <Tooltip title={e.DocLink.slice(54)} placement="top">
                                                        <Grid item m={1} md={3}>
                                                            <img className="hoverImage" style={{ width: '100%' }} onClick={(event) => { window.open(e.DocLink) }} src={e.DocLink} />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                                {e.DocLink.split('.').pop().toLowerCase() == "pdf" &&
                                                    <Tooltip title={e.DocLink.slice(54)} placement="top">
                                                        <Grid className="margin0" item m={1} md={3}>
                                                            <img onClick={(event) => { window.open(e.DocLink) }} style={{ width: '100%' }} className="bms-view-request-img" src={pdfIcon} alt="No Image" />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                                {e.DocLink.split('.').pop().toLowerCase() == "xlsx" &&
                                                    <Tooltip title={e.DocLink.slice(54)} placement="top">
                                                        <Grid item m={1} className="margin0" md={3}>
                                                            <img className="bms-view-request-img" onClick={(event) => { window.open(e.DocLink) }} src={excelIcon} alt="No Image" key={key} />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                            </>
                                        ))
                                    }
                                </Grid>
                            </Typography>
                        </Box>
                    </Modal>
                    <Modal
                        open={this.state.statusModal}
                        onClose={this.handleCloseStatuslModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style4}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Update Status
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <Grid container>
                                    <Grid item md={12} >
                                        <FormControl variant="standard" sx={{ width: '30ch' }} style={{ marginLeft: '0px' }}>
                                            <InputLabel id="statusUpdate">Status</InputLabel>
                                            <Select
                                                labelId="statusUpdate"
                                                id="statusUpdate3"
                                                value={this.state.StatusID}
                                                onChange={(e) => {
                                                    this.setState({
                                                        StatusID: e.target.value
                                                    })
                                                }}
                                                label="Update Status"
                                                variant="standard"
                                            >
                                                <MenuItem value="">
                                                    <em>Select Status</em>
                                                </MenuItem>
                                                {this.state.unique_statusDD.map((eachItem, key) => (
                                                    <MenuItem value={eachItem.StatusID} key={key} >{eachItem.Status}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid style={{ textAlign: 'right' }} item md={12} >
                                        <Button onClick={(e) => this.handleSubmitUpdate(e)} size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }} >
                                            Update
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Typography>
                        </Box>
                    </Modal>
                    <Dialog fullScreen
                        TransitionComponent={this.Transition}
                        onClose={this.handleCloseInvMondal} open={this.state.openInvMondal}
                    >
                        <Container maxWidth="xl" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                            <AppBar style={{ height: 'auto', backgroundColor: 'white', color: 'black' }} sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={this.handleCloseInvMondal}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <Button autoFocus color="inherit" onClick={this.handleCloseInvMondal}>
                                        Close
                                    </Button>
                                </Toolbar>
                            </AppBar>
                            <div style={{ margin: "4%" }}>
                                <MUIDataTable
                                    title={"Invoice Details"}
                                    data={this.state.rowData2}
                                    columns={this.state.columns2}
                                    options={options}
                                />
                            </div>
                        </Container>
                        <Modal
                            open={this.state.openCommentModal}
                            onClose={this.handleCloseComentModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style5}>
                                <div className="row" style={{ marginBottom: "18px" }}>
                                    <div className="col-sm-12">
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                            Comments
                                        </Typography>
                                    </div>
                                    <div className="col-sm-12 mt-3" style={{ maxHeight: '420px', minHeight: '300px' }}>
                                        <div style={{ height: '89%' }} className="table-responsive" >
                                            <table className="tableInvoice table">
                                                <tr>
                                                    <th style={{ width: '65%' }}>
                                                        Comments
                                                    </th>
                                                    <th>
                                                        Commented By
                                                    </th>
                                                    <th>
                                                        Date
                                                    </th>
                                                </tr>
                                                {this.state.CommentHistory != null &&
                                                    this.state.CommentHistory.map((e, key) => (
                                                        <tr>
                                                            <td>
                                                                {JSON.parse(e).remarks}
                                                            </td>
                                                            <td>
                                                                {JSON.parse(e).updatedBy}
                                                            </td>
                                                            <td>
                                                                {JSON.parse(e).updateddate}
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </table>
                                        </div>
                                    </div>
                                    <form style={{ width: "100%" }} onSubmit={this.handleSubmitComment} method="POST">
                                        <div className="col-sm-12">
                                            <TextField sx={{ width: '100%' }}
                                                id="standard-multiline-static"
                                                label="Add Comment"
                                                rows={3}
                                                multiline
                                                value={this.state.comment}
                                                variant="outlined"
                                                onChange={(e) => {
                                                    this.changeComments(e)
                                                }}
                                                required
                                            />
                                            <h6 style={{ color: this.state.commentColor, }}> <b> **No Special character like $%&@ and double space is not allowed </b></h6>
                                        </div>
                                        <div className="col-sm-12" style={{ textAlign: 'right' }}>
                                            <Button type='submit' size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }} >
                                                Add
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </Box>
                        </Modal>
                    </Dialog>
                </Container>
                <Dialog fullScreen
                    TransitionComponent={this.Transition}
                    onClose={this.handleCloseEagleViewModal} open={this.state.openEagleViewModal}
                >
                    {/* openFullModal */}
                    <Container maxWidth="xl" style={{ maxWidth: 'inherit', paddingLeft: '0px', paddingRight: '0px' }}>
                        <AppBar style={{ height: 'auto', backgroundColor: 'white', color: 'black' }} sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={this.handleCloseEagleViewModal}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Button autoFocus color="inherit" onClick={this.handleCloseEagleViewModal}>
                                    Close
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <div style={{ margin: "26px" }}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" color="inherit" component="div" style={{ textAlign: 'center' }}>
                                        <b><u> Eagle View for Billing Month</u></b>
                                    </Typography>
                                    <br />
                                    <Grid pt={1} container spacing={1}>
                                        <Grid item md={12} style={{ marginTop: '3vh', marginBottom: '10vh' }}>
                                            <Paper elevation={6}>
                                                <MUIDataTable
                                                    title={`Grand Total - ₹ ${new Intl.NumberFormat("en-IN").format(this.state.invoiceDetailsTotalEagle)}`}
                                                    data={this.state.rowDataEagleView}
                                                    columns={this.state.columnsInvDetailsEagle}
                                                    options={options}
                                                />
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </div>
                        <Modal
                            open={this.state.openDownloadFilesModal2}
                            onClose={this.handleCloseAttachmentsModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style4}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Download Attachments
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    <Grid container>
                                        {
                                            this.state.downloadFiles2.map((e, key) => (
                                                <>
                                                    {e.DocLink.split('.').pop().toLowerCase() != "pdf" && e.DocLink.split('.').pop().toLowerCase() != "xlsx" &&
                                                        <Tooltip title={e.DocLink.slice(54)} placement="top">
                                                            <Grid item m={1} md={3}>
                                                                <img className="hoverImage" style={{ width: '100%' }} onClick={(event) => { window.open(e.DocLink) }} src={e.DocLink} />
                                                            </Grid>
                                                        </Tooltip>
                                                    }
                                                    {e.DocLink.split('.').pop().toLowerCase() == "pdf" &&
                                                        <Tooltip title={e.DocLink.slice(54)} placement="top">
                                                            <Grid className="margin0" item m={1} md={3}>
                                                                <img onClick={(event) => { window.open(e.DocLink) }} style={{ width: '100%' }} className="bms-view-request-img" src={pdfIcon} alt="No Image" />
                                                            </Grid>
                                                        </Tooltip>
                                                    }
                                                    {e.DocLink.split('.').pop().toLowerCase() == "xlsx" &&
                                                        <Tooltip title={e.DocLink.slice(54)} placement="top">
                                                            <Grid item m={1} className="margin0" md={3}>
                                                                <img className="bms-view-request-img" onClick={(event) => { window.open(e.DocLink) }} src={excelIcon} alt="No Image" key={key} />
                                                            </Grid>
                                                        </Tooltip>
                                                    }
                                                </>
                                            ))
                                        }
                                    </Grid>
                                </Typography>
                            </Box>
                        </Modal>
                        <Modal
                            open={this.state.openInvStatsItemDetails}
                            onClose={this.handleCloseInvStatsItemDetailsModal}
                            closeAfterTransition
                            style={{
                                left: "-72%",
                                width: "250%",
                            }}
                        >
                            <Fade in={this.state.openInvStatsItemDetails}>
                                <Box sx={style2}>
                                    <Card style={{}} className="pan-image">
                                        <CardContent>
                                            <MUIDataTable
                                                title={"Item Details"}
                                                data={this.state.rowItemDetailData}
                                                columns={this.state.columnsInvItemDetails}
                                                options={options}
                                            />
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Fade>
                        </Modal>
                    </Container>
                </Dialog>
                <Dialog fullScreen
                    TransitionComponent={this.Transition}
                    onClose={this.handleCloseFullModal} open={this.state.openFullModal}
                >
                    {/* openFullModal */}
                    <Container maxWidth="xl" style={{ maxWidth: 'inherit', paddingLeft: '0px', paddingRight: '0px' }}>
                        <AppBar style={{ height: 'auto', backgroundColor: 'white', color: 'black' }} sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={this.handleCloseFullModal}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      Sound
                    </Typography> */}
                                <Button autoFocus color="inherit" onClick={this.handleCloseFullModal}>
                                    Close
                                </Button>
                            </Toolbar>
                        </AppBar>
                        {/* <Header order="rohan" /> */}
                        <Grid style={{ background: 'rgb(250 250 250)', }} container>
                            <Grid item md={this.state.headingGenerate == 'Generate Invoice' ? 12 : 9} style={{ padding: '0px 0px' }}>
                                <form onSubmit={this.onSubmitModal} method="POST">
                                    <Container className="contain" pt={3} style={{ maxWidth: 'inherit', paddingBottom: "8%", marginTop: '1%', paddingRight: '15px !important' }} >
                                        <Typography pt={2} style={{ textAlign: 'center', color: '#333333' }} variant="h4"> <b><u> {this.state.heading}</u></b></Typography>
                                        <Paper elevation={6} style={{ padding: '10px' }}>
                                            <Typography pt={2} style={{ marginBottom: '10px' }} variant="h6"><b> {this.state.headingGenerate} :
                                                <FormControl variant="standard" fullWidth={true} sx={{ m: 1, }} style={{ marginTop: '-8px', marginLeft: '50px', width: '20%' }}>
                                                    {/* <InputLabel id="clientProject">Client Name</InputLabel> */}
                                                    <Autocomplete
                                                        value={this.state.clientNameProject}
                                                        onChange={(e, newValue) => { this.handleChangeClientProject(e, newValue) }}
                                                        id="controllable-states-demo"
                                                        options={this.state.unique_clientNamesProject.map((option) => option.ClientName)}
                                                        style={{ width: 240 }}
                                                        required
                                                        renderInput={(params) => <TextField {...params} label="Client Name" variant="standard" />}
                                                    />
                                                    {/* <Select
                                                        labelId="clientProject"
                                                        id="clientProject2"
                                                        value={this.state.clientNameProject}
                                                        onChange={(e) => { this.handleChangeClientProject(e) }}
                                                        label="clientProject"
                                                        required
                                                    >
                                                        <MenuItem value="0">
                                                            <em>Select Client</em>
                                                        </MenuItem>
                                                        {this.state.unique_clientNamesProject.map((eachItem, key) => (
                                                            <MenuItem value={eachItem.ClientName} key={key} >{eachItem.ClientName}</MenuItem>
                                                        ))}
                                                    </Select> */}
                                                </FormControl>
                                                <FormControl variant="standard" sx={{ m: 1, minWidth: 165 }} style={{ marginTop: '-8px', marginLeft: '50px', width: '30%' }}>
                                                    {/* <InputLabel id="clientProject">Projects</InputLabel> */}
                                                    <Autocomplete
                                                        multiple
                                                        id="checkboxes-tags-demo"
                                                        value={this.state.projectName}
                                                        options={this.state.unique_project.map((option) => option.projectName)}
                                                        disableCloseOnSelect
                                                        onChange={(e, newValue) => { this.handleProjectChange(e, newValue) }}
                                                        renderOption={(props, options, { selected }) => (
                                                            <li {...props}>
                                                                <Checkbox
                                                                    icon={icon}
                                                                    checkedIcon={checkedIcon}
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                                {options}
                                                            </li>
                                                        )}
                                                        required
                                                        style={{ width: 500 }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Projects" variant="standard" placeholder="Choose Projects..." />
                                                        )}
                                                    />
                                                    {/* <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        multiple
                                                        value={this.state.projectName}
                                                        onChange={(e) => { this.handleProjectChange(e) }}
                                                        // input={<OutlinedInput label="Tag" />}
                                                        renderValue={(selected) => selected.join(', ')}
                                                        MenuProps={MenuProps}
                                                        label="Project"
                                                        required
                                                    >
                                                        {this.state.unique_project.map((name, key) => (
                                                            <MenuItem key={key} value={name.projectName}>
                                                                <Checkbox checked={this.state.projectName.indexOf(name.projectName) > -1} />
                                                                <ListItemText primary={name.projectName} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select> */}
                                                </FormControl>
                                            </b>
                                            </Typography>
                                        </Paper>
                                        <Grid pt={1} container>
                                            <Grid item md={6} style={{ marginTop: "10px" }} >
                                                <Paper elevation={8} style={{ padding: '15px', height: '100%' }}>
                                                    <Grid pt={1} container>
                                                        <Grid item md={12} >
                                                            <b style={{ fontSize: '15px', margin: '5px 9px' }}> BILL FROM :</b>
                                                        </Grid>
                                                        <Grid item md={5} m={2} >
                                                            <FormControl variant="standard" fullWidth={true} sx={{ m: 1, }} style={{ marginLeft: '0px' }}>
                                                                <InputLabel id="State"></InputLabel>
                                                                <Select
                                                                    labelId="State"
                                                                    id="State2"
                                                                    value='logicarts'
                                                                    // onChange={(e) => {
                                                                    //     this.setState({
                                                                    //         stateValue: e.target.value
                                                                    //     })
                                                                    // }}
                                                                    label="State"
                                                                    required
                                                                >
                                                                    <MenuItem value="logicarts">
                                                                        Logicarts
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item md={5} m={2} >
                                                            <FormControl variant="standard" fullWidth={true} sx={{ m: 1, }} style={{ marginLeft: '0px' }}>
                                                                <InputLabel id="State">State</InputLabel>
                                                                <Select
                                                                    labelId="State"
                                                                    id="State2"
                                                                    value={this.state.stateFrom}
                                                                    onChange={(e) => { this.handleFromStateChange('logicarts', 1, e) }}
                                                                    label="State"
                                                                    required
                                                                >
                                                                    <MenuItem value="0">
                                                                        <em>Select State</em>
                                                                    </MenuItem>
                                                                    {this.state.unique_statesFrom.map((eachItem, key) => (
                                                                        <MenuItem value={eachItem.State} key={key} >{eachItem.State}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item md={12} >
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Bussiness Name :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} style={{ fontFamily: 'sans-serif' }} >
                                                            {this.state.billFromDetails.billingName ? this.state.billFromDetails.billingName : 'N/A'}
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Address :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} style={{ fontFamily: 'sans-serif' }} >
                                                            {this.state.billFromDetails.address ? this.state.billFromDetails.address : 'N/A'}
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> GST :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} style={{ fontFamily: 'sans-serif' }} >
                                                            {this.state.billFromDetails.GST ? this.state.billFromDetails.GST : 'N/A'}
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> PAN :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} style={{ fontFamily: 'sans-serif' }} >
                                                            {this.state.billFromDetails.PAN ? this.state.billFromDetails.PAN : 'N/A'}
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            <Grid item md={6} style={{ maxWidth: "48%", marginLeft: "17px", marginTop: "10px" }} >
                                                <Paper elevation={8} style={{ padding: '15px', height: '100%' }}>
                                                    <Grid pt={1} container>
                                                        <Grid item md={12} >
                                                            <b style={{ fontSize: '15px', margin: '5px 9px' }}> BILL TO :</b>
                                                        </Grid>
                                                        <Grid item md={5} m={2} >
                                                            <FormControl variant="standard" fullWidth={true} sx={{ m: 1, }} style={{ marginLeft: '0px' }}>
                                                                <Autocomplete
                                                                    value={this.state.clientNameTo}
                                                                    onChange={(e, newValue) => { this.handleChangeClientTo(e, newValue) }}
                                                                    autoComplete='off'
                                                                    id="controllable-states-demo"
                                                                    options={this.state.unique_clientNamesTo.map((option) => option.ClientName)}
                                                                    style={{ width: '24ch' }}
                                                                    required
                                                                    renderInput={(params) => <TextField {...params} label="Client Name" variant="standard" />}
                                                                />
                                                                {/* <InputLabel id="clientTo"></InputLabel>
                                                                <Select
                                                                    labelId="clientTo"
                                                                    id="clientTo2"
                                                                    value={this.state.clientNameTo}
                                                                    onChange={(e) => { this.handleChangeClientTo(e) }}
                                                                    label="Client"
                                                                    required
                                                                >
                                                                    <MenuItem value="0">
                                                                        <em>Select Client</em>
                                                                    </MenuItem>
                                                                    {this.state.unique_clientNamesTo.map((eachItem, key) => (
                                                                        <MenuItem value={eachItem.ClientName} key={key} >{eachItem.ClientName}</MenuItem>
                                                                    ))}
                                                                </Select> */}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item md={5} m={2} >
                                                            <FormControl variant="standard" fullWidth={true} sx={{ m: 1, }} style={{ marginLeft: '0px' }}>
                                                                <InputLabel id="State">State</InputLabel>
                                                                <Select
                                                                    labelId="State"
                                                                    id="State2"
                                                                    value={this.state.stateTo}
                                                                    onChange={(e) => { this.handleFromStateChange(this.state.clientNameTo, 2, e) }}
                                                                    label="State"
                                                                    required
                                                                >
                                                                    <MenuItem value="0">
                                                                        <em>Select State</em>
                                                                    </MenuItem>
                                                                    {this.state.unique_statesTo.map((eachItem, key) => (
                                                                        <MenuItem value={eachItem.State} key={key} >{eachItem.State}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item md={12} >
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Bussiness Name :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} style={{ fontFamily: 'sans-serif' }} >
                                                            {this.state.billToDetails.billingName ? this.state.billToDetails.billingName : 'N/A'}
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Address :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} style={{ fontFamily: 'sans-serif' }} >
                                                            {this.state.billToDetails.address ? this.state.billToDetails.address : 'N/A'}
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> GST :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} style={{ fontFamily: 'sans-serif' }} >
                                                            {this.state.billToDetails.GST ? this.state.billToDetails.GST : 'N/A'}
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> PAN :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} style={{ fontFamily: 'sans-serif' }} >
                                                            {this.state.billToDetails.PAN ? this.state.billToDetails.PAN : 'N/A'}
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            <Grid item md={6} style={{ marginTop: "20px" }} >
                                                <Paper elevation={8} style={{ padding: '15px' }}>
                                                    <Grid pt={1} container>
                                                        <Grid item md={12} m={1} style={{ fontSize: '15px', margin: '5px 0px' }} >
                                                            <Typography pt={1} style={{ textAlign: 'center', paddingTop: '0px' }} variant="h6">Invoice Details</Typography>
                                                        </Grid>
                                                        <Grid item m={1} md={12} >
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Requestor Email :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} >
                                                            <TextField sx={{ width: '30ch' }} id="reqEmail" label=""
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        reqEmail: e.target.value
                                                                    })
                                                                }}
                                                                value={this.state.reqEmail}
                                                                variant="standard"
                                                                type='email'
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Requestor Name :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} >
                                                            <TextField sx={{ width: '30ch' }} id="reqName" label=""
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        reqName: e.target.value
                                                                    })
                                                                }}
                                                                value={this.state.reqName}
                                                                variant="standard"
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> PO/ Ref No. :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} >
                                                            <TextField sx={{ width: '30ch' }} id="po" label=""
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        po: e.target.value
                                                                    })
                                                                }}
                                                                value={this.state.po}
                                                                variant="standard"
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Vendor Code :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} >
                                                            <TextField sx={{ width: '30ch' }} id="vendorCode" label=""
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        vendorCode: e.target.value
                                                                    })
                                                                }}
                                                                value={this.state.vendorCode}
                                                                variant="standard"
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Place of Service :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} >
                                                            <TextField sx={{ width: '30ch' }} id="placeofService" label=""
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        placeofService: e.target.value
                                                                    })
                                                                }}
                                                                value={this.state.placeofService}
                                                                variant="standard"
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Invoice Date :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} >
                                                            <TextField sx={{ width: '30ch' }} id="invDate" label=""
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        invoiceDate: e.target.value
                                                                    })
                                                                }}
                                                                value={this.state.invoiceDate}
                                                                variant="standard"
                                                                type="date"
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Billing Month :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={5} >
                                                            <FormControl variant="standard" fullWidth={true} style={{ marginLeft: '0px' }}>
                                                                <Select
                                                                    labelId="Stat3"
                                                                    id="State3"
                                                                    value={this.state.billingMonth}
                                                                    onChange={(e) => this.handleChangeDateRange(e)}
                                                                    label="Billing"
                                                                    variant="standard"
                                                                    required
                                                                >
                                                                    <MenuItem value="0">
                                                                        <em>Billing Month</em>
                                                                    </MenuItem>
                                                                    {this.state.uniqueDateRanges.map((eachItem, key) => (
                                                                        <MenuItem value={eachItem.billingMonth} key={key} >{eachItem.billingMonth}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Billing Start Date :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} >
                                                            <TextField sx={{ width: '30ch' }} id="newStartDate" label=""
                                                                onChange={(e) => {
                                                                    console.log(e.target.value);
                                                                    this.setState({
                                                                        newStartDate: e.target.value
                                                                    })
                                                                }}
                                                                value={this.state.newStartDate}
                                                                variant="standard"
                                                                type="date"
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Billing End Date :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} >
                                                            <TextField sx={{ width: '30ch' }} id="newEndDate" label=""
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        newEndDate: e.target.value
                                                                    })
                                                                }}
                                                                value={this.state.newEndDate}
                                                                variant="standard"
                                                                type="date"
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Invoice Name :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} >
                                                            <TextField sx={{ width: '30ch' }} id="invoiceName" label=""
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        invoiceName: e.target.value
                                                                    })
                                                                }}
                                                                value={this.state.invoiceName}
                                                                variant="standard"
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Cost Center :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} >
                                                            <TextField sx={{ width: '30ch' }} id="costCenter" label=""
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        costCenter: e.target.value
                                                                    })
                                                                }}
                                                                value={this.state.costCenter}
                                                                variant="standard"
                                                            />
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Client SPOC Name :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} >
                                                            <TextField sx={{ width: '30ch' }} id="clientSpocName" label=""
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        clientSpocName: e.target.value
                                                                    })
                                                                }}
                                                                value={this.state.clientSpocName}
                                                                variant="standard"
                                                            />
                                                        </Grid>

                                                        {this.state.showTransportationText == true &&
                                                            <Grid item m={1} md={4} >
                                                                <b style={{ fontSize: '15px', margin: '5px' }}> Transportation Text :</b>
                                                            </Grid>
                                                        }
                                                        {this.state.showTransportationText == true &&
                                                            <Grid item m={1} md={7} >
                                                                <TextField sx={{ width: '30ch' }} id="transportationText" label=""
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            transportationText: e.target.value
                                                                        })
                                                                    }}
                                                                    value={this.state.transportationText}
                                                                    variant="standard"
                                                                />
                                                            </Grid>
                                                        }

                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            <Grid item md={6} style={{ maxWidth: "48%", marginLeft: "17px", marginTop: "20px" }} >
                                                <Paper elevation={8} style={{ padding: '15px', height: '100%' }}>
                                                    <Grid pt={1} container>
                                                        <Grid item md={5}   >
                                                            <b style={{ fontSize: '15px', margin: '5px 9px' }}> Company Bank Details :</b>
                                                        </Grid>
                                                        <Grid item md={5}  >
                                                            <FormControl variant="standard" fullWidth={true} sx={{}} >
                                                                <InputLabel id="bankName">Select Bank Name</InputLabel>
                                                                <Select
                                                                    labelId="bankName"
                                                                    id="bankName2"
                                                                    value={this.state.bankDetailsDD}
                                                                    onChange={(e) => { this.handleChangeBankName(e) }}
                                                                    label="bankDetails"
                                                                    variant="standard"
                                                                    required
                                                                >
                                                                    <MenuItem value="0">
                                                                        <em>Select BankName</em>
                                                                    </MenuItem>
                                                                    {this.state.unique_bankDetailsDD.map((eachItem, key) => (
                                                                        <MenuItem value={eachItem.bankID} key={key} >{eachItem.bankName}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item md={12} style={{ marginTop: "30px" }} m={1} >
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Beneficiary Name :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} style={{ fontFamily: 'sans-serif' }} >
                                                            {this.state.bankDetails.beneficiaryName ? this.state.bankDetails.beneficiaryName : 'N/A'}
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Vendor Name :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} style={{ fontFamily: 'sans-serif' }} >
                                                            {this.state.bankDetails.vendorName ? this.state.bankDetails.vendorName : 'N/A'}
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Account No. :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} style={{ fontFamily: 'sans-serif' }} >
                                                            {this.state.bankDetails.acctno ? this.state.bankDetails.acctno : 'N/A'}
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> IFSC :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} style={{ fontFamily: 'sans-serif' }} >
                                                            {this.state.bankDetails.IFSC ? this.state.bankDetails.IFSC : 'N/A'}
                                                        </Grid>
                                                        <Grid item m={1} md={4} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}> Bank Name :</b>
                                                        </Grid>
                                                        <Grid item m={1} md={7} style={{ fontFamily: 'sans-serif' }}  >
                                                            {this.state.bankDetails.bankName ? this.state.bankDetails.bankName : 'N/A'}
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            <Grid item md={1}>
                                            </Grid>
                                        </Grid>
                                        <Paper elevation={8} className={this.state.headingGenerate == 'Generate Invoice' ? 'tableGrid' : 'tableGrid2'}>
                                            <Grid pt={1} container>
                                                <Typography pt={4} style={{ margin: '0px 27px', marginBottom: '18px' }} variant="h6"> <b> Item Details:</b></Typography>
                                                <Grid item md={12} >
                                                    <div style={{ padding: '0px 40px' }} className="table-responsive">
                                                        <table className="tableInvoice table">
                                                            <tr>
                                                                <th>
                                                                    SR No
                                                                </th>
                                                                <th>
                                                                    Description
                                                                </th>
                                                                <th>
                                                                    SAC_DD
                                                                </th>
                                                                <th>
                                                                    Amount
                                                                </th>
                                                                <th>
                                                                    sGST Amount
                                                                </th>
                                                                <th>
                                                                    sGST%
                                                                </th>
                                                                <th>
                                                                    cGST Amount
                                                                </th>
                                                                <th>
                                                                    cGST %
                                                                </th>
                                                                <th>
                                                                    iGST Amount
                                                                </th>
                                                                <th>
                                                                    iGST %
                                                                </th>
                                                                <th>
                                                                    Actions
                                                                </th>
                                                            </tr>
                                                            {
                                                                this.state.itemDetails.map((eachItem, key) => (
                                                                    <>
                                                                        <tr>
                                                                            <td>
                                                                                {key + 1}.
                                                                            </td>
                                                                            <td>
                                                                                {/* <TextField sx={{ width: '20ch' }} id="Description" label=""
                                                                                    onChange={(e) => { this.handleChangeItemDetails(key, e) }}
                                                                                    name='Description'
                                                                                    value={eachItem.Description}
                                                                                    variant="standard"
                                                                                    required
                                                                                /> */}
                                                                                <TextField sx={{ mt: 3, width: '40ch' }}
                                                                                    id="standard-multiline-static"
                                                                                    label=""
                                                                                    rows={3}
                                                                                    name='Description'
                                                                                    multiline
                                                                                    defaultValue={eachItem.Description}
                                                                                    variant="standard"
                                                                                    onChange={(e) => { this.handleChangeItemDetails(key, e) }}
                                                                                    required
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <FormControl sx={{ width: '12ch' }} variant="standard" fullWidth={true} style={{ marginLeft: '0px' }}>
                                                                                    <Select
                                                                                        labelId="SAC"
                                                                                        id="SAC2"
                                                                                        value={eachItem.SAC}
                                                                                        onChange={(e) => { this.handleChangeItemDetails(key, e) }}
                                                                                        name='SAC'
                                                                                        label="SAC"
                                                                                        variant="standard"
                                                                                        required
                                                                                    >
                                                                                        <MenuItem value="">
                                                                                            <em>Select SAC</em>
                                                                                        </MenuItem>
                                                                                        {this.state.unique_sac.map((eachItem, key) => (
                                                                                            <MenuItem value={eachItem.SAC} key={key} >{eachItem.SAC}</MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </td>
                                                                            <td>
                                                                                <TextField sx={{ width: '10ch' }} id="client" label=""
                                                                                    onChange={(e) => { this.handleChangeItemDetails(key, e) }}
                                                                                    name='grandTotal'
                                                                                    value={eachItem.grandTotal}
                                                                                    type='number'
                                                                                    variant="standard"
                                                                                    required
                                                                                />
                                                                            </td>
                                                                            <td style={{ textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px' }}>
                                                                                {eachItem.sGSTAmount ? eachItem.sGSTAmount : 0}
                                                                            </td>
                                                                            <td style={{ textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px' }}>
                                                                                {eachItem.sGST ? eachItem.sGST : 0} %
                                                                            </td>
                                                                            <td style={{ textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px' }}>
                                                                                {eachItem.cGSTAmount ? eachItem.cGSTAmount : 0}
                                                                            </td>
                                                                            <td style={{ textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px' }}>
                                                                                {eachItem.cGST ? eachItem.cGST : 0} %
                                                                            </td>
                                                                            <td style={{ textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px' }}>
                                                                                {eachItem.iGSTAmount ? eachItem.iGSTAmount : 0}
                                                                            </td>
                                                                            <td style={{ textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px' }}>
                                                                                {eachItem.iGST ? eachItem.iGST : 0} %
                                                                            </td>
                                                                            <td>
                                                                                <DeleteIcon onClick={(e) => this.removeNewItem(key, e)} className="deleteIcon" />
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                                )}
                                                        </table>
                                                    </div>
                                                </Grid>
                                                <Grid item md={9}>
                                                </Grid>
                                                <Grid item md={3}>
                                                    {/* onClick={(e) => this.addNewItem(key, e)} */}
                                                    <div style={{ textAlign: "right", marginRight: '8px' }}>
                                                        <Button size="large" onClick={(e) => this.addNewItem(e)} variant="contained" style={{ marginRight: '26px', marginBottom: '20px', marginTop: "18px", color: "white" }} >
                                                            <PlusIcon />
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                        <Paper elevation={8} className={this.state.headingGenerate == 'Generate Invoice' ? 'tableGrid' : 'tableGrid2'}>
                                            <Grid pt={1} container style={{ padding: '12px' }}>
                                                <Grid item m={1} md={12} >
                                                </Grid>
                                                <Grid item m={1} md={3} >
                                                    <b style={{ fontSize: '15px', margin: '5px' }}> Total Amount :</b>
                                                </Grid>
                                                <Grid item m={1} md={7} style={{ fontFamily: 'sans-serif', fontSize: '15px' }}>
                                                    ₹ {this.state.totalAmount ? new Intl.NumberFormat("en-IN").format(this.state.totalAmount) : 0}
                                                </Grid>
                                                <Grid item m={1} md={3} >
                                                    <b style={{ fontSize: '15px', margin: '5px' }}> Total GST :</b>
                                                </Grid>
                                                <Grid item m={1} md={7} style={{ fontFamily: 'sans-serif', fontSize: '15px' }}>
                                                    ₹  {this.state.totalGST ? new Intl.NumberFormat("en-IN").format(this.state.totalGST) : 0}
                                                </Grid>
                                                <Grid item m={1} md={3} >
                                                    <b style={{ fontSize: '15px', margin: '5px' }}> Grand Total :</b>
                                                </Grid>
                                                <Grid item m={1} md={7} style={{ fontFamily: 'sans-serif', fontSize: '15px' }}>
                                                    ₹ {this.state.grandTotal ? new Intl.NumberFormat("en-IN").format(this.state.grandTotal) : 0}
                                                </Grid>
                                                <Grid item m={1} md={12} style={{ fontFamily: 'sans-serif', fontSize: '15px' }}>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                        <Paper elevation={8} className={this.state.headingGenerate == 'Generate Invoice' ? 'tableGrid' : 'tableGrid2'}>
                                            <Grid pt={1} container>
                                                <Typography pt={4} style={{ margin: '0px 27px', marginBottom: '18px' }} variant="h6"> <b> Attachments:</b></Typography>
                                                <Grid item md={12} >
                                                    <div style={{ padding: '0px 40px' }} className="table-responsive">
                                                        <table className="tableInvoice table">
                                                            <tr>
                                                                <th>
                                                                    SR No
                                                                </th>
                                                                <th>
                                                                    Description
                                                                    <span style={{
                                                                        color: "red"
                                                                    }}>*</span>
                                                                </th>
                                                                <th>
                                                                    Attach / View
                                                                </th>
                                                                <th>
                                                                    Action
                                                                </th>
                                                            </tr>
                                                            {
                                                                this.state.attachements.map((eachItem, key) => (
                                                                    <>
                                                                        <tr>
                                                                            <td>
                                                                                {key + 1}.
                                                                            </td>
                                                                            <td style={{ width: '24%' }}>
                                                                                <TextField sx={{ width: '100%' }} id="Description" label=""
                                                                                    onChange={(e) => { this.handleChangeAttachment(key, e) }}
                                                                                    name='Description'
                                                                                    value={eachItem.Description}
                                                                                    variant="standard"
                                                                                />
                                                                            </td>
                                                                            <td style={{ textAlign: 'center' }}>
                                                                                <>
                                                                                    {eachItem.DocLink == '' ?
                                                                                        <Button
                                                                                            color="primary"
                                                                                            size="large"
                                                                                            variant="contained"
                                                                                            component="label"
                                                                                            onChange={(e) => { this.handleChangeAttachment(key, e) }}
                                                                                            style={{ color: "white" }}
                                                                                            className={this.state.color}
                                                                                        >
                                                                                            Upload
                                                                                            <input
                                                                                                type="file"
                                                                                                hidden
                                                                                                accept="image/*,.pdf,.xls,.xlsx"
                                                                                            />
                                                                                        </Button>
                                                                                        :
                                                                                        <>
                                                                                            {eachItem.DocLink.split('.').pop().toLowerCase() != "pdf" && eachItem.DocLink.split('.').pop().toLowerCase() != "xlsx" &&
                                                                                                <div style={{ width: '152px', marginLeft: 'auto', marginRight: 'auto' }}>
                                                                                                    <img style={{ width: '100%' }} src={eachItem.DocLink} />
                                                                                                </div>
                                                                                            }
                                                                                            {eachItem.DocLink.split('.').pop().toLowerCase() == "pdf" &&
                                                                                                <img className="bms-view-request-img" onClick={(e) => { window.open(eachItem.DocLink) }} src={pdfIcon} alt="No Image" key={key} />
                                                                                            }
                                                                                            {eachItem.DocLink.split('.').pop().toLowerCase() == "xlsx" &&
                                                                                                <img className="bms-view-request-img" onClick={(e) => { window.open(eachItem.DocLink) }} src={excelIcon} alt="No Image" key={key} />
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            </td>
                                                                            {/* <td style={{ textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px' }}>
                                   <div style={{width: '200px'}}>
                                    <img style={{width: '100%'}} src="https://images.unsplash.com/photo-1617854818583-09e7f077a156?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80" />
                                   </div>
                                </td> */}
                                                                            <td>
                                                                                <DeleteIcon onClick={(e) => this.removeNewAttachment(key, e)} className="deleteIcon" />
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                                )}
                                                        </table>
                                                    </div>
                                                </Grid>
                                                <Grid item md={9}>
                                                </Grid>
                                                <Grid item md={3}>
                                                    {/* onClick={(e) => this.addNewItem(key, e)} */}
                                                    <div style={{ textAlign: "right", marginRight: '8px' }}>
                                                        <Button size="large" onClick={(e) => this.addNewAttachment(e)} variant="contained" style={{ marginRight: '26px', marginBottom: '20px', marginTop: "18px", color: "white" }} >
                                                            <PlusIcon />
                                                        </Button>
                                                    </div>
                                                </Grid>
                                                <Grid item md={9} >
                                                </Grid>
                                                <Grid item md={6} >
                                                </Grid>
                                                <Grid mt={2} item md={3} style={{ marginBottom: '27px' }} >
                                                    <Button type="submit" size="large" variant="contained" style={{ marginBottom: '18px', marginTop: "18px", background: "rgb(234 67 68)", color: "white" }} endIcon={<SendIcon />}>
                                                        {this.state.submitButton}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Container>
                                </form>
                            </Grid>
                            {this.state.headingGenerate == 'Generate Invoice' ? '' :
                                <Grid
                                    item
                                    md={3}
                                    style={{ paddingRight: "8px", paddingTop: "18px" }}
                                >
                                    {/* <Grid container style={{ background: '#e0e1e0', }}> */}
                                    <Grid item md={12}>
                                        {this.state.attachements[0].Description != '' && this.state.attachements.map((e, key) => (
                                            <Card style={{ marginTop: "12px" }}>
                                                <Typography
                                                    style={{ marginLeft: "12px", marginTop: "5px" }}
                                                    variant="h6"
                                                >
                                                    {" "}
                                                    Description : {e.Description != '' ? e.Description : ''}
                                                </Typography>
                                                <div style={{ padding: "12px 22px", marginBottom: "14px" }}>
                                                    {e.DocLink.split('.').pop().toLowerCase() != "pdf" && e.DocLink.split('.').pop().toLowerCase() != "xlsx" && e.DocLink.split('.').pop().toLowerCase() != "xls" &&
                                                        <Tooltip title='Download / View' placement="bottom">
                                                            <CardMedia
                                                                component="img"
                                                                className="pan-image"
                                                                height="194"
                                                                image={e.DocLink}
                                                                onClick={(event) => {
                                                                    this.handleOpenModal(e.DocLink, 'image');
                                                                }}
                                                                alt="Attachment"
                                                                style={{
                                                                    border: "1px solid black",
                                                                    objectFit: "contain",
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    }
                                                    {e.DocLink.split('.').pop().toLowerCase() == "pdf" &&
                                                        <Tooltip title='Download / View' placement="bottom">
                                                            <CardMedia
                                                                component="img"
                                                                className="pan-image"
                                                                height="194"
                                                                image="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1673776018164_4373076_adobe_file_logo_logos_pdf_icon.png"
                                                                onClick={(event) => {
                                                                    this.handleOpenModal(e.DocLink, 'pdf');
                                                                }}
                                                                alt="Attachment"
                                                                style={{
                                                                    border: "1px solid black",
                                                                    objectFit: "contain",
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    }
                                                    {e.DocLink.split('.').pop().toLowerCase() == "xlsx" &&
                                                        <Tooltip title='Download / View' placement="bottom">
                                                            <CardMedia
                                                                component="img"
                                                                className="pan-image"
                                                                height="194"
                                                                image="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/Inv_JAN2023_Supporting_nk.jpg"
                                                                onClick={(event) => {
                                                                    window.open(e.DocLink, '_blank', 'noopener,noreferrer');
                                                                    // this.handleOpenModal(e.DocLink, 'excel');
                                                                }}
                                                                alt="Attachment"
                                                                style={{
                                                                    border: "1px solid black",
                                                                    objectFit: "contain",
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    }
                                                </div>
                                            </Card>
                                        ))}
                                    </Grid>
                                    {/* </Grid> */}
                                </Grid>
                            }
                        </Grid>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={this.state.viewModal}
                            onClose={this.handleCloseModal}
                            closeAfterTransition
                        >
                            <Fade in={this.state.viewModal}>
                                {/* <Box sx={style2}  >
                                        <iframe src={this.state.modalData} style={{width:'100%', height:'100%'}}></iframe>
                                    </Box> */}
                                <Box sx={style2}>
                                    <Card className="pan-image">
                                        <CardMedia
                                            component="img"
                                            height='auto'
                                            image={this.state.modalData}
                                            alt="Error"
                                        />
                                    </Card>
                                </Box>
                            </Fade>
                        </Modal>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={this.state.viewImageModal}
                            onClose={this.handleCloseModal}
                            closeAfterTransition
                        >
                            <Fade in={this.state.viewImageModal}>
                                <Box sx={style2}>
                                    <Card className="pan-image">
                                        <CardMedia
                                            component="img"
                                            height='auto'
                                            image={this.state.modalData}
                                            alt="Error"
                                        />
                                    </Card>
                                </Box>
                            </Fade>
                        </Modal>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={this.state.viewPdfModal}
                            onClose={this.handleCloseModal}
                            closeAfterTransition
                        >
                            <Fade in={this.state.viewPdfModal}>
                                <Box sx={style6}  >
                                    <iframe src={this.state.modalData} style={{ width: '100%', height: '100%' }}></iframe>
                                </Box>
                            </Fade>
                        </Modal>
                        <Modal
                            open={this.state.invoiceModal}
                            onClose={this.handleCloseInvoiceModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style2} style={{ textAlign: 'center' }}>
                                <Typography style={{ textAlign: 'left' }} id="modal-modal-title" variant="h6" component="h2">
                                    Invoice Generated:
                                </Typography>
                                <Button size="large"
                                    onClick={(e) => { window.open(this.state.invoiceUrl); }}
                                    variant="contained" style={{ marginBottom: '18px', marginTop: "18px", background: "rgb(234 67 68)", color: "white" }} endIcon={<SendIcon />}>
                                    Download
                                </Button>
                            </Box>
                        </Modal>
                    </Container>
                </Dialog>
            </div>
        );
    }
}
HomePage.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(mapStateToProps, { setAlert })(HomePage);