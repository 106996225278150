import {
  GET_BASIC_DETAILS,
  GET_ACADEMIC_DETAILS,
  DETAILS_ERROR,
  CLEAR_DETAILS
} from "../constants/actionTypes";

const initialState = {
  basicDetails: null,
  loading: true,
  academicDetails: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BASIC_DETAILS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        basicDetails: payload
      };

    case GET_ACADEMIC_DETAILS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        academicDetails: payload
      };

    case DETAILS_ERROR:
    case CLEAR_DETAILS:
      return {
        ...state,
        loading: false,
        basicDetails: null,
        academicDetails: null
      };
    default:
      return state;
  }
}
