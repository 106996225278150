/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import axios from "axios";
import {
    FormGroup,
    // Label,
    Input
} from "reactstrap";
import Config from "../../../config";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import "../../../assets/customCssFiles/loader-spinner.css"

class MapContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            locations: [],
            inilat: "12.9716",
            inilng: "77.5946",
            routeNo: 0,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            unique_locationids: [],
            unique_dates: [],
            unique_departments: [],
            Locationid: 7,
            Department: "",
            selected_date: new Date(),
            alert: null,
            show: false,
            basicTitle: "",
            basicType: "default",
            style: {},
            alert_message: ""
        };
    }


    changeLocation = async (event) => {
        event.preventDefault();
        await this.setState({
            style: {},
            Locationid: event.target.value
        })
        await this.getUniqueFieldsByLocationId(this.state.Locationid)
        await this.setState({
            style: { display: "none" }
        })
    }

    getUniqueFieldsByLocationId = async (locationId) => {
        await axios.get(`${Config.hostName}/ems/get/unique/map-view/AttnDate/${locationId}`,
        )
            .then(async (res) => {
                // console.log(res.data);
                const index = res.data.data.length - 1
                this.setState({
                    unique_dates: res.data.data,
                    selected_date: res.data.data[index].AttnDate
                })
            })
            .catch((error) => {
                console.log(error)
            });
        await axios.get(`${Config.hostName}/ems/get/unique/map-view/department/${locationId}`)
            .then(async (res) => {
                console.log(res.data);
                this.setState({
                    unique_departments: res.data.data,
                    Department: res.data.data[0].Department
                })
            })
            .catch((error) => {
                console.log(error)
            });
    }

    getData = async () => {
        await axios.get(`${Config.hostName}/unique/locations`)
            .then(res => {
                // console.log(res.data);

                this.setState({
                    unique_locationids: res.data.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    componentWillMount = async () => {

        await this.getData();
        await this.getUniqueFieldsByLocationId(this.state.Locationid)
        await this.handleSearchMap()

    };


    handleSearchMap = async () => {
        // console.log(this.state.Department);
        await this.setState({
            style: {}
        })
        try {
            let result = await axios.post(
                `${Config.hostName}/ems/map-view/search/1`,
                {
                    locationId: this.state.Locationid,
                    date: this.state.selected_date,
                    department: this.state.Department

                }
            );
            // console.log(result.data);

            await this.setState({
                locations: result.data.data,
                inilat: result.data.data[0] && result.data.data[0].AttnLat,
                inilng: result.data.data[0] && result.data.data[0].AttnLong,
                style: { display: 'none' }

            });
        } catch (e) { }
    };

    onMarkerDragEnd = (coord, index) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();

        this.setState(prevState => {
            const locations = [...this.state.locations];
            locations[index] = { ...locations[index], lat: lat, long: lng };
            return { locations };
        });
    };

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }
    onMapClicked = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    closeAlert = () => {
        this.setState({
            show: false
        });
    };



    render() {
        const google = window.google;
        let style = this.state.style;

        return (
            <div>
                <div id='cover-spin' style={style}>
                </div>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                    {this.state.alert_message}
                </SweetAlert>
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">

                                <nav
                                    className="navbar navbar-light "
                                    style={{ backgroundColor: "none" }}
                                >
                                    <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    name="locationid"
                                                    value={this.state.Locationid}
                                                    id="locationid"
                                                    onChange={this.changeLocation}
                                                >
                                                    <option value={""} disabled selected>Select Location</option>

                                                    {this.state.unique_locationids.map(locationid => (
                                                        <option value={locationid.Locationid}>
                                                            {locationid.name}

                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    name="date"
                                                    value={this.state.selected_date}
                                                    id="date"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            selected_date: e.target.value
                                                        })
                                                    }}
                                                >
                                                    <option value={""} disabled selected>Select date</option>

                                                    {this.state.unique_dates.map(date => (
                                                        <option key={date.AttnDate} value={date.AttnDate}>
                                                            {date.AttnDate}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    name="department"
                                                    value={this.state.Department}
                                                    id="department"
                                                    onChange={event => {
                                                        this.setState({ Department: event.target.value });
                                                    }}                                                >
                                                    <option value={""} disabled selected>Select Department</option>

                                                    {this.state.unique_departments.map(department => (
                                                        <option key={department.Department} value={department.Department}>
                                                            {department.Department}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-2">
                                            <button
                                                type="submit"
                                                className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                                onClick={this.handleSearchMap}
                                            >
                                                Search
                      </button>
                                        </div>

                                    </div>
                                </nav>

                            </div>

                            <div className="card-body" style={{ flex: "4", height: "100", margin: "0" }}>
                                <div className="col-md-12">
                                    <Map
                                        google={this.props.google}
                                        // onMouseout={this.onMapClicked}
                                        onClick={this.onMapClicked}
                                        style={{
                                            width: "100%",
                                            height: "70vh",
                                            position: "fixed"
                                            // position: "absolute"
                                        }}
                                        className={"map"}
                                        initialCenter={{
                                            lat: this.state.inilat,
                                            lng: this.state.inilng
                                        }}
                                        center={{
                                            lat: this.state.inilat,
                                            lng: this.state.inilng
                                        }}
                                        zoom={14}
                                    >
                                        {this.state.locations.map((location, index) => {
                                            return (
                                                <Marker
                                                    // icon={{
                                                    //     url: 'http://image.flaticon.com/icons/svg/252/252025.svg',
                                                    //     scaledSize: new google.maps.Size(60, 40),
                                                    //     origin: new google.maps.Point(0, 0),
                                                    //     anchor: new google.maps.Point(32, 65),
                                                    //     labelOrigin: { x: 45, y: 20 }
                                                    // ${location.Islate === 0 ? "green" : "red"}
                                                    // }}
                                                    icon={{
                                                        url: `http://maps.google.com/mapfiles/ms/icons/green-dot.png`,
                                                        scaledSize: new google.maps.Size(40, 30)
                                                    }}
                                                    labelOrigin={{ x: 10, y: 0 }}
                                                    label={{
                                                        text: `${location.FullName}`,
                                                        fontFamily: "Arial",
                                                        fontSize: "14px",
                                                        color: "black"
                                                    }}
                                                    title={location.FullName}
                                                    name={
                                                        <div>
                                                            <p>
                                                                Mobile Number   :{" "}
                                                                {location.mobile}
                                                                <br />
                                                                Designation : {location.Designation}
                                                                <br />

                                                                Department : {location.Department}
                                                            </p>
                                                        </div>
                                                    }
                                                    onClick={this.onMarkerClick}
                                                    position={{
                                                        lat: location.AttnLat,
                                                        lng: location.AttnLong
                                                    }}
                                                    draggable={true}
                                                    onDragend={(t, map, coord) =>
                                                        this.onMarkerDragEnd(coord, index)
                                                    }
                                                />
                                            );
                                        })}

                                        <InfoWindow
                                            onMouseover={this.onMarkerClick}
                                            onMouseout={this.onMapClicked}
                                            marker={this.state.activeMarker}
                                            visible={this.state.showingInfoWindow}
                                        >
                                            <div>
                                                <p
                                                    style={{
                                                        width: "80px",
                                                        fontSize: "0.7rem",
                                                        height: "100%"
                                                    }}
                                                >
                                                    <strong>{this.state.selectedPlace.name}</strong>
                                                </p>
                                            </div>
                                        </InfoWindow>
                                    </Map>
                                </div>
                            </div>

                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: String(Config.apiKey),
    v: "3"
})(MapContainer);
