import React, {
    Component
    // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup, Input,
    Table, Card, CardBody, Button,
    Label
} from "reactstrap";

// import { Button } from "@material-ui/core";
// import {Link} from "react-router-dom";
// import { CSVLink } from "react-csv";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumb from "../../../common/breadcrumb.component";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import "../../../../assets/customCssFiles/bmsViewRequest.css";
import "../../../../assets/customCssFiles/bmsViewReqPayee.css";

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SaveIcon from '@material-ui/icons/Save';
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
// import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import DeleteIcon from '@material-ui/icons/Delete';
import LinearProgress from '@material-ui/core/LinearProgress';

import BankIcon from "../../../../assets/customImages/bankIconPms.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
class RouteAddForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: "",
            style: {},
            category: "",
            uniqueCategories: [],
            priority: "",
            totalAmount: 0,
            remarks: "",
            items: [],
            attachments: [],
            contentId: "",
            content: undefined,
            bmsId: "",
            initialFormItems: [],
            selectedFile: null,
            selectedFileType: "",
            isAttachmentsLoading: false,
            mainApiData: {},
            remarksDataTotal: [],
            isChangeCategoryModal: false,
            newCategory: "",
            uniqueItems: [],
            isChangeCategory: false,
            mainCategory: "",
            mainCategoryAnother: false,

            payeeDetails: [],
            bankAccountNumber: "",
            bankAccountHolderName: "",
            bankIfscCode: "",
            billingDate: "",
            ccXrefId: "",
            /** Drop Downs Data  */
            uniqueLocationsDropDown: [],
            uniqueClientsDropDown: [],
            uniqueSegmentsDropDown: [],
            uniquePayeeTypesDropDown: [],
            uniquePayeesDropDown: [],
            uniquePayeeBanksDropDown: [],
            /** Drop Downs Selected Value  */
            selectedLocationDropDown: "",
            selectedClientDropDown: "",
            selectedSegmentDropDown: "",
            selectedPayeeTypeDropDown: "",
            selectedPayeeDropDownName: "",
            selectedPayeeDropDown: "",
            selectedLocationPayeeDropDown: "",
            selectedPayeeBankDropDown: "",
            selectedPayeeBankAmountValue: "",

            selectedBanksList: [],
            isAddPayeeBankModalOpen: false,
            isEditPayeeBankModalOpen: false,
            isAddPayeeModalOpen: false,
            isEditPayeeBankAmountEditModalOpen: false,

            addPayeeBankBeneficiaryName: "",
            addPayeeBankAccountNumber: "",
            addPayeeBankBankName: "",
            addPayeeBankIfsc: "",
            addPayeeBankIsPrimary: false,
            addPayeeBankTds: "",


            addPayeeName: "",
            addPayeeEmail: "",
            addPayeeContactNumber: "",
            addPayeeContactNumberAlternate: "",
            addPayeeIsCompany: false,
            addPayeeTds: "",
            addPayeeAttachments: [],
            addPayeeBeneficiaryName: "",
            addPayeeAccountNumber: "",
            addPayeeBankName: "",
            addPayeeIfsc: "",

            editBankModalDetails: [],

            stringVer: true
        };

    }


    getUniqueLocationsDropDown = async () => {
        await axios.get(`${Config.hostName}/unique/locations`)
            .then(async (res) => {
                await this.setState({ uniqueLocationsDropDown: res.data.data })
            })
            .catch((error) => {
                // console.log(error)
                alert("getUniqueLocationsDropDown : ", error);
            });
    }

    getUniqueClientsDropDown = async () => {
        await axios
            .post(`${Config.hostName}/unique/clients`, {
                locationId: this.state.selectedLocationDropDown,
                clientName: ""
            })
            .then(async res => {
                // console.log(res.data);
                await this.setState({
                    uniqueClientsDropDown: res.data.data,
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    getUniqueSegmentsDropDown = async () => {
        await axios
            .post(`${Config.hostName}/unique/segments`, {
                locationId: this.state.selectedLocationDropDown,
                clientName: this.state.selectedClientDropDown
            })
            .then(res => {
                this.setState({
                    uniqueSegmentsDropDown: res.data.data,
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    getUniquePayeeTypesDropDown = async () => {
        await axios
            .get(`${Config.hostName}/pms/get/payeeType/dropdown`)
            .then(async res => {
                await this.setState({
                    uniquePayeeTypesDropDown: res.data.data,
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    getUniquePayeesDropDown = async () => {
        await axios
            .get(`${Config.hostName}/pms/get/payee/dropdown/${this.state.selectedLocationPayeeDropDown}/${this.state.selectedPayeeTypeDropDown}`)
            .then(async res => {
                await this.setState({
                    uniquePayeesDropDown: res.data.data,
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    getUniquePayeeBanksDropDown = async () => {
        await axios
            .get(`${Config.hostName}/pms/get/payeeBank/dropdown/${this.state.selectedPayeeDropDown}`)
            .then(async res => {
                await this.setState({
                    uniquePayeeBanksDropDown: res.data.data,
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    addPayeeBankApiCall = async () => {
        await this.setState({
            style: {}
        })
        await axios.post(`${Config.hostName}/pms/add/bank/payee`, {
            "pmsId": this.state.selectedPayeeDropDown,
            "bankName": this.state.addPayeeBankBankName,
            "bankAccountNo": this.state.addPayeeBankAccountNumber,
            "ifsc": this.state.addPayeeBankIfsc,
            "beneficaryName": this.state.addPayeeBankIfsc,
            "tds": this.state.addPayeeBankTds,
            "isPrimary": this.state.addPayeeBankIsPrimary
        })
            .then(async res => {
                console.log(res.data);
                if (res.data.success == true) {
                    console.log("hi");
                    await this.toggleAddPayeeBankModal();
                    await this.getUniquePayeeBanksDropDown();
                    await this.setState({
                        style: { display: "none" }
                    })
                    alert("Succesfully Added");
                }
                else {
                    await this.setState({
                        style: { display: "none" }
                    })
                    alert(res.data.message);

                }
            })
            .catch(async err => {
                await this.setState({
                    style: { display: "none" }
                })
                alert(err)
                // console.log(err);
            });
    }

    addPayeeApiCall = async () => {
        await this.setState({
            style: {}
        })
        const {
            addPayeeName, addPayeeEmail, addPayeeContactNumber, addPayeeContactNumberAlternate, addPayeeIsCompany, addPayeeTds, addPayeeAttachments,
            addPayeeBeneficiaryName, addPayeeAccountNumber, addPayeeBankName, addPayeeIfsc, selectedPayeeTypeDropDown, selectedLocationPayeeDropDown } = this.state;
        await axios.post(`${Config.hostName}/pms/add/payee`, {
            "payeeName": addPayeeName,
            "payeeTypeId": selectedPayeeTypeDropDown,
            "locationId": selectedLocationPayeeDropDown,
            "bankName": addPayeeBankName,
            "bankAccountNo": addPayeeAccountNumber,
            "ifsc": addPayeeIfsc,
            "beneficaryName": addPayeeBeneficiaryName,
            "tds": addPayeeTds,
            "isCompany": addPayeeIsCompany === false ? 0 : 1,
            "email": addPayeeEmail,
            "contactNumber": addPayeeContactNumber,
            "contactNumber02": addPayeeContactNumberAlternate,
            "attachments": addPayeeAttachments

        })
            .then(async res => {
                console.log(res.data);
                if (res.data.success == true) {
                    console.log("hi");
                    await this.toggleAddPayeeModal();
                    await this.getUniquePayeesDropDown();
                    await this.setState({
                        style: { display: "none" }
                    })
                    alert("Succesfully Added");
                }
                else {
                    await this.setState({
                        style: { display: "none" }
                    })
                    alert(res.data.message);

                }
            })
            .catch(async err => {
                await this.setState({
                    style: { display: "none" }
                })
                alert(err)
                // console.log(err);
            });
    }

    changeLocation = async event => {
        event.preventDefault();
        await this.setState({ style: {}, uniqueClientsDropDown: [], uniqueSegmentsDropDown: [], selectedLocationDropDown: event.target.value });
        await this.getUniqueClientsDropDown();
        await this.setState({ style: { display: "none" } });
    };

    changeClient = async event => {
        event.preventDefault();
        await this.setState({ style: {}, uniqueSegmentsDropDown: [], selectedClientDropDown: event.target.value });
        await this.getUniqueSegmentsDropDown();
        await this.setState({ style: { display: "none" } });
    };

    changeSegment = async event => {
        event.preventDefault();
        const value = event.target.value;
        console.log(value, "value")
        await this.setState({ selectedSegmentDropDown: value, ccXrefId: value });
        // await this.setState({ style: { display: "none" } });
    };

    changeLocationPayee = async event => {
        event.preventDefault();
        await this.setState({
            // style: {},
            uniquePayeeTypesDropDown: [],
            uniquePayeesDropDown: [],
            uniquePayeeBanksDropDown: [],
            selectedLocationPayeeDropDown: event.target.value,
            selectedPayeeTypeDropDown: "",
            selectedPayeeDropDown: ""
        });
        await this.getUniquePayeeTypesDropDown();
        // await this.setState({ style: { display: "none" } });
    };

    changePayeeType = async event => {
        event.preventDefault();
        await this.setState({ uniquePayeeBanksDropDown: [], uniquePayeesDropDown: [], selectedPayeeTypeDropDown: event.target.value, selectedPayeeDropDown: "" });
        await this.getUniquePayeesDropDown();
        // await this.setState({ style: { display: "none" } });
    };

    changePayee = async event => {
        event.preventDefault();
        const payeeValue = event.target.value
        console.log(payeeValue)
        if (payeeValue == 88888) {
            await this.setState({
                isAddPayeeModalOpen: true
            })
        }
        else {
            await this.setState({ uniquePayeeBanksDropDown: [], selectedPayeeDropDown: event.target.value, selectedPayeeBankDropDown: "" });
            await this.getUniquePayeeBanksDropDown();
        }
        // await this.setState({ style: { display: "none" } });
    };

    changePayeeBank = async event => {
        event.preventDefault();
        const payeeBankValue = event.target.value
        console.log(payeeBankValue)
        if (payeeBankValue == 99999) {
            await this.setState({
                isAddPayeeBankModalOpen: true
            })
        }
        else {
            await this.setState({ selectedPayeeBankDropDown: payeeBankValue });
            // await this.getUniquePayeeBanksDropDown();
            // const { selectedBanksList, uniquePayeeBanksDropDown } = this.state;
            // uniquePayeeBanksDropDown.filter((bankItem, i) => {
            //     if (bankItem.payeebankID == payeeBankValue) {
            //         bankItem.amount = "";
            //         selectedBanksList.push(bankItem)
            //     }
            // })

            // await this.setState({
            //     selectedBanksList: selectedBanksList,
            //     style: { display: "none" }
            // }
            // );
        }
    };

    changePayeeBankAmountValue = async event => {
        event.preventDefault();
        const payeeBankAmountValue = event.target.value
        console.log(payeeBankAmountValue)
        await this.setState({
            selectedPayeeBankAmountValue: payeeBankAmountValue,
        }
        );
    };


    deletePayeeBank = async (value) => {
        console.log(value);
        // await this.setState({ style: {} });
        // await this.getUniquePayeeBanksDropDown();
        const { selectedBanksList } = this.state;
        const banKList = selectedBanksList.filter((bankItem, i) => {
            // if(bankItem.payeebankID==value){
            // return selectedBanksList.pop(bankItem);
            return bankItem.payeebankID != value;

            // }
        })
        console.log(banKList)

        await this.setState({
            selectedBanksList: banKList,
            // style: { display: "none" }
        }
        );
    };

    editPayeeBank = async (value) => {
        console.log(value);
        const { selectedBanksList } = this.state;
        const reqObj = [];
        const banKList = selectedBanksList.filter((bankItem, i) => {
            // return bankItem.payeebankID != value;
            if (bankItem.payeebankID == value) {
                return reqObj.push(bankItem)
            }
        })
        console.log(reqObj, "reqObj")
        await this.setState({
            isEditPayeeBankModalOpen: true,
            editBankModalDetails: reqObj,
            addPayeeBankBeneficiaryName: reqObj[0].fullname,
            addPayeeBankAccountNumber: reqObj[0].AccountNumber,
            addPayeeBankBankName: reqObj[0].bankname,
            addPayeeBankIfsc: reqObj[0].IFSC,
            addPayeeBankIsPrimary: reqObj[0].IsPrimary,
            // addPayeeBankTds: reqObj[0].tds,
            selectedPayeeDropDown: reqObj[0].pmsid,
            selectedPayeeDropDownName: reqObj[0].fullname,
            selectedPayeeBankDropDown: value
        })

    };

    editPayeeBankAmount = async (value) => {
        console.log(value);
        const { selectedBanksList } = this.state;
        const reqObj = [];
        const banKList = selectedBanksList.filter((bankItem, i) => {
            // return bankItem.payeebankID != value;
            if (bankItem.payeebankID == value) {
                return reqObj.push(bankItem)
            }
        })
        console.log(reqObj, "reqObj")
        await this.setState({
            isEditPayeeBankAmountEditModalOpen: true,
            selectedPayeeDropDownName: reqObj[0].fullname,
            selectedPayeeBankAmountValue: reqObj[0].amount,
            selectedPayeeBankAmountValueObject: reqObj
        })

    };

    createPayeeBank = async (e) => {
        e.preventDefault();
        console.log("addPayeeBank");
        await this.addPayeeBankApiCall();
    }

    createPayee = async (e) => {
        e.preventDefault();
        console.log("addPayee");
        await this.addPayeeApiCall();

    }

    savePayeeBankApiCall = async () => {
        await this.setState({
            style: {}
        })
        const neededBank = this.state.selectedPayeeBankDropDown;
        await axios.post(`${Config.hostName}/pms/update/bank/payee`, {
            "pmsId": this.state.selectedPayeeDropDown,
            "payeeBankId": this.state.selectedPayeeBankDropDown,
            "bankName": this.state.addPayeeBankBankName,
            "bankAccountNo": this.state.addPayeeBankAccountNumber,
            "ifsc": this.state.addPayeeBankIfsc,
            "beneficaryName": this.state.addPayeeBankIfsc,
            "tds": "",
            "isPrimary": 0
        })
            .then(async res => {
                console.log(res.data);
                if (res.data.success == true) {
                    console.log("hi");
                    await this.setState({ style: {} });
                    await this.getUniquePayeeBanksDropDown();
                    const { selectedBanksList, uniquePayeeBanksDropDown } = this.state;
                    const tempBankList = [];

                    uniquePayeeBanksDropDown.filter((bankItem, i) => {
                        if (bankItem.payeebankID == neededBank) {
                            tempBankList.push(bankItem);
                        }
                    })
                    console.log(tempBankList, "tempBankList");

                    selectedBanksList.filter((bank => {
                        if (bank.payeebankID == neededBank) {
                            // return bank = tempBankList[0]
                            return (
                                bank.AccountNumber = tempBankList[0].AccountNumber,
                                bank.IFSC = tempBankList[0].IFSC,
                                bank.IsPrimary = tempBankList[0].IsPrimary,
                                // bank.amount = tempBankList[0].amount,
                                bank.bankname = tempBankList[0].bankname,
                                bank.fullname = tempBankList[0].fullname,
                                bank.payeebankID = tempBankList[0].payeebankID,
                                bank.pmsid = tempBankList[0].pmsid,
                                bank.tds = tempBankList[0].tds
                            )
                        }
                    }))
                    console.log(selectedBanksList, "selectedBanksList");
                    await this.setState({
                        selectedBanksList: selectedBanksList,
                    });
                    await this.setState({
                        style: { display: "none" }
                    })
                    await this.toggleEditPayeeBankModal();
                    alert("Succesfully Updated");
                }
                else {
                    await this.setState({
                        style: { display: "none" }
                    })
                    alert(res.data.message);

                }
            })
            .catch(async err => {
                await this.setState({
                    style: { display: "none" }
                })
                alert(err)
                // console.log(err);
            });
    }

    savePayeeBankDetails = async (e) => {
        e.preventDefault();
        console.log("addPayeeBank");
        await this.savePayeeBankApiCall();
    }

    savePayeeBankAmountDetails = async (e) => {
        e.preventDefault();
        const { selectedBanksList, selectedPayeeBankAmountValueObject, selectedPayeeBankAmountValue } = this.state;
        // console.log("addPayeeBank", selectedPayeeBankAmountValue, selectedBanksList, selectedPayeeBankAmountValueObject);
        const map = selectedBanksList.map(item => {
            if (item.payeebankID == selectedPayeeBankAmountValueObject[0].payeebankID) {
                // console.log(selectedPayeeBankAmountValue, "selectedPayeeBankAmountValue", item)
                return item.amount = selectedPayeeBankAmountValue
            }
        })
        const res = await Promise.all(map);
        // console.log(selectedBanksList, "selectedBanksList")
        await this.setState({
            selectedBanksList: selectedBanksList,
            isEditPayeeBankAmountEditModalOpen: false,
            selectedPayeeBankAmountValue: ""
        })
        // console.log(this.state.selectedBanksList, "this.state.selectedBanksList")
    }

    getUrlToAttachmentsAddPayee = async (url, name) => {
        const { addPayeeAttachments } = this.state;
        addPayeeAttachments.filter(attachment => {
            if (attachment.name == name) {
                return attachment.AttachmentUrl = url
            }
        })
        await this.setState({
            addPayeeAttachments: addPayeeAttachments
        })
    }

    uploadFileAndGetUrlAddPayee = async (formData) => {
        // console.log("uploading formdata")
        const fileData = await axios({
            method: "POST",
            url: `${Config.hostName}/unique/upload/any-file`,
            timeout: 3 * 60 * 1000,
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*"
            },
            data: formData,
        });
        return fileData;
    }

    singleUploadFileAddPayee = async (file) => {
        const reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = async (e) => {
            await this.setState({
                selectedFile: [reader.result],
            });
        }
        // console.log(url); // Would see a path?
        const fileType = file.type.substring(0, 5);
        await this.setState({ selectedFileAddPayee: file, selectedFileTypeAddPayee: fileType });
        const formData = new FormData();
        formData.append(
            "media",
            this.state.selectedFileAddPayee,
            this.state.selectedFileAddPayee.name
        );
        // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
        // console.log(formData, "formData", fileType);
        const res = await this.uploadFileAndGetUrlAddPayee(formData);
        const urlFromAws = res.data.url;
        // const urlFromAws = "https://logicarts.s3.ap-south-1.amazonaws.com/clientlogos/capp_truck.png";
        await this.getUrlToAttachmentsAddPayee(urlFromAws, file.name);
        const { addPayeeAttachments } = this.state;
        setTimeout(async () => {
            addPayeeAttachments.filter(att => {
                if (att.name == file.name && att.isUploaded == false) {
                    return att.isUploaded = true
                }
            })
            await this.setState({
                addPayeeAttachments: addPayeeAttachments
            })
        }, 2000);
    }

    onFileUploadAddPayee = async (event) => {
        // console.log(event.target.files, "event.target.files");
        const filesObj = event.target.files;
        const filesObjKeys = Object.keys(filesObj);
        const { addPayeeAttachments } = this.state;
        const multiMapFileNames = filesObjKeys.map(async (fileKey, index) => {
            addPayeeAttachments.push({
                name: filesObj[fileKey].name,
                AttachmentUrl: "",
                isUploaded: false
            })
        })
        const multiPromiseFileNames = await Promise.all(multiMapFileNames);
        await this.setState({
            addPayeeAttachments: addPayeeAttachments
        })
        for (let i = 0; i < filesObjKeys.length; i++) {
            // console.log(i, "i")
            const item = filesObj[i];
            await this.singleUploadFileAddPayee(item);
        }

    };

    deleteAttachmentAddPayee = async (name) => {
        const { addPayeeAttachments } = this.state;
        // console.log(name, "deleteAttachment");
        addPayeeAttachments.filter((item) => {

            if (item.name == name) {
                const index = addPayeeAttachments.indexOf(item);
                if (index > -1) {
                    addPayeeAttachments.splice(index, 1);
                }
            }
        })
        await this.setState({
            addPayeeAttachments: addPayeeAttachments
        })
    }

    createNewPayeeSelectedBankCard = async (e) => {
        e.preventDefault();
        if (this.state.selectedPayeeBankDropDown.length == 0) {
            return alert("Please Select Bank Details to continue")
        }
        if (this.state.selectedPayeeBankAmountValue.length == 0) {
            return alert("Please Enter Amount to continue")
        }
        else {
            await this.setState({ style: {} });
            const { selectedBanksList, uniquePayeeBanksDropDown, selectedPayeeBankDropDown, selectedPayeeBankAmountValue } = this.state;
            uniquePayeeBanksDropDown.filter((bankItem, i) => {
                if (bankItem.payeebankID == selectedPayeeBankDropDown) {
                    bankItem.amount = selectedPayeeBankAmountValue;
                    selectedBanksList.push(bankItem)
                }
            })

            await this.setState({
                selectedBanksList: selectedBanksList,
                uniquePayeeTypesDropDown: [],
                uniquePayeesDropDown: [],
                uniquePayeeBanksDropDown: [],
                /** Drop Downs Selected Value  */
                selectedPayeeTypeDropDown: "",
                selectedPayeeDropDownName: "",
                selectedPayeeDropDown: "",
                selectedLocationPayeeDropDown: "",
                selectedPayeeBankDropDown: "",
                selectedPayeeBankAmountValue: "",
                style: { display: "none" }
            });
        }
    };

    toggleAddPayeeBankModal = async () => {
        await this.setState({
            isAddPayeeBankModalOpen: !this.state.isAddPayeeBankModalOpen
        });
    }

    toggleEditPayeeBankModal = async () => {
        await this.setState({
            isEditPayeeBankModalOpen: !this.state.isEditPayeeBankModalOpen
        });
    }

    toggleEditPayeeBankAmountModal = async () => {
        await this.setState({
            isEditPayeeBankAmountEditModalOpen: !this.state.isEditPayeeBankAmountEditModalOpen,
            selectedPayeeBankAmountValue: ""
        });
    }

    toggleAddPayeeModal = async () => {
        await this.setState({
            isAddPayeeModalOpen: !this.state.isAddPayeeModalOpen
        });
    }

    handleCheckPrimary = async () => {

        await this.setState({
            addPayeeBankIsPrimary: !this.state.addPayeeBankIsPrimary
        });

        console.log(this.state.addPayeeBankIsPrimary, "addPayeeBankIsPrimary")
    }

    handleCheckCompany = async () => {

        await this.setState({
            addPayeeBankIsCompany: !this.state.addPayeeBankIsCompany
        });

        console.log(this.state.addPayeeBankIsCompany, "addPayeeBankIsCompany")
    }

    getCategoriesData = async () => {
        const data = await axios.post(`${Config.hostName}/bms/get/category-dropdown`, {
            locationId: this.props.auth.user.locationid,
            category: ""
        })
            .then(async (res) => {
                const data = res.data.data;
                // console.log(data, "data")
                // const filteredArray = data.filter(function (item, pos) {
                //     return data.indexOf(item.category) == pos;
                // });
                const filteredArray = data.map(item => item.category)
                    .filter((value, index, self) => self.indexOf(value) === index)

                // console.log(filteredArray);
                // console.log(data, "filteredArray");
                if (data.length > 0) {
                    await this.setState({
                        uniqueCategories: filteredArray
                    });
                }
            }).catch((err) => {
                alert(err);
            });
    }

    changeCategoryAnotherAgain = async () => {
        const data = await axios.post(`${Config.hostName}/bms/get/category-dropdown`, {
            locationId: this.props.auth.user.locationid,
            category: this.state.category
        })
            .then(async (res) => {
                const data = res.data.data;
                // console.log(data, "data")
                const filteredArray = data.map(item => item.items)
                    .filter((value, index, self) => self.indexOf(value) === index)
                // console.log(filteredArray);
                if (data.length > 0) {
                    await this.setState({
                        uniqueItems: filteredArray,
                        isChangeCategory: false,
                        initialFormItems: [],
                        mainCategoryAnother: true
                    });
                    await this.addInitialItemRow();

                }
            }).catch((err) => {
                alert(err);
            });
    }

    changeCategory = async (e) => {
        // console.log(e.target.value)
        if (this.state.mainCategoryAnother) {
            await this.setState({
                category: e.target.value,
                isChangeCategory: true
            });
        }
        else {
            await this.setState({
                category: e.target.value,
                mainCategoryAnother: e.target.value,
                isChangeCategory: false
            });
            await this.changeCategoryAnotherAgain()

        }

    }

    changePriority = async (e) => {
        await this.setState({
            priority: e.target.value
        });
    }

    updateContentApi = async (contentId, data) => {
        // console.log(contentId, JSON.parse(data), "contentId, data");
        // console.log(contentId, JSON.stringify(data), "contentId, data");
        console.log(contentId, data, "contentId, data");
        await axios.post(`${Config.hostName}/bms/update/content`, {
            "bmsId": this.state.bmsId,
            "category": this.state.category,
            "totalAmount": this.state.totalAmount,
            "contentId": contentId,
            "content": data,
            "userId": this.props.auth.user.USERID

        })
            .then(async (response) => {
                // console.log(response.data, "response");
                if (response.data.success === false) {
                    await this.setState({
                        style: { display: "none" }
                    })
                    // await this.props.setAlert(response.data.message, "danger");
                    alert(response.data.message);
                }
                else {
                    await this.getInitialDataByRequest();
                    await this.setState({
                        style: { display: "none" }
                    })
                    // await this.props.setAlert("Succesfully Updated", "success");
                    alert("Succesfully Updated");


                }
            }).catch((error) => {
                // console.log(error, 'error');
                alert(error)
            })
    }

    getTotalPayeeAmount = async () => {
        const { selectedBanksList } = this.state;
        let totalAmount = 0;
        const map = selectedBanksList.map((item) => {
            // console.log(item, 'item');
            totalAmount = totalAmount + Number(item.amount)
        })
        const prom = await Promise.all(map);
        console.log(totalAmount, "totalAmount");
        return totalAmount;
    }

    updateRequest = async (contentId) => {
        console.log(contentId, "contentId")
        await this.setState({ style: {} });

        if (!this.state.category) {
            await this.setState({
                style: { display: "none" }
            })
            return await this.props.setAlert("Category is Required", "danger");
        }
        if (!this.state.priority) {
            await this.setState({
                style: { display: "none" }
            })
            return await this.props.setAlert("Priority is Required", "danger");
        }
        if (!this.state.remarks) {
            await this.setState({
                style: { display: "none" }
            })
            return await this.props.setAlert("Remarks is Required", "danger");
        }
        if (contentId == 6) {
            const totalPayeeAmount = await this.getTotalPayeeAmount();
            if (this.state.totalAmount != totalPayeeAmount) {
                await this.setState({
                    style: { display: "none" }
                })
                return alert("Amount You Spent is mismatching with Payable Amount");
            }
        }
        console.log(contentId, "contentId")

        let newItems = []

        if (contentId == 1) {
            const initialFormItems = this.state.initialFormItems;
            initialFormItems.filter(item => {
                newItems.push({
                    item: item.item,
                    amount: item.amount
                })
            })
        }
        console.log(contentId, "contentId")

        let newAttachments = [];

        if (contentId == 2) {
            // console.log(this.state.attachments, "this.state.attachments");
            const mapAttachments = this.state.attachments.map(attachment => {
                newAttachments.push({
                    AttachmentUrl: attachment.AttachmentUrl
                })
            })
            const promiseAttachments = await Promise.all(mapAttachments);
        }
        console.log(contentId, "contentId")

        const { selectedBanksList } = this.state;

        const data = (contentId == 1) ? newItems :
            (contentId == 2) ? newAttachments :
                (contentId == 3) ? this.state.remarks :
                    (contentId == 6) ? selectedBanksList :
                        "";
        console.log(contentId, data, data.length, "contentId, data")
        await this.updateContentApi(contentId, data);
    }

    addInitialItemRow = async () => {
        const itemsArr = this.state.initialFormItems;
        const newRowObject = {
            id: 1,
            item: "",
            amount: "",
            isDisabled: true,
            itemDropDownName: ""
        }
        itemsArr.push(newRowObject);
        await this.setState({
            initialFormItems: itemsArr
        })
    }

    getItemsByItems = async (cat) => {
        const dataCat = await axios.post(`${Config.hostName}/bms/get/category-dropdown`, {
            locationId: this.props.auth.user.locationid,
            category: cat
        })
            .then(async (res) => {
                const data = res.data.data;
                // console.log(data, "data")
                const filteredArray = data.map(item => item.items)
                    .filter((value, index, self) => self.indexOf(value) === index)
                // console.log(filteredArray);
                if (data.length > 0) {
                    await this.setState({
                        uniqueItems: filteredArray
                    });
                }
            }).catch((err) => {
                alert(err);
            });

    }

    getInitialDataByRequest = async () => {
        const data = await axios.get(`${Config.hostName}/bms/get/details/id/${this.props.match.params.bmsId}`)
            .then(async (res) => {
                const data = res.data.data;
                console.log(data, "getInitialDataByRequest");
                if (data.length > 0) {
                    const dataObj = data[0];

                    const attachments = JSON.parse(dataObj.Jdata).attachments;
                    const map = attachments.map(attachment => {
                        if (Object.keys(attachment).length > 0) {
                            const sdr = attachment.AttachmentUrl.split('/').pop();
                            const spr = sdr.split('_').pop();
                            return (attachment.isUploaded = true, attachment.name = spr, attachment.isAlreadyUploaded = true)
                        }
                    })
                    await this.getItemsByItems(dataObj.category);
                    const itemsNew = JSON.parse(dataObj.Jdata).itemDetails;
                    itemsNew.map((newItem, i) => {
                        let isBoo = this.state.uniqueItems.includes(newItem.item);
                        // console.log(isBoo, "isbbooo", i)
                        if (isBoo) {
                            return (newItem.isDisabled = true, newItem.itemDropDownName = newItem.item, newItem.id = i + 1);
                        }
                        else {
                            return (newItem.isDisabled = false, newItem.itemDropDownName = "Others", newItem.id = i + 1);
                        }

                    })
                    // }))
                    console.log(dataObj.Jdata, "JSON.parse(dataObj.Jdata)")
                    console.log(dataObj.Jdata.BillingDate, "JSON.parse(dataObj.Jdata)")
                    console.log(JSON.parse(dataObj.Jdata), "JSON.parse(dataObj.Jdata)")

                    console.log(JSON.parse(dataObj.Jdata).PayeeDetails, "JSON.parse(dataObj.Jdata)")
                    console.log(JSON.parse(dataObj.Jdata).BillingDate, "JSON.parse(dataObj.Jdata)")
                    const promise = await Promise.all(map)
                    const paymentDetails = JSON.parse(dataObj.Jdata).PayeeDetails;
                    await this.getUniqueLocationsDropDown();
                    const billDate = JSON.parse(dataObj.Jdata).BillingDate
                    await this.setState({
                        bmsId: this.props.match.params.bmsId,
                        mainApiData: dataObj,
                        attachments: attachments,
                        initialFormItems: itemsNew,
                        remarks: JSON.parse(dataObj.Jdata).notes[0].Comments,
                        totalAmount: dataObj.totalAmount,
                        category: dataObj.category,
                        priority: JSON.parse(dataObj.Jdata).priority,
                        remarksDataTotal: JSON.parse(dataObj.Jdata).notes,
                        newCategory: dataObj.category,
                        mainCategoryAnother: true,
                        mainCategory: dataObj.category,
                        bankAccountNumber: JSON.parse(dataObj.Jdata).BankDetails ? JSON.parse(dataObj.Jdata).BankDetails[0].acntNumber : "",
                        bankAccountHolderName: JSON.parse(dataObj.Jdata).BankDetails ? JSON.parse(dataObj.Jdata).BankDetails[0].acntHoldName : "",
                        bankIfscCode: JSON.parse(dataObj.Jdata).BankDetails ? JSON.parse(dataObj.Jdata).BankDetails[0].ifsc : "",
                        selectedBanksList: paymentDetails,
                        selectedLocationDropDown: dataObj.locationid,
                        billingDate: String(billDate)
                        // moment(dataObj.BillingDate).format("YYYY-MM-DD")
                        // selectedClientDropDown:dataObj.ClientName,
                        // selectedSegmentDropDown:dataObj.Segment
                    });
                    await this.getUniqueClientsDropDown();
                    await this.setState({
                        selectedClientDropDown: dataObj.ClientName
                    })
                    await this.getUniqueSegmentsDropDown();
                    await this.setState({
                        selectedSegmentDropDown: dataObj.CCxrefID
                    })
                    console.log(this.state.billingDate, "this.state.billingDate");
                }
            }).catch((err) => {
                alert(err)
            });
    }

    componentDidMount = async () => {
        await this.getCategoriesData();
        // await this.getUniqueLocationsDropDown();
        // await this.addInitialItemRow();
        await this.getInitialDataByRequest();
        await this.setState({
            style: { display: "none" }
        })
    }

    addNewItem = async () => {
        const itemsArr = this.state.initialFormItems;
        const newRowObject = {
            id: itemsArr.length + 1,
            item: "",
            amount: "",
            isDisabled: true,
            itemDropDownName: ""
        }
        itemsArr.push(newRowObject);
        await this.setState({
            initialFormItems: itemsArr
        })
    };

    formControlChangeItemName = async (id, e) => {
        const value = e.target.value;
        const { initialFormItems } = this.state;
        // console.log(value, id, "formName");
        initialFormItems.filter((item) => {
            if (item.id == id) {
                return item.item = value
            }
        })
        await this.setState({
            initialFormItems: initialFormItems
        })
    }

    getTotalAmount = async () => {
        const { initialFormItems } = this.state;
        let totalAmount = 0;
        const map = initialFormItems.map((item) => {
            // console.log(item, 'item');
            totalAmount = totalAmount + Number(item.amount)
        })
        const prom = await Promise.all(map);
        await this.setState({
            totalAmount: totalAmount
        })
        // console.log(this.state.totalAmount, "this.state.totalAmount");
    }

    formControlChangeItemAmount = async (id, e) => {
        const value = e.target.value;
        let { initialFormItems } = this.state;
        // console.log(value, id, "formAmount");
        initialFormItems.filter((item) => {
            if (item.id == id) {
                return (
                    item.amount = Number(value)
                )
            }
        })
        await this.getTotalAmount()
        await this.setState({
            initialFormItems: initialFormItems
        })
    }

    deleteExtraItem = async (id) => {
        const { initialFormItems } = this.state;
        // console.log(id, "deleteExtraItem");
        if (initialFormItems.length > 1) {
            initialFormItems.filter((item) => {

                if (item.id == id) {
                    const index = initialFormItems.indexOf(item);
                    if (index > -1) {
                        initialFormItems.splice(index, 1);
                    }
                }
            })
            await this.setState({
                initialFormItems: initialFormItems
            })
            await this.getTotalAmount();

        }
        else {
            await this.props.setAlert("Min 1 Item is required", "danger")
        }
    }

    getUrlToAttachments = async (url, name) => {
        const { attachments } = this.state;
        attachments.filter(attachment => {
            if (attachment.name == name) {
                return attachment.AttachmentUrl = url
            }
        })
        await this.setState({
            attachments: attachments
        })
    }

    uploadFileAndGetUrl = async (formData) => {
        console.log("uploading formdata")
        const fileData = await axios({
            method: "POST",
            url: `${Config.hostName}/unique/upload/any-file`,
            timeout: 3 * 60 * 1000,
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*"
            },
            data: formData,
        });
        // console.log(fileData, "urlFromAws")
        if (fileData.data.success == true) {

            return fileData;
        }
        else {
            alert(fileData.data.message)
        }
    }

    singleUploadFile = async (file) => {
        const reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = async (e) => {
            await this.setState({
                selectedFile: [reader.result],
            });
        }
        // console.log(url); // Would see a path?
        const fileType = file.type.substring(0, 5);
        await this.setState({ selectedFile: file, selectedFileType: fileType });
        const formData = new FormData();
        formData.append(
            "media",
            this.state.selectedFile,
            this.state.selectedFile.name
        );
        // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
        // console.log(formData, "formData", fileType);
        const res = await this.uploadFileAndGetUrl(formData);
        const urlFromAws = res.data.url;
        // const urlFromAws = "https://logicarts.s3.ap-south-1.amazonaws.com/clientlogos/capp_truck.png";
        await this.getUrlToAttachments(urlFromAws, file.name);
        const { attachments } = this.state;
        // setTimeout(async () => {
        attachments.filter(att => {
            if (att.name == file.name && att.isUploaded == false) {
                return att.isUploaded = true
            }
        })
        await this.setState({
            attachments: attachments
        })
        // }, 2000);
        // console.log(this.state.attachments, "data")
    }

    onFileUpload = async (event) => {
        // console.log(event.target.files, "event.target.files");
        const filesObj = event.target.files;
        const filesObjKeys = Object.keys(filesObj);
        const { attachments } = this.state;
        const multiMapFileNames = filesObjKeys.map(async (fileKey, index) => {
            attachments.push({
                name: filesObj[fileKey].name,
                AttachmentUrl: "",
                isUploaded: false
            })
        })
        const multiPromiseFileNames = await Promise.all(multiMapFileNames);
        await this.setState({
            attachments: attachments
        })
        // const multiMap = filesObjKeys.map(async (fileKey, i) => {
        //     const numberRes = filesObjKeys.length - 1;
        //     console.log(numberRes, i, "ohio");
        //     // if (i < numberRes) {
        //     //     console.log("SeetttInterval", new Date())
        //     //     this.timer = setInterval(async () => {
        //     await this.singleUploadFile(filesObj[fileKey]);
        //     // }, 2000);
        //     // }
        //     // else {
        //     //     console.log("clearing interval");
        //     //     clearInterval(this.timer)
        //     // }

        // })
        // const multiPromise = await Promise.all(multiMap);
        for (let i = 0; i < filesObjKeys.length; i++) {
            // console.log(i, "i")
            const item = filesObj[i];
            await this.singleUploadFile(item);
        }

    };

    deleteAttachment = async (name) => {
        const { attachments } = this.state;
        // console.log(name, "deleteAttachment");
        attachments.filter((item) => {

            if (item.name == name) {
                const index = attachments.indexOf(item);
                if (index > -1) {
                    attachments.splice(index, 1);
                }
            }
        })
        await this.setState({
            attachments: attachments
        })
    }

    onChangeItemDropDown = async (e, item) => {
        console.log(e.target.value, item);
        const { initialFormItems } = this.state;
        initialFormItems.filter((itemForm, i) => {
            if (itemForm.id == item.id) {
                // console.log("id matched");
                if (e.target.value == "Others") {
                    // console.log("others");
                    return (itemForm.item = "", itemForm.isDisabled = false, itemForm.itemDropDownName = "Others")
                }
                else {
                    return (itemForm.item = e.target.value, itemForm.isDisabled = true, itemForm.itemDropDownName = e.target.value)
                }
            }
        })
        // console.log(initialFormItems, 'initialFormItems')
        await this.setState({
            initialFormItems: initialFormItems
        })
    }


    render() {
        const {
            style, totalAmount, initialFormItems, attachments, selectedFileType, isAttachmentsLoading,
            isChangeCategoryModal, isChangeCategory, mainCategoryAnother, selectedBanksList,
            isAddPayeeBankModalOpen, isAddPayeeModalOpen,
            addPayeeAttachments, isEditPayeeBankModalOpen, isEditPayeeBankAmountEditModalOpen, selectedPayeeDropDownName
        } = this.state;
        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;

        return (
            <div>
                <div>
                    <div id='cover-spin' style={style}>
                    </div>

                    <div className="container-fluid">
                        <div className="edit-profile">
                            <div className="row ">
                                <div className="col-xl-12">
                                    <Alert />

                                    <nav
                                        className="card-body "
                                        style={{ backgroundColor: "none" }}
                                    >
                                        <div style={{ paddingTop: "8px", width: "100%" }}>

                                            <div className="row ">

                                                <div className="col-sm-3">
                                                    <FormGroup>
                                                        <Input
                                                            type="select"
                                                            name="category"
                                                            required
                                                            value={this.state.category}
                                                            id="category"
                                                            onChange={this.changeCategory}
                                                        >
                                                            <option value={""} disabled selected>Select Category</option>

                                                            {this.state.uniqueCategories.map(categoryItem => (
                                                                <option value={categoryItem}>
                                                                    {categoryItem}

                                                                </option>
                                                            ))}
                                                        </Input>
                                                    </FormGroup>
                                                </div>

                                                <div className="col-sm-3">
                                                    <FormGroup>
                                                        <Input
                                                            required
                                                            type="select"
                                                            name="priority"
                                                            value={this.state.priority}
                                                            id="priority"
                                                            onChange={this.changePriority}
                                                        >
                                                            <option value={""} disabled selected>Select Priority</option>

                                                            <option value="P1 (Today)">P1-Today</option>
                                                            <option value="P2 (3 Days)">P2-3 Days</option>
                                                            <option value="P3 (1 Week)">P3-1 Week</option>
                                                        </Input>
                                                    </FormGroup>
                                                </div>

                                                <div className="col-sm-3">
                                                    <FormGroup>
                                                        {/* <Input */}
                                                        <input
                                                            type="date"
                                                            value={this.state.billingDate}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    billingDate: e.target.value,
                                                                });
                                                            }}
                                                            disabled
                                                            className="form-control billingDateCalenderDate"
                                                            placeholder="Select Billing Date"
                                                        />
                                                        {/* </Input> */}
                                                    </FormGroup>
                                                </div>

                                            </div>

                                            {/* Project Details */}

                                            <div className="bms-create-req-items-main">

                                                <div className="bms-create-req-items-main-label">
                                                    <span className="bms-create-req-items-label">Project Details : </span>
                                                </div>

                                                <div className="row ">

                                                    <div className="col-sm-3">
                                                        <FormGroup>
                                                            <Input
                                                                type="select"
                                                                name="locationid"
                                                                value={this.state.selectedLocationDropDown}
                                                                id="locationid"
                                                                onChange={this.changeLocation}
                                                                disabled
                                                            >
                                                                <option value={""} disabled selected>Select Location</option>

                                                                {this.state.uniqueLocationsDropDown.length > 1 && this.state.uniqueLocationsDropDown.map(locationid => (

                                                                    <option value={locationid.Locationid}>
                                                                        {locationid.name}

                                                                    </option>
                                                                ))}
                                                            </Input>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <FormGroup>
                                                            <Input
                                                                type="select"
                                                                name="clientName"
                                                                value={this.state.selectedClientDropDown}
                                                                id="clientName"
                                                                disabled
                                                                onChange={this.changeClient}
                                                            >
                                                                <option value={""} disabled selected>Select Client</option>

                                                                {this.state.uniqueClientsDropDown.map(client => (
                                                                    <option value={client.ClientName}>
                                                                        {client.ClientName}
                                                                    </option>
                                                                ))}

                                                            </Input>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <FormGroup>
                                                            <Input
                                                                type="select"
                                                                name="routeNo"
                                                                value={this.state.selectedSegmentDropDown}
                                                                id="routeNo"
                                                                disabled
                                                                onChange={this.changeSegment}
                                                            >
                                                                <option value={""} disabled selected>Select Segment</option>

                                                                {this.state.uniqueSegmentsDropDown.map(segment => (
                                                                    <option value={segment.CCxrefID}>
                                                                        {segment.Segment}
                                                                    </option>
                                                                ))}

                                                                {/* <option value={0}>All Segments</option> */}
                                                            </Input>
                                                        </FormGroup>
                                                    </div>

                                                </div>

                                            </div>

                                            {/* Item Details */}

                                            <div className="bms-create-req-items-main">
                                                <div className="row bms-create-req-items-main-label">
                                                    {/* <span className="bms-create-req-items-label">Item Details : </span> */}
                                                    <div className="col-sm-2">
                                                        <span className="bms-create-req-items-label">Item Details : </span>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-danger overflow-hidden mb-3 px-auto"
                                                            value={1}
                                                            onClick={() => this.updateRequest(1)}
                                                        >
                                                            Update
</button>
                                                    </div>
                                                </div>


                                                <div className="form-row">
                                                    {initialFormItems.map((item, i) => {

                                                        return <div key={i} className="form-group col-md-12 col-md-12 row showcase_row_area">

                                                            <div className="col-md-3">
                                                                <FormGroup>
                                                                    <Input
                                                                        type="select"
                                                                        name="item"
                                                                        required
                                                                        value={item.itemDropDownName}
                                                                        onChange={(e) => this.onChangeItemDropDown(e, item)}
                                                                    >
                                                                        <option value={""} disabled selected>Select Item</option>

                                                                        {this.state.uniqueItems.map(Item => (
                                                                            <option value={Item}>
                                                                                {Item}
                                                                            </option>
                                                                        ))}
                                                                    </Input>
                                                                </FormGroup>
                                                            </div>


                                                            <div className="col-md-4 showcase_text_area">
                                                                <input
                                                                    placeholder="Name"
                                                                    formControlName={item.id}
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={item.item}
                                                                    disabled={item.isDisabled}
                                                                    onChange={(e) => this.formControlChangeItemName(item.id, e)}
                                                                />
                                                            </div>

                                                            <div className="col-md-3 showcase_text_area">
                                                                <input
                                                                    placeholder="Amount"
                                                                    formControlName={item.id}
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={item.amount}
                                                                    onChange={(e) => this.formControlChangeItemAmount(item.id, e)}
                                                                />
                                                            </div>
                                                            {(i + 1 === initialFormItems.length) &&
                                                                <div className="col-sm-1 ml-auto">
                                                                    <button
                                                                        type="submit"
                                                                        // style={{ backgroundColor: "#FF4848", color: "#ffffff" }}
                                                                        className="btn btn-success overflow-hidden mb-3 px-auto"
                                                                        onClick={this.addNewItem}
                                                                    >
                                                                        <AddIcon />
                                                                    </button>
                                                                </div>
                                                            }

                                                            <div className="col-sm-1 ml-auto">
                                                                <button
                                                                    type="submit"
                                                                    // style={{ backgroundColor: "#FF4848", color: "#ffffff" }}
                                                                    className="btn btn-danger overflow-hidden mb-3 px-auto"
                                                                    onClick={() => this.deleteExtraItem(item.id)}
                                                                >
                                                                    <CloseIcon />

                                                                </button>

                                                            </div>
                                                        </div>
                                                    })}


                                                </div>

                                            </div>

                                            {/* Attachments Details */}

                                            <div>

                                                <div className="row ">

                                                    <div className="bms-create-req-attachments-main">

                                                        <span className="bms-create-req-attachments-label">Attachments : </span>

                                                        <div className="col-sm-2">

                                                            <label className="custom-file-upload-edit-req">
                                                                {/* <button
                type="submit"
                className="btn bms-attachments-card-button ml-auto"
            > */}
                                                                <input
                                                                    name="Upload"
                                                                    type="file"
                                                                    className="bms-attachments-card-button ml-auto"
                                                                    multiple
                                                                    onChange={this.onFileUpload}
                                                                />
                                                                <CloudUploadIcon />  Upload

        {/* </button> */}
                                                            </label>

                                                        </div>

                                                        <div className="col-sm-2">

                                                            <button
                                                                type="submit"
                                                                // style={{ backgroundColor: "#FF4848", color: "#ffffff" }}
                                                                className="btn btn-danger overflow-hidden mb-3 px-auto"
                                                                value={2}
                                                                onClick={() => this.updateRequest(2)}
                                                            >
                                                                Update
</button>
                                                        </div>

                                                    </div>

                                                </div>

                                                <div className="row">

                                                    <div className="bms-create-request-attachments">
                                                        {attachments.length > 0 && attachments.map((item, i) => {
                                                            return <div key={i} className="row bms-create-request-attachments-main-card">
                                                                <div key={i} className="bms-attachments-card">
                                                                    <span className="bms-attachments-card-item">

                                                                        <div className='bms-attachments-card-item-file-name-main'>
                                                                            <span className="bms-attachments-card-item-file-name">{item.name}</span>
                                                                            <span
                                                                                className="bms-attachments-card-button-delete"
                                                                                onClick={() => this.deleteAttachment(item.name)}
                                                                            >
                                                                                <DeleteIcon />
                                                                            </span>
                                                                        </div>

                                                                        {!item.isUploaded &&
                                                                            <span className="bms-attachments-card-progress-bar-loader">
                                                                                <LinearProgress />
                                                                            </span>
                                                                        }

                                                                        {item.isUploaded && !item.isAlreadyUploaded && <span className="bms-attachments-card-item-file-success-status">File Uploaded Succesfully</span>}
                                                                    </span>

                                                                </div>
                                                            </div>

                                                        })}

                                                    </div>
                                                </div>

                                            </div>

                                            {/** Payee Details */}

                                            <div className="bms-view-req-items-main-container">

                                                <div className="bms-view-req-items-main" style={{ display: "flex", flexDirection: "row" }}>
                                                    <div className="bms-view-req-items-main-label">
                                                        <span className="bms-view-req-items-label">Payee Details : </span>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-danger overflow-hidden mb-3 px-auto"
                                                            value={6}
                                                            onClick={() => this.updateRequest(6)}
                                                        >
                                                            Update
</button>
                                                    </div>
                                                </div>

                                                <div className="row ">

                                                    {/** Select Payee Location */}
                                                    <div className="col-sm-3">
                                                        <FormGroup>
                                                            <Input
                                                                type="select"
                                                                name="locationid"
                                                                value={this.state.selectedLocationPayeeDropDown}
                                                                id="locationid"
                                                                onChange={this.changeLocationPayee}
                                                            >
                                                                <option value={""} disabled selected>Select Payee Location</option>

                                                                {this.state.uniqueLocationsDropDown.length > 1 && this.state.uniqueLocationsDropDown.map(locationid => (

                                                                    <option value={locationid.Locationid}>
                                                                        {locationid.name}

                                                                    </option>
                                                                ))}
                                                            </Input>
                                                        </FormGroup>
                                                    </div>
                                                    {/** Select Payee Type */}
                                                    <div className="col-sm-3">
                                                        <FormGroup>
                                                            <Input
                                                                type="select"
                                                                name="payeeType"
                                                                value={this.state.selectedPayeeTypeDropDown}
                                                                id="payeeType"
                                                                onChange={this.changePayeeType}
                                                            >
                                                                <option value={""} disabled selected>Select Payee Type</option>
                                                                {this.state.uniquePayeeTypesDropDown.map(payeeTypeItem => (
                                                                    <option value={payeeTypeItem.PMStypeID}>
                                                                        {payeeTypeItem.PMSType}
                                                                    </option>
                                                                ))}

                                                            </Input>
                                                        </FormGroup>
                                                    </div>
                                                    {/** Select Payee Name*/}
                                                    <div className="col-sm-3">
                                                        <FormGroup>
                                                            <Input
                                                                type="select"
                                                                name="payee"
                                                                value={this.state.selectedPayeeDropDown}
                                                                id="payee"
                                                                onChange={this.changePayee}
                                                            >
                                                                <option value={""} disabled selected>Select Payee Name </option>
                                                                {this.state.uniquePayeesDropDown.map(payeeItem => (
                                                                    <option value={payeeItem.PMSID}>
                                                                        {payeeItem.fullName}
                                                                    </option>
                                                                ))}
                                                                {this.state.selectedPayeeTypeDropDown.length > 0 && <option value={88888} > + Add Payee </option>}


                                                            </Input>
                                                        </FormGroup>
                                                    </div>
                                                    {/** Select Payee Bank */}
                                                    <div className="col-sm-3">
                                                        <FormGroup>
                                                            <Input
                                                                type="select"
                                                                name="payeeBank"
                                                                value={this.state.selectedPayeeBankDropDown}
                                                                id="payeeBank"
                                                                onChange={this.changePayeeBank}
                                                            >
                                                                <option value={""} disabled selected>Select Payee Bank</option>
                                                                {this.state.uniquePayeeBanksDropDown.map(payeeBankItem => (
                                                                    <option key={payeeBankItem.payeebankID} value={payeeBankItem.payeebankID} className="pmsPayeeBankDropdownValue">
                                                                        {payeeBankItem.bankname}&nbsp;-&nbsp;{payeeBankItem.AccountNumber}
                                                                        {/* <span><EditIcon /></span> */}
                                                                    </option>
                                                                ))}
                                                                {this.state.selectedPayeeDropDown.length > 0 && <option value={99999} > + Add Payee Bank </option>}

                                                            </Input>
                                                        </FormGroup>
                                                    </div>
                                                    {/** Enter Amount */}
                                                    <div className="col-sm-3 showcase_text_area">
                                                        <input
                                                            placeholder="Enter Amount"
                                                            formControlName={this.state.selectedPayeeBankAmountValue}
                                                            type="number"
                                                            className="form-control"
                                                            value={this.state.selectedPayeeBankAmountValue}
                                                            onChange={this.changePayeeBankAmountValue}
                                                        />
                                                    </div>
                                                    {/** Add Payee Details Button */}
                                                    <div className="col-sm-3 ml-5">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-danger overflow-hidden mb-3 px-auto createNewPayeeSelectedBankCard"
                                                            onClick={e => this.createNewPayeeSelectedBankCard(e)}
                                                        >
                                                            <AddIcon /> Add
        </button>

                                                    </div>

                                                </div>

                                                <div className="row bms-view-req-payee-details-card-view-main-container">
                                                    {selectedBanksList.length > 0 && selectedBanksList.map((bankListItem, i) => {

                                                        return (<div key={i} className="card col-sm-4 bmsCreateReqPayeeDetailsCardView">
                                                            {/* BankId: {bankListItem.payeebankID} */}
                                                            <div className="bmsCreateReqPayeeDetailsCardViewLeft">
                                                                <img className="bmsCreateReqPayeeDetailsCardViewLeftBankIcon" src={BankIcon} alt={i} width="50" height="60" />
                                                            </div>

                                                            <div className="row bmsCreateReqPayeeDetailsCardViewRight">

                                                                <div className="bmsViewReqPayeeDetailsCardViewRightName">
                                                                    <span className="bmsViewReqPayeeDetailsCardViewRightNameValue">
                                                                        Name: {bankListItem.fullname ? bankListItem.fullname : " - "}
                                                                    </span>
                                                                </div>

                                                                <div className="bmsViewReqPayeeDetailsCardViewRightBenefeciaryName">
                                                                    <span className="bmsViewReqPayeeDetailsCardViewRightBenefeciaryNameValue">
                                                                        Benefeciary Name: {bankListItem.beneficiaryName ? bankListItem.beneficiaryName : " - "}
                                                                    </span>
                                                                </div>

                                                                <div className="bmsCreateReqPayeeDetailsCardViewRightAccountNumber">
                                                                    <span className="bmsCreateReqPayeeDetailsCardViewRightAccountNumberValue">
                                                                        A/C No: {bankListItem.AccountNumber ? bankListItem.AccountNumber : " - "}
                                                                    </span>
                                                                </div>
                                                                <div className="bmsCreateReqPayeeDetailsCardViewRightBankName">
                                                                    <span className="bmsCreateReqPayeeDetailsCardViewRightBankNameValue">
                                                                        Bank : {bankListItem.bankname ? bankListItem.bankname : " - "}
                                                                    </span>
                                                                </div>
                                                                <div className="bmsCreateReqPayeeDetailsCardViewRightIfsc">
                                                                    <span className="bmsCreateReqPayeeDetailsCardViewRightIfscValue">
                                                                        IFSC : {bankListItem.IFSC ? bankListItem.IFSC : " - "}
                                                                    </span>
                                                                </div>

                                                                {/* <div className="bmsCreateReqPayeeDetailsCardViewRightBillingDate">
                    <span className="bmsCreateReqPayeeDetailsCardViewRightBillingDateValue">
                        Bill Date : {bankListItem.BillingDate ? bankListItem.BillingDate : " - "}
                    </span>
                </div> */}

                                                                <div className="bmsCreateReqPayeeDetailsCardViewRightAmount">
                                                                    <span className="bmsCreateReqPayeeDetailsCardViewRightAmountValue">
                                                                        Amount : {bankListItem.amount ? bankListItem.amount : " - "}
                                                                    </span>
                                                                    <span className="bmsCreateReqPayeeDetailsCardViewRightMostEditIconAmount" onClick={() => this.editPayeeBankAmount(bankListItem.payeebankID)}>
                                                                        <EditIcon color="secondary" fontSize="small" />
                                                                    </span>
                                                                </div>

                                                            </div>

                                                            <div className="bmsCreateReqPayeeDetailsCardViewRightMost">
                                                                <div className="bmsCreateReqPayeeDetailsCardViewRightMostEditIcon" onClick={() => this.editPayeeBank(bankListItem.payeebankID)}>
                                                                    <EditIcon color="danger" />
                                                                </div>
                                                                <div className="bmsCreateReqPayeeDetailsCardViewRightMostCloseIcon" onClick={() => this.deletePayeeBank(bankListItem.payeebankID)}>
                                                                    <CloseIcon color="danger" />
                                                                </div>
                                                            </div>

                                                        </div>)
                                                    })
                                                    }
                                                </div>

                                            </div>

                                            <AvForm >
                                                {/* Total Amount */}
                                                <div className="col-md-4">
                                                    <AvField name="totalAmount"
                                                        label="Total Amount"
                                                        value={this.state.totalAmount}
                                                        disabled
                                                        onChange={(e) => {
                                                            this.setState({
                                                                totalAmount: e.target.value
                                                            })
                                                        }}
                                                        labelClass="bms-create-req-total-amount"
                                                        type="number"
                                                        placeholder="Total Amount"
                                                    />
                                                </div>
                                                {this.state.remarksDataTotal.length == 1 && <div className="row bms-create-request-remarks-main">
                                                    {/* Remarks */}
                                                    <div className="col-sm-9">
                                                        <AvField
                                                            name="Remarks"
                                                            label="Remarks"
                                                            value={this.state.remarks}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    remarks: e.target.value
                                                                })
                                                            }}
                                                            type="textarea"
                                                            labelClass="bms-create-req-remarks"
                                                            placeholder="Remarks"
                                                        />
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <button
                                                            type="submit"
                                                            // style={{ backgroundColor: "#FF4848", color: "#ffffff" }}
                                                            className="btn btn-danger overflow-hidden bms-create-req-remarks-save-button"
                                                            value={3}
                                                            onClick={() => this.updateRequest(3)}
                                                        >
                                                            <SaveIcon /> Save
</button>
                                                    </div>

                                                </div>
                                                }
                                            </AvForm>

                                            <div>

                                                <Modal
                                                    size="lg"
                                                    // style={{ maxWidth: '1600px', width: '70%' }}
                                                    centered={true}
                                                    isOpen={isChangeCategoryModal}
                                                >
                                                    <ModalHeader>Do you want to Change Category </ModalHeader>
                                                    <ModalFooter>
                                                        <Button
                                                            color="success"
                                                            onClick={
                                                                async () => {
                                                                    await this.updateRequest(1);
                                                                    await this.setState({
                                                                        isChangeCategoryModal: false,
                                                                        newCategory: this.state.category,
                                                                        style: {
                                                                            display: "none"
                                                                        }
                                                                    });
                                                                }
                                                            }                                                        >
                                                            Yes
                                                </Button>{" "}
                                                        <Button
                                                            color="danger"
                                                            onClick={
                                                                async () => {
                                                                    await this.setState({
                                                                        isChangeCategoryModal: false,
                                                                        category: this.state.newCategory
                                                                    })
                                                                }
                                                            }
                                                        >
                                                            No
                                                </Button>{" "}
                                                    </ModalFooter>

                                                </Modal>

                                            </div>

                                            {/* isChangeCategory */}
                                            <div>
                                                <Modal
                                                    size="lg"
                                                    // style={{ maxWidth: '1600px', width: '70%' }}
                                                    centered={true}
                                                    isOpen={isChangeCategory}
                                                >
                                                    <ModalHeader>Do you want to Change the Category ? It will clear all Items you entered</ModalHeader>
                                                    <ModalFooter>
                                                        <Button
                                                            color="success"
                                                            onClick={this.changeCategoryAnotherAgain}
                                                        >
                                                            Yes
                                                </Button>{" "}
                                                        <Button
                                                            color="danger"
                                                            onClick={async () => {
                                                                await this.setState({
                                                                    isChangeCategory: false,

                                                                })
                                                            }}

                                                        >
                                                            No
                                                </Button>{" "}
                                                    </ModalFooter>

                                                </Modal>

                                            </div>

                                            {/* Modal For Add Bank */}

                                            <div>
                                                {isAddPayeeBankModalOpen && <Modal
                                                    // size="lg"
                                                    // style={{ maxWidth: '1600px', width: '70%' }}
                                                    centered={true}
                                                    isOpen={isAddPayeeBankModalOpen}
                                                // toggle={this.toggleAddPayeeBankModal}
                                                >
                                                    <ModalFooter>

                                                        <div className="col-sm-3">
                                                            <span className="bms-view-req-modal-header-label">PmsId : {this.state.selectedPayeeDropDown}</span>
                                                        </div>
                                                        <div className="col-sm-3 ml-auto">
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <Button
                                                                        color="danger"
                                                                        onClick={this.toggleAddPayeeBankModal}
                                                                    >
                                                                        <CloseIcon />
                                                                    </Button>{" "}
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </ModalFooter>

                                                    <ModalBody>
                                                        <div className="bms-view-req-modal-body">

                                                            <form >
                                                                <div
                                                                    className="col-lg-12 text-right"
                                                                    style={{ paddingTop: "4vh" }}
                                                                >
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="grid">
                                                                        <div className="grid-body">
                                                                            <div className="item-wrapper">
                                                                                <div className="row mb-3">
                                                                                    <div className="col-md-12 mx-auto">


                                                                                        <div className="form-row">
                                                                                            <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>Bank Name</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter Bank Name"
                                                                                                        formControlName="addPayeeBankBankName"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={this.state.addPayeeBankBankName}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                addPayeeBankBankName: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="form-group col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>Account No</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter Account No"
                                                                                                        formControlName=" addPayeeBankAccountNumber"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={this.state.addPayeeBankAccountNumber}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                addPayeeBankAccountNumber: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="form-row">
                                                                                            <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>IFSC Code</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter IFSC Code"
                                                                                                        formControlName="addPayeeBankIfsc"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={this.state.addPayeeBankIfsc}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                addPayeeBankIfsc: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="form-group col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>Benificary Name</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter Benificary Name"
                                                                                                        formControlName="addPayeeBankBeneficiaryName"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={this.state.addPayeeBankBeneficiaryName}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                addPayeeBankBeneficiaryName: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        {/* <div className="col-sm-4">
                                                                                            <FormGroup check>
                                                                                                <Label check>
                                                                                                    <Input
                                                                                                        type="checkbox"
                                                                                                        onChange={this.handleCheckPrimary}
                                                                                                        // value={this.state.IsOdometer}
                                                                                                        checked={this.state.addPayeeBankIsPrimary}
                                                                                                    />{" "}
                                                                                                    Is Primary
                                                                                                </Label>
                                                                                            </FormGroup>
                                                                                        </div> */}

                                                                                        <button
                                                                                            type="submit"
                                                                                            className="btn btn-outline-primary btn-lg"
                                                                                            style={{ float: "right" }}
                                                                                            onClick={e => this.createPayeeBank(e)}
                                                                                        >
                                                                                            Create
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </ModalBody>


                                                </Modal>
                                                }
                                            </div>

                                            {/* Modal For Add Payee */}

                                            <div>
                                                {isAddPayeeModalOpen && <Modal
                                                    // size="lg"
                                                    style={{ maxWidth: '1600px', width: '70%' }}
                                                    centered={true}
                                                    isOpen={isAddPayeeModalOpen}
                                                // toggle={this.toggleAddPayeeBankModal}
                                                >
                                                    <ModalFooter>

                                                        <div className="col-sm-3">
                                                            <span className="bms-view-req-modal-header-labelAddPayee">Add Payee Details</span>
                                                            {/* <span className="bms-view-req-modal-header-label">PmsTypeId : {this.state.selectedPayeeTypeDropDown}</span> */}
                                                        </div>
                                                        <div className="col-sm-3 ml-auto">
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <Button
                                                                        color="danger"
                                                                        onClick={this.toggleAddPayeeModal}
                                                                    >
                                                                        <CloseIcon />
                                                                    </Button>{" "}
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </ModalFooter>

                                                    <ModalBody>
                                                        <div className="bms-view-req-modal-body">

                                                            <form >
                                                                <div
                                                                    className="col-lg-12 text-right"
                                                                    style={{ paddingTop: "4vh" }}
                                                                >
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="grid">
                                                                        <div className="grid-body">
                                                                            <div className="item-wrapper">
                                                                                <div className="row mb-3">
                                                                                    <div className="col-md-12 mx-auto">
                                                                                        {/** Name, Email */}
                                                                                        <div className="form-row">
                                                                                            <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>Name</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter Name"
                                                                                                        formControlName="addPayeeName"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={this.state.addPayeeName}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                addPayeeName: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="form-group col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>Email</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter Email"
                                                                                                        formControlName="addPayeeEmail"
                                                                                                        type="email"
                                                                                                        className="form-control"
                                                                                                        value={this.state.addPayeeEmail}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                addPayeeEmail: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/** Contact, Contact2 */}
                                                                                        <div className="form-row">
                                                                                            <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>Mobile</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter Mobile No"
                                                                                                        formControlName="addPayeeContactNumber"
                                                                                                        type="number"
                                                                                                        className="form-control"
                                                                                                        value={this.state.addPayeeContactNumber}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                addPayeeContactNumber: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="form-group col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>Contact</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter Alternate Mobile No"
                                                                                                        formControlName="addPayeeContactNumberAlternate"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={this.state.addPayeeContactNumberAlternate}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                addPayeeContactNumberAlternate: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        {/* Attachments Details */}

                                                                                        <div>

                                                                                            <div className="row ">

                                                                                                <div className="bms-create-req-attachments-main">

                                                                                                    <span className="bms-create-req-attachments-label">Attachments : </span>

                                                                                                    <label className="custom-file-upload">
                                                                                                        {/* <button
                                                                type="submit"
                                                                className="btn bms-attachments-card-button ml-auto"
                                                            > */}
                                                                                                        <input
                                                                                                            name="Upload"
                                                                                                            type="file"
                                                                                                            className="bms-attachments-card-button ml-auto"
                                                                                                            multiple
                                                                                                            onChange={this.onFileUploadAddPayee}
                                                                                                        />
                                                                                                        <CloudUploadIcon />  Upload

                                                        {/* </button> */}
                                                                                                    </label>

                                                                                                </div>

                                                                                            </div>

                                                                                            <div className="row">

                                                                                                <div className="bms-create-request-attachments">
                                                                                                    {addPayeeAttachments.length > 0 && addPayeeAttachments.map((item, i) => {
                                                                                                        return <div key={i} className="row bms-create-request-attachments-main-card">
                                                                                                            <div key={i} className="bms-attachments-card">
                                                                                                                <span className="bms-attachments-card-item">

                                                                                                                    <div className='bms-attachments-card-item-file-name-main'>
                                                                                                                        <span className="bms-attachments-card-item-file-name">{item.name}</span>
                                                                                                                        <span
                                                                                                                            className="bms-attachments-card-button-delete"
                                                                                                                            onClick={() => this.deleteAttachmentAddPayee(item.name)}
                                                                                                                        >
                                                                                                                            <DeleteIcon />
                                                                                                                        </span>
                                                                                                                    </div>

                                                                                                                    {!item.isUploaded &&
                                                                                                                        <span className="bms-attachments-card-progress-bar-loader">
                                                                                                                            <LinearProgress />
                                                                                                                        </span>
                                                                                                                    }

                                                                                                                    {item.isUploaded && <span className="bms-attachments-card-item-file-success-status">File Uploaded Succesfully</span>}
                                                                                                                </span>

                                                                                                            </div>
                                                                                                        </div>

                                                                                                    })}

                                                                                                </div>
                                                                                            </div>

                                                                                        </div>


                                                                                        {/** Bank Details */}
                                                                                        <div className="form-row">
                                                                                            <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>Bank Name</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter Bank Name"
                                                                                                        formControlName="addPayeeBankName"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={this.state.addPayeeBankName}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                addPayeeBankName: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="form-group col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>Account No</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter Account No"
                                                                                                        formControlName=" addPayeeAccountNumber"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={this.state.addPayeeAccountNumber}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                addPayeeAccountNumber: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/** Bank Details */}
                                                                                        <div className="form-row">
                                                                                            <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>IFSC Code</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter IFSC Code"
                                                                                                        formControlName="addPayeeIfsc"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={this.state.addPayeeIfsc}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                addPayeeIfsc: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="form-group col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>Benificary Name</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter Benificary Name"
                                                                                                        formControlName="addPayeeBeneficiaryName"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={this.state.addPayeeBeneficiaryName}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                addPayeeBeneficiaryName: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-sm-4">
                                                                                            <FormGroup check>
                                                                                                <Label check>
                                                                                                    <Input
                                                                                                        type="checkbox"
                                                                                                        onChange={this.handleCheckCompany}
                                                                                                        // value={this.state.IsOdometer}
                                                                                                        checked={this.state.addPayeeBankICompany}
                                                                                                    />{" "}
                                                                                                    IsCompany
                                                                                                </Label>
                                                                                            </FormGroup>
                                                                                        </div>

                                                                                        <button
                                                                                            type="submit"
                                                                                            className="btn btn-outline-primary btn-lg"
                                                                                            // style={{ float: "right" }}
                                                                                            onClick={e => this.createPayee(e)}
                                                                                        >
                                                                                            Add
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </ModalBody>


                                                </Modal>
                                                }
                                            </div>

                                            {/* Modal For Edit Bank */}

                                            <div>
                                                {isEditPayeeBankModalOpen && <Modal
                                                    size="lg"
                                                    style={{ maxWidth: '1600px', width: '70%' }}
                                                    centered={true}
                                                    isOpen={isEditPayeeBankModalOpen}
                                                // toggle={this.toggleAddPayeeBankModal}
                                                >
                                                    <ModalFooter>

                                                        <div className="col-sm-3">
                                                            <span className="bms-view-req-modal-header-label">Payee : {selectedPayeeDropDownName}</span>
                                                        </div>
                                                        <div className="col-sm-3 ml-auto">
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <Button
                                                                        color="danger"
                                                                        onClick={this.toggleEditPayeeBankModal}
                                                                    >
                                                                        <CloseIcon />
                                                                    </Button>{" "}
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </ModalFooter>

                                                    <ModalBody>
                                                        <div className="bms-view-req-modal-body">

                                                            <form >
                                                                <div
                                                                    className="col-lg-12 text-right"
                                                                    style={{ paddingTop: "4vh" }}
                                                                >
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="grid">
                                                                        <div className="grid-body">
                                                                            <div className="item-wrapper">
                                                                                <div className="row mb-3">
                                                                                    <div className="col-md-12 mx-auto">

                                                                                        <div className="form-row">
                                                                                            <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>Bank Name</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter Bank Name"
                                                                                                        formControlName="addPayeeBankBankName"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={this.state.addPayeeBankBankName}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                addPayeeBankBankName: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="form-group col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>Account No</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter Account No"
                                                                                                        formControlName=" addPayeeBankAccountNumber"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={this.state.addPayeeBankAccountNumber}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                addPayeeBankAccountNumber: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="form-row">
                                                                                            <div className="form-group col-md-6 col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>IFSC Code</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter IFSC Code"
                                                                                                        formControlName="addPayeeBankIfsc"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={this.state.addPayeeBankIfsc}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                addPayeeBankIfsc: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="form-group col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>Benificary Name</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter Benificary Name"
                                                                                                        formControlName="addPayeeBankBeneficiaryName"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={this.state.addPayeeBankBeneficiaryName}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                addPayeeBankBeneficiaryName: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="row">
                                                                                            {/* <div className="col-sm-6">
                                                                                                <FormGroup check>
                                                                                                    <Label check>
                                                                                                        <Input
                                                                                                            type="checkbox"
                                                                                                            onChange={this.handleCheckPrimary}
                                                                                                            // value={this.state.IsOdometer}
                                                                                                            checked={this.state.addPayeeBankIsPrimary}
                                                                                                        />{" "}
                                                                                                    Is Primary
                                                                                                </Label>
                                                                                                </FormGroup>
                                                                                            </div> */}

                                                                                            <div className="col-sm-3">
                                                                                                <button
                                                                                                    type="submit"
                                                                                                    className="btn btn-danger overflow-hidden mb-3 px-auto editPayeeBankModalSaveButton"
                                                                                                    onClick={e => this.savePayeeBankDetails(e)}
                                                                                                >
                                                                                                    Save
                                                                                        </button>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </ModalBody>


                                                </Modal>
                                                }
                                            </div>

                                            {/* Modal For Edit Bank */}

                                            <div>
                                                {isEditPayeeBankAmountEditModalOpen && <Modal
                                                    size="lg"
                                                    style={{ maxWidth: '1600px', width: '70%' }}
                                                    centered={true}
                                                    isOpen={isEditPayeeBankAmountEditModalOpen}
                                                // toggle={this.toggleAddPayeeBankModal}
                                                >
                                                    <ModalFooter>

                                                        <div className="col-sm-3">
                                                            <span className="bms-view-req-modal-header-label">Payee : {selectedPayeeDropDownName}</span>
                                                        </div>
                                                        <div className="col-sm-3 ml-auto">
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <Button
                                                                        color="danger"
                                                                        onClick={this.toggleEditPayeeBankAmountModal}
                                                                    >
                                                                        <CloseIcon />
                                                                    </Button>{" "}
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </ModalFooter>

                                                    <ModalBody>
                                                        <div className="bms-view-req-modal-body">

                                                            <form >
                                                                <div
                                                                    className="col-lg-12 text-right"
                                                                    style={{ paddingTop: "4vh" }}
                                                                >
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="grid">
                                                                        <div className="grid-body">
                                                                            <div className="item-wrapper">
                                                                                <div className="row mb-3">
                                                                                    <div className="col-md-12 mx-auto">

                                                                                        <div className="form-row">

                                                                                            <div className="form-group col-md-6 row showcase_row_area">
                                                                                                <div className="col-md-3 showcase_text_area">
                                                                                                    <label>Amount</label>
                                                                                                </div>
                                                                                                <div className="col-md-9 showcase_text_area">
                                                                                                    <input
                                                                                                        placeholder="Enter Account No"
                                                                                                        formControlName=" addPayeeBankAccountNumber"
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={this.state.selectedPayeeBankAmountValue}
                                                                                                        onChange={event => {
                                                                                                            this.setState({
                                                                                                                selectedPayeeBankAmountValue: event.target.value
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-md-3">
                                                                                                <button
                                                                                                    type="submit"
                                                                                                    className="btn btn-danger overflow-hidden mb-3 px-auto editPayeeBankModalSaveButton"
                                                                                                    onClick={e => this.savePayeeBankAmountDetails(e)}
                                                                                                >
                                                                                                    Save
                                                                                        </button>

                                                                                            </div>
                                                                                        </div>

                                                                                        {/* <div className="row">

                                                                                            <div className="col-sm-3">
                                                                                                <button
                                                                                                    type="submit"
                                                                                                    className="btn btn-danger overflow-hidden mb-3 px-auto editPayeeBankModalSaveButton"
                                                                                                    onClick={e => this.savePayeeBankAmountDetails(e)}
                                                                                                >
                                                                                                    Save
                                                                                        </button>

                                                                                            </div>
                                                                                        </div> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </ModalBody>


                                                </Modal>
                                                }
                                            </div>


                                        </div>
                                    </nav>

                                    <div style={{ marginBottom: "10vh" }}>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <GeeksUpload /> */}
                </div>


            </div >
        );
    }
}

RouteAddForm.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(RouteAddForm);
