import React, { Component } from "react";
import axios from "axios";
// import Breadcrumb from "../../../common/breadcrumb.component";
import PropTypes from "prop-types";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup, Input,
    Table, Card, CardBody
} from "reactstrap";
import { Link } from "react-router-dom";
// import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
// import moment from "moment";
import { AgGridReact } from 'ag-grid-react';
import Pagination from "react-js-pagination";
import "../../../../assets/customCssFiles/loader-spinner.css"
import "../../../../assets/customCssFiles/calender.css"
import CountUp from "react-countup";
import "../../../../assets/customCssFiles/bmsDashboard.css";
import "../../../../assets/customCssFiles/bmsViewRequest.css";
import "../../../../assets/customCssFiles/bmsViewReqPayee.css";
import "../../../../assets/customCssFiles/bmsShowUserHistoryModal.css";

import CategoryIcon from '@material-ui/icons/Category';
import PersonIcon from '@material-ui/icons/Person';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SearchIcon from '@material-ui/icons/Search';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import DeleteIcon from '@material-ui/icons/Delete';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import pdfIcon from "../../../../assets/customImages/pdfIcon.png";
import excelIcon from "../../../../assets/customImages/excel.jpg";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from '@material-ui/icons/Close';
import BankIcon from "../../../../assets/customImages/bankIconPms.png";
import RotateRightIcon from '@material-ui/icons/RotateRight';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

class NoticeAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link: "",
            modal: false,
            locations: [],
            description: "",
            message: "",
            style: {},
            unique_locationids: [],
            unique_vendors: [],
            anotherModal: false,
            Locationid: "",
            VendorName: "",
            Contact2: "",
            Contact3: "",
            showText: false,
            columnDefs: [
                {
                    headerName: "Bms Id", field: "bmsid", sortable: true, filter: true, minWidth: 50, maxWidth: 100, width: 50
                },
                {
                    headerName: "city", field: "city", sortable: true, filter: true
                },
                {
                    headerName: "category", field: "category", sortable: true, filter: true
                },
                // {
                //     headerName: "itemDetails", field: "itemDetails", sortable: true, filter: true
                // },
                // {
                //     headerName: "attachments", field: "attachments", sortable: true, filter: true
                // },
                // {
                //     headerName: "notes", field: "notes", sortable: true, filter: true
                // },
                // {
                //     headerName: "statusid", field: "statusid", sortable: true, filter: true
                // },
                {
                    headerName: "statuscode", field: "statuscode", sortable: true, filter: true
                },
                {
                    headerName: "Last Updated By", field: "StatusUpdatedby", sortable: true, filter: true
                },
                {
                    headerName: "StatusDate", field: "StatusDate", sortable: true, filter: true
                },
                {
                    headerName: "totalAmount", field: "totalAmount", sortable: true, filter: true
                },
                {
                    headerName: "Requestor", field: "Requestor", sortable: true, filter: true
                },

                {
                    headerName: "priority", field: "priority", sortable: true, filter: true
                },
                {
                    headerName: "View", field: "View", editable: true,
                    cellRendererFramework: (params) => {
                        return <Button
                            color="primary"
                            onClick={() => this.onClickOpenViewReqModal(params.node.data.bmsid)}
                        >
                            <ViewComfyIcon /> View
                </Button>
                    }
                },
                // {
                //     headerName: "Delete", field: "Delete", editable: true,
                //     cellRendererFramework: (params) => {
                //         return <Button
                //             color="danger"
                //             onClick={() => this.deleteRequestByBmsId(params.node.data.bmsid)}
                //         >
                //             <DeleteIcon /> Delete
                //         </Button>
                //     }
                // }


            ],
            rowData: [],
            activePage: 1,
            itemsCountPerPage: 30,
            totalItemsCount: 2000,
            pageRangeDisplayed: 5,
            domLayout: "autoHeight",
            mainDataArray: [],
            searchText: "",
            widgetArray: [],
            widgetKeys: [],
            statusCode: "",
            tableStyle: {},
            viewRequestsModal: false,
            viewRequestPageData: [],
            isViewRequestPageData: false,
            apiData: [],
            modalData: [],
            modalImagesArray: [],
            isImageAttachmentModal: false,
            imageUrlModal: "",
            deleteRequestModal: false,
            isDeleteRequestModal: false,
            isDeleteRequestModalLoading: false,
            isFilterModalLoading: false,
            isFilterModal: false,
            status: "",
            uniqueStatuses: [],
            filterCategories: "",
            filterMinAmount: "",
            filterMaxAmount: "",
            filterPriority: "",
            uniqueCategories: [],
            newCategory: "",
            rotation: 0,

            isShowHistoryModal: false,
            userHistory: []

        };
    }

    getApiDetailsData = async () => {
        const result = await axios.post(`${Config.hostName}/bms/get/details`, {
            "userId": this.props.auth.user.USERID,
            "managerView": 1
        });
        const data = result.data.data;
        // console.log(data, "data");
        if (data.length > 0) {
            await this.setState({
                apiData: data,
                rowData: data.map(eachItem => {
                    return {
                        city: eachItem.city,
                        category: eachItem.category,
                        itemDetails: eachItem.itemDetails,
                        attachments: eachItem.attachments,
                        notes: eachItem.notes,
                        statusid: eachItem.statusid,
                        statuscode: eachItem.statuscode,
                        StatusDate: eachItem.StatusDate,
                        totalAmount: eachItem.totalAmount,
                        Requestor: eachItem.Requestor,
                        bmsid: eachItem.bmsid,
                        priority: eachItem.priority,
                        StatusUpdatedby: eachItem.StatusUpdatedby
                    }
                })
            });
        }
    }

    getUserHistoryData = async () => {
        const result = await axios.post(`${Config.hostName}/bms/get/user/history`, {
            "userId": this.props.auth.user.USERID,
        });
        const data = result.data.data;
        console.log(data, "data");
        if (data.length > 0) {
            await this.setState({
                userHistory: data
            });
        }
    }

    getApiDetailsSearchData = async () => {
        const result = await axios.post(`${Config.hostName}/bms/app/search`, {
            "userId": this.props.auth.user.USERID,
            "search": this.state.searchText,
            isManager: 1
        });
        if (result.data.success) {
            const data = result.data.data;

            if (data.length > 0) {
                await this.setState({
                    apiData: data,
                    rowData: data.map(eachItem => {
                        return {
                            city: eachItem.city,
                            category: eachItem.category,
                            itemDetails: eachItem.itemDetails,
                            attachments: eachItem.attachments,
                            notes: eachItem.notes,
                            statusid: eachItem.statusid,
                            statuscode: eachItem.statuscode,
                            StatusDate: eachItem.StatusDate,
                            totalAmount: eachItem.totalAmount,
                            Requestor: eachItem.Requestor,
                            bmsid: eachItem.bmsid,
                            priority: eachItem.priority,
                            StatusUpdatedby: eachItem.StatusUpdatedby
                        }
                    })
                });
            }
        }
        else {
            alert(result.data.message)
        }


    }

    getWidgetData = async () => {
        const data = await axios.post(`${Config.hostName}/bms/get/user-request/status`, {
            userId: this.props.auth.user.USERID,
            category: this.state.newCategory,
            isManager: 1
        })
            .then(async (res) => {
                if (res.data.success) {
                    const data = res.data.data;
                    if (data.length > 0) {
                        const keys = [];
                        const map = data.map(dataItem => {
                            keys.push(...Object.keys(dataItem));
                        });
                        const prom = await Promise.all(map);
                        const newKeys = [...new Set(keys)]
                        const index = newKeys.indexOf("Createdby");
                        if (index !== -1) {
                            newKeys.splice(index, 1);
                        }
                        await this.setState({
                            widgetArray: data,
                            widgetKeys: [...newKeys]
                        });
                    }
                }
                else {
                    alert(res.data.message)
                }
            }).catch((err) => {
                console.log(err);
            });
    }

    getDataByStatusCode = async () => {
        const result = await axios.post(`${Config.hostName}/bms/get/status-code/details/mobile`, {
            "userId": this.props.auth.user.USERID,
            "statsuCode": this.state.statusCode,
            isManager: 1
        });
        // console.log(this.state.statusCode, "this.state.statusCode");
        const data = result.data.data;
        // console.log(data, "data");
        if (result.data.success) {
            if (data.length > 0) {
                await this.setState({
                    apiData: data,
                    rowData: data.map(eachItem => {
                        return {
                            city: eachItem.city,
                            category: eachItem.category,
                            itemDetails: eachItem.itemDetails,
                            attachments: eachItem.attachments,
                            notes: eachItem.notes,
                            statusid: eachItem.statusid,
                            statuscode: eachItem.statuscode,
                            StatusDate: eachItem.StatusDate,
                            totalAmount: eachItem.totalAmount,
                            Requestor: eachItem.Requestor,
                            bmsid: eachItem.bmsid,
                            priority: eachItem.priority,
                            StatusUpdatedby: eachItem.StatusUpdatedby
                        }
                    })
                });
            }
        }
        else {
            alert(result.data.message)
        }
    }

    onDivClickTableUpdate = async (value) => {
        // console.log(value, "valueeeeee");
        await this.setState({
            statusCode: String(value),
            tableStyle: {}
        })
        await this.getDataByStatusCode();
        await this.setState({
            tableStyle: { display: "none" }
        })
    };

    getInitialDataApiCalls = async () => {
        await this.getWidgetData();
        await this.getApiDetailsData();
        await this.getUserHistoryData();
    }

    getStatusDropDown = async () => {
        console.log(this.props.auth.user.USERID, this.state.bmsId);
        const data = await axios.get(`${Config.hostName}/bms/get/status/dropdown/${this.props.auth.user.USERID}/${this.state.bmsId}`)
            .then(async (res) => {
                const data = res.data.data;
                console.log(res, "uniqueStatuses");
                if (data.length > 0) {
                    await this.setState({
                        uniqueStatuses: data
                    });
                }
                else {
                    await this.setState({
                        uniqueStatuses: []
                    });
                }
            }).catch((err) => {
                // console.log(err);
                alert(`Status DropDown : ${err}`)
            });
    }

    componentWillMount = async () => {
        await this.setState({
            style: {}
        })
        await this.getInitialDataApiCalls();
        await this.setState({
            style: { display: "none" },
            tableStyle: { display: "none" }
        })
    }

    changeStatus = async (e) => {
        await this.setState({
            status: e.target.value
        });
    }

    handleSearchShipment = async () => {
        await this.setState({
            style: {}
        })
        await this.getApiDetailsSearchData();
        await this.setState({
            style: { display: "none" }
        })
    }

    toggleViewRequestsModal = async () => {
        await this.setState({
            viewRequestsModal: !this.state.viewRequestsModal
        });
    }
    toggleOpenViewRequestsModal = async () => {
        await this.setState({
            viewRequestsModal: true,
            isViewRequestPageData: true,
        })
        // await this.toggleViewRequestsModal();
    }
    toggleCloseViewRequestsModal = async () => {
        await this.toggleViewRequestsModal();
        // await this.setState({
        //     imagesTableModal: []
        // });
    }

    viewReqModalFunction = async (bmsId) => {
        // console.log(bmsId, "bmsId", this.state.apiData);
        const viewReqData = [];
        await this.state.apiData.filter(item => {
            // console.log(item, "item");
            if (item.bmsid == bmsId) {
                return viewReqData.push(item);
            }
        })
        console.log(viewReqData, "viewReqData");
        if (viewReqData.length > 0) {
            const modalPageData = viewReqData[0];
            const imagesArray = modalPageData.attachments.length > 0 ? JSON.parse(modalPageData.attachments) : modalPageData.attachments;
            await this.setState({
                modalData: modalPageData,
                bmsId: bmsId,
                modalImagesArray: imagesArray,
                category: modalPageData.category
            })
            await this.getStatusDropDown();

            await this.toggleOpenViewRequestsModal();
        }
        else {
            await this.setState({
                style: { display: "none" }
            })
            await this.toggleOpenViewRequestsModal();

        }

    }

    onClickOpenViewReqModal = async (bmsId) => {
        // await this.getApiDetailsData();
        await this.viewReqModalFunction(bmsId);
    };


    deleteRequestApiCall = async () => {
        await this.setState({
            isDeleteRequestModalLoading: true
        })
        const data = await axios.post(`${Config.hostName}/bms/delete/request`, {
            bmsId: this.state.bmsId
        })
            .then(async (res) => {
                const data = res.data;
                // console.log(data, "widgetData");
                if (data.success) {
                    await this.getInitialDataApiCalls();
                    await this.setState({
                        isDeleteRequestModal: false,
                        isDeleteRequestModalLoading: false
                    })
                    setTimeout(async () => {
                        await this.props.setAlert("Deleted Succesfully", "success")
                    }, 2000);
                }
                else {
                    await this.setState({
                        isDeleteRequestModalLoading: false
                    })
                    alert(data.message)
                }
            }).catch((err) => {
                alert(err)
                console.log(err);
            });
    }


    toggleCloseDeleteRequestModal = async () => {
        await this.setState({
            isDeleteRequestModal: false
        })
    }

    deleteRequestByBmsId = async (bmsId) => {
        // console.log("deleting bms request", bmsId);
        await this.setState({
            bmsId: bmsId,
            isDeleteRequestModal: true
        })
    }


    updateContentApi = async (contentId, data) => {
        // console.log(contentId, JSON.parse(data), "contentId, data");
        // console.log(contentId, JSON.stringify(data), "contentId, data");
        // console.log(contentId, data, "contentId, data");
        await axios.post(`${Config.hostName}/bms/update/content`, {
            "bmsId": this.state.bmsId,
            "category": this.state.category ? this.state.category : null,
            "totalAmount": this.state.totalAmount ? this.state.totalAmount : null,
            "contentId": contentId,
            "content": data,
            "userId": this.props.auth.user.USERID
        })
            .then(async (response) => {
                // console.log(response.data, "response");
                if (response.data.success === false) {
                    await this.setState({
                        style: { display: "none" }
                    })
                    // await this.props.setAlert(response.data.message, "danger");
                    alert(response.data.message)
                }
                else {
                    // await this.getInitialDataByRequest();
                    await this.getApiDetailsData();
                    await this.viewReqModalFunction(this.state.bmsId);
                    await this.setState({
                        style: { display: "none" },
                        status: "",
                        remarks: ""
                    })
                    // await this.props.setAlert("Succesfully Updated", "success");
                    if (!this.state.modalData) {
                        alert("Something Failed");
                    }
                    alert("Succesfully Updated")

                }
            }).catch((error) => {
                console.log(error, 'error');
            })
    }

    updateRequestRemarks = async (contentId) => {
        await this.setState({ style: {} });
        if (!this.state.remarks) {
            await this.setState({
                style: { display: "none" }
            })
            return alert("Remarks field should not be empty")
        }
        const data = (contentId == 4) ? this.state.remarks : "";
        // console.log(contentId, data, data.length, "contentId, data");
        await this.updateContentApi(contentId, data);
    }

    updateRequestStatus = async (contentId) => {
        await this.setState({ style: {} });
        if (!this.state.status) {
            await this.setState({
                style: { display: "none" }
            })
            return alert("Status should not be empty")
        }
        const data = (contentId == 5) ? this.state.status : "";
        // console.log(contentId, data, data.length, "contentId, data");
        await this.updateContentApi(contentId, data);
        // console.log(this.state.category)
        await this.getWidgetData();
        await this.setState({
            status: "",
            viewRequestsModal: !this.state.viewRequestsModal

        })
    }

    updateRequest = async (contentId) => {
        if (contentId == 4) {
            await this.updateRequestRemarks(contentId);
        }
        if (contentId == 5) {
            await this.updateRequestStatus(contentId);
        }
    }

    toggleOpenImageAttachmentModal = async () => {
        await this.setState({
            imageAttachmentModal: !this.state.imageAttachmentModal
        });
    }

    onClickLiveTrack = async (value) => {
        window.open(value, '_blank');
    }

    openImageAttachmentModal = async (url) => {
        console.log(url, "url");
        if (url.length > 0) {
            if (url.split('.').pop().toLowerCase() != "pdf" && url.split('.').pop().toLowerCase() != "xlsx") {
                await this.setState({
                    isImageAttachmentModal: !this.state.isImageAttachmentModal,
                    imageUrlModal: url
                })
                await this.toggleOpenImageAttachmentModal();
            }
            else {
                window.open(url, '_blank');
            }
        }
    }

    closeImageAttachmentModal = async () => {

        await this.toggleOpenImageAttachmentModal();
        await this.setState({
            isImageAttachmentModal: !this.state.isImageAttachmentModal,
            imageUrlModal: ""
        })
    }

    getCategoriesData = async () => {
        const data = await axios.post(`${Config.hostName}/bms/get/category-dropdown`, {
            locationId: this.props.auth.user.locationid,
            category: ""
        }).then(async (res) => {
            if (res.data.success) {
                const data = res.data.data;
                const filteredArray = data.map(item => item.category)
                    .filter((value, index, self) => self.indexOf(value) === index)

                console.log(filteredArray);
                const anotherArray = [];
                filteredArray.map(item => {
                    anotherArray.push({
                        category: item,
                        isSelected: false
                    })
                })
                console.log(anotherArray, "filteredArray");
                if (data.length > 0) {
                    await this.setState({
                        uniqueCategories: anotherArray
                    });
                }
                // if (data.length > 0) {
                //     await this.setState({
                //         uniqueCategories: data
                //     });
                // }
            }
            else {
                alert(res.data.message)
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    toggleFilterModal = async () => {
        await this.setState({
            isFilterModal: !this.state.isFilterModal
        })
    }

    toggleOpenFilterModal = async () => {
        await this.getCategoriesData();
        await this.toggleFilterModal();
    }
    toggleCloseFilterModal = async () => {
        await this.setState({
            filterCategories: "",
            filterMinAmount: "",
            filterMaxAmount: "",
            filterPriority: "",
        })
        await this.toggleFilterModal();
    }

    supplyCategoriesToString = async () => {
        let str = "";
        this.state.uniqueCategories.filter(item => {
            if (item.isSelected) {
                str = str + "," + item.category
            }

        })
        // console.log(str, "strrrr")
        await this.setState({
            filterCategories: str,
            newCategory: str
        })
    }

    selectMultipleCategoryFilter = async (e) => {
        e.preventDefault();
        // console.log(e.target.value, "selectMultipleCategoryFilter")
        const data = this.state.uniqueCategories;
        data.filter((dataItem) => {
            if (dataItem.category == e.target.value) {
                return dataItem.isSelected = !dataItem.isSelected
            }
        })
        await this.setState({
            uniqueCategories: data
        })
        await this.supplyCategoriesToString();
    }

    handleApplyFiltersData = async () => {
        await this.setState({
            isFilterModalLoading: true
        })
        const result = await axios.post(`${Config.hostName}/bms/app/filter`, {
            "userId": this.props.auth.user.USERID,
            "category": this.state.filterCategories,
            "minAmount": this.state.filterMinAmount,
            "maxAmount": this.state.filterMaxAmount,
            "priority": this.state.filterPriority,
            isManager: 1
        });
        const data = result.data.data;
        console.log(result.data, "data");
        if (data.length > 0) {
            await this.getWidgetData();
            await this.setState({
                apiData: data,
                rowData: data.map(eachItem => {
                    return {
                        city: eachItem.city,
                        category: eachItem.category,
                        itemDetails: eachItem.itemDetails,
                        attachments: eachItem.attachments,
                        notes: eachItem.notes,
                        statusid: eachItem.statusid,
                        statuscode: eachItem.statuscode,
                        StatusDate: eachItem.StatusDate,
                        totalAmount: eachItem.totalAmount,
                        Requestor: eachItem.Requestor,
                        bmsid: eachItem.bmsid,
                        priority: eachItem.priority,
                        StatusUpdatedby: eachItem.StatusUpdatedby
                    }
                }),
                isFilterModalLoading: false,
                filterCategories: "",
                filterMinAmount: "",
                filterMaxAmount: "",
                filterPriority: "",
            });
            await this.toggleCloseFilterModal();
        }
        else {
            await this.setState({
                rowData: data,
                isFilterModalLoading: false,
                apiData: data,
                filterCategories: "",
                filterMinAmount: "",
                filterMaxAmount: "",
                filterPriority: "",
            })
            await this.toggleCloseFilterModal();

        }
    }

    toggleShowHistoryModal = async () => {
        await this.setState({
            isShowHistoryModal: !this.state.isShowHistoryModal
        })
    }

    toggleCloseShowHistoryModal = async () => {
        await this.toggleShowHistoryModal();
    }

    onClickOpenShowHistoryModal = async () => {
        await this.toggleShowHistoryModal();
    }

    render() {
        const { widgetArray, style, tableStyle, viewRequestsModal, isViewRequestPageData, isImageAttachmentModal, isFilterModalLoading } = this.state;
        const { modalImagesArray, modalData, imageAttachmentModal, isDeleteRequestModal, isDeleteRequestModalLoading, isFilterModal, rotation
            , isShowHistoryModal
        } = this.state;

        const defaultColDef = {
            flex: 1,
            minWidth: 90,
            resizable: true
        }

        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;


        return (
            <div>
                <div id='cover-spin' style={style}></div>
                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />

                                <nav
                                    className="navbar navbar-light "
                                    style={{ backgroundColor: "none" }}
                                >
                                    <div className="row" style={{ paddingTop: "8px", width: "100%" }}>


                                        <div className="col-sm-3">
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    name="searchText"
                                                    placeholder="Search"
                                                    value={this.state.searchText}
                                                    id="searchText"
                                                    onChange={event => {
                                                        this.setState({ searchText: event.target.value });
                                                    }}
                                                >
                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-2 mr-auto">
                                            <button
                                                type="submit"
                                                // className="btn btn-secondary"
                                                className="btn btn-danger overflow-hidden mb-3 px-auto"
                                                onClick={this.handleSearchShipment}
                                            >
                                                <SearchIcon />Search
                      </button>
                                        </div>

                                        <div className="col-sm-2">
                                            <button
                                                type="submit"
                                                // className="btn btn-secondary"
                                                className="btn btn-danger overflow-hidden mb-3 px-auto"
                                                onClick={this.toggleOpenFilterModal}
                                            >
                                                <FilterListIcon />Filter
                      </button>
                                        </div>

                                    </div>

                                </nav>


                                <div className="card-body">

                                    <div>
                                        <div className="container-fluid">
                                            <div className="row">
                                                {this.state.widgetKeys.map((key, keyValue) => {
                                                    return <div key={keyValue} className="col-sm-2 col-lg-2 custom-card-widget-main" >

                                                        {/* <button id={key} value={key} onClick={(e) => this.onDivClickTableUpdate(e)}> */}
                                                        <div
                                                            id={key} value={key} onClick={() => this.onDivClickTableUpdate(key)}
                                                            className="card custom-card-widget-invoice"
                                                        >
                                                            {/* <div className="card"> */}
                                                            <div className="ecommerce-widget card-body">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        {widgetArray.map((item) => {
                                                                            return <div>
                                                                                <h3 className="counter-category-count-bms">
                                                                                    <CountUp end={item[String(key)] ? item[String(key)] : 0} />
                                                                                </h3>
                                                                            </div>
                                                                        })}

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        {/* </div> */}
                                                        <h6 className="text-secondary" style={{ textAlign: "center", fontWeight: "bolder", marginTop: "-5%" }}>{key}</h6>
                                                        {/* <h6 className="text-secondary">{key}</h6> */}
                                                        {/* </button> */}
                                                    </div>

                                                })}

                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div id="cover-spin" style={tableStyle}></div>

                                        <div
                                            className="ag-theme-balham"
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                minHeight: "500px"
                                            }}
                                        >
                                            <AgGridReact
                                                columnDefs={this.state.columnDefs}
                                                rowData={this.state.rowData}
                                                defaultColDef={defaultColDef}
                                                enableCellTextSelection={true}
                                                rowHeight={50}
                                                domLayout={this.state.domLayout}
                                                // enableCellTextSelection={true}
                                                suppressAutoSize={true}
                                                gridAutoHeight={true}
                                            >
                                            </AgGridReact>
                                        </div>
                                    </div>

                                    <div>
                                        {isViewRequestPageData && <Modal
                                            size="lg"
                                            // style={{ width: '70%', maxWidth: "100%" }}
                                            style={{ maxWidth: '1600px', width: '70%' }}
                                            centered={true}
                                            isOpen={viewRequestsModal}
                                        >
                                            <ModalFooter>

                                                <div className="col-sm-3">
                                                    <span className="bms-view-req-modal-header-label">BMS ID : {this.state.bmsId}</span>
                                                </div>
                                                <div className="col-sm-3">
                                                    <span className="bms-view-req-modal-header-label">Billing Date : {modalData.BillingDate}</span>
                                                </div>
                                                <div className="col-sm-3 ml-auto">
                                                    <div className="row">
                                                        <div className="col-sm-2 ">
                                                            <Button
                                                                color="danger"
                                                                onClick={this.toggleCloseViewRequestsModal}
                                                            >
                                                                <CloseIcon />
                                                            </Button>{" "}
                                                        </div>
                                                    </div>
                                                </div>

                                            </ModalFooter>
                                            <ModalBody>
                                                <div className="bms-view-req-modal-body">
                                                    <div>

                                                        <div className="row bmsViewReqFirstRow">

                                                            <div className="col-sm-3 bmsViewReqFirstRowItems">
                                                                <Card>
                                                                    <CardBody>
                                                                        {/* <CategoryIcon /> Category <br /> {data.category} */}
                                                                        <div className="bmsViewReqFirstRowCardBodyMain">
                                                                            <div className="bmsViewReqFirstRowCardBodyIcon">
                                                                                <span><CategoryIcon /></span>
                                                                            </div>
                                                                            <div className="bmsViewReqFirstRowCardBodyNameMain">
                                                                                <span className="bmsViewReqFirstRowCardBodyValue">{modalData.category}</span><br />
                                                                                <span className="bmsViewReqFirstRowCardBodyLabel">Category</span>

                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </div>
                                                            <div className="col-sm-3 bmsViewReqFirstRowItems">
                                                                <Card>
                                                                    <CardBody>
                                                                        {/* <AccountBalanceWalletIcon /> Category <br /> {data.category} */}
                                                                        <div className="bmsViewReqFirstRowCardBodyMain">
                                                                            <div className="bmsViewReqFirstRowCardBodyIcon">
                                                                                {/* <span><i className="icofont icofont-cur-rupee" ></i></span> */}
                                                                                <span><AccountBalanceWalletIcon /></span>
                                                                            </div>
                                                                            <div className="bmsViewReqFirstRowCardBodyNameMain">
                                                                                <span className="bmsViewReqFirstRowCardBodyValue">{modalData.totalAmount}</span><br />
                                                                                <span className="bmsViewReqFirstRowCardBodyLabel">Total&nbsp;Amount</span>

                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </div>
                                                            <div className="col-sm-3 bmsViewReqFirstRowItems">
                                                                <Card>
                                                                    <CardBody>
                                                                        {/* <CategoryIcon /> Category <br /> {data.category} */}
                                                                        <div className="bmsViewReqFirstRowCardBodyMain">
                                                                            <div className="bmsViewReqFirstRowCardBodyIcon">
                                                                                <span className="bmsViewReqFirstRowCardBodyIconRupee"><PersonIcon /></span>
                                                                            </div>
                                                                            <div className="bmsViewReqFirstRowCardBodyNameMain">
                                                                                <span className="bmsViewReqFirstRowCardBodyValue">{modalData.Requestor}</span><br />
                                                                                <span className="bmsViewReqFirstRowCardBodyLabel">Requestor</span>

                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </div>
                                                            <div className="col-sm-3 bmsViewReqFirstRowItems">
                                                                <Card>
                                                                    <CardBody>
                                                                        {/* <CategoryIcon /> Category <br /> {data.category} */}
                                                                        <div className="bmsViewReqFirstRowCardBodyMain">
                                                                            <div className="bmsViewReqFirstRowCardBodyIcon">
                                                                                <span className="bmsViewReqFirstRowCardBodyIconRupee"><PersonIcon /></span>
                                                                            </div>
                                                                            <div className="bmsViewReqFirstRowCardBodyNameMain">
                                                                                <span className="bmsViewReqFirstRowCardBodyValue">{modalData.ClientCity}_{modalData.ProjectName}</span><br />
                                                                                <span className="bmsViewReqFirstRowCardBodyLabel">Project</span>

                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </div>


                                                        </div>

                                                        <div className="row bmsViewReqSecondRowItemDetails">
                                                            <span className="bms-view-request-items">Items : </span>
                                                            <Table responsive className="styled-table">
                                                                <thead>
                                                                    <tr className="styled-table-header-row">
                                                                        <th >Item&nbsp;Name</th>
                                                                        <th >Amount</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {JSON.parse(modalData.itemDetails).map(item =>
                                                                        <tr className="styled-table-row">
                                                                            <td>{item.item}</td>
                                                                            <td>{item.amount}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>

                                                            </Table>
                                                        </div>

                                                        <div className="row bmsViewReqThirdRowAttachments">
                                                            <span className="bms-view-request-attachments">Attachments : </span><br />
                                                        </div>

                                                        <div className="row bmsViewReqFourthRowGallery">
                                                            <div className="bms-view-request-main-div">
                                                                {modalImagesArray.length > 0 && modalImagesArray.map((imageUrl, i) => {
                                                                    return <div
                                                                        className="bms-open-attachment-modal-image"
                                                                        onClick={() => this.openImageAttachmentModal(imageUrl.AttachmentUrl)}
                                                                    >
                                                                        {imageUrl.AttachmentUrl && imageUrl.AttachmentUrl.split('.').pop().toLowerCase() != "pdf" && imageUrl.AttachmentUrl.split('.').pop().toLowerCase() != "xlsx" &&

                                                                            <img className="bms-view-request-img" src={imageUrl.AttachmentUrl} alt="No Image" key={i} />
                                                                        }
                                                                        {imageUrl.AttachmentUrl && imageUrl.AttachmentUrl.split('.').pop().toLowerCase() == "pdf" &&

                                                                            <img className="bms-view-request-img" src={pdfIcon} alt="No Image" key={i} />
                                                                        }
                                                                        {imageUrl.AttachmentUrl && imageUrl.AttachmentUrl.split('.').pop().toLowerCase() == "xlsx" &&

                                                                            <img className="bms-view-request-img" src={excelIcon} alt="No Image" key={i} />
                                                                        }
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div>

                                                        <div className="row bmsViewReqFifthRowRemarks">
                                                            <span className="bms-view-request-remarks">Remarks : </span>
                                                            <Table responsive className="styled-table">
                                                                <thead>
                                                                    <tr className="styled-table-header-row">
                                                                        <th >Date</th>
                                                                        <th >Note</th>
                                                                        <th >Commented&nbsp;By</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {JSON.parse(modalData.notes.replace(/\n/g, "<br>")).map(note =>
                                                                        <tr className="styled-table-row">
                                                                            <td>{note.notedate}</td>
                                                                            <td>{note.Comments}</td>
                                                                            <td>{note.Commentedby}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>

                                                            </Table>
                                                        </div>

                                                        <AvForm>
                                                            <div className="row bmsViewReqSixthRowAddRemarks">
                                                                <div className="col-sm-10">
                                                                    <AvField
                                                                        name="Remarks"
                                                                        // label="Remarks"
                                                                        value={this.state.remarks}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                remarks: e.target.value
                                                                            })
                                                                        }}
                                                                        type="textarea"
                                                                        placeholder="Remarks"
                                                                    />
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <Button
                                                                        // type="submit"
                                                                        color="info"
                                                                        // className="btn btn-primary w-100 overflow-hidden mb-3 px-auto"
                                                                        value={3}
                                                                        onClick={() => this.updateRequest(4)}
                                                                    >
                                                                        Add Note
</Button>
                                                                </div>
                                                            </div>
                                                        </AvForm>

                                                        {/** Payee Details */}
                                                        {JSON.parse(modalData.PayeeDetails) != null && JSON.parse(modalData.PayeeDetails).length > 0 &&
                                                            <div className="bms-view-req-items-main">

                                                                <div className="bms-view-req-items-main-label">
                                                                    <span className="bms-view-req-items-label">Payee Details : </span>
                                                                </div>

                                                                <div className="row bms-view-req-payee-details-card-view-main-container">
                                                                    {JSON.parse(modalData.PayeeDetails) != null && JSON.parse(modalData.PayeeDetails).length > 0 && JSON.parse(modalData.PayeeDetails).map((bankListItem, i) => {
                                                                        return (<div key={i} className="card col-sm-4 bmsViewReqPayeeDetailsCardView">
                                                                            <div className="bmsViewReqPayeeDetailsCardViewLeft">
                                                                                <img className="bmsViewReqPayeeDetailsCardViewLeftBankIcon" src={BankIcon} alt={i} width="50" height="60" />
                                                                            </div>

                                                                            <div className="bmsViewReqPayeeDetailsCardViewRight">

                                                                                <div className="bmsViewReqPayeeDetailsCardViewRightName">
                                                                                    <span className="bmsViewReqPayeeDetailsCardViewRightNameValue">
                                                                                        Name: {bankListItem.fullname ? bankListItem.fullname : " - "}
                                                                                    </span>
                                                                                </div>

                                                                                <div className="bmsViewReqPayeeDetailsCardViewRightBenefeciaryName">
                                                                                    <span className="bmsViewReqPayeeDetailsCardViewRightBenefeciaryNameValue">
                                                                                        Benefeciary Name: {bankListItem.beneficiaryName ? bankListItem.beneficiaryName : " - "}
                                                                                    </span>
                                                                                </div>

                                                                                <div className="bmsViewReqPayeeDetailsCardViewRightAccountNumber">
                                                                                    <span className="bmsViewReqPayeeDetailsCardViewRightAccountNumberValue">
                                                                                        A/C No: {bankListItem.AccountNumber ? bankListItem.AccountNumber : " - "}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="bmsViewReqPayeeDetailsCardViewRightBankName">
                                                                                    <span className="bmsViewReqPayeeDetailsCardViewRightBankNameValue">
                                                                                        Bank : {bankListItem.bankname ? bankListItem.bankname : " - "}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="bmsViewReqPayeeDetailsCardViewRightIfsc">
                                                                                    <span className="bmsViewReqPayeeDetailsCardViewRightIfscValue">
                                                                                        IFSC : {bankListItem.IFSC ? bankListItem.IFSC : " - "}
                                                                                    </span>
                                                                                </div>

                                                                                <div className="bmsViewReqPayeeDetailsCardViewRightAmount">
                                                                                    <span className="bmsViewReqPayeeDetailsCardViewRightAmountValue">
                                                                                        Amount : {bankListItem.amount ? bankListItem.amount : " - "}
                                                                                    </span>
                                                                                </div>

                                                                            </div>


                                                                        </div>)
                                                                    })
                                                                    }
                                                                </div>

                                                            </div>
                                                        }

                                                        {this.state.uniqueStatuses.length > 0 ?
                                                            <div className="row bmsViewReqSixthRowStatusUpdate">
                                                                <div className="col-sm-3">
                                                                    <FormGroup>
                                                                        <Input
                                                                            type="select"
                                                                            name="status"
                                                                            required
                                                                            value={this.state.status}
                                                                            id="status"
                                                                            onChange={this.changeStatus}
                                                                        >
                                                                            <option value={""} disabled selected>Select Status</option>

                                                                            {this.state.uniqueStatuses.map(statusItem => (
                                                                                <option value={statusItem.StatusID}>
                                                                                    {statusItem.description}
                                                                                </option>
                                                                            ))}
                                                                        </Input>
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-sm-2 mr-auto">
                                                                    <button
                                                                        type="submit"
                                                                        // className="btn btn-secondary"
                                                                        className="btn btn-danger overflow-hidden mb-3 px-auto"
                                                                        lj onClick={() => this.updateRequest(5)}
                                                                    >
                                                                        Update
                      </button>
                                                                </div>

                                                            </div>
                                                            : ""}

                                                    </div>
                                                    {/* <ViewRequestPage data={viewRequestPageData} /> */}
                                                </div>
                                            </ModalBody>

                                        </Modal>
                                        }
                                    </div>
                                    {/** Show Images Modal */}
                                    <div>
                                        {isImageAttachmentModal && <Modal
                                            size="lg"
                                            // style={{ maxWidth: '1600px', width: '70%' }}
                                            style={{ width: '70%', maxWidth: "100%" }}

                                            centered={true}
                                            isOpen={imageAttachmentModal}
                                        >
                                            <ModalFooter>
                                                <div className="col-sm-3 ">

                                                    <Button
                                                        color="disabled"
                                                        onClick={() => this.setState({
                                                            rotation: this.state.rotation - 90
                                                        })}
                                                    >
                                                        <RotateLeftIcon />
                                                    </Button>{" "}
                                                    <Button
                                                        color="disabled"
                                                        onClick={() => this.setState({
                                                            rotation: this.state.rotation + 90
                                                        })}
                                                    >
                                                        <RotateRightIcon />
                                                    </Button>{" "}
                                                </div>
                                                <div className="col-sm-3 ml-auto">

                                                    <Button
                                                        color="danger"
                                                        onClick={this.closeImageAttachmentModal}
                                                    >
                                                        <CloseIcon />
                                                    </Button>{" "}

                                                </div>
                                            </ModalFooter>                                            <ModalBody>
                                                <div className="bms-view-image-url-modal">
                                                    <img src={this.state.imageUrlModal}
                                                        style={{ transform: `rotate(${rotation}deg)` }}

                                                        alt="No Image" width="100%" height="100%" />
                                                    {/* <iframe
                                                        src={"https://docs.google.com/viewer?url=" + this.state.imageUrlModal + "&embedded=true"}
                                                        title="file"
                                                        width="100%"
                                                        height="600"
                                                    ></iframe> */}
                                                    {/* <iframe src={`http://docs.google.com/gview?embedded=true&url=${this.state.imageUrlModal}`} /> */}
                                                </div>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button
                                                    color="danger"
                                                    onClick={this.closeImageAttachmentModal}
                                                >
                                                    Close
                                                </Button>{" "}
                                            </ModalFooter>

                                        </Modal>
                                        }
                                    </div>
                                    {/** Delete Request Modal */}
                                    <div>
                                        <Modal
                                            size="lg"
                                            // style={{ maxWidth: '1600px', width: '70%' }}
                                            centered={true}
                                            isOpen={isDeleteRequestModal}
                                        >
                                            <ModalHeader>Do you want to delete BMS Request - {this.state.bmsId}</ModalHeader>
                                            <ModalFooter>
                                                <Button
                                                    color="success"
                                                    onClick={this.deleteRequestApiCall}
                                                >
                                                    {isDeleteRequestModalLoading && (
                                                        <i
                                                            className="fa fa-refresh fa-spin"
                                                            style={{ marginRight: "5px" }}
                                                        />
                                                    )}
                                                    {isDeleteRequestModalLoading && <span>Deleting ...</span>}
                                                    {!isDeleteRequestModalLoading && <span>Delete</span>}
                                                </Button>{" "}
                                                <Button
                                                    color="danger"
                                                    onClick={this.toggleCloseDeleteRequestModal}
                                                >
                                                    No
                                                </Button>{" "}
                                            </ModalFooter>

                                        </Modal>

                                    </div>
                                    {/** Filters Modal */}
                                    <div>
                                        <Modal
                                            size="lg"
                                            style={{ maxWidth: '1600px', width: '70%' }}
                                            centered={true}
                                            isOpen={isFilterModal}
                                        >
                                            <ModalHeader>Filters</ModalHeader>
                                            <ModalBody>
                                                <div className="row bms-apply-filter-main-div">

                                                    <div className="bms-create-req-items-main-label">
                                                        <span className="bms-create-req-items-label">Choose Category : </span>
                                                    </div>
                                                    <div className="row bms-apply-filter-category-main">
                                                        {this.state.uniqueCategories.map((cat => {
                                                            return <div className="bms-manager-view-apply-filter-category-button">
                                                                <Button
                                                                    color={cat.isSelected ? "danger" : "secondary"}
                                                                    value={cat.category}
                                                                    onClick={this.selectMultipleCategoryFilter}
                                                                >
                                                                    {cat.category}
                                                                </Button>
                                                            </div>
                                                        }))}

                                                    </div>

                                                    <div className="bms-create-req-items-main-label">
                                                        <span className="bms-create-req-items-label">Amount Range : </span>
                                                    </div>
                                                    <div className="row bms-apply-filter-amount-range">
                                                        {/* <div className="row"> */}
                                                        <div className="col-sm-3 bms-apply-filter-min-amount">
                                                            <FormGroup>
                                                                <Input
                                                                    type="number"
                                                                    name="filterMinAmount"
                                                                    placeholder="Min Amount"
                                                                    value={this.state.filterMinAmount}
                                                                    id="filterMinAmount"
                                                                    onChange={event => {
                                                                        this.setState({ filterMinAmount: event.target.value });
                                                                    }}
                                                                >
                                                                </Input>
                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-sm-3 bms-apply-filter-max-amount">
                                                            <FormGroup>
                                                                <Input
                                                                    type="number"
                                                                    name="filterMaxAmount"
                                                                    placeholder="Max Amount"
                                                                    value={this.state.filterMaxAmount}
                                                                    id="filterMaxAmount"
                                                                    onChange={event => {
                                                                        this.setState({ filterMaxAmount: event.target.value });
                                                                    }}
                                                                >
                                                                </Input>
                                                            </FormGroup>
                                                        </div>
                                                        {/* </div> */}
                                                    </div>

                                                </div>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button
                                                    color="success"
                                                    onClick={this.handleApplyFiltersData}
                                                >
                                                    {isFilterModalLoading && (
                                                        <i
                                                            className="fa fa-refresh fa-spin"
                                                            style={{ marginRight: "5px" }}
                                                        />
                                                    )}
                                                    {isFilterModalLoading && <span>Processing ...</span>}
                                                    {!isFilterModalLoading && <span>Apply</span>}
                                                </Button>{" "}
                                                <Button
                                                    color="danger"
                                                    onClick={this.toggleCloseFilterModal}
                                                >
                                                    Close
                                                </Button>{" "}
                                            </ModalFooter>

                                        </Modal>

                                    </div>
                                    {/** Show History Modal */}
                                    <div>
                                        {isShowHistoryModal && <Modal
                                            // size="lg"
                                            style={{ width: '70%', maxWidth: "100%" }}
                                            isOpen={isShowHistoryModal}
                                        >
                                            <ModalFooter>
                                                <div className="col-sm-3 ml-auto">
                                                    <Button
                                                        color="danger"
                                                        onClick={this.toggleCloseShowHistoryModal}
                                                    >
                                                        <CloseIcon />
                                                    </Button>{" "}
                                                </div>
                                            </ModalFooter>

                                            <ModalBody>
                                                <div className="bmsShowUserHistoryModalMain">

                                                    {this.state.userHistory.map((historyItem, i) => {
                                                        return (<div key={i}>
                                                            {historyItem.BMSID}
                                                        </div>)
                                                    })}

                                                </div>
                                            </ModalBody>

                                            <ModalFooter>
                                                <Button
                                                    color="danger"
                                                    onClick={this.toggleCloseShowHistoryModal}
                                                >
                                                    Close
                                                </Button>{" "}
                                            </ModalFooter>

                                        </Modal>
                                        }
                                    </div>

                                    <div style={{ margin: "10vh auto", marginLeft: "25%", justifyContent: "center" }}>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* </div> */}

                </div>
            </div>
        );
    }


}

NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);
