/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
import LineChart from "react-linechart";
import { Link } from "react-router-dom";

import Slider from "react-slick";
import axios from "axios";
// Import custom components
import Breadcrumb from "../../../common/breadcrumb.component";
import GeneralWidget from "./GeneralWidget";
import Config from "../../../../config";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/alert.actions";
import CanvasJSReact from "../../../../assets/canvas/canvasjs.react";
import moment from "moment";
import { FormGroup, Input, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import "../../../../assets/customCssFiles/calender.css"

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
} from "reactstrap";
import "../../../../assets/customCssFiles/loader-spinner.css"

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiData: [
        {
          totalCustomers: [],
          totalOrders: [],
          statusReport: [],
          graphData: [],
          exportData: [],
        },
      ],
      startDate: "",
      endDate: "",
      reportId: "",
      style: {},
      isData: false,
      isOpen: false,
      isGraphData: false,
      dataPointsGraph: [
        {
          // type: "line",
          type: "line",
          cursor: "pointer",
          markerType: "circle",
          markerColor: "white",
          markerSize: "12",

          //     showInLegend: true,
          // legendText: "Numbers",
          // backgroundColor:"green",
          // data: [
          // {
          // type: "line",

          lineColor: "white", //**Change the linecolor here
          lineThickness: 5,

          // }],
          toolTipContent: "{label}: {y} customers",
          dataPoints: [],
          // xValueFormatString: "YYYY-MM-DD",
        },
      ],
      userCcId: "",
      unique_cities: [],
      ccid: "",
      uniqueDateRanges: [],
      dateRangeSelected: "",
      modal: false,
    };
  }

  toggle = async () => {
    await this.setState({
      modal: !this.state.modal,
    });
    // this.setState({
    //   modal: !this.state.modal,
    // });
  };

  getData = async (reportId) => {
    // console.log(this.state.startDate, this.state.endDate);

    await axios
      .post(
        `${Config.hostName}/shipment/get/data/dashboard/royaloak/${reportId}/${this.state.userCcId}`,
        {
          startDate: this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : this.state.startDate,
          endDate: this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : this.state.endDate,

        }
      )
      .then(async (res) => {
        // console.log(res.data);

        if (res.data.data.length > 0) {
          let data = this.state.apiData;
          if (reportId === 4) {
            data[0].totalCustomers.push(res.data.data[0]);
          }
          if (reportId === 1) {
            data[0].totalOrders.push(res.data.data[0]);
          }
          if (reportId === 2) {
            data[0].statusReport.push(res.data.data);
          }
          if (reportId === 3) {
            if (res.data.data) {
              data[0].graphData.push(res.data.data);
              await this.setState({
                isGraphData: true,
              });
            }
          }
          if (reportId === 99) {
            data[0].exportData.push(res.data.data);
          }
          await this.setState({
            apiData: data,
          });
        } else {
          await this.setState({
            apiData: [
              {
                totalCustomers: [],
                totalOrders: [],
                statusReport: [],
                graphData: [],
                exportData: [],
              },
            ],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAllData = async () => {
    await this.getData(1);
    await this.getData(2);
    await this.getData(3);
    await this.getData(4);
    await this.getData(99);
    await this.setState({
      isData: true,
    });
    console.log(this.state.apiData, "data");

  };

  getUniqueDateRanges = async () => {
    await axios
      .get(
        `${Config.hostName}/shipment/get/data/dashboard/royaloak/unique/date-ranges`
      )
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            uniqueDateRanges: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getUniqueCities = async () => {
    await axios
      .get(
        `${Config.hostName}/shipment/get/unique-cities/${this.props.auth.user.CCID}`
      )
      .then(async (res) => {
        let { data } = res.data;
        if (data.length > 0) {
          data.push({
            ccid: this.props.auth.user.CCID,
            city: "All Cities",
          });
          await this.setState({
            unique_cities: data,
          });
        } else {
          this.setState({
            unique_dates: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentWillMount = async () => {
    await this.setState({
      userCcId: this.props.auth.user.CCID,
    });
    await this.getAllData();
    await this.getUniqueDateRanges();
    await this.getUniqueCities();
    await this.setState({
      style: { display: "none" },
    });
  };

  handleSearchShipment = async () => {
    console.log(this.state.startDate, this.state.endDate, this.state.userCcId);

    await this.setState({
      style: {},
      apiData: [
        {
          totalCustomers: [],
          totalOrders: [],
          statusReport: [],
          graphData: [],
          exportData: [],
        },
      ],
      dataPointsGraph: [
        {
          type: "line",
          cursor: "pointer",
          markerType: "circle",
          markerColor: "white",
          markerSize: "12",
          lineColor: "white", //**Change the linecolor here
          lineThickness: 5,
          toolTipContent: "{label}: {y} customers",
          dataPoints: [],
        },
      ],
      isData: false,
      isGraphData: false,
    });
    await this.getAllData();
    await this.getUniqueCities();
    // await this.getUniqueDateRanges();
    await this.setState({
      style: { display: "none" },
    });
  };

  handleChangeCity = async (e) => {
    await this.setState({ userCcId: e.target.value, ccid: e.target.value });
  };

  handleChangeDateRange = async (e) => {
    console.log(e.target.value);
    let value = e.target.value;
    // console.log();2222
    if (value == 2222) {
      await this.setState({
        dateRangeSelected: e.target.value,
        startDate: "",
        endDate: "",
      });
      await this.toggle();
    } else {
      this.state.uniqueDateRanges.filter(async (eachDate, i) => {
        if (i == value) {
          // console.log("true", eachDate);
          await this.setState({
            dateRangeSelected: e.target.value,
            startDate: eachDate.startDate,
            endDate: eachDate.endDate,
          });
        }
      });
    }
  };

  toggleCloseModal = async () => {
    await this.toggle();
    await this.setState({
      dateRangeSelected: "",
    });
  };
  toggleConfirmModal = async () => {
    await this.toggle();
    await this.setState({
      dateRangeSelected: 2222,
    });
  };

  render() {
    let modal = this.state.modal;
    let { style, startDate, endDate } = this.state;
    const {
      totalCustomers,
      totalOrders,
      statusReport,
      graphData,
    } = this.state.apiData[0];
    const isData = this.state.isData;
    const propsData = {
      totalCustomers,
      totalOrders,
      statusReport,
      isData,
      startDate,
      endDate
    };
    // console.log(graphData, "graphData");
    let data;
    if (graphData.length > 0) {
      const graphDataModified = graphData[0];
      // console.log(graphDataModified, "graphDataModified");moment(graphItem.LoadDate).format("YYYY-MM-DD")

      data = this.state.dataPointsGraph;
      graphDataModified.map((graphItem, i) => {
        const date = graphItem.LoadDate;
        let object = {
          label: date,
          y: graphItem.orderCount,
          cursor: "pointer",
        };
        data[0].dataPoints[i] = object;
      });
      // console.log(data, "data");
    }
    const options = {
      animationEnabled: true,
      theme: "dark1",
      // colorSet: "greenShades",
      height: 400,
      maxHeight: "100%",
      maxWidth: "100%",
      data: data,
      backgroundColor: "#4caf50", //backgroundColor

      legend: {
        fontColor: "red",
      },
      axisY: {
        title: "Customers",
      },
      axisX: {
        labelAngle: -60,
        title: "Time Line",
        // interval: 2
      },
    };

    return (
      <div className="main-home-page" style={{ paddingBottom: "60px" }}>
        <div className="cover-spin" style={style}>
        </div>

        <div className="container-fluid">
          <div className="col-xl-12">
            <nav
              className="navbar navbar-light "
              style={{ backgroundColor: "none" }}
            >
              <div className="row" style={{ paddingTop: "8px", width: "100%" }}>
                {this.props.auth.user.user_type === "ROSuperAdmin" ? (
                  <div className="col-sm-3">
                    <FormGroup>
                      <Input
                        type="select"
                        name="city"
                        value={this.state.ccid}
                        // style={{ borderColor: "#FF4848" }}
                        id="city"
                        onChange={this.handleChangeCity}
                      >
                        <option value={""} disabled>
                          Select Cities
                        </option>

                        {this.state.unique_cities.map((eachItem) => (
                          <option value={eachItem.ccid} key={eachItem.ccid}>
                            {eachItem.city}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </div>
                ) : (
                    ""
                  )}

                <div className="col-sm-3">
                  <FormGroup>
                    <Input
                      type="select"
                      name="dateRange"
                      value={this.state.dateRangeSelected}
                      id="dateRange"
                      onChange={this.handleChangeDateRange}
                    >
                      <option value={""} disabled>
                        Select Date Range
                      </option>

                      {this.state.uniqueDateRanges.map((eachItem, i) => (
                        <option value={i} key={i}>
                          {eachItem.title}
                        </option>
                      ))}
                      <option value={2222}>
                        {/* <Button onClick={this.toggle}> */}
                        Custom Date Range
                        {/* </Button> */}
                      </option>
                      <div>
                        <Modal
                          isOpen={modal}
                        // toggle={this.toggle}
                        >
                          <ModalHeader>Select Start and End date</ModalHeader>
                          <ModalBody>
                            <div className="row" style={{ minHeight: "1px" }}>
                              <div className="col float-left">
                                <div
                                  className="cal-datepicker"
                                  style={{ height: "100%" }}
                                >
                                  <div
                                    className="datepicker-here text-center"
                                    style={{ height: "100%" }}
                                    data-language="en"
                                  >
                                    <DatePicker
                                      height="400"
                                      placeholderText="Select Start Date"
                                      id="startDateInput"
                                      inline
                                      selectsRange
                                      selected={this.state.startDate}
                                      startDate={this.state.startDate}
                                      onChange={(date) => {
                                        this.setState({
                                          startDate: date,
                                        });
                                      }}
                                      dateFormat="yyyy-MM-dd"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col float-left">
                                <div className="cal-datepicker">
                                  <div
                                    className="datepicker-here text-center"
                                    data-language="en"
                                  >
                                    <DatePicker
                                      height="400"
                                      id="endDateInput"
                                      inline
                                      placeholderText="Select End Date"
                                      selected={this.state.endDate}
                                      onChange={(date) => {
                                        this.setState({
                                          endDate: date,
                                        });
                                      }}
                                      dateFormat="yyyy-MM-dd"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <Button color="info"
                              onClick={this.toggleConfirmModal}

                            >Confirm</Button>{" "}
                            <Button
                              color="danger"
                              onClick={this.toggleCloseModal}
                            >
                              Close
                            </Button>{" "}
                          </ModalFooter>
                        </Modal>
                      </div>
                    </Input>
                  </FormGroup>
                </div>


                <div className="col-sm-2">
                  <button
                    type="submit"
                    className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                    // style={{ backgroundColor: "#FF4848", color: "white" }}
                    onClick={this.handleSearchShipment}
                  >
                    Search
                  </button>
                </div>
                <div className="col-sm-2 ml-auto">
                  {this.state.apiData[0].exportData.length > 0 && (
                    <CSVLink data={this.state.apiData[0].exportData[0]}>
                      <button
                        type="submit"
                        // style={{ backgroundColor: "#FF4848", color: "#ffffff" }}
                        className="btn btn-danger w-100 overflow-hidden mb-3 px-auto"
                      >
                        Export to CSV
                      </button>
                    </CSVLink>
                  )}
                </div>
              </div>
            </nav>
            {/* </div> */}
          </div>

          {this.state.isData && <GeneralWidget data={propsData} />}

          {this.state.isGraphData && (
            <div className="row" style={{ height: "100%" }}>
              <div className="col-xl-12 col-lg-12 mt-5">
                <div
                  className="card"
                  id="card-for-graph-homepage"
                  style={{
                    borderRadius: "0.5rem"
                  }}
                >
                  <div
                    className="card-header p-0"
                    style={{
                      width: "90%",
                      position: "absolute",
                      top: "-20px",
                      right: "0",
                      borderRadius: "0.5rem",
                      boxShadow: "0px 0px 5px 0px #888888",
                      marginLeft: "5%",
                      marginRight: "5%",
                      height: "400px",
                      maxHeight: "400px",
                    }}
                  >
                    <div
                      className="top-sale-chart"
                      style={{ height: "100%", maxHeight: "100%" }}
                    >
                      {this.state.isGraphData && (
                        <CanvasJSChart options={options} />
                      )}
                    </div>

                  </div>
                  {/* <div className="card-body p-1">
                    <h4 style={{ color: "#01CBC6", fontWeight: "bold" }}>
                      Monthly Sales
                    </h4>
                  </div> */}
                </div>
              </div>
            </div>
          )}
        </div>
        {/*Container-fluid Ends*/}
      </div>
    );
  }
}

HomePage.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(HomePage);
