/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import './TripMaster.css';
import axios from "axios";
import PropTypes from "prop-types";
import {
    Button, FormGroup, Input, Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Collapse,
    Card,
    CardBody
} from "reactstrap";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../../common/Alert";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import moment from "moment";
import { AgGridReact } from 'ag-grid-react';
import Pagination from "react-js-pagination";
import "../../../../assets/customCssFiles/loader-spinner.css"
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import XLSX from "xlsx";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class NoticeAdd extends Component {

    state = {
        link: "",
        modal: false,
        locations: [],
        unique_locationids: [],
        unique_segments: [],
        unique_clients: [],
        anotherModal: false,
        index: null,
        AdvpayDate: new Date(),
        description: "",
        startDate: "",
        endDate: "",
        Locationid: null,
        Segment: null,
        ServiceType: "",
        ClientName: null,
        IsVerified: "",
        IsVerifiedValue: "",
        Trip: "",
        selectedArray: [],
        AdvPay: 0,
        activePage: 1,
        itemsCountPerPage: 30,
        totalItemsCount: 5000,
        pageRangeDisplayed: 5,
        columnDefs: [
            {
                headerName: "Trip Id", field: "TripID", sortable: true, filter: true, minWidth: 80
            },
            {
                headerName: "City", field: "LocationID", sortable: true, filter: true
            },
            {
                headerName: "Trip Date", field: "TripDate", sortable: true, filter: true
            },
            {
                headerName: "Vehicle No", field: "Vehiclenumber", sortable: true, filter: true, minWidth: 110
            },
            {
                headerName: "Vehicle Size", field: "VehicleSize", sortable: true, filter: true, minWidth: 110
            },
            {
                headerName: "Service Type", field: "ServiceType", sortable: true, filter: true
            },
            {
                headerName: "Created By", field: "CreatedBy", sortable: true, filter: true
            },
            {
                headerName: "View", field: "View", minWidth: 150, width: 200, maxWidth: 300,
                cellRendererFramework: (params) => {
                    return <Link to={`/admin/tms/trip-master/show-more/${params.node.data.TripID}`}>
                        <Button

                            color="primary"
                            onClick={() =>
                                this.setState({
                                    modal: !this.state.modal
                                })
                            }
                        >
                            <ViewComfyIcon /> View

                  </Button>
                    </Link>
                },
            }
        ],
        rowData: [],
        search: false,
        domLayout: "autoHeight",
        uniqueDateRanges: [],
        dateRangeSelected: "",

        tripIdSearch: "",
        isFilterModalOpen: false,
        isExportLoading: false,
        tmsExportData: [],
        selectedOption: "",
        style: {},

        filterDropDownCities: [],
        filterDropDownClients: [],
        filterDropDownSegments: [],
        filterDropDownServiceTypes: [],
        filterDropDownVendorNames: [],
        filterDropDownVehicleSizes: [],
        filterDropDownVehicleNumbers: [],

        filterSelectedCity: "",
        filterSelectedClient: "",
        filterSelectedSegment: "",
        filterSelectedServiceType: "",
        filterSelectedVendorName: "",
        filterSelectedVehicleSize: "",
        filterSelectedVehicleNumber: "",


        filterSelectedLocationId: null,

        sandeepDropDownCities: [],
        sandeepDropDownClients: [],
        sandeepDropDownSegments: [],
        sandeepDropDownServiceTypes: [],
        sandeepDropDownVendorNames: [],
        sandeepDropDownVehicleSizes: [],
        sandeepDropDownVehicleNumbers: [],

        allClientsAndSegmentsData: []


    };

    getUniqueLocations = async () => {
        await axios.get(`${Config.hostName}/unique/locations`)
            .then(async res => {
                await this.setState({
                    filterDropDownCities: res.data.data
                });
            })
            .catch(error => {
                alert(error)
            });
    }

    getAllClientsAndSegments = async () => {
        await axios.post(`${Config.hostName}/unique/all`, {
            "locationId": "2",
            "clientName": "0"
        })
            .then(async res => {
                const data = res.data.data;
                const clientData = [];
                const segmentData = [];
                const map = data.map((dataItem) => {
                    clientData.push(dataItem)
                    segmentData.push(dataItem)
                })
                const prom = await Promise.all(map)
                const clientUniqueData = [];
                const mapClients = new Map();
                for (const item of clientData) {
                    if (!mapClients.has(item.ClientName)) {
                        mapClients.set(item.ClientName, true);    // set any value to Map
                        clientUniqueData.push(item);
                    }
                }
                const segmentUniqueData = [];
                const mapSegments = new Map();
                for (const item of segmentData) {
                    if (!mapSegments.has(item.Segment)) {
                        mapSegments.set(item.Segment, true);    // set any value to Map
                        segmentUniqueData.push(item);
                    }
                }
                console.log(clientUniqueData, segmentUniqueData);
                await this.setState({
                    filterDropDownClients: clientUniqueData,
                    filterDropDownSegments: segmentUniqueData,
                    allClientsAndSegmentsData: data
                });
            })
            .catch(error => {
                alert(error)
            });
    }

    getUniqueDateRanges = async () => {
        await axios
            .get(
                `${Config.hostName}/shipment/get/data/dashboard/royaloak/unique/date-ranges`
            )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        uniqueDateRanges: res.data.data,
                        startDate: res.data.data.startDate,
                        endDate: res.data.data.endDate,

                    });
                }
            }).catch((err) => {
                alert(err);
            });
    };

    getUniqueServiceTypes = async () => {
        const data = [
            { id: 1, name: "Adhoc" },
            { id: 2, name: "Regular" }
        ];
        await this.setState({
            filterDropDownServiceTypes: data
        })
    }

    getUniqueVendors = async () => {
        await axios.get(`${Config.hostName}/vms/get/unique/fields/tms-report/vendors`)
            .then(async res => {
                const data = res.data.data;
                await this.setState({
                    filterDropDownVendorNames: data
                });
            })
            .catch(error => {
                alert(error);
            });
    }

    getUniqueVehicles = async () => {
        await axios.get(`${Config.hostName}/vms/get/unique/fields/tms-report/vehicles`)
            .then(async res => {
                const data = res.data.data;
                const vehicleSizesUniqueData = [];
                const mapVehicleSizes = new Map();
                for (const item of data) {
                    if (!mapVehicleSizes.has(item.VehicleSize)) {
                        mapVehicleSizes.set(item.VehicleSize, true);    // set any value to Map
                        vehicleSizesUniqueData.push(item);
                    }
                }
                console.log(vehicleSizesUniqueData, 'vehicleSizesUniqueData');
                await this.setState({
                    filterDropDownVehicleNumbers: data,
                    filterDropDownVehicleSizes: vehicleSizesUniqueData
                });
            })
            .catch(error => {
                alert(error);
            });
    }

    getInitialTmsData = async () => {
        let { activePage } = this.state;
        const result = await axios.get(`${Config.hostName}/tms/details/${activePage}`)
        await this.setState({
            style: { display: 'none' },
            tmsExportData: result.data.data,
            rowData: result.data.data.map(eachItem => {
                return {
                    TripID: eachItem.TripID,
                    LocationID: eachItem.city,
                    TripDate: eachItem.TripDate,
                    ReportingTime: moment(eachItem.ReportingTime).format("YYYY-MM-DD hh:mm"),
                    Vehiclenumber: eachItem.VehicleNumber,
                    IsVerified: eachItem.IsVerified,
                    VehicleSize: eachItem.VehicleSize,
                    VendorName: eachItem.VendorName,
                    ServiceType: eachItem.ServiceType,
                    CreatedBy: (eachItem.ServiceType === "Regular") ? "Admin" : eachItem.CreatedBy
                }
            })
        });
    }

    componentDidMount = async () => {
        await this.getUniqueLocations();
        await this.getUniqueDateRanges();
        await this.getUniqueServiceTypes();
        await this.getAllClientsAndSegments();
        await this.getUniqueVendors();
        await this.getUniqueVehicles();
        await this.setState({ search: false })
        await this.getInitialTmsData();
    };

    changeLocation = async event => {
        this.setState({ style: {}, unique_segments: [], unique_clients: [] });
        event.preventDefault();
        await this.setState({ Locationid: event.target.value });
        let { Locationid } = this.state;
        await axios
            .post(`${Config.hostName}/unique/clients`, {
                locationId: Locationid,
                clientName: ""
            })
            .then(res => {
                // console.log(res.data);
                this.setState({
                    unique_clients: res.data.data,
                    style: { display: 'none' }

                });
            })
            .catch(err => {
                console.log(err);
            });
    };

    changeClient = async event => {
        // console.log(event.target.value);
        event.preventDefault();
        await this.setState({ style: {}, unique_segments: [], ClientName: event.target.value });
        let { ClientName, Locationid } = this.state;
        await axios.post(`${Config.hostName}/unique/segments`, {
            locationId: Locationid,
            clientName: ClientName
        })
            .then(res => {
                // console.log(res);
                this.setState({
                    unique_segments: res.data.data,
                    style: { display: 'none' }

                });
            })
            .catch(err => {
                alert(err);
                console.log(err);
            });
        console.log(this.state.unique_segments);
    };

    handlePageChange = async (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        // await this.setState({ activePage: pageNumber, style: {} });
        // await this.componentWillMount();
        console.log(`active page is ${pageNumber}`);
        await this.setState({ activePage: pageNumber, style: {} });
        if (this.state.search === false)
            await this.componentWillMount();
        else
            await this.handleSearchShipment();

    }

    handleSearchShipment = async () => {
        try {
            console.log(this.state.startDate, this.state.endDate)
            await this.setState({ style: {}, search: true })
            let result = await axios.post(
                `${Config.hostName}/tms/search`,

                {
                    tripStartDate: this.state.startDate,
                    tripEndDate: this.state.endDate,
                    locationId: this.state.Locationid,
                    clientName: this.state.ClientName,
                    segment: this.state.Segment,
                    pageNumber: this.state.activePage,
                }
            );
            // console.log(result.data);

            await this.setState({
                style: { display: 'none' },
                tmsExportData: result.data.data,
                rowData: result.data.data.map(eachItem => {
                    return {
                        TripID: eachItem.TripID,
                        LocationID: eachItem.city,
                        TripDate: eachItem.TripDate,
                        ReportingTime: moment(eachItem.ReportingTime).format("YYYY-MM-DD hh:mm"),
                        Vehiclenumber: eachItem.VehicleNumber,
                        ServiceType: eachItem.ServiceType,
                        IsVerified: eachItem.IsVerified,
                        VehicleSize: eachItem.VehicleSize,
                        VendorName: eachItem.VendorName,
                        CreatedBy: eachItem.CreatedBy,

                    }
                })
            });
        } catch (e) { }
    };

    onSubmit = async e => {
        e.preventDefault();
        // console.log(this.state.index, "index");

        // console.log(e.target.value);
        let data = e.target.value;

        // let result = this.state.locations[this.state.index]

        try {
            await this.setState({ style: {} })
            // eslint-disable-next-line
            const res = await axios.post(`${Config.hostName}/tms/verify`, {

                tripId: data,
                user: this.props.auth.user.name,
                isVerified: 1,
                comments: null,
                advPay: null,
                advPayDate: null
            }).then(async (response) => {
                // console.log(response.data, "response");
                if (response.data.success === false) {
                    this.props.setAlert(response.data.message, "danger");

                }
                else {
                    await this.componentWillMount()
                    await this.props.setAlert(`Approved Trip ${data} `, "success");
                }

            }
            ).catch((error) => {
                console.log(error, 'error');

            }
            )



        } catch (error) {
            const errors = error.response.data.errors;
            if (errors) {
                errors.forEach(error => this.props.setAlert(error.msg, "danger"));
            }
        }

    };

    handleChangeDateRange = async (e) => {
        // console.log(e.target.value);
        let value = e.target.value;
        // console.log();2222
        if (value == 2222) {
            await this.setState({
                dateRangeSelected: e.target.value,
                startDate: "",
                endDate: "",
            });
            await this.toggle();
        } else {
            this.state.uniqueDateRanges.filter(async (eachDate, i) => {
                if (i == value) {
                    console.log("true", eachDate);
                    await this.setState({
                        dateRangeSelected: e.target.value,
                        startDate: moment(eachDate.startDate).format("YYYY-MM-DD"),
                        endDate: moment(eachDate.endDate).format("YYYY-MM-DD"),
                    });
                }
            });
        }
    };

    toggle = async () => {
        await this.setState({
            modal: !this.state.modal,
        });
        // this.setState({
        //   modal: !this.state.modal,
        // });
    };

    toggleCloseModal = async () => {
        await this.toggle();
        await this.setState({
            dateRangeSelected: "",
        });
    };

    toggleConfirmModal = async () => {
        await this.toggle();
        await this.setState({
            dateRangeSelected: 2222,
        });
    };

    handleSearchByTripId = async () => {
        console.log(this.state.tripIdSearch);
    }

    toggleOpenFilterModal = async () => {
        await this.setState({
            isFilterModalOpen: !this.state.isFilterModalOpen
        })
    }

    toggleCloseFilterModal = async () => {
        await this.setState({
            isFilterModalOpen: false
        })
    }

    handleExportExcel = async (e) => {
        e.preventDefault();
        var workSheet = XLSX.utils.json_to_sheet(this.state.tmsExportData);
        var workBook = XLSX.utils.book_new();
        var wscols = [
            { wch: 6 },
            { wch: 35 },
            { wch: 15 },
            { wch: 15 },
            { wch: 35 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 }
        ];
        workSheet['!cols'] = wscols;
        XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
        XLSX.writeFile(workBook, 'TMS_Report' + ".xlsx");
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    };

    handleApplyFiltersApiCall = async () => {
        const body = {
            locationId: this.state.filterSelectedLocationId,
            clientName: this.state.filterSelectedClient,
            segment: this.state.filterSelectedSegment,
            serviceType: this.state.filterSelectedServiceType,
            vendorName: this.state.filterSelectedVendorName,
            vehicleNumber: this.state.filterSelectedVehicleNumber,
            startDate: this.state.startDate,
            endDate: this.state.endDate
        }
    }

    getLocationIdByCityName = async (filterSelectedCity) => {
        const { filterDropDownCities } = this.state;
        filterDropDownCities.filter(async (item) => {
            if (item.name == filterSelectedCity) {
                await this.setState({
                    filterSelectedLocationId: item.Locationid
                })
            }
        })
    }

    handleApplyFilters = async (e) => {
        e.preventDefault();
        const {
            filterSelectedCity, filterSelectedClient, filterSelectedSegment, filterSelectedServiceType,
            filterSelectedVendorName, filterSelectedVehicleNumber, startDate, endDate
        } = this.state;
        console.log(
            filterSelectedCity, filterSelectedClient, filterSelectedSegment, filterSelectedServiceType,
            filterSelectedVendorName, filterSelectedVehicleNumber, startDate, endDate
        );
        await this.getLocationIdByCityName(filterSelectedCity);
        await this.setState({
            style: {}
        })
        setTimeout(async () => {
            await this.setState({
                style: { display: 'none' },
            })
        }, 1000);

    }

    handleClearFilters = async (e) => {
        e.preventDefault();
        await this.setState({
            style: {},
            filterSelectedCity: "",
            filterSelectedClient: "",
            filterSelectedSegment: "",
            filterSelectedServiceType: "",
            filterSelectedVendorName: "",
            filterSelectedVehicleSize: "",
            filterSelectedVehicleNumber: "",

            sandeepDropDownCities: [],
            sandeepDropDownClients: [],
            sandeepDropDownSegments: [],
            sandeepDropDownServiceTypes: [],
            sandeepDropDownVendorNames: [],
            sandeepDropDownVendorSizes: [],
            sandeepDropDownVehicleNumbers: [],
        })
        setTimeout(async () => {
            await this.setState({
                style: { display: 'none' },
            })
        }, 1000);

    }

    getClientDataByCityChange = async (selectedCity) => {
        const clientsData = [];
        const mapClient = this.state.allClientsAndSegmentsData.map((client) => {

            if (client.name == selectedCity) {
                clientsData.push(client)
            }
        })
        const promClient = await Promise.all(mapClient)
        const clientUniqueData = [];
        const mapClients = new Map();
        for (const item of clientsData) {
            if (!mapClients.has(item.ClientName)) {
                mapClients.set(item.ClientName, true);    // set any value to Map
                clientUniqueData.push(item);
            }
        }
        return clientUniqueData;
    }

    getSegmentDataByCityChange = async (selectedCity) => {
        const segmentsData = [];
        const mapSegment = this.state.filterDropDownSegments.map((segment) => {
            if (segment.name == selectedCity) {
                segmentsData.push(segment)
            }
        })
        const promSegment = await Promise.all(mapSegment)
        const segmentUniqueData = [];
        const mapSegments = new Map();
        for (const item of segmentsData) {
            if (!mapSegments.has(item.Segment)) {
                mapSegments.set(item.Segment, true);    // set any value to Map
                segmentUniqueData.push(item);
            }
        }
        return segmentUniqueData;
    }

    getVendorNameDataByCityChange = async (selectedCity) => {
        const vendorNamesData = [];
        const mapVendorName = this.state.filterDropDownVendorNames.map((vendor) => {
            if (vendor.name == selectedCity) {
                vendorNamesData.push(vendor)
            }
        })
        const promVendorName = await Promise.all(mapVendorName)
        return vendorNamesData;
    }

    getVehicleSizeDataByCityChange = async (selectedCity) => {
        const vehicleSizesData = [];
        const mapVehicleNumber = this.state.filterDropDownVehicleNumbers.map((vehicle) => {
            if (vehicle.name == selectedCity) {
                vehicleSizesData.push(vehicle)
            }
        })
        const promVehicleNumber = await Promise.all(mapVehicleNumber)
        const vehicleSizeUniqueData = [];
        const mapVehicleSize = new Map();
        for (const item of vehicleSizesData) {
            if (!mapVehicleSize.has(item.VehicleSize)) {
                mapVehicleSize.set(item.VehicleSize, true);    // set any value to Map
                vehicleSizeUniqueData.push(item);
            }
        }
        return vehicleSizeUniqueData;
    }

    getVehicleNumberDataByCityChange = async (selectedCity) => {
        const vehicleNumbersData = [];
        const mapVehicleNumber = this.state.filterDropDownVehicleNumbers.map((vehicle) => {
            if (vehicle.name == selectedCity) {
                vehicleNumbersData.push(vehicle)
            }
        })
        const promVehicleNumber = await Promise.all(mapVehicleNumber)
        return vehicleNumbersData;
    }

    handleChangeFilterSelectedCity = async (e) => {

        const selectedCity = e.target.outerText;

        /** -------------------- Clients ------------------------------- */

        const clientsData = await this.getClientDataByCityChange(selectedCity);
        console.log(clientsData, "clientsData");

        /** -------------------- Segments ------------------------------- */

        const segmentsData = await this.getSegmentDataByCityChange(selectedCity);
        console.log(segmentsData, "segmentsData");

        /** -------------------- Vendor Names ------------------------------- */

        const vendorNamesData = await this.getVendorNameDataByCityChange(selectedCity);
        console.log(vendorNamesData, "vendorNamesData");

        /** -------------------- Vehicle Numbers ------------------------------- */

        const vehicleSizesData = await this.getVehicleSizeDataByCityChange(selectedCity);
        console.log(vehicleSizesData, "vehicleSizesData");

        /** -------------------- Vehicle Numbers ------------------------------- */

        const vehicleNumbersData = await this.getVehicleNumberDataByCityChange(selectedCity);
        console.log(vehicleNumbersData, "vehicleNumbersData");


        /** Update State Values  */

        await this.setState({
            filterSelectedCity: selectedCity,
            sandeepDropDownClients: clientsData,
            sandeepDropDownSegments: segmentsData,
            sandeepDropDownVendorNames: vendorNamesData,
            sandeepDropDownVehicleSizes: vehicleSizesData,
            sandeepDropDownVehicleNumbers: vehicleNumbersData

        });

    }

    handleChangeFilterSelectedClient = async (e) => {
        const selectedClient = e.target.outerText;
        const { filterSelectedCity } = this.state;

        const segmentsData = [];
        const mapClient = this.state.allClientsAndSegmentsData.map((client) => {
            if (filterSelectedCity.length > 0) {
                if (client.ClientName == selectedClient && client.name == filterSelectedCity) {
                    segmentsData.push(client)
                }
            }
            else {
                if (client.ClientName == selectedClient) {
                    segmentsData.push(client)
                }
            }
        })
        const promClient = await Promise.all(mapClient);
        const segmentUniqueData = [];
        const mapSegments = new Map();
        for (const item of segmentsData) {
            if (!mapSegments.has(item.Segment)) {
                mapSegments.set(item.Segment, true);    // set any value to Map
                segmentUniqueData.push(item);
            }
        }
        const cityUniqueData = [];
        const mapCities = new Map();
        for (const item of segmentsData) {
            if (!mapCities.has(item.name)) {
                mapCities.set(item.name, true);    // set any value to Map
                cityUniqueData.push(item);
            }
        }
        await this.setState({
            filterSelectedClient: selectedClient,
            sandeepDropDownSegments: segmentUniqueData,
            sandeepDropDownCities: cityUniqueData
        });
    }

    handleChangeFilterSelectedSegment = async (e) => {
        const selectedSegment = e.target.outerText;
        console.log(selectedSegment);
        if (selectedSegment !== undefined) {

            const { filterSelectedCity, filterSelectedClient } = this.state;
            console.log(filterSelectedCity.length, filterSelectedClient.length);
            const clientsData = [];
            const citiesData = [];
            const mapClient = this.state.allClientsAndSegmentsData.map((client) => {
                if (filterSelectedCity.length > 0) {
                    if (filterSelectedClient.length > 0) {
                        if (client.Segment == selectedSegment && client.name == filterSelectedCity && client.ClientName == filterSelectedClient) {
                            clientsData.push(client);
                            citiesData.push(client);
                        }
                    }
                    else {
                        if (client.Segment == selectedSegment && client.name == filterSelectedCity) {
                            clientsData.push(client);
                            citiesData.push(client);
                        }
                    }
                }
                else {
                    if (filterSelectedClient.length > 0) {
                        if (client.Segment == selectedSegment && client.ClientName == filterSelectedClient) {
                            clientsData.push(client);
                            citiesData.push(client);
                        }
                    }
                    else {
                        if (client.Segment == selectedSegment) {
                            clientsData.push(client);
                            citiesData.push(client);
                        }
                    }

                }
            })
            const promClient = await Promise.all(mapClient);
            const clientUniqueData = [];
            const mapSegments = new Map();
            for (const item of clientsData) {
                if (!mapSegments.has(item.Segment)) {
                    mapSegments.set(item.Segment, true);    // set any value to Map
                    clientUniqueData.push(item);
                }
            }
            const cityUniqueData = [];
            const mapCities = new Map();
            for (const item of citiesData) {
                if (!mapCities.has(item.name)) {
                    mapCities.set(item.name, true);    // set any value to Map
                    cityUniqueData.push(item);
                }
            }

            await this.setState({
                filterSelectedSegment: selectedSegment,
                sandeepDropDownClients: clientUniqueData,
                sandeepDropDownCities: cityUniqueData
            });
        }
        else {
            await this.setState({
                filterSelectedSegment: "",
                sandeepDropDownClients: [],
                sandeepDropDownCities: []
            });
        }
    }

    handleChangeFilterSelectedServiceType = async (e) => {
        await this.setState({
            filterSelectedServiceType: e.target.outerText
        });
    }

    handleChangeFilterSelectedVendorName = async (e) => {
        const selectedVendor = e.target.outerText;
        const vehicleNumbersData = [];
        const mapVehicleNumber = this.state.filterDropDownVehicleNumbers.map((vehicle) => {
            if (vehicle.TransportName == selectedVendor) {
                vehicleNumbersData.push(vehicle)
            }
        })
        const promVehicleNumber = await Promise.all(mapVehicleNumber)
        console.log(vehicleNumbersData, selectedVendor);
        await this.setState({
            filterSelectedVendorName: selectedVendor,
            sandeepDropDownVehicleNumbers: vehicleNumbersData
        });
    }

    handleChangeFilterSelectedVehicleNumber = async (e) => {
        await this.setState({
            filterSelectedVehicleNumber: e.target.outerText
        });
    }

    handleChangeFilterSelectedVehicleSize = async (e) => {
        await this.setState({
            filterSelectedVehicleSize: e.target.outerText
        });
    }

    render() {

        let {
            style, filterDropDownCities, filterDropDownClients, filterDropDownSegments, filterDropDownServiceTypes,
            filterDropDownVendorNames, filterDropDownVehicleNumbers, uniqueDateRanges, modal, sandeepDropDownClients,
            sandeepDropDownSegments, sandeepDropDownVendorNames, sandeepDropDownVehicleNumbers, filterDropDownVehicleSizes,
            sandeepDropDownVehicleSizes, sandeepDropDownCities
        } = this.state
        const defaultColDef = {
            flex: 1,
            minWidth: 100,
            resizable: true
        }

        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;


        return (
            <div>

                <div id="cover-spin" style={style}></div>

                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />

                                <nav
                                    className="navbar navbar-light "
                                    style={{ backgroundColor: "none" }}
                                >
                                    <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                                        <div className="col-sm-2 w-100">
                                            <FormGroup>
                                                <Input
                                                    type="number"
                                                    name="tripIdSearch"
                                                    placeholder="Trip Id"
                                                    value={this.state.tripIdSearch}
                                                    id="tripIdSearch"
                                                    onChange={event => {
                                                        this.setState({ tripIdSearch: event.target.value });
                                                    }}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-sm-2">
                                            <button
                                                className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                                onClick={this.handleSearchByTripId}
                                            >
                                                <i
                                                    className="fa fa-search"
                                                    style={{ marginRight: "5px" }}
                                                />
                                                Search
                                            </button>
                                        </div>

                                        <div className="col-sm-2">
                                            <button
                                                className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                                onClick={this.toggleOpenFilterModal}
                                            >
                                                <i
                                                    className="fa fa-filter"
                                                    style={{ marginRight: "5px" }}
                                                />
                                                Filters
                                            </button>
                                        </div>

                                        <div className="col-sm-2 ml-auto">
                                            {this.state.tmsExportData.length > 0 && (
                                                // <CSVLink data={this.state.exportData}>
                                                <button
                                                    className={(!this.state.tmsExportData.length > 0 && !this.state.tmsExportData.length > 0) ? 'btn btn-danger w-100 overflow-hidden mb-3 px-0 disabled' : 'btn btn-danger w-100 overflow-hidden mb-3 px-0'}
                                                    onClick={this.handleExportExcel}
                                                    disabled={!this.state.tmsExportData && !this.state.tmsExportData.length > 0}
                                                >
                                                    <i
                                                        className="fa fa-file-excel-o"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                    Export
                                                </button>
                                                // </CSVLink>
                                            )}
                                        </div>

                                    </div>
                                </nav>

                                <Collapse isOpen={this.state.isFilterModalOpen}>
                                    <Card>
                                        <CardBody>

                                            <div className="row">

                                                {/** Date Range */}

                                                <div className="col-sm-3">
                                                    <FormGroup>
                                                        <Input
                                                            type="select"
                                                            name="dateRange"
                                                            value={this.state.dateRangeSelected}
                                                            id="dateRange"
                                                            onChange={this.handleChangeDateRange}
                                                        >
                                                            <option className="bms-dashboaord-date-range" value={""} disabled>
                                                                Select Date Range
                                                            </option>

                                                            {uniqueDateRanges.map((eachItem, i) => (
                                                                <option value={i} key={i}>
                                                                    {eachItem.title}
                                                                </option>
                                                            ))}
                                                            <option value={2222}>
                                                                {/* <Button onClick={this.toggle}> */}
                                                                Custom Date Range
                                                                {/* </Button> */}
                                                            </option>
                                                            <div>
                                                                <Modal
                                                                    isOpen={modal}
                                                                // toggle={this.toggle}
                                                                >
                                                                    <ModalHeader>Select Start and End date</ModalHeader>
                                                                    <ModalBody>
                                                                        <div className="row" style={{ minHeight: "1px" }}>
                                                                            <div className="col float-left">
                                                                                <div
                                                                                    className="cal-datepicker"
                                                                                    style={{ height: "100%" }}
                                                                                >
                                                                                    <div
                                                                                        className="datepicker-here text-center"
                                                                                        style={{ height: "100%" }}
                                                                                        data-language="en"
                                                                                    >
                                                                                        <DatePicker
                                                                                            height="400"
                                                                                            placeholderText="Select Start Date"
                                                                                            id="startDateInput"
                                                                                            inline
                                                                                            selectsRange
                                                                                            selected={this.state.startDate}
                                                                                            startDate={this.state.startDate}
                                                                                            onChange={(date) => {
                                                                                                this.setState({
                                                                                                    startDate: date,
                                                                                                });
                                                                                            }}
                                                                                            dateFormat="yyyy-MM-dd"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col float-left">
                                                                                <div className="cal-datepicker">
                                                                                    <div
                                                                                        className="datepicker-here text-center"
                                                                                        data-language="en"
                                                                                    >
                                                                                        <DatePicker
                                                                                            height="400"
                                                                                            id="endDateInput"
                                                                                            inline
                                                                                            placeholderText="Select End Date"
                                                                                            selected={this.state.endDate}
                                                                                            onChange={(date) => {
                                                                                                this.setState({
                                                                                                    endDate: date,
                                                                                                });
                                                                                            }}
                                                                                            dateFormat="yyyy-MM-dd"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ModalBody>
                                                                    <ModalFooter>
                                                                        <Button color="info"
                                                                            onClick={this.toggleConfirmModal}

                                                                        >Confirm</Button>{" "}
                                                                        <Button
                                                                            color="danger"
                                                                            onClick={this.toggleCloseModal}
                                                                        >
                                                                            Close
                            </Button>{" "}
                                                                    </ModalFooter>
                                                                </Modal>
                                                            </div>
                                                        </Input>
                                                    </FormGroup>
                                                </div>

                                                {/** City */}

                                                <div className="col-sm-3" >

                                                    <Autocomplete
                                                        freeSolo
                                                        id="free-solo-demo"
                                                        getOptionLabel={option => option}
                                                        options={sandeepDropDownCities.length === 0 ? filterDropDownCities.map((option) => option.name) : sandeepDropDownCities.map((option) => option.name)}
                                                        onChange={(e) => this.handleChangeFilterSelectedCity(e)}
                                                        value={this.state.filterSelectedCity}
                                                        size="small"

                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                formControlName={'filterSelectedCity'}
                                                                fullWidth
                                                                label={sandeepDropDownCities.length === 0 ? `City (${filterDropDownCities.length})` : `City (${sandeepDropDownCities.length})`}
                                                                name="filterSelectedCity"
                                                                type="text"
                                                                size="small"
                                                                variant="outlined"
                                                            />
                                                        )}

                                                    />
                                                </div>

                                                {/** Client */}

                                                <div className="col-sm-3" >
                                                    <Autocomplete
                                                        freeSolo
                                                        id="free-solo-demo"
                                                        getOptionLabel={option => option}
                                                        options={sandeepDropDownClients.length === 0 ? filterDropDownClients.map((option) => option.ClientName) : sandeepDropDownClients.map((option) => option.ClientName)}
                                                        onChange={(e) => this.handleChangeFilterSelectedClient(e)}
                                                        value={this.state.filterSelectedClient}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                formControlName={'filterSelectedClient'}
                                                                fullWidth
                                                                label={sandeepDropDownClients.length === 0 ? `Client (${filterDropDownClients.length})` : `Client (${sandeepDropDownClients.length})`}
                                                                // label="Client"
                                                                name="filterSelectedClient"
                                                                type="text"
                                                                size="small"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </div>

                                                {/** Segment */}

                                                <div className="col-sm-3" >
                                                    <Autocomplete
                                                        freeSolo
                                                        id="free-solo-demo"
                                                        getOptionLabel={option => option}
                                                        options={sandeepDropDownSegments.length === 0 ? filterDropDownSegments.map((option) => option.Segment) : sandeepDropDownSegments.map((option) => option.Segment)}
                                                        onChange={(e) => this.handleChangeFilterSelectedSegment(e)}
                                                        value={this.state.filterSelectedSegment}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                formControlName={'filterSelectedSegment'}
                                                                fullWidth
                                                                label={sandeepDropDownSegments.length === 0 ? `Segment (${filterDropDownSegments.length})` : `Segment (${sandeepDropDownSegments.length})`}
                                                                // label="Segment"
                                                                name="filterSelectedSegment"
                                                                type="text"
                                                                size="small"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </div>

                                            </div>

                                            <div className="row">


                                                {/** Vendor Name */}

                                                <div className="col-sm-3" >
                                                    <Autocomplete
                                                        filterSelectedOptions
                                                        freeSolo
                                                        getOptionLabel={option => option}
                                                        id="free-solo-demo"
                                                        onChange={(e) => this.handleChangeFilterSelectedVendorName(e)}
                                                        value={this.state.filterSelectedVendorName}
                                                        options={sandeepDropDownVendorNames.length === 0 ? filterDropDownVendorNames.map((option) => option.TransportName) : sandeepDropDownVendorNames.map((option) => option.TransportName)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                formControlName={'filterSelectedVendorName'}
                                                                fullWidth
                                                                label={sandeepDropDownVendorNames.length === 0 ? `Vendor Name (${filterDropDownVendorNames.length})` : `Vendor Name (${sandeepDropDownVendorNames.length})`}
                                                                // label="Vendor Name"
                                                                name="filterSelectedVendorName"
                                                                type="text"
                                                                size="small"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </div>

                                                {/** Vehicle Size */}

                                                <div className="col-sm-3" >
                                                    <Autocomplete
                                                        filterSelectedOptions
                                                        freeSolo
                                                        getOptionLabel={option => option}
                                                        id="free-solo-demo"
                                                        onChange={(e) => this.handleChangeFilterSelectedVehicleSize(e)}
                                                        value={this.state.filterSelectedVehicleSize}
                                                        options={sandeepDropDownVehicleSizes.length === 0 ? filterDropDownVehicleSizes.map((option) => option.VehicleSize) : sandeepDropDownVehicleSizes.map((option) => option.VehicleSize)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                formControlName={'filterSelectedVehicleSize'}
                                                                fullWidth
                                                                label={sandeepDropDownVehicleSizes.length === 0 ? `Vehicle Size (${filterDropDownVehicleSizes.length})` : `Vehicle Size (${sandeepDropDownVehicleSizes.length})`}
                                                                // label="Vehicle Size"
                                                                name="filterSelectedVehicleSize"
                                                                type="text"
                                                                size="small"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </div>

                                                {/** Vehicle No */}

                                                <div className="col-sm-3" >
                                                    <Autocomplete
                                                        filterSelectedOptions
                                                        freeSolo
                                                        getOptionLabel={option => option}
                                                        id="free-solo-demo"
                                                        onChange={(e) => this.handleChangeFilterSelectedVehicleNumber(e)}
                                                        value={this.state.filterSelectedVehicleNumber}
                                                        options={sandeepDropDownVehicleNumbers.length === 0 ? filterDropDownVehicleNumbers.map((option) => option.VehicleNumber) : sandeepDropDownVehicleNumbers.map((option) => option.VehicleNumber)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                formControlName={'filterSelectedVehicleNumber'}
                                                                fullWidth
                                                                label={sandeepDropDownVehicleNumbers.length === 0 ? `Vehicle Number (${filterDropDownVehicleNumbers.length})` : `Vehicle Number (${sandeepDropDownVehicleNumbers.length})`}
                                                                // label="Vehicle Number"
                                                                name="filterSelectedVehicleNumber"
                                                                type="text"
                                                                size="small"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </div>


                                                {/** Service Type */}

                                                <div className="col-sm-3" >
                                                    <Autocomplete
                                                        filterSelectedOptions
                                                        freeSolo
                                                        getOptionLabel={option => option}
                                                        id="free-solo-demo"
                                                        options={filterDropDownServiceTypes.map((option) => option.name)}
                                                        onChange={(e) => this.handleChangeFilterSelectedServiceType(e)}
                                                        value={this.state.filterSelectedServiceType}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                formControlName={'filterSelectedServiceType'}
                                                                fullWidth
                                                                label="Service Type"
                                                                name="filterSelectedServiceType"
                                                                type="text"
                                                                size="small"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-sm-2">
                                                    <button
                                                        className="btn btn-danger w-75 overflow-hidden mb-3 px-0"
                                                        onClick={this.handleApplyFilters}
                                                    >
                                                        <i
                                                            className="fa fa-check"
                                                            style={{ marginRight: "5px" }}
                                                        />
                                                        Apply
                                                    </button>
                                                </div>

                                                <div className="col-sm-2">
                                                    <button
                                                        className="btn btn-danger w-75 overflow-hidden mb-3 px-0"
                                                        onClick={this.toggleCloseFilterModal}
                                                    >
                                                        <i
                                                            className="fa fa-times"
                                                            style={{ marginRight: "5px" }}
                                                        />
                                                        Close
                                                    </button>
                                                </div>

                                                <div className="col-sm-2">
                                                    <button
                                                        className="btn btn-danger w-75 overflow-hidden mb-3 px-0"
                                                        onClick={this.handleClearFilters}
                                                    >
                                                        <i
                                                            className="fa fa-ban"
                                                            style={{ marginRight: "5px" }}
                                                        />
                                                        Clear
                                                    </button>
                                                </div>

                                            </div>

                                        </CardBody>
                                    </Card>
                                </Collapse>

                                <div>

                                    <div
                                        className="ag-theme-balham"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            minHeight: "500px"
                                        }}
                                    >
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            rowData={this.state.rowData}
                                            defaultColDef={defaultColDef}
                                            enableCellTextSelection={true}
                                            rowHeight={50}
                                            domLayout={this.state.domLayout}
                                            // enableCellTextSelection={true}
                                            suppressAutoSize={true}
                                            gridAutoHeight={true}
                                        >
                                        </AgGridReact>
                                    </div>

                                    <div style={{ margin: "9vh auto", marginLeft: "25%", justifyContent: "center" }}>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.itemsCountPerPage}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                                            onChange={this.handlePageChange.bind(this)}
                                            prevPageText='prev'
                                            nextPageText='next'
                                            firstPageText='first'
                                            lastPageText='last'
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);
