import React, { Component, createRef, useState, useEffect } from "react";
import "../app.css";
// Import custom components
import Header from "./common/header/header.component";
import Sidebar from "./common/sidebar/sidebar.component";
import Footer from "./common/footer/footer.component";
import { useScreenshot, createFileName } from "use-react-screenshot";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FormControl from "@mui/material/FormControl";
// import InputUnstyled from "@mui/base/InputUnstyled";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/material";
import axios from "axios";
import Config from "../config";
import DotsLoading from "../assets/images/icons8-dot-bricks.gif";
import SyncIcon from "@mui/icons-material/Sync";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Redirect } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid silver",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const styleImage = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "1px solid silver",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

export default function App(props) {
  const ref = createRef(null);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [respondImage, setRespondImage] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [userName, setUserName] = useState("");
  const [mobile, setMobile] = useState("");
  const [createdBy, setCreatedBy] = useState();
  const [pageID, setPageID] = useState();
  const [btnLoading, setBtnLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [vertical, setvertical] = useState("top");
  const [horizontal, sethorizontal] = useState("center");
  const [showScreenshot, setShowScreenshot] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [textAreaDisabled, setTextAreaDisabled] = useState(false);
  const [openModelImage, setOpenModelImage] = useState(false);

  const download = async (image, { name = "img", extension = "jpg" } = {}) => {
    await setImageLoading(true);
    await axios
      .post(`${Config.hostName}/unique/upload/base64`, {
        baseBinary: image,
      })
      .then(async (res) => {
        if (res.data.success == true) {
          return (
            setRespondImage(res.data.url),
            setImageLoading(false),
            setTextAreaDisabled(false)
          );
        }
      })
      .catch((err) => {
        console.log(console.log(err));
      });
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDescription("");
    setRespondImage(DotsLoading)
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setShowScreenshot(false);
    setOpenModal(false);
  };

  const handleCloseModalImage = () => {
    setOpenModelImage(false);
  };

  useEffect(() => {

    if (!localStorage.getItem("persist:root")) {
     return <Redirect to={`${process.env.PUBLIC_URL}/login`} />
    }


    const userData = localStorage.getItem("persist:root");
    const parseUserData = JSON.parse(userData);
    const authUserData = JSON.parse(parseUserData.auth);

    setUserName(authUserData.name);
    setCreatedBy(authUserData.USERID);
    setPhoneNo(authUserData.mobile);

    const locationString = window.location.href.toString();

    if (locationString.includes("billing")) {
      setPageID(3);
    } else {
      setPageID(2);
    }
  });

  const manageTickets = async () => {
    await setBtnLoading(true);
    await setBtnDisabled(true);

    await axios
      .post(`${Config.hostName}/tk/manageTickets`, {
        jdata: `{"pageurl":"${
          window.location.href
        }","log":"","ismobile":"0","description": "${description}","userName": "${userName}","mobile": "${
          !phoneNo ? "" : phoneNo
        }","createdBY":"${createdBy}","url":"${respondImage}","pageID":"${pageID}"}`,
      })
      .then(async (res) => {
        if (res.data.success == true) {
          setBtnLoading(false);
          setOpen(false);
          setOpenSnack(true);
          setRespondImage(DotsLoading);
          setDescription("");
        } else {
          alert("Unable to to rasie your ticket!");
          setOpen(false);
          setRespondImage(DotsLoading);
          setDescription("");
          setBtnLoading(false);
        }
      })
      .catch((err) => {
        console.log(console.log(err));
      });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const onChangeHandler = (value) => {
    if (description.length >= 5) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }

    setDescription(value);
  };

  return (
    <>
      <a class="whats-app" onClick={handleOpenModal}>
        <i class="fa fa-whatsapp my-float"></i>
      </a>

      <div className="page-wrapper">
        <Header />
        <div className="page-body-wrapper" ref={ref}>
          <Sidebar />
          <div className="page-body" key={props.children}>
            {props.children}
          </div>
        </div>
        <Footer />
      </div>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do yo want to take the screenshot?
          </Typography>
          <Stack
            direction="row"
            spacing={5}
            sx={{
              justifyContent: "center",
              marginTop: "5%",
            }}
          >
            <Button
              variant="contained"
              component="label"
              onClick={() => {
                setOpenModal(false);
                downloadScreenshot();
                setOpen(true);
                setShowScreenshot(true);
                setTextAreaDisabled(true);
              }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              component="label"
              onClick={() => {
                setOpen(true);
                handleCloseModal();
              }}
            >
              No
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openModelImage}
        onClose={handleCloseModalImage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleImage}>
          <img
            src={respondImage}
            style={{
              width: "100%",
              overflow: "scroll",
            }}
          />
        </Box>
      </Modal>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openModelImage}
        onClose={handleCloseModalImage}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <div>
            <DialogTitle
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
              }}
            >
              View Full Image
            </DialogTitle>
          </div>
          <div>
            <DialogActions>
              <Button onClick={handleCloseModalImage}>
                <CancelOutlinedIcon />
              </Button>
            </DialogActions>
          </div>
        </div>
        <DialogContent>
          <Box>
            <img
              src={respondImage}
              style={{
                width: "100%",
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <div>
            <DialogTitle
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
              }}
            >
              Report Issue
            </DialogTitle>
          </div>
          <div>
            <DialogActions>
              <Button onClick={handleClose}>
                <CancelOutlinedIcon />
              </Button>
            </DialogActions>
          </div>
        </div>

        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                cursor: "pointer",
              }}
              onClick={() => setOpenModelImage(true)}
            >
              <img
                style={{
                  width: "50%",
                  margin: "auto",
                  display: showScreenshot == false ? "none" : "block",
                  height: imageLoading == true ? "100%" : "350px",
                }}
                src={imageLoading == true ? DotsLoading : respondImage}
              />
            </Box>
          </Box>

          <FormControl
            fullWidth
            sx={{ m: 1, marginTop: "2%" }}
            variant="filled"
          >
            <textarea
              style={{
                width: "100%",
                fontSize: "0.875rem",
                fontWeight: "400",
                lineHeight: 1.5,
                padding: "12px",
                borderRadius: "8px",
                color: "#1A2027",
                background: "#fff",
                border: "1px solid #E0E3E7",
                boxShadow: "0px 2px 2px #F3F6F9",
              }}
              disabled={textAreaDisabled == true ? true : false}
              placeholder="Enter Your Description..."
              rows={6}
              value={description}
              onChange={(e) => onChangeHandler(e.target.value)}
            />
          </FormControl>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1.5%",
            }}
          >
            <Button
              variant="contained"
              disabled={btnDisabled == true ? true : false}
              sx={{
                width: "25%",
              }}
              onClick={manageTickets}
            >
              {btnLoading == true ? <SyncIcon className="rotate" /> : "Send"}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="success"
          sx={{ width: "100%" }}
        >
          Successfully raised your issue!
        </Alert>
      </Snackbar>
    </>
  );
}
