import React, { Component } from "react";
import {
    Card, CardText,
    CardTitle, CardSubtitle
} from 'reactstrap';
import PropTypes from "prop-types";
import { connect } from "react-redux";


class Example extends Component {

    render() {
        return (
            <div>
                <Card body>
                    <CardTitle>Welcome To Logicarts Admin Dashboard</CardTitle>
                    {/* {console.log(this.props.auth.user, "ccid")} */}

                    <CardSubtitle>You have given access as <strong>{this.props.auth.user.user_type}</strong> </CardSubtitle>
                    <br />
                    {/* <CardText>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium labore accusantium id voluptas nisi dolor,
                        doloribus cupiditate
                        vero magni tenetur eligendi numquam minus doloremque, saepe repellendus sequi odio exercitationem sit.
                    </CardText> */}
                    <br />
                    <CardText>
                        Please use the sidebar to navigate through app
                    </CardText>

                    {/* <CardBody>
                        <Button>Go somewhere</Button>
                        <br />

                    </CardBody> */}
                    <CardText>
                        If you face any issues feel free to drop an email at &nbsp;
<strong>sandeep@logicarts.in</strong>

                    </CardText>
                </Card>
            </div>
        );
    }
};


// export default Example;

Example.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {})(Example);
