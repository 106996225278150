import React, {
    Component
    // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import './layout.css';
import Select from '@mui/material/Select';
import { Paper, CardContent, Modal, Backdrop, Fade, Box, Card, CardMedia, Collapse, Alert, AlertTitle, Toolbar, AppBar, Dialog, Slide, Tooltip, IconButton, Grid, Container, Typography, TextField, Button, FormControl, MenuItem, InputLabel } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import PlusIcon from '@mui/icons-material/Add';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from '@mui/icons-material/Download';

import MUIDataTable from "mui-datatables";
import { Download } from "@mui/icons-material";
import { saveAs } from 'file-saver';

const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};


const style3 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};


const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                },
            },
        },
    },
    palette: {
        primary: {
            light: '#757ce8',
            main: '#f44336',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});


const options = {
    filterType: 'multiselect',
    // filterOptions: {fullWidth: true},
    selectableRows: 'none',
    setRowProps: row => {
        if (row[0] === "New") {
            return {
                style: { background: "snow" }
            };
        }
    },
    draggableColumns: { enabled: true },
    pagination: true,
    // resizableColumns: true,
    // responsive: "standard",
    fixedHeader: false,
    downloadOptions: {
        filterOptions: {
            useDisplayedRowsOnly: true,
        }
    }
};



class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {},
            selectedDate: new Date(),
            heading: '',
            unique_cities: [],
            locationID: 'All',
            unique_payeeTypes: [],
            payeeType: 'All',
            successText: '',
            alertState: false,
            severity: '',
            severity_text: '',
            modalImage: '',
            open: false,
            panText: '',
            pmsID: '',
            firstname: '',
            bankDetails: [],
            openBankModal: false,
            projectCnt: 0,
            pms_stats: [],
            rowData: [],
            columns: [
                {
                    name: "PMSID",
                    label: "ID",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },



                {
                    name: "fullName",
                    label: "Name",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value == null || value == '') {
                                return (
                                    <div style={{ textAlign: 'center' }}>
                                        -
                                    </div>

                                )
                            } else {
                                return (
                                    <>
                                        {value}
                                    </>

                                )
                            }

                        }
                    },
                },

                {
                    name: "city",
                    label: "city",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value == null || value == '') {
                                return (
                                    <div style={{ textAlign: 'center' }}>
                                        -
                                    </div>

                                )
                            } else {
                                return (
                                    <>
                                        {value}
                                    </>

                                )
                            }

                        }
                    }
                },
                {
                    name: "contactNumber",
                    label: "Mobile",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value == null || value == '') {
                                return (
                                    <div style={{ textAlign: 'center' }}>
                                        -
                                    </div>

                                )
                            } else {
                                return (
                                    <>
                                        {value}
                                    </>

                                )
                            }

                        }
                    }
                },

                {
                    name: "PMSType",
                    label: "Payee Type",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value == null || value == '') {
                                return (
                                    <div style={{ textAlign: 'center' }}>
                                        -
                                    </div>

                                )
                            } else {
                                return (
                                    <>
                                        {value}
                                    </>

                                )
                            }

                        }
                    }
                },

                {
                    name: "BeneficaryName",
                    label: "Beneficary Name",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value == null || value == '') {
                                return (
                                    <div style={{ textAlign: 'center' }}>
                                        -
                                    </div>

                                )
                            } else {
                                return (
                                    <>
                                        {value}
                                    </>

                                )
                            }

                        }
                    }
                },


                {
                    name: "AccountNumber",
                    label: "Account No",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value == null || value == '') {
                                return (
                                    <div style={{ textAlign: 'center' }}>
                                        -
                                    </div>

                                )
                            } else {
                                return (
                                    <>
                                        {value}
                                    </>

                                )
                            }

                        }
                    }
                },


                {
                    name: "BankName",
                    label: "Bank Name",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value == null || value == '') {
                                return (
                                    <div style={{ textAlign: 'center' }}>
                                        -
                                    </div>

                                )
                            } else {
                                return (
                                    <>
                                        {value}
                                    </>

                                )
                            }

                        }
                    }
                },


                {
                    name: "IsPrimary",
                    label: "Is Primary",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {

                            return (
                                <>
                                    {value ? 'Yes' : 'No'}
                                </>

                            )

                        }
                    }
                },


                {
                    name: "PMSType",
                    label: "Payee Type",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value == null || value == '') {
                                return (
                                    <div style={{ textAlign: 'center' }}>
                                        -
                                    </div>

                                )
                            } else {
                                return (
                                    <>
                                        {value}
                                    </>

                                )
                            }

                        }
                    }
                },

                // {
                //     name: "BeneficaryName",
                //     label: "Beneficary Name",
                //     options: {
                //         filter: true,
                //         sort: true,
                //         customBodyRenderLite: (dataIndex) => {
                //             return (
                //                 <div style={{ textAlign: 'center' }}>
                //                     <Tooltip title="View Bank Details">
                //                         <Button
                //                             // component={RouterLink}
                //                             size="small"
                //                             onClick={(e) =>
                //                                 this.handleOpenBankModal(dataIndex)
                //                             }
                //                             style={{ color: "black", minWidth: "40px" }}
                //                         >
                //                             <RemoveRedEyeIcon />
                //                         </Button>
                //                     </Tooltip>
                //                 </div>
                //             )

                //         }
                //     }
                // },




                {
                    name: "panNO",
                    label: "Pan details",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            
                            if (value) {
                                return (
                                    <Tooltip title="View Pan Card">
                                        <div className="panNo" onClick={(e) => this.handleOpenModal(tableMeta.rowIndex)}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                )
                            } else {
                                return (
                                    <div >
                                        -
                                    </div>
                                )
                            }


                        }
                    }
                },


                {
                    name: "RegisteredDate",
                    label: "Registered Date",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value == null || value == '') {
                                return (
                                    <div style={{ textAlign: 'center' }}>
                                        -
                                    </div>

                                )
                            } else {
                                const dateString = value;
                                const date = new Date(dateString);

                                const formattedDate = new Intl.DateTimeFormat('en-US', {
                                    month: 'short',
                                    day: '2-digit',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true,
                                }).format(date);

                                return (
                                    <>
                                        {formattedDate}
                                    </>

                                )
                            }

                        }
                    }
                },


            ],


        };
        this.validator = new SimpleReactValidator();
    }

    delay = async (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }


    getNewRequests = async () => {

      

        let locationID = this.state.locationID;
        if (locationID == "All") {
            const locationIds = [];
            await this.state.unique_cities.map((eachLocation, i) => {
                locationIds.push(eachLocation.Locationid)
            })
            locationID = locationIds.toString();
        }

        let payeeType = this.state.payeeType;
        if (payeeType == "All") {
            const payeeTypes = [];
            await this.state.unique_payeeTypes.map((eachLocation, i) => {
                payeeTypes.push(eachLocation.PMStypeID)
            })
            payeeType = payeeTypes.toString();
        }




        const res = await axios.post(
            `${Config.hostName}/pms/getPayeePrimaryBankDetails`,
            {
                "pmsTypeId": payeeType,
                "locationId": locationID
            }
        )
            .then(async (res) => {
                // console.log(res.data.data, 'project');
                if (res.data.data.length > 0) {
                    this.setState({
                        successText: '',
                        alertState: false,
                        severity: '',
                        severity_text: '',
                    })
                    await this.setState({
                        rowData: res.data.data
                    });
                } else {
                    await this.setState({
                        rowData: [],
                        successText: 'No Recoords Found',
                        alertState: true,
                        severity: 'error',
                        severity_text: 'Error'
                    });

                }
            })
            .catch((error) => {
                alert(error);
            });
    }


    getUniqueCities = async () => {
        const res = await axios.get(
            `${Config.hostName}/unique/locations`,
            {

            }
        )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    this.setState({
                        unique_cities: res.data.data,
                    });
                } else {
                    this.setState({
                        unique_cities: [],
                    });
                }
            })
            .catch((error) => {
                alert(error);
            });
    }

    getPayeeTypes = async () => {
        const res = await axios.get(
            `${Config.hostName}/pms/get/payeeType/dropdown`,
            {
                // ManagerID: 113
            }
        )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        unique_payeeTypes: res.data.data
                    });
                } else {
                    this.setState({
                        unique_payeeTypes: [],
                    });
                }
            })
            .catch((error) => {
                alert(error);
            });
    }



    getStats = async () => {

        const res = await axios.get(
            `${Config.hostName}/pms/payeeMasterStats`,
            {
                // ManagerID: 113
            }
        )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    let projectCnt = 0;
                    await res.data.data.map(async (e, key) => {
                        projectCnt += e.cnt;
                    })
                    
                    await this.setState({
                        pms_stats: res.data.data,
                        projectCnt: projectCnt
                    });
                } else {
                    this.setState({
                        pms_stats: [],
                    });
                }
            })
            .catch((error) => {
                alert(error);
            });
    }


    async componentDidMount() {
        await this.setState({
            style: {}
        });

        this.getStats();
        await this.getUniqueCities();
        await this.getPayeeTypes();

        await this.getNewRequests();
        await this.setState({
            style: {
                display: "none"
            }
        });
    }


    handleChangeLocation = async (e) => {
        if (e.target.value == "All") {
            await this.setState({
                locationID: 'All',
            });
        } else {
            await this.setState({
                locationID: e.target.value,
            });
        }

        this.getNewRequests();
    };


    handleChangePayeeType = async (value) => {
        await this.setState({
            style: {}
        });
        if (value == "All") {
            await this.setState({
                payeeType: 'All',
            });
        } else {
            await this.setState({
                payeeType: value,
            });
        }

        await this.getNewRequests();
        await this.setState({
            style: {
                display: "none"
            }
        });
    };

    handleOpenModal = async (dataIndex) => {

        await this.setState({ modalImage: this.state.rowData[dataIndex].panURL, panText: this.state.rowData[dataIndex].panNO, pmsID: this.state.rowData[dataIndex].PMSID, firstname: this.state.rowData[dataIndex].fullName });

        await this.setState({ open: true });
    };

    handleCloseModal = async (e) => {
        await this.setState({ open: false });
    };

    handleOpenBankModal = async (index) => {
        await this.setState({
            openBankModal: true,
            bankDetails: this.state.rowData[index]
        });
    }

    handleCloseBankModal = async () => {
        await this.setState({
            openBankModal: false,
        });

    }



    handleDownloadPanCard = async (url, pmsID, fullName) => {
        let extension = '';
        if (url) {
            extension = url;
            extension = extension.split('.').pop();
        }

        const postData = {
            url: url
        };

        const n2 = `${fullName}_${pmsID}_PAN.${extension}`;

        fetch(`${Config.ptlHostName}/common/downloadFiles`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
            .then((res) => res.blob())
            .then((blob) => {
                saveAs(blob, n2);
            });

    };


    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    render() {


        return (
            <div>
                <ThemeProvider theme={theme}>
                    <Container pt={3} style={{ maxWidth: 'inherit', background: 'white', paddingBottom: "12%", marginTop: '7%' }} >
                        <div id='cover-spin' style={this.state.style}></div>

                        <Grid pt={3} container>
                            <Grid item md={12} mb={1}  >
                                <Collapse in={this.state.alertState}>
                                    <Alert onClose={() => {
                                        this.setState({
                                            successText: '',
                                            alertState: false,
                                            severity: '',
                                            severity_text: '',
                                        })
                                    }} severity={this.state.severity}>
                                        <AlertTitle>{this.state.severity_text}</AlertTitle>
                                        {this.state.successText}
                                    </Alert>
                                </Collapse>
                            </Grid>

                            <Grid item md={3} className="mt-2">
                                <FormControl
                                    variant="standard"
                                    sx={{ width: "25vh" }}
                                    style={{ marginLeft: "0px" }}
                                >
                                    <InputLabel id="Location">Location</InputLabel>
                                    <Select
                                        labelId="Location"
                                        id="Location"
                                        value={this.state.locationID}
                                        onChange={(e) => {
                                            this.handleChangeLocation(e);
                                        }}
                                        label="Location"
                                        variant="standard"
                                    >
                                        <MenuItem value="All">
                                            All
                                        </MenuItem>

                                        {this.state.unique_cities.map((eachItem, key) => (
                                            <MenuItem value={eachItem.Locationid} key={key}>
                                                {eachItem.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item md={3} className="mt-2">
                                <FormControl
                                    variant="standard"
                                    sx={{ width: "25vh" }}
                                    style={{ marginLeft: "0px" }}
                                >
                                    <InputLabel id="payeeType">Payee Type</InputLabel>
                                    <Select
                                        labelId="payeeType"
                                        id="payeeType"
                                        value={this.state.payeeType}
                                        onChange={(e) => {
                                            this.handleChangePayeeType(e.target.value);
                                        }}
                                        label="Location"
                                        variant="standard"
                                    >
                                        <MenuItem value="All">
                                            All
                                        </MenuItem>


                                        {this.state.unique_payeeTypes.map((eachItem, key) => (
                                            <MenuItem value={eachItem.PMStypeID} key={key}>
                                                {eachItem.PMSType}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>



                            <Grid style={{ float: 'right', textAlign: 'right' }} item md={8}>
                                {/* <Button onClick={(e) => this.handleOpenFullModal('add', e)} size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }} endIcon={<PlusIcon />}>
                                Add Client
                            </Button> */}
                            </Grid>


                        </Grid>

                        <Grid mt={3}>
                            {this.state.pms_stats != [] &&
                                <Grid item md={12} className="mt-2">
                                    <Typography variant="h6" color="inherit" component="div" style={{ textAlign: 'center', fontSize: '29px' }}>
                                        {this.state.pms_stats != 0 && <>
                                            Total Payees :- <b style={{ color: 'red' }}>{this.state.projectCnt}</b>
                                        </>
                                        }
                                    </Typography>
                                </Grid>
                            }
                            <Grid item md={10} className="mt-2">
                                <Grid pt={1} container spacing={1}>
                                    {/* here */}
                                    {this.state.pms_stats != [] && this.state.pms_stats.map((eachItem, key) => (
                                        <Grid item md={2} className="mt-2 mb-2 ">
                                            <Paper elevation={6}>
                                                <Card
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    className="hoverBoder"
                                                    onClick={(e)=>this.handleChangePayeeType(eachItem.PMSTypeID)}
                                                >
                                                    <CardContent
                                                        style={{
                                                            textAlign: "center",
                                                            maxHeight: "135px"
                                                        }}
                                                    >
                                                        <Typography
                                                            mb={2}
                                                            style={{
                                                                fontSize: "12px",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {eachItem.PMSType}
                                                        </Typography>
                                                        <Grid container spacing={2} mb={2}>

                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    style={{
                                                                        fontWeight: 600,
                                                                        color: "#ff4700c9",
                                                                        fontSize: "14px",
                                                                    }}
                                                                >
                                                                    {eachItem.cnt}
                                                                </Typography>
                                                                <Typography
                                                                    style={{
                                                                        fontSize: "12px",
                                                                        fontWeight: 300,
                                                                    }}
                                                                >
                                                                    {/* Payee Count */}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Paper>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>

                            <br />

                            <Grid item md={8}>
                                <MUIDataTable
                                    title={`Payee Central-${this.state.rowData.length}`}
                                    data={this.state.rowData}
                                    columns={this.state.columns}
                                    options={options}
                                />
                            </Grid>

                        </Grid>

                    </Container>
                </ThemeProvider>





                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.open}
                    onClose={this.handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.open}>
                        <Box sx={style2}>

                            <Grid container>
                                <Grid className="panText" item md={12}>
                                    Pan No: {this.state.panText}
                                </Grid>

                                <Grid item md={12}>
                                    <Card style={{}} className="pan-image">

                                        <CardMedia
                                            component="img"
                                            height="auto"
                                            image={this.state.modalImage}
                                            alt="Error"
                                        />
                                    </Card>
                                </Grid>
                                <Grid className="panDownload" item md={12}>
                                    <Button
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        style={{
                                            marginTop: "18px",
                                            background: "rgb(234 67 68)",
                                            color: "white",
                                        }}
                                        onClick={(e) => this.handleDownloadPanCard(this.state.modalImage, this.state.pmsID, this.state.firstname)}
                                        endIcon={<DownloadIcon />}
                                    >
                                        Download
                                    </Button>
                                </Grid>

                            </Grid>



                        </Box>
                    </Fade>
                </Modal>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.openBankModal}
                    onClose={this.handleCloseBankModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openBankModal}>
                        <Box sx={style3}>

                            <Grid container className="borderBank">
                                <Grid className="panText" style={{ fontWeight: '700' }} item md={12}>
                                    Bank Details
                                </Grid>

                                <Grid className="panText bankMargin" item md={6}>
                                    <Grid container>
                                        <Grid item md={6}>
                                            Beneficiary:
                                        </Grid>

                                        <Grid item md={6}>
                                            <b> {this.state.bankDetails.BeneficaryName ? this.state.bankDetails.BeneficaryName : 'N/A'}</b>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className="panText bankMargin" item md={5}>
                                    <Grid container>
                                        <Grid item md={6}>
                                            Account No:
                                        </Grid>

                                        <Grid item md={6}>
                                            <b> {this.state.bankDetails.AccountNumber ? this.state.bankDetails.AccountNumber : 'N/A'}</b>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className="panText bankMargin" item md={6}>
                                    <Grid container>
                                        <Grid item md={6}>
                                            Bank Name:
                                        </Grid>

                                        <Grid item md={6}>
                                            <b>  {this.state.bankDetails.BankName ? this.state.bankDetails.BankName : 'N/A'}</b>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className="panText bankMargin" item md={5}>
                                    <Grid container>
                                        <Grid item md={6}>
                                            IFSC:
                                        </Grid>

                                        <Grid item md={6}>
                                            <b>  {this.state.bankDetails.IFSC ? this.state.bankDetails.IFSC : 'N/A'}</b>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className="panText bankMargin" item md={6}>
                                    <Grid container>
                                        <Grid item md={6}>
                                            Is Primary:
                                        </Grid>

                                        <Grid item md={6}>
                                            <b>   {this.state.bankDetails.IsPrimary ? 'Yes' : 'No'}</b>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid className="panDownload" item md={12}>
                                    <Button
                                        type="submit"
                                        size="small"
                                        variant="contained"
                                        style={{
                                            marginTop: "35px",
                                            background: "rgb(234 67 68)",
                                            color: "white",
                                        }}
                                        onClick={(e) => this.handleCloseBankModal()}
                                    >
                                        Close
                                    </Button>
                                </Grid>

                            </Grid>



                        </Box>
                    </Fade>
                </Modal>


            </div>


        );
    }
}

HomePage.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(HomePage);
