import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logout } from "../../../redux/actions/user.actions";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { Button } from "reactstrap";
import { withRouter } from "react-router";

class UserMenu extends Component {
    logOut(e) {
        e.preventDefault();
        this.props.logout();
    }

    render() {
        return (
            <Fragment >
                {!this.props.auth.isAuthenticated ? (
                    <Fragment>
                        <div className="media  align-items-center" style={{ height: "100%" }}>
                            <div className="media-body" style={{ height: "100%" }}>
                                <Link to="/login">
                                    <Button className="btn-square" color="primary btn-md">
                                        Login
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Fragment>
                ) : (
                        <li className="onhover-dropdown" style={{ height: "100%" }}>
                            <div className="media  align-items-center" style={{ height: "100%" }}>
                                <img
                                    className="align-self-center pull-right mr-2"
                                    src={require("./user-icon.png")}
                                    width="40" height="40"
                                    style={{ marginTop: "0vh", marginBottom: "1vh", marginLeft: "2vh" }}
                                    alt="header-user"
                                />
                                <div className="media-body">
                                    <h6 className="m-0 txt-dark f-16">
                                        {this.props.auth.user.name}
                                        <i className="fa fa-angle-down pull-right ml-2"></i>
                                    </h6>
                                </div>
                            </div>

                            <ul className="profile-dropdown onhover-show-div p-20">
                                <li>
                                    <Link to="/" onClick={this.logOut.bind(this)} >
                                        <i className="icon-power-off"></i>
                                        Logout</Link>
                                </li>
                            </ul>

                        </li>
                    )}
            </Fragment>
        );
    }
}

UserMenu.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default compose(
    withRouter,
    connect(mapStateToProps, { logout })
)(UserMenu);
