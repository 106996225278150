import React, { Component } from "react";

class Footer extends Component {
  render() {
    let hrefLink = "#";
    return (
      // <div>
      <footer className="main-footer  fixed-bottom">
        <p>2021 © Logicarts admin</p>
        <div className="pull-right hidden-xs">
          <ul>
            <li>
              <a href={hrefLink}>About</a>
            </li>
            <li>
              <a href={hrefLink}>Privacy</a>
            </li>
            <li>
              <a href={hrefLink}>T&C</a>
            </li>
          </ul>
        </div>
      </footer>
      // </div>
    );
  }
}

export default Footer;
