/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from "react";
import axios from "axios";
import Config from "../../../../config";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/alert.actions";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import "../../../../assets/customCssFiles/calender.css"
import CountUp from "react-countup";
import "../../../../assets/customCssFiles/bmsViewReqPayee.css";
import "../../../../assets/customCssFiles/bmsDashboard.css";
// import "../../../../assets/customCssFiles/tableInvoice.css";
import { AgGridReact } from 'ag-grid-react';


import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    // FormGroup,
    Input, Table,
    Card, CardText, CardImg,
    CardBody,
    CardTitle, CardSubtitle
} from "reactstrap";
import "../../../../assets/customCssFiles/loader-spinner.css"
import pdfIcon from "../../../../assets/customImages/pdfIcon.png";
import excelIcon from "../../../../assets/customImages/excel.jpg";
import CloseIcon from '@material-ui/icons/Close';
import CategoryIcon from '@material-ui/icons/Category';
import PersonIcon from '@material-ui/icons/Person';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SearchIcon from '@material-ui/icons/Search';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import DeleteIcon from '@material-ui/icons/Delete';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BankIcon from "../../../../assets/customImages/bankIconPms.png";
import * as XLSX from 'xlsx';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import FilterListIcon from "@material-ui/icons/FilterList";

import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { saveAs } from "file-saver";
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import MessageIcon from '@material-ui/icons/Message';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { enCA } from "date-fns/locale";
import { withThemeCreator } from "@material-ui/styles";


class HomePage extends Component {


    constructor(props) {
        super(props);

        this.state = {
            apiData: [],
            startDate: "",
            endDate: "",
            reportId: "",
            style: {},
            tableStyle: { display: "none" },
            isData: false,
            isOpen: false,
            userCcId: "",
            ccid: "",
            uniqueDateRanges: [],
            dateRangeSelected: "",
            modal: false,
            statusKeys: [],
            statusCode: "",
            status: "",
            bmsId: "",
            statusDropdown: [],
            StatusID: "",
            columnDefs: [
                {
                    headerName: "Request ID", field: "RequestorID", sortable: true, filter: true, minWidth: 50, maxWidth: 200, width: 50,
                    cellRendererFramework: (params) => {

                        return (<div>
                            {params.node.data.requestStatus != "Rejected" ?
                                <span style={{ fontWieght: "bold" }} >{params.node.data.RequestorID}</span> :
                                <span style={{ fontWieght: "bold", color: "#878787" }} >{params.node.data.RequestorID}</span>
                            }
                            {params.node.data.newChat == 1 && <span style={{ color: 'red', fontSize: '26px' }}>*</span>}


                        </div>

                        )
                    }
                },
                {
                    headerName: "City", field: "requestorCity", sortable: true, filter: true, minWidth: 50, maxWidth: 200, width: 50,
                    cellRendererFramework: (params) => {

                        return (<div>
                            {params.node.data.requestStatus != "Rejected" ?
                                <span style={{ fontWieght: "bold" }} >{params.node.data.requestorCity}</span> :
                                <span style={{ fontWieght: "bold", color: "#878787" }} >{params.node.data.requestorCity}</span>
                            }

                        </div>

                        )
                    }
                },
                {
                    headerName: "Requestor", field: "requestor", sortable: true, filter: true, minWidth: 50, maxWidth: 200, width: 50,
                    cellRendererFramework: (params) => {

                        return (<div>
                            {params.node.data.requestStatus != "Rejected" ?
                                <span style={{ fontWieght: "bold" }} >{params.node.data.requestor}</span> :
                                <span style={{ fontWieght: "bold", color: "#878787" }} >{params.node.data.requestor}</span>
                            }

                        </div>

                        )
                    }
                },
                {
                    headerName: "Request Type", field: "requestType", sortable: true, filter: true, minWidth: 50, maxWidth: 200, width: 50,
                    cellRendererFramework: (params) => {

                        return (<div>
                            {params.node.data.requestStatus != "Rejected" ?
                                <span style={{ fontWieght: "bold" }} >{params.node.data.requestType}</span> :
                                <span style={{ fontWieght: "bold", color: "#878787" }} >{params.node.data.requestType}</span>
                            }

                        </div>

                        )
                    }
                },

                {
                    headerName: "Created Date", field: "createddate", sortable: true, filter: true,
                    cellRendererFramework: (params) => {
                        const createddate = params.node.data.createddate;
                        let date1 = new Date(createddate).toLocaleDateString();
                        return (<div>
                            {params.node.data.requestStatus != "Rejected" ?
                                <span style={{ fontWieght: "bold" }} >{date1}</span> :
                                <span style={{ fontWieght: "bold", color: "#878787" }} >{date1}</span>
                            }

                        </div>

                        )
                    }
                },

                {
                    // Payment Done

                    headerName: "Status", field: "requestStatus", sortable: true, filter: true,
                    cellRendererFramework: (params) => {
                        const status = params.node.data.requestStatus;
                        const StatusColor = (status === "Payment Done") ? "text-success" :
                            (status === "Pending Approval") ? "text-danger" :
                                "";
                        return (<div>
                            {params.node.data.requestStatus != "Rejected" ?
                                <span className={StatusColor} style={{ fontWieght: "bold", }} >{status}</span> :
                                <span className={StatusColor} style={{ fontWieght: "bold", color: "#878787" }} >{status}</span>
                            }


                        </div>

                        )
                    }
                },
                {
                    headerName: "View", field: "View",
                    cellRendererFramework: (params) => {
                        return <div>
                            <MessageIcon onClick={() => this.onClickOpenMessModal(params.node.data.RequestorID)} />
                            {params.node.data.newChat == 1 && <span style={{ color: 'red', fontSize: '26px' }}>*</span>}
                        </div>

                    }
                },
                // {
                //     headerName: "Update Status", field: "Status",
                //     cellRendererFramework: (params) => {

                //         return <div id={params.node.data.RequestorID}>
                //             {params.node.data.requestStatus != "Rejected" &&
                //                 <FormControl fullWidth>

                //                     <Input
                //                         type="select"
                //                         name="StatusID"
                //                         onChange={(e) => this.handleUpdateStatus(params.node.data.RequestorID, params.node.data.requestTypeID, e)}
                //                     >
                //                         <option value={""} disabled selected>Select Status</option>
                //                         {this.state.statusDropdown.map((StatusID, i) => (
                //                             <option key={i} value={StatusID.StatusID}>{StatusID.status}</option>
                //                         ))}

                //                     </Input>
                //                 </FormControl>
                //             }

                //         </div>

                //     }
                // }

            ],
            domLayout: "autoHeight",
            rowData: [],
            activePage: 1,
            isExportLoading: false,
            isSelectWidget: false,
            rotation: 0,
            notesMain: [],
            isModalDataStepper: false,
            modalDataStepper: [],
            payeeDetails: null,
            payeekey: null,
            exportData: [],
            search_val: '',
            isViewRequestPageData: false,
            NoteHistory: [],
            addMessage: '',
            isImageAttachmentModal: false,
            imageUrlModal: "",
            uploading: "Type Something",


        };
    }



    toggle = async () => {
        await this.setState({
            modal: !this.state.modal,
        });
    };

    setStartEndDates = async () => {
        const dateObj = new Date();
        const month = dateObj.getUTCMonth() + 1; //months from 1-12
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        // eslint-disable-next-line no-useless-concat
        const newMon = String(month).length == 1 ? `0${month}` : month
        const newDate = String(day).length == 1 ? `0${day}` : day;
        // const iDate = `2020-12-01T00:00:00.000Z`
        // const mDate = year + "-" + month + "-" + day;
        const mDate = `${year}-${newMon}-${newDate}T23:59:00.000Z`;
        const iDate = `${year}-${newMon}-01T00:00:00.000Z`;

        await this.setState({
            startDate: iDate,
            endDate: mDate
            // startDate: "2020-12-01",
            // endDate: "2020-12-31"
        })
    };

    getData = async () => {

        console.log(this.props.auth.user.user_type);
        let ManagerView = 0;
        if (this.props.auth.user.user_type === 'Manager') {
            ManagerView = 1;
        }
        const res = await axios.post(
            `${Config.hostName}/hrconnect/getDashboardDetails`,
            {
                StartDate: this.state.startDate,
                EndDate: this.state.endDate,
                userID: this.props.auth.user.USERID,
                ManagerView: 0
            }
        )
            .then(async (res) => {
                console.log(res.data, "api data");
                if (res.data.data.length > 0) {
                    await this.setState({
                        apiData: res.data.data,
                        exportData: res.data.data,
                        rowData: res.data.data.map(eachItem => {
                            return {
                                RequestorID: eachItem.RequestorID,
                                createddate: eachItem.createddate,
                                directto: eachItem.directto,
                                requestStatus: eachItem.requestStatus,
                                requestType: eachItem.requestType,
                                requestorCity: eachItem.requestorCity,
                                Createdby: eachItem.Createdby,
                                requestor: eachItem.requestor,
                                requestTypeID: eachItem.requestTypeID,
                                newChat: eachItem.newChat
                            }
                        })
                    });
                } else {
                    await this.setState({
                        apiData: [],
                        rowData: []
                    });
                }
            }).catch((err) => {
                alert(err);
            });
    };


    getAllData = async () => {
        if (this.state.isCheckedToggleSwitch) {
            // console.log("hi", this.state.isCheckedToggleSwitch)
            await this.getData();
            await this.setState({
                isData: true
            });
        }
        else {
            // console.log("bi", this.state.isCheckedToggleSwitch)
            await this.getData();
            await this.setState({
                isData: true,
            });
        }

        // console.log(this.state.apiData, "data");

    };

    handleRefreshData = async () => {
        await this.setState({
            style: {},
            apiData: [],
            isData: false
        });
        await this.getAllData();
        await this.setState({
            style: { display: "none" },
        });
    }

    handleUpdateStatus = async (RequestorID, requestTypeID, e) => {
        e.preventDefault();
        console.log(this.props.auth.user.USERID);

        let status = e.target.value;

        await axios.post(
            `${Config.hostName}/hrconnect/updateContent`,
            {
                HRID: RequestorID,
                contentid: 2,
                content: '{"statusid": ' + e.target.value + ', "requestTypeID": ' + requestTypeID + '}',
                userID: this.props.auth.user.USERID,
            }
        )
            .then(async (res) => {
                // console.log(res.data, "api data");
                if (status == 5) {
                    var element = document.getElementById(RequestorID);
                    element.remove();
                }
                if (res.data.data.length > 0) {
                    // console.log(res.data);

                    // console.log('roh');
                    // content: '[{statusid: '+e.target.value+', requestTypeID: '+ requestTypeID+'}]',
                } else {
                    // await this.setState({
                    //     apiData: [],
                    //     rowData: []
                    // });
                }
            }).catch((err) => {
                alert(err);
            });

    }

    getUniqueDateRanges = async () => {
        await axios
            .get(
                `${Config.hostName}/shipment/get/data/dashboard/royaloak/unique/date-ranges`
            )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    await this.setState({
                        uniqueDateRanges: res.data.data,
                    });
                }
            }).catch((err) => {
                alert(err);
            });
    };

    getStatusDropdown = async () => {
        const res = await axios.post(
            `${Config.hostName}/hrconnect/statusDropDown`,
            {
                userID: this.props.auth.user.USERID,
            }
        )
            .then(async (res) => {
                console.log(res.data, "api data");
                if (res.data.data.length > 0) {
                    await this.setState({
                        statusDropdown: res.data.data,
                        statusDropdown: res.data.data.map(eachItem => {
                            return {
                                StatusID: eachItem.StatusID,
                                status: eachItem.status,
                            }
                        })
                    });
                    console.log('roh');
                    console.log(this.state.statusDropdown);
                } else {
                    await this.setState({
                        apiData: [],
                        rowData: []
                    });
                }
            }).catch((err) => {
                alert(err);
            });
    }

    componentWillMount = async () => {
        await this.setState({
            userCcId: this.props.auth.user.CCID,
            style: {}
        });
        await this.getStatusDropdown();
        await this.setStartEndDates();
        await this.getAllData();
        await this.getUniqueDateRanges();
        await this.setState({
            style: { display: "none" }
        });
    };

    handleSearchShipment = async () => {
        await this.setState({
            style: {},
            apiData: [],
            isData: false
        });
        await this.getAllData();
        await this.setState({
            style: { display: "none" },
        });
    };

    handleChangeDateRange = async (e) => {
        // console.log(e.target.value);
        let value = e.target.value;
        // console.log();2222
        if (value == 2222) {
            await this.setState({
                dateRangeSelected: e.target.value,
                startDate: "",
                endDate: "",
            });
            await this.toggle();
        } else {
            this.state.uniqueDateRanges.filter(async (eachDate, i) => {
                if (i == value) {
                    // console.log("true", eachDate);
                    await this.setState({
                        dateRangeSelected: e.target.value,
                        startDate: eachDate.startDate,
                        endDate: eachDate.endDate,
                    });
                }
            });
        }
    };

    toggleCloseModal = async () => {
        await this.toggle();
        await this.setState({
            dateRangeSelected: "",
        });
    };

    toggleConfirmModal = async () => {
        await this.toggle();
        await this.setState({
            dateRangeSelected: 2222,
        });
    };








    onClickOpenMessModal = async (RequestorID) => {
        await this.viewReqModalFunction(RequestorID);

    }

    viewReqModalFunction = async (RequestorID) => {
        const viewReqData = [];
        await this.state.apiData.filter(item => {
            if (item.RequestorID == RequestorID) {
                return viewReqData.push(item);
            }
        })
        // console.log(viewReqData);
        if (viewReqData.length > 0) {
            const selectedRow = viewReqData[0]
            await this.setState({
                // BMSID,Createdby
                Createdby: selectedRow.Createdby,
                NoteHistory: selectedRow.NoteHistory,
                RequestorID: selectedRow.RequestorID,
                createddate: selectedRow.createddate,
                directto: selectedRow.directto,
                requestStatus: selectedRow.requestStatus,
                requestType: selectedRow.requestType,
                requestorCity: selectedRow.requestorCity,
                requestor: selectedRow.requestor,
                requestTypeID: selectedRow.requestTypeID,
                newChat: selectedRow.newChat

            })
            var veeee = JSON.parse(this.state.NoteHistory);


            const result = veeee.filter(veeee => veeee != null);
            // let neeeee = await this.removeEmpty(veeee);
            console.log(result);
            await this.setState({
                NoteHistory: result,
            })
            await this.toggleOpenMessModal();
            var objDiv = document.getElementById("neww");
            objDiv.scrollTop = objDiv.scrollHeight;
        }
        else {
            await this.setState({
                style: { display: "none" },
            })
            await this.toggleOpenMessModal();
        }

    }
    removeEmpty(obj) {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
    }

    toggleOpenMessModal = async () => {
        await this.setState({
            isViewRequestPageData: !this.state.isViewRequestPageData,
        })
    }

    toggleCloseMessModal = async () => {
        await this.toggleViewRequestsModal();
        // await this.setState({
        //     imagesTableModal: []
        // });
    }





    handleSearchShipment = async () => {
        await this.setState({
            style: {},
            apiData: [],
            isData: false
        });
        await this.getAllData();
        await this.setState({
            style: { display: "none" },
        });
    };

    handleChangeDateRange = async (e) => {
        // console.log(e.target.value);
        let value = e.target.value;
        // console.log();2222
        if (value == 2222) {
            await this.setState({
                dateRangeSelected: e.target.value,
                startDate: "",
                endDate: "",
            });
            await this.toggle();
        } else {
            this.state.uniqueDateRanges.filter(async (eachDate, i) => {
                if (i == value) {
                    // console.log("true", eachDate);
                    await this.setState({
                        dateRangeSelected: e.target.value,
                        startDate: eachDate.startDate,
                        endDate: eachDate.endDate,
                    });
                }
            });
        }
    };

    toggleCloseModal = async () => {
        await this.toggle();
        await this.setState({
            dateRangeSelected: "",
        });
    };

    toggleConfirmModal = async () => {
        await this.toggle();
        await this.setState({
            dateRangeSelected: 2222,
        });
    };











    toggleOpenMessModal = async () => {
        await this.setState({
            isViewRequestPageData: !this.state.isViewRequestPageData,
        })
    }

    toggleCloseMessModal = async () => {
        await this.toggleViewRequestsModal();
        // await this.setState({
        //     imagesTableModal: []
        // });
    }




    addMessage = async (e) => {
        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + " " + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + ((parseInt(d.getMinutes() / 5) * 5).toString().length == 2 ? (parseInt(d.getMinutes() / 5) * 5).toString() : "0" + (parseInt(d.getMinutes() / 5) * 5).toString()) + ":00";


        let messages = [...this.state.NoteHistory];
        messages.push(
            {
                Commentedby: this.props.auth.user.name,
                Comments: this.state.addMessage,
                notedate: date_format_str,
                userID: this.props.auth.user.USERID
            }
        );
        if (this.state.addMessage != '') {
            const res = await axios.post(
                `${Config.hostName}/hrconnect/updateContent`,
                {
                    HRID: this.state.RequestorID,
                    contentid: 1,
                    content: '{"attachments": "", "notes": "' + this.state.addMessage + '"}',
                    userID: this.props.auth.user.USERID,
                }
            )
                .then(async (res) => {
                    // console.log(res.data, "api data");
                    if (res.data.data.length > 0) {


                    } else {
                        // console.log(messages);

                        this.setState({
                            addMessage: '',
                        })
                        await this.refreshData();
                        var objDiv = document.getElementById("neww");
                        objDiv.scrollTop = objDiv.scrollHeight;
                    }
                }).catch((err) => {
                    alert(err);
                });
        }



    }

    uploadFileAndGetUrlAddPayee = async (formData) => {
        // console.log("uploading formdata")
        const fileData = await axios({
            method: "POST",
            url: `${Config.hostName}/unique/upload/any-file`,
            timeout: 3 * 60 * 1000,
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*"
            },
            data: formData,
        });
        return fileData;
    }

    singleUploadFileAddPayee = async (file) => {
        const reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = async (e) => {
            await this.setState({
                selectedFile: [reader.result],
            });
        }
        // console.log(url); // Would see a path?
        const fileType = file.type.substring(0, 5);
        await this.setState({ selectedFileAddPayee: file, selectedFileTypeAddPayee: fileType });
        const formData = new FormData();
        formData.append(
            "media",
            this.state.selectedFileAddPayee,
            this.state.selectedFileAddPayee.name
        );
        // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
        console.log(formData, "formData", fileType);
        const imageURL = await this.uploadFileAndGetUrlAddPayee(formData);
        console.log(imageURL.data.url);
        let image = JSON.stringify(imageURL.data.url);
        console.log(image);
        const res = await axios.post(
            `${Config.hostName}/hrconnect/updateContent`,
            {
                HRID: this.state.RequestorID,
                contentid: 1,
                content: '{"attachments": "' + imageURL.data.url + '", "notes": ""}',
                userID: this.props.auth.user.USERID,
            }
        )
            .then(async (res) => {

                var objDiv = document.getElementById("neww");
                objDiv.scrollTop = objDiv.scrollHeight;

            }).catch((err) => {
                alert(err);
            });


        console.log(res);
    }


    onFileUpload = async (event) => {

        this.setState({
            uploading: "Uploading, Please wait..."
        })
        const filesObj = event.target.files;
        const item = filesObj[0];
        await this.singleUploadFileAddPayee(item);
        this.setState({
            uploading: "File Uploaded, Type Something..."
        })

       await this.refreshData();

        var objDiv = document.getElementById("neww");
                objDiv.scrollTop = objDiv.scrollHeight;

        document.getElementById('multifile').value = "";
        // console.log(event.target.files[0], "event.target.files");

        // const res = await axios.post(
        //     `${Config.hostName}/unique/upload/any-file`,
        //     {
        //         headers: {
        //             "Content-Type": "multipart/form-data",
        //             "Access-Control-Allow-Origin": "*"
        //         },
        //         data: filesObj,
        //     },

        // )
        //     .then(async (res) => {
        //         console.log(res.data, "api data");

        //     }).catch((err) => {
        //         alert(err);
        //     });

    }

    toggleOpenImageAttachmentModal = async () => {
        await this.setState({
            imageAttachmentModal: !this.state.imageAttachmentModal
        });
    }

    openImageAttachmentModal = async (url) => {
        if (url.length > 0) {
            if (url.split('.').pop().toLowerCase() != "pdf" && url.split('.').pop().toLowerCase() != "xlsx") {
                await this.setState({
                    isImageAttachmentModal: !this.state.isImageAttachmentModal,
                    imageUrlModal: url
                })
                await this.toggleOpenImageAttachmentModal();
            }
            else {
                window.open(url, '_blank');
            }
        }
    }

    closeImageAttachmentModal = async () => {

        await this.toggleOpenImageAttachmentModal();
        await this.setState({
            isImageAttachmentModal: !this.state.isImageAttachmentModal,
            imageUrlModal: ""
        })
    }

    refreshData = async (RequestorID) => {
        let ManagerView = 0;
        if (this.props.auth.user.user_type === 'Manager') {
            ManagerView = 1;
        }
        const res = await axios.post(
            `${Config.hostName}/hrconnect/getDashboardDetails`,
            {
                StartDate: this.state.startDate,
                EndDate: this.state.endDate,
                userID: this.props.auth.user.USERID,
                ManagerView: 0
            }
        )
            .then(async (res) => {
                console.log(res.data, "api data");
                if (res.data.data.length > 0) {
                    await this.setState({
                        apiData: res.data.data,
                        exportData: res.data.data,
                        rowData: res.data.data.map(eachItem => {
                            return {
                                RequestorID: eachItem.RequestorID,
                                createddate: eachItem.createddate,
                                directto: eachItem.directto,
                                requestStatus: eachItem.requestStatus,
                                requestType: eachItem.requestType,
                                requestorCity: eachItem.requestorCity,
                                Createdby: eachItem.Createdby,
                                requestor: eachItem.requestor,
                                requestTypeID: eachItem.requestTypeID,
                                newChat: eachItem.newChat
                            }
                        })
                    });

                    let viewReqData = [];
                    await this.state.apiData.filter(item => {
                        if (item.RequestorID == this.state.RequestorID) {
                            return viewReqData.push(item);
                        }
                    })
                    console.log(viewReqData);
                    if (viewReqData.length > 0) {
                        const selectedRow = viewReqData[0]
                        await this.setState({
                            // BMSID,Createdby
                            Createdby: selectedRow.Createdby,
                            NoteHistory: selectedRow.NoteHistory,
                            RequestorID: selectedRow.RequestorID,
                            createddate: selectedRow.createddate,
                            directto: selectedRow.directto,
                            requestStatus: selectedRow.requestStatus,
                            requestType: selectedRow.requestType,
                            requestorCity: selectedRow.requestorCity,
                            requestor: selectedRow.requestor,
                            requestTypeID: selectedRow.requestTypeID,
                            newChat: selectedRow.newChat

                        })
                        var veeee = JSON.parse(this.state.NoteHistory);


                        const result = veeee.filter(veeee => veeee != null);
                        // let neeeee = await this.removeEmpty(veeee);
                        console.log(result);
                        await this.setState({
                            NoteHistory: result,
                        })
                        var objDiv = document.getElementById("neww");
                        objDiv.scrollTop = objDiv.scrollHeight;
                    }
                    else {
                        await this.setState({
                            style: { display: "none" },
                        })
                        await this.toggleOpenMessModal();
                    }

                    var objDiv = document.getElementById("neww");
                    objDiv.scrollTop = objDiv.scrollHeight;
                } else {
                    await this.setState({
                        apiData: [],
                        rowData: []
                    });
                }
            }).catch((err) => {
                alert(err);
            });

    }



    handleExportExcel = async (e) => {
        e.preventDefault();
        var workSheet = XLSX.utils.json_to_sheet(this.state.exportData);
        var workBook = XLSX.utils.book_new();
        var wscols = [
            { wch: 6 },
            { wch: 35 },
            { wch: 15 },
            { wch: 15 },
            { wch: 35 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 }
        ];
        // workSheet['!cols'] = wscols;
        XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
        XLSX.writeFile(workBook, 'HR_Connect_Report' + ".xlsx");
    }

    getExcelReport = async () => {
        await this.setState({
            isExportLoading: true
        })
        const res = await axios.post(
            `${Config.hostName}/bms/get/excel/report/dashboard`,
            {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                category: this.state.isCheckedToggleSwitch ? this.state.categoryWidget : "",
                statusCode: this.state.isCheckedToggleSwitch ? "" : this.state.statusCode
            }, {
            responseType: "blob",
        }
        )
            .then(async (res) => {
                // console.log(res.data);
                const pdfBlob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                // console.log(pdfBlob, "pdf");
                saveAs(pdfBlob, `BMS_REPORT${moment(new Date()).format("MMDD")}.xlsx`);
                await this.setState({
                    exportData: res.data.data,
                    isExportLoading: false
                });
            }).catch((err) => {
                alert(err);
            });
    }

    render() {
        let modal = this.state.modal;
        let { style, startDate, endDate, tableStyle, exportData, isViewRequestPageData, imageAttachmentModal, isImageAttachmentModal
        } = this.state;


        const defaultColDef = {
            flex: 1,
            minWidth: 90,
            resizable: true
        }

        const classes = makeStyles({
            table: {
                minWidth: 650,
            },
            chatSection: {
                width: '100%',
                height: '80vh'
            },
            headBG: {
                backgroundColor: '#e0e0e0'
            },
            borderRight500: {
                borderRight: '1px solid #e0e0e0'
            },
            messageArea: {
                height: '70vh',
                overflowY: 'auto'
            }
        });
        //   const classes = useStyles();

        const AntSwitch = withStyles(() => ({
            checked: {}
        }))(Switch);
        return (
            <div className="main-home-page" style={{ paddingBottom: "60px" }}>
                <div id="cover-spin" style={style}>
                </div>

                <div className="container-fluid">
                    <div className="col-xl-12">
                        <nav
                            className="navbar navbar-light "
                            style={{ backgroundColor: "none" }}
                        >
                            <div className="row" style={{ paddingTop: "8px", width: "100%" }}>


                                <div className="col-sm-3">
                                    <FormGroup>
                                        <Input
                                            type="select"
                                            name="dateRange"
                                            value={this.state.dateRangeSelected}
                                            id="dateRange"
                                            onChange={this.handleChangeDateRange}
                                        >
                                            <option className="bms-dashboaord-date-range" value={""} disabled>
                                                Select Date Range
                                            </option>

                                            {this.state.uniqueDateRanges.map((eachItem, i) => (
                                                <option value={i} key={i}>
                                                    {eachItem.title}
                                                </option>
                                            ))}
                                            <option value={2222}>
                                                {/* <Button onClick={this.toggle}> */}
                                                Custom Date Range
                                                {/* </Button> */}
                                            </option>
                                            <div>
                                                <Modal
                                                    isOpen={modal}
                                                // toggle={this.toggle}
                                                >
                                                    <ModalHeader>Select Start and End date</ModalHeader>
                                                    <ModalBody>
                                                        <div className="row" style={{ minHeight: "1px" }}>
                                                            <div className="col float-left">
                                                                <div
                                                                    className="cal-datepicker"
                                                                    style={{ height: "100%" }}
                                                                >
                                                                    <div
                                                                        className="datepicker-here text-center"
                                                                        style={{ height: "100%" }}
                                                                        data-language="en"
                                                                    >
                                                                        <DatePicker
                                                                            height="400"
                                                                            placeholderText="Select Start Date"
                                                                            id="startDateInput"
                                                                            inline
                                                                            selectsRange
                                                                            selected={this.state.startDate}
                                                                            startDate={this.state.startDate}
                                                                            onChange={(date) => {
                                                                                this.setState({
                                                                                    startDate: date,
                                                                                });
                                                                            }}
                                                                            dateFormat="yyyy-MM-dd"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col float-left">
                                                                <div className="cal-datepicker">
                                                                    <div
                                                                        className="datepicker-here text-center"
                                                                        data-language="en"
                                                                    >
                                                                        <DatePicker
                                                                            height="400"
                                                                            id="endDateInput"
                                                                            inline
                                                                            placeholderText="Select End Date"
                                                                            selected={this.state.endDate}
                                                                            onChange={(date) => {
                                                                                this.setState({
                                                                                    endDate: date,
                                                                                });
                                                                            }}
                                                                            dateFormat="yyyy-MM-dd"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button color="info"
                                                            onClick={this.toggleConfirmModal}

                                                        >Confirm</Button>{" "}
                                                        <Button
                                                            color="danger"
                                                            onClick={this.toggleCloseModal}
                                                        >
                                                            Close
                                                        </Button>{" "}
                                                    </ModalFooter>
                                                </Modal>
                                            </div>
                                        </Input>
                                    </FormGroup>
                                </div>

                                <div className="col-sm-3">
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            name="search_val"
                                            value={this.state.search_val}
                                            // style={{ borderColor: "#FF4848" }}
                                            style={{
                                                fontFamily: 'Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif',
                                                fontVariantNumber: 'tabular-nums'
                                            }}
                                            id="search_val"
                                            placeholder="Requestor ID"
                                            onChange={(e) => {
                                                this.setState({ search_val: e.target.value })
                                            }}
                                        >
                                        </Input>




                                    </FormGroup>
                                </div>
                                <div className="col-sm-2">
                                    <button
                                        type="submit"
                                        className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                        // style={{ backgroundColor: "#FF4848", color: "white" }}
                                        onClick={this.handleSearchShipment}
                                    >
                                        <i
                                            className="fa fa-search"
                                            style={{ marginRight: "5px" }}
                                        />
                                        Search
                                    </button>
                                </div>



                                {/* <div className="col-sm-2">
                                    <button
                                        type="submit"
                                        className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                        // style={{ backgroundColor: "#FF4848", color: "white" }}
                                        onClick={this.handleRefreshData}
                                    >

                                        <i
                                            className="fa fa-refresh"
                                            style={{ marginRight: "5px" }}
                                        />
                                        Refresh
                                    </button>
                                </div> */}

                                <div className="col-sm-2">
                                    {this.state.exportData.length > 0 && (
                                        <button
                                            className={(!this.state.exportData.length > 0 && !this.state.exportData.length > 0) ? 'btn btn-danger w-100 overflow-hidden mb-3 px-0 disabled' : 'btn btn-danger w-100 overflow-hidden mb-3 px-0'}
                                            onClick={this.handleExportExcel}
                                            disabled={!this.state.exportData && !this.state.exportData.length > 0}
                                        >
                                            <i
                                                className="fa fa-file-excel-o"
                                                style={{ marginRight: "5px" }}
                                            />
                                            Export
                                        </button>
                                    )}
                                </div>


                            </div>

                        </nav>
                        {/* </div> */}
                    </div>

                    <div>
                        <div id="cover-spin" style={tableStyle}></div>

                        <div
                            className="ag-theme-balham"
                            style={{
                                height: '100%',
                                width: '100%',
                                minHeight: "500px"
                            }}
                        >
                            <AgGridReact
                                columnDefs={this.state.columnDefs}
                                rowData={this.state.rowData}
                                defaultColDef={defaultColDef}
                                enableCellTextSelection={true}
                                rowHeight={50}
                                domLayout={this.state.domLayout}
                                // enableCellTextSelection={true}
                                suppressAutoSize={true}
                                gridAutoHeight={true}
                            >
                            </AgGridReact>
                        </div>

                    </div>
                    {/* } */}

                    <div>
                        <Modal
                            size="lg"
                            style={{ maxWidth: '1600px', width: '80%' }}
                            centered={true}
                            isOpen={isViewRequestPageData}
                        >
                            <ModalFooter>
                                <div className="col-sm-2">
                                    <span className="bms-view-req-modal-header-label"
                                        style={{
                                            fontFamily: 'Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif',
                                            fontVariantNumber: 'tabular-nums'
                                        }}
                                    >RequestorID: {this.state.RequestorID}</span>
                                </div>
                                <div className="col-sm-6">
                                    {/* <span className="bms-view-req-modal-header-label"
                                                    style={{
                                                        fontFamily: 'Lucida Sans , Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif',
                                                        fontVariantNumber: 'tabular-nums'
                                                    }}
                                                >Project :{this.state.Project}</span> */}
                                </div>




                                <div className="col-sm-3">
                                    <Button
                                        color="danger"
                                        onClick={() => this.refreshData(this.state.RequestorID)}
                                    >
                                        <i
                                            className="fa fa-refresh"

                                        />
                                    </Button>{" "}
                                    <Button
                                        color="danger"
                                        onClick={this.toggleOpenMessModal}
                                        style={{ margin: '0px 18px' }}
                                    >
                                        <CloseIcon />
                                    </Button>{" "}
                                </div>

                            </ModalFooter>
                            <ModalBody>

                                <div className="bms-view-req-modal-body">
                                    <div>
                                        <Grid item xs={12} style={{
                                            margin: '2px 90px',
                                            border: '2px solid #b3b3b3',
                                            borderRadius: '14px',
                                            overflow: 'scroll',
                                            height: '75vh'

                                        }} id="neww" >
                                            <List className={classes.messageArea}>
                                                {this.state.NoteHistory && Object.values(this.state.NoteHistory).map((eachItem, i) => (

                                                    <ListItem key={i}>

                                                        <Grid container>
                                                            {
                                                                eachItem.Comments != '' &&
                                                                <Grid item xs={12}>
                                                                    <ListItemText align={eachItem.Commentedby != null && eachItem.Commentedby == this.props.auth.user.name ? 'right' : 'left'} primary={eachItem.Comments}></ListItemText>
                                                                </Grid>
                                                            }

                                                            {
                                                                eachItem.Comments != '' &&
                                                                <Grid item xs={12}>
                                                                    <ListItemText align={eachItem.Commentedby != null && eachItem.Commentedby == this.props.auth.user.name ? 'right' : 'left'} secondary={eachItem.notedate}></ListItemText>
                                                                </Grid>
                                                            }
                                                            {
                                                                eachItem.attachments != '[object Object]' && eachItem.attachments != '' &&
                                                                <Grid item xs={12} onClick={() => this.openImageAttachmentModal(eachItem.attachments)}>
                                                                    {eachItem.attachments && eachItem.attachments.split('.').pop().toLowerCase() != "pdf" && eachItem.attachments.split('.').pop().toLowerCase() != "xlsx" &&

                                                                        <img className="bms-view-request-img" src={eachItem.attachments} align={ eachItem.Commentedby != null && eachItem.Commentedby == this.props.auth.user.name ? 'right' : 'left'} style={{ width: '26vh', height: '26vh', maxWidth: '26vh', maxHeight: '26vh' }} alt="No Image" key={i} />

                                                                    }
                                                                    {eachItem.attachments && eachItem.attachments.split('.').pop().toLowerCase() == "pdf" &&

                                                                        <img className="bms-view-request-img" align={ eachItem.Commentedby != null && eachItem.Commentedby == this.props.auth.user.name ? 'right' : 'left'} src={pdfIcon} alt="No Image" key={i} />
                                                                    }
                                                                    {eachItem.attachments && eachItem.attachments.split('.').pop().toLowerCase() == "xlsx" &&

                                                                        <img className="bms-view-request-img" align={ eachItem.Commentedby != null && eachItem.Commentedby == this.props.auth.user.name ? 'right' : 'left'} src={excelIcon} alt="No Image" key={i} />
                                                                    }

                                                                </Grid>
                                                            }
                                                            {
                                                                eachItem.attachments != '[object Object]' && eachItem.attachments != '' &&
                                                                <Grid item xs={12}>
                                                                    <ListItemText align={eachItem.Commentedby != null && eachItem.Commentedby == this.props.auth.user.name ? 'right' : 'left'} secondary={eachItem.notedate} ></ListItemText>
                                                                </Grid>
                                                            }





                                                        </Grid>




                                                    </ListItem>


                                                ))}

                                            </List>
                                            <Divider />
                                            <Grid container style={{ padding: '20px' }}>
                                                <Grid item xs={10}>
                                                    <TextField id="outlined-basic-email" value={this.state.addMessage} onChange={(e) => {
                                                        this.setState({
                                                            addMessage: e.target.value,
                                                        });
                                                    }} label={this.state.uploading} fullWidth />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <label className="custom-file-upload" style={{ width: "6vh", height: "6vh", background: "red", borderRadius: "50%", boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);" }}>
                                                        <input
                                                            name="Upload"
                                                            type="file"
                                                            className="bms-attachments-card-button ml-auto"
                                                            onChange={this.onFileUpload}
                                                            id="multifile"
                                                        />
                                                        <AttachmentIcon style={{ margin: "7px 3px", color: "white", transform: "rotate(-45deg)" }} />
                                                        {/* </button> */}
                                                    </label>
                                                </Grid>

                                                <Grid xs={1} align="right">
                                                    <Fab color="primary" onClick={this.addMessage} aria-label="add"><SendIcon /></Fab>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </div>
                                </div>
                            </ModalBody>

                        </Modal>
                    </div>

                    <div>
                        {isImageAttachmentModal && <Modal
                            size="lg"
                            style={{ maxWidth: '1600px', width: '70%' }}
                            centered={true}
                            isOpen={imageAttachmentModal}
                        >
                            <ModalFooter>

                                <div className="col-sm-3 ml-auto">

                                    <Button
                                        color="danger"
                                        onClick={this.closeImageAttachmentModal}
                                    >
                                        <CloseIcon />
                                    </Button>{" "}

                                </div>
                            </ModalFooter>
                            <ModalBody>
                                <div className="bms-view-image-url-modal">
                                    <img src={this.state.imageUrlModal} alt="No Image" width="100%" height="100%" />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="danger"
                                    onClick={this.closeImageAttachmentModal}
                                >
                                    Close
                                </Button>{" "}
                            </ModalFooter>

                        </Modal>
                        }
                    </div>




                </div>
            </div >
        );
    }
}

HomePage.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(HomePage);
