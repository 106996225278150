import React, {
  Component,
  // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../../redux/actions/alert.actions";
import Config from "../../../../../config";
import {
  IconButton,
  Toolbar,
  AppBar,
  Dialog,
  Slide,
  Chip,
  Avatar,
  Checkbox,
  Autocomplete,
  Alert,
  Snackbar,
  TextField,
  Button,
  MenuItem,
  Select,
  Grid,
  Card,
  Container,
  FormControl,
  InputLabel,
  Typography,
  CardActionArea,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import PlusIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardContent from "@material-ui/core/CardContent";

import ReactApexChart from "react-apexcharts";
// import '@coreui/coreui/dist/css/coreui.min.css'
import { CRow, CCol, CWidgetStatsB } from "@coreui/react";
// import './layout.css';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const options = {
  filterType: "multiselect",
  // filterOptions: {fullWidth: true},
  selectableRows: "none",
  setRowProps: (row) => {
    if (row[0] === "New") {
      return {
        style: { background: "snow" },
      };
    }
  },
  draggableColumns: { enabled: true },
  pagination: true,
  // resizableColumns: true,
  // responsive: "standard",
  fixedHeader: false,
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},
      vertical: "top",
      horizontal: "center",
      alertSnackbar: false,
      unique_cities: [],
      locationID: "",
      uniqueDateRanges: [],
      dateID: "",
      startDate: "",
      endDate: "",
      startDateOpen : "",
      endDateOpen : "",
      barCardDisplay: "none",
      statsDisplay1: "",
      statsDisplay2: "none",
      unique_project: [],
      projectName: [],
      userid: "",
      rowData: [],
      reportID: 1,
      StatsCategoryDropdown: [],
      rowDataStats: [],
      statsOverAll: [],
      statsOther: [],
      columns: [
        {
          name: "FullName",
          label: "Profile Pic / Full Name",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>
                  <div className="user-pic">
                    <Avatar
                      className="profilePic"
                      src={this.state.rowData[dataIndex].EmployeeUrl}
                      style={{ float: "left" }}
                      alt="E"
                      sx={{ width: 38, height: 38 }}
                    />
                    <div style={{ marginLeft: "51px", paddingTop: "8px" }}>
                      {this.state.rowData[dataIndex].FullName}
                    </div>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "city",
          label: "City",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Designation",
          label: "Designation",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "Department",
          label: "Department",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "AttnDate",
          label: "Date",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "timing",
          label: "Timing",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "WorkShift",
          label: "Work Shift",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "AttnStatus",
          label: "Status",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const statusReal = this.state.rowData[dataIndex].AttnStatus;
              if (statusReal == 1) {
                return (
                  <div>
                    <Chip
                      color="success"
                      style={{
                        backgroundColor: "#2EB85C",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Present"
                    />
                  </div>
                );
              } else if (statusReal == 2) {
                return (
                  <div>
                    <Chip
                      color="warning"
                      style={{
                        backgroundColor: "#3399fe",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Half Day"
                    />
                  </div>
                );
              } else if (statusReal == 3) {
                return (
                  <div>
                    <Chip
                      color="secondary"
                      style={{
                        backgroundColor: "f9b114",
                        color: "white",
                        backgroundColor: "#a8af1d",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Sick Leave"
                    />
                  </div>
                );
              } else if (statusReal == 9) {
                return (
                  <div>
                    <Chip
                      color="info"
                      style={{
                        backgroundColor: "#4e5d72",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Yet To Get Attendance"
                    />
                  </div>
                );
              } else if (statusReal == 0) {
                return (
                  <div>
                    <Chip
                      color="error"
                      style={{
                        backgroundColor: "#e55353",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Absent"
                    />
                  </div>
                );
              } else {
                return (
                  <div>
                    <Chip
                      color="error"
                      style={{ borderRadius: "4px", height: "25px" }}
                      label="Absent"
                    />
                  </div>
                );
              }
            },
          },
        },

        {
          name: "projects",
          label: "Projects",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const pro = this.state.rowData[dataIndex];
              return (
                <div style={{ textAlign: "center" }}>{pro.ProjectCnt}</div>
              );
            },
          },
        },
      ],
      series: null,
      options: {
        title: {
          text: "Attendance View",
        },
        chart: {
          width: 380,
          type: "donut",
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          formatter: function(val, opt) {
            console.log(opt);
            return Math.trunc(val) + "%";
            // return opt.w.config.series[opt.seriesIndex];
          },
          offsetX: 0,
        },
        colors: ["#2EB85C", "#e55353", "#3399fe"],
        labels: ["Present", "Absent", "Half Day"],

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      series2: null,
      options2: {
        title: {
          text: "Sick Leaves",
        },
        chart: {
          width: 380,
          type: "donut",
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          formatter: function(val, opt) {
            // console.log(opt);
            return Math.trunc(val) + "%";
            // return opt.w.config.series[opt.seriesIndex];
          },
          offsetX: 0,
        },
        colors: ["#f9b114", "#4e5d72"],
        labels: ["Sick Leave", "Yet To Get Attendance"],

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartData: null,

      options3: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "Empolyees Attendance",
        },
        xaxis: {
          type: "datetime",
          categories: [
            "01-01-2011 GMT",
            "01/02/2011 GMT",
            "01/03/2011 GMT",
            "01/04/2011 GMT",
            "01/05/2011 GMT",
            "01/06/2011 GMT",
          ],
          labels: {
            formatter: function(val) {
              return val + "K";
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val + "K";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      series3: [
        {
          name: "Day",
          data: [44, 55, 41, 67, 22, 43],
        },
        {
          name: "Night",
          data: [13, 23, 20, 8, 13, 27],
        },
      ],
      barChartModal: false,
      firstModal: false,
      barChartData: [],
      barChartColumns: [
        {
          name: "FullName",
          label: "Profile Pic / Full Name",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>
                  <div className="user-pic">
                    <Avatar
                      className="profilePic"
                      src={this.state.rowData[dataIndex].EmployeeUrl}
                      style={{ float: "left" }}
                      alt="E"
                      sx={{ width: 38, height: 38 }}
                    />
                    <div style={{ marginLeft: "51px", paddingTop: "8px" }}>
                      {this.state.rowData[dataIndex].FullName}
                    </div>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "city",
          label: "City",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Designation",
          label: "Designation",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "Department",
          label: "Department",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "AttnDate",
          label: "Date",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "timing",
          label: "Timing",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "WorkShift",
          label: "Work Shift",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "AttnStatus",
          label: "Status",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const statusReal = this.state.rowData[dataIndex].AttnStatus;
              if (statusReal == 1) {
                return (
                  <div>
                    <Chip
                      color="success"
                      style={{
                        backgroundColor: "#2EB85C",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Present"
                    />
                  </div>
                );
              } else if (statusReal == 2) {
                return (
                  <div>
                    <Chip
                      color="warning"
                      style={{
                        backgroundColor: "#3399fe",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Half Day"
                    />
                  </div>
                );
              } else if (statusReal == 3) {
                return (
                  <div>
                    <Chip
                      color="secondary"
                      style={{
                        backgroundColor: "f9b114",
                        color: "white",
                        backgroundColor: "#a8af1d",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Sick Leave"
                    />
                  </div>
                );
              } else if (statusReal == 9) {
                return (
                  <div>
                    <Chip
                      color="info"
                      style={{
                        backgroundColor: "#4e5d72",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Yet To Get Attendance"
                    />
                  </div>
                );
              } else if (statusReal == 0) {
                return (
                  <div>
                    <Chip
                      color="error"
                      style={{
                        backgroundColor: "#e55353",
                        borderRadius: "4px",
                        height: "25px",
                      }}
                      label="Absent"
                    />
                  </div>
                );
              } else {
                return (
                  <div>
                    <Chip
                      color="error"
                      style={{ borderRadius: "4px", height: "25px" }}
                      label="Absent"
                    />
                  </div>
                );
              }
            },
          },
        },

        {
          name: "projects",
          label: "Projects",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const pro = this.state.rowData[dataIndex];
              return (
                <div style={{ textAlign: "center" }}>{pro.ProjectCnt}</div>
              );
            },
          },
        },
      ],
      statsModal: false,
      statsData: [],
      statsColumn: [
        {
          name: "FullName",
          label: "Profile Pic / Full Name",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>
                  <div className="user-pic">
                    <Avatar
                      className="profilePic"
                      src={this.state.rowDataStats[dataIndex].Picture}
                      style={{ float: "left" }}
                      alt="E"
                      sx={{ width: 38, height: 38 }}
                    />
                    <div style={{ marginLeft: "51px", paddingTop: "8px" }}>
                      {this.state.rowDataStats[dataIndex].FullName}
                    </div>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "City",
          label: "City",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Designation",
          label: "Designation",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Department",
          label: "Department",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "Status",
          label: "Status",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: `SalaryDetails`,
          label: "Salary",
          options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const SalaryDetails = JSON.parse(
                this.state.rowDataStats[dataIndex].SalaryDetails
              );

              if (SalaryDetails === null) {
                return <p>₹ 0</p>;
              } else {
                console.log(SalaryDetails.MonthlyCTC);
                return <p>₹ {SalaryDetails.MonthlyCTC}</p>;
              }
            },
          },
        },
        // {
        //   name: "Projects",
        //   label: "Projects",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },
        // {
        //   name: "EmployeeID",
        //   label: "New EmployeeID",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },
        // {
        //   name: "OldEmpID",
        //   label: "Old EmployeeID",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },

        // {
        //   name: "email",
        //   label: "Email",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },
        // {
        //   name: "DoB",
        //   label: "Date of Birth",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },

        // {
        //   name: "DoJ",
        //   label: "Date of Joining",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },

        // {
        //   name: "Mobile",
        //   label: "Contact No.",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },

        // {
        //   name: "WorkingBranch",
        //   label: "Working Branch",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },
        // {
        //   name: "Reportto",
        //   label: "Report to",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },
        // {
        //   name: "EmploymentType",
        //   label: "Employment Type",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },
        // {
        //   name: "AccessString",
        //   label: "Access",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },
        // {
        //   name: "bloodGroup",
        //   label: "Blood Group",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },
        // {
        //   name: "pan",
        //   label: "Pan Card",
        //   options: {
        //     filter: true,
        //     sort: true,
        //     customBodyRenderLite: (dataIndex) => {
        //       return (
        //         <div>
        //           <div
        //             className="user-pic"

        //           >
        //             <img
        //               src={this.state.rowDataStats[dataIndex].pan}
        //               style={{ float: "left", height: "200px" }}
        //               alt="E"
        //             />
        //           </div>
        //         </div>
        //       );
        //     },
        //   },
        // },
        // {
        //   name: "AdharFront",
        //   label: "Adhar Card",
        //   options: {
        //     filter: true,
        //     sort: true,
        //     customBodyRenderLite: (dataIndex) => {
        //       return (
        //         <div>
        //           <div
        //             className="user-pic"

        //           >
        //             <img
        //               src={this.state.rowDataStats[dataIndex].AdharFront}
        //               style={{ float: "left", height: "200px"}}
        //               alt="E"
        //             />
        //           </div>
        //           <div
        //             className="user-pic"

        //           >
        //             <img
        //               src={this.state.rowDataStats[dataIndex].AdharBack}
        //               style={{ float: "left", height: "200px" }}
        //               alt="E"
        //             />
        //           </div>
        //         </div>
        //       );
        //     },
        //   },
        // },
        // {
        //   name: "IDCard",
        //   label: "ID Card",
        //   options: {
        //     filter: true,
        //     sort: true,
        //     customBodyRenderLite: (dataIndex) => {
        //       return (
        //         <div>
        //           <div
        //             className="user-pic"

        //           >
        //             <img
        //               src={this.state.rowDataStats[dataIndex].IDCard}
        //               style={{ float: "left", height: "250px" }}
        //               alt="E"
        //             />
        //           </div>
        //         </div>
        //       );
        //     },
        //   },
        // },
        // {
        //   name: "BankAccountNo",
        //   label: "Bank Account No",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },
        // {
        //   name: "IFSC",
        //   label: "IFSC",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },
        // {
        //   name: "BankBeneficiaryName",
        //   label: "Beneficiary Name",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },
      ],
    };
    this.validator = new SimpleReactValidator();
  }

  delay = async (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  getUniqueCities = async () => {
    await axios
      .get(`${Config.hostName}/unique/locations`)
      .then((res) => {
        if (res.data.data.length > 0) {
          this.setState({
            unique_cities: res.data.data,
          });
        } else {
          this.setState({
            unique_cities: [],
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  getUniqueDateRanges = async () => {
    await axios
      .get(
        `${Config.hostName}/shipment/get/data/dashboard/royaloak/unique/date-ranges`
      )
      .then(async (res) => {
        if (res.data.data.length > 0) {
          await this.setState({
            uniqueDateRanges: res.data.data,
            startDateOpen : res.data.data[4].startDate,
            endDateOpen : res.data.data[4].endDate
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  barChart = async (projectID) => {
    await axios
      .post(`${Config.hostName}/ems/dashboardAttendanceBarChart`, {
        userid: this.state.userid,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        locationID: this.state.locationID,
        projectID: projectID,
      })
      .then(async (res) => {
        // console.log(res.data.data, "barchartData");
        if (res.data.data.length > 0) {
          const data = res.data.data;
          let fd = [];
          let hd = [];
          let absent = [];
          let date = [];
          data.map((e, key) => {
            const DasDetails = JSON.parse(e.DasDetails);
            if (DasDetails[0] != undefined) {
              if (DasDetails[0].DAS == "FD") {
                fd.push(DasDetails[0].DasCnt);
              } else {
                hd.push(DasDetails[0].DasCnt);
              }
            } else {
              hd.push(0);
              fd.push(0);
            }

            if (DasDetails[1] != undefined) {
              if (DasDetails[1].DAS == "FD") {
                fd.push(DasDetails[1].DasCnt);
              } else {
                hd.push(DasDetails[1].DasCnt);
              }
            } else {
              if (DasDetails[0].DAS == "FD") {
                hd.push(0);
              } else {
                fd.push(0);
              }
            }
            date.push(`${e.Date} GMT`);
            const neww = Number(e.totalEmpcnt) - Number(e.Empcnt);
            absent.push(neww);
          });
          // console.log(fd, "FD");
          // console.log(hd, 'HD');
          // console.log(date, 'Dates');

          this.setState({
            barCardDisplay: "block",
          });

          await this.setState({
            chartData: data,
            series3: [
              {
                name: "Full Day",
                data: fd,
              },
              {
                name: "Half Day",
                data: hd,
              },
              {
                name: "Absent",
                data: absent,
              },
            ],

            options3: {
              chart: {
                type: "bar",
                height: 350,
                stacked: true,
                toolbar: {
                  show: true,
                },
                zoom: {
                  enabled: true,
                },
                events: {
                  click: (event, chartContext, config) => {
                    // console.log("rohan");
                    // console.log(event, "event");
                    // console.log(config, "event");
                    // console.log(chartContext, "event");
                    this.handleClickBarChart(config);
                  },
                },
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  dataLabels: {
                    total: {
                      enabled: true,
                      offsetX: 0,
                      style: {
                        fontSize: "13px",
                        fontWeight: 900,
                      },
                    },
                  },
                },
              },
              stroke: {
                width: 1,
                colors: ["#fff"],
              },
              title: {
                text: "Empolyees Attendance",
              },
              xaxis: {
                type: "datetime",
                categories: date,
              },
              yaxis: {
                title: {
                  text: undefined,
                },
              },
              tooltip: {
                y: {
                  formatter: function(val) {
                    return val;
                  },
                },
              },
              fill: {
                opacity: 1,
              },
              legend: {
                position: "top",
                horizontalAlign: "left",
                offsetX: 40,
              },
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  barChartOpen = async (projectID) => {
    await axios
      .post(`${Config.hostName}/ems/dashboardAttendanceBarChart`, {
        userid: this.state.userid,
        startDate: this.state.startDateOpen,
        endDate: this.state.endDateOpen,
        locationID: this.state.locationID,
        projectID: projectID,
      })
      .then(async (res) => {
        // console.log(res.data.data, "barchartData");
        if (res.data.data.length > 0) {
          const data = res.data.data;
          let fd = [];
          let hd = [];
          let absent = [];
          let date = [];
          data.map((e, key) => {
            const DasDetails = JSON.parse(e.DasDetails);
            if (DasDetails[0] != undefined) {
              if (DasDetails[0].DAS == "FD") {
                fd.push(DasDetails[0].DasCnt);
              } else {
                hd.push(DasDetails[0].DasCnt);
              }
            } else {
              hd.push(0);
              fd.push(0);
            }

            if (DasDetails[1] != undefined) {
              if (DasDetails[1].DAS == "FD") {
                fd.push(DasDetails[1].DasCnt);
              } else {
                hd.push(DasDetails[1].DasCnt);
              }
            } else {
              if (DasDetails[0].DAS == "FD") {
                hd.push(0);
              } else {
                fd.push(0);
              }
            }
            date.push(`${e.Date} GMT`);
            const neww = Number(e.totalEmpcnt) - Number(e.Empcnt);
            absent.push(neww);
          });
          // console.log(fd, "FD");
          // console.log(hd, 'HD');
          // console.log(date, 'Dates');

          this.setState({
            barCardDisplay: "block",
          });

          await this.setState({
            chartData: data,
            series3: [
              {
                name: "Full Day",
                data: fd,
              },
              {
                name: "Half Day",
                data: hd,
              },
              {
                name: "Absent",
                data: absent,
              },
            ],

            options3: {
              chart: {
                type: "bar",
                height: 350,
                stacked: true,
                toolbar: {
                  show: true,
                },
                zoom: {
                  enabled: true,
                },
                events: {
                  click: (event, chartContext, config) => {
                    // console.log("rohan");
                    // console.log(event, "event");
                    // console.log(config, "event");
                    // console.log(chartContext, "event");
                    this.handleClickBarChartOpen(config);
                  },
                },
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  dataLabels: {
                    total: {
                      enabled: true,
                      offsetX: 0,
                      style: {
                        fontSize: "13px",
                        fontWeight: 900,
                      },
                    },
                  },
                },
              },
              stroke: {
                width: 1,
                colors: ["#fff"],
              },
              title: {
                text: "Empolyees Attendance",
              },
              xaxis: {
                type: "datetime",
                categories: date,
              },
              yaxis: {
                title: {
                  text: undefined,
                },
              },
              tooltip: {
                y: {
                  formatter: function(val) {
                    return val;
                  },
                },
              },
              fill: {
                opacity: 1,
              },
              legend: {
                position: "top",
                horizontalAlign: "left",
                offsetX: 40,
              },
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleClickBarChart = async (event) => {
    // console.log(event.seriesIndex);
    await this.setState({
      style: {},
    });
    const series = event.seriesIndex;
    let chartID = 1;
    if (series == 1) {
      chartID = 2;
    } else if (series == 0) {
      chartID = 3;
    }

    await axios
      .post(`${Config.hostName}/ems/dashboardAttendanceChartDetails`, {
        userid: this.state.userid,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        locationID: this.state.locationID,
        chartID: chartID,
      })
      .then(async (res) => {
        // console.log(res);
        await this.setState({
          barChartData: res.data.data,
          barChartModal: true,
        });
        await this.setState({
          style: {
            display: "none",
          },
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(alert);
        this.setState({
          style: {
            display: "none",
          },
        });
      });
  };

  handleClickBarChartOpen = async (event) => {
    // console.log(event.seriesIndex);
    await this.setState({
      style: {},
    });
    const series = event.seriesIndex;
    let chartID = 1;
    if (series == 1) {
      chartID = 2;
    } else if (series == 0) {
      chartID = 3;
    }

    await axios
      .post(`${Config.hostName}/ems/dashboardAttendanceChartDetails`, {
        userid: this.state.userid,
        startDate: this.state.startDateOpen,
        endDate: this.state.endDateOpen,
        locationID: this.state.locationID,
        chartID: chartID,
      })
      .then(async (res) => {
        // console.log(res);
        await this.setState({
          barChartData: res.data.data,
          barChartModal: true,
        });
        await this.setState({
          style: {
            display: "none",
          },
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(alert);
        this.setState({
          style: {
            display: "none",
          },
        });
      });
  };

  getData = async () => {
    await this.setState({
      style: {},
    });
    let ccxrefID = [];

    await this.state.projectName.map(async (name, key) => {
      // console.log(name);
      await this.state.unique_project.map(async (project, i) => {
        if (name == project.projectName) {
          ccxrefID.push(project.ccxrefID);
        }
      });
    });
    let projectID = "";
    if (ccxrefID != []) {
      projectID = ccxrefID.toString();
    }
    await axios
      .post(`${Config.hostName}/ems/dashboardAttendanceCalender`, {
        userid: this.state.userid,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        locationID: this.state.locationID,
        projectID: projectID,
      })
      .then(async (res) => {
        // console.log(res.data);

        if (res.data.data.length > 0) {
          await this.setState({
            rowData: res.data.data,
          });
          let present = 0;
          let absent = 0;
          let half = 0;
          let sick = 0;
          let yet = 0;

          await res.data.data.map((e) => {
            if (e.AttnStatus == 0) {
              absent++;
            } else if (e.AttnStatus == 1) {
              present++;
            } else if (e.AttnStatus == 2) {
              half++;
            } else if (e.AttnStatus == 3) {
              sick++;
            } else if (e.AttnStatus == 9) {
              yet++;
            }
          });

          await this.setState({
            rowData: res.data.data,
            series: [present, absent, half],
          });

          if (sick == 0 && yet == 0) {
            await this.setState({
              series2: null,
            });
          } else {
            await this.setState({
              series2: [sick, yet],
            });
          }

          await this.barChart(projectID);

          // console.log(this.state.rowData);
        } else {
          this.setState({
            alertSnackbar: true,
            rowData: [],
            series: null,
          });
        }
        await this.setState({
          style: {
            display: "none",
          },
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alertSnackbar: true,
          rowData: [],
          series: null,
          style: {
            display: "none",
          },
        });
      });
  };

  getDataOpen = async () => {
    await this.setState({
      style: {},
    });
    let ccxrefID = [];

    await this.state.projectName.map(async (name, key) => {
      // console.log(name);
      await this.state.unique_project.map(async (project, i) => {
        if (name == project.projectName) {
          ccxrefID.push(project.ccxrefID);
        }
      });
    });
    let projectID = "";
    if (ccxrefID != []) {
      projectID = ccxrefID.toString();
    }
    await axios
      .post(`${Config.hostName}/ems/dashboardAttendanceCalender`, {
        userid: this.state.userid,
        startDate: this.state.startDateOpen,
        endDate: this.state.endDateOpen,
        locationID: this.state.locationID,
        projectID: projectID,
      })
      .then(async (res) => {
        // console.log(res.data);

        if (res.data.data.length > 0) {
          await this.setState({
            rowData: res.data.data,
          });
          let present = 0;
          let absent = 0;
          let half = 0;
          let sick = 0;
          let yet = 0;

          await res.data.data.map((e) => {
            if (e.AttnStatus == 0) {
              absent++;
            } else if (e.AttnStatus == 1) {
              present++;
            } else if (e.AttnStatus == 2) {
              half++;
            } else if (e.AttnStatus == 3) {
              sick++;
            } else if (e.AttnStatus == 9) {
              yet++;
            }
          });

          await this.setState({
            rowData: res.data.data,
            series: [present, absent, half],
          });

          if (sick == 0 && yet == 0) {
            await this.setState({
              series2: null,
            });
          } else {
            await this.setState({
              series2: [sick, yet],
            });
          }

          await this.barChartOpen(projectID);

          // console.log(this.state.rowData);
        } else {
          this.setState({
            alertSnackbar: true,
            rowData: [],
            series: null,
          });
        }
        await this.setState({
          style: {
            display: "none",
          },
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alertSnackbar: true,
          rowData: [],
          series: null,
          style: {
            display: "none",
          },
        });
      });
  };

  handleAlertCloseSnackBar = async () => {
    await this.setState({
      alertSnackbar: false,
    });
  };

  getAllData = async () => {
    await this.getData();
  };

  handleFilter = async () => {
    await this.getData();
  };

  handleCloseQuickReports = async () => {
    await this.setState({
      barChartModal: false,
    });
  };

  handleCloseStatsReport = async () => {
    await this.setState({
      statsModal: false,
    });
  };

  async componentDidMount() {
    await this.setState({
      style: {},
    });
   await this.getUniqueCities();
   await this.getUniqueDateRanges();
    this.dashboardEmployeeStatsCategoryDropdown();
    await this.dashboardEmployeeStats();
    await this.getDataOpen()
    await this.setState({
      style: {
        display: "none",
      },
    });
  }

  handleChangeDate = async (e) => {
    const id = e.target.value;
    // console.log(e.target.value, "id");
    if (e.target.value != 0) {
      let value = e.target.value;
      if (value == 2222) {
        await this.setState({
          dateID: e.target.value,
          startDate: "",
          endDate: "",
        });
        await this.toggle();
      } else {
        this.state.uniqueDateRanges.filter(async (eachDate, i) => {
          if (eachDate.title == id) {
            await this.setState({
              dateID: eachDate.title,
              startDate: eachDate.startDate,
              endDate: eachDate.endDate,
            });
          }
        });
      }
    }
  };

  handleChangeLocation = async (e) => {
    if (e.target.value != "") {
      await this.setState({
        locationID: e.target.value,
        projectName: [],
      });
    }

    const res = await axios
      .post(`${Config.hostName}/ems/dashboardProjectDropDown`, {
        locationID: this.state.locationID,
      })
      .then(async (res) => {
        // console.log(res.data.data);
        if (res.data.data.length > 0) {
          await this.setState({
            unique_project: res.data.data,
          });
        } else {
          alert("No Records Found... !!");
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  handleProjectChange = async (e, newValue) => {
    const value = newValue;
    await this.setState({
      projectName: typeof value === "string" ? value.split(",") : value,
    });
    // console.log(this.state.projectName);
  };

  handleClose = () => {
    this.setState({
      firstModal: false,
    });
  };

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  dashboardEmployeeStats = async () => {
    const res = await axios
      .post(`${Config.hostName}/ems/dashboardEmployeeStats`, {
        userid: this.state.userid,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        locationID: this.state.locationID,
        reportID: 1,
      })
      .then(async (res) => {
        // console.log(res.data.data);
        if (res.data.data.length > 0) {
          await this.setState({
            statsOverAll: res.data.data[0].category1,
            statsOther: res.data.data[0].category2,
          });
        } else {
          alert("No Records Found... !!");
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  dashboardEmployeeStatsOverallDetails = async () => {
    await this.setState({
      style: {},
    });
    const res = await axios
      .post(`${Config.hostName}/ems/dashboardEmployeeStatsDetails`, {
        reportID:4,
        statusID: "",
        segment: '',
        isOnroll: '',
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.data.length > 0) {
          await this.setState({
            rowDataStats: res.data.data,
            statsData: res.data.data,
            statsModal: true,
          });
        } else {
          alert("No Records Found... !!");
        }
        await this.setState({
          style: {
            display: "none",
          },
        });
      })
      .catch((error) => {
        alert(error);
        this.setState({
          style: {
            display: "none",
          },
        });
      });
  };

  dashboardEmployeeStatsDetails = async (statusID, segment, roll) => {
    await this.setState({
      style: {},
    });
    const res = await axios
      .post(`${Config.hostName}/ems/dashboardEmployeeStatsDetails`, {
        reportID: this.state.reportID,
        statusID: this.state.reportID != 2 ? statusID : "" ,
        segment: segment,
        isOnroll: roll,
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.data.length > 0) {
          await this.setState({
            rowDataStats: res.data.data,
            statsData: res.data.data,
            statsModal: true,
          });
        } else {
          alert("No Records Found... !!");
        }
        await this.setState({
          style: {
            display: "none",
          },
        });
      })
      .catch((error) => {
        alert(error);
        this.setState({
          style: {
            display: "none",
          },
        });
      });
  };

  dashboardEmployeeStatsCategoryDropdown = async () => {
    await axios
      .get(`${Config.hostName}/ems/dashboardEmployeeStatsCategoryDropdown`)
      .then((res) => {
        console.log(res.data.data);
        this.setState({
          StatsCategoryDropdown: res.data.data,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  handleStatsCategoryDropdown = async (e) => {
    await this.setState({
      style: {},
    });

    if (e.target.value != "") {
      await this.setState({
        reportID: e.target.value,
      });

      await axios
        .post(`${Config.hostName}/ems/dashboardEmployeeStats`, {
          userid: this.state.userid,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          locationID: this.state.locationID,
          reportID: e.target.value,
        })
        .then(async (res) => {
          // console.log(res.data.data);
          if (res.data.data.length > 0) {
            await this.setState({
              statsOverAll: res.data.data[0].category1,
              statsOther: res.data.data[0].category2,
            });
            await this.setState({
              style: {
                display: "none",
              },
            });
          } else {
            alert("No Records Found... !!");
          }

          let listArr = [];
          let orderListArr = [];

          if (e.target.value === 3) {
            res.data.data[0].category1.map((value, key) => {
              let upperOffArr = 0;
              let lowerOffArr = 0;

              const newVal = value.OffRoll.split("-");

              console.log(newVal[0]);

              upperOffArr = newVal[0];
              lowerOffArr = newVal[1];

              listArr[key] = value;
              listArr[key]["offUpper"] = upperOffArr;
              listArr[key]["offLower"] = lowerOffArr;
            });

            res.data.data[0].category1.map((value, key) => {
              let upperOnArr = 0;
              let lowerOnArr = 0;
              const newVal = value.OnRoll.split("-");

              upperOnArr = newVal[0];
              lowerOnArr = newVal[1];

              listArr[key] = value;
              listArr[key]["onUpper"] = upperOnArr;
              listArr[key]["onLower"] = lowerOnArr;
            });

            res.data.data[0].category2.map((value, key) => {
              let upperOffArr = 0;
              let lowerOffArr = 0;

              const newVal = value.OffRoll.split("-");

              console.log(newVal[0]);

              upperOffArr = newVal[0];
              lowerOffArr = newVal[1];

              listArr[key] = value;
              listArr[key]["offUpper"] = upperOffArr;
              listArr[key]["offLower"] = lowerOffArr;
            });
            res.data.data[0].category2.map((value, key) => {
              let upperOnArr = 0;
              let lowerOnArr = 0;
              const newVal = value.OnRoll.split("-");

              upperOnArr = newVal[0];
              lowerOnArr = newVal[1];

              listArr[key] = value;
              listArr[key]["onUpper"] = upperOnArr;
              listArr[key]["onLower"] = lowerOnArr;
            });
            await this.setState({
              statsOther: listArr,
            });
          } else {
            console.log("no split");
          }

          console.log(this.state.statsOverAll);
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  render() {
    const { vertical, horizontal } = this.state;

    return (
      <div>
        <Container
          pt={3}
          style={{
            maxWidth: "inherit",
            background: "#f1f1f1",
            paddingBottom: "12%",
            paddingTop: "2%",
            marginTop: "7%",
          }}
        >
          <div id="cover-spin" style={this.state.style}></div>

          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={this.state.alertSnackbar}
            autoHideDuration={5000}
            onClose={this.handleAlertCloseSnackBar}
            key={vertical + horizontal}
          >
            <Alert
              onClose={this.handleAlertCloseSnackBar}
              severity="error"
              sx={{ width: "100%" }}
            >
              No Records Found!
            </Alert>
          </Snackbar>

          <Card>
            <CardContent>
              <Grid pt={3} container>
                <Grid item md={12}>
                  {/* <Collapse in={this.state.alertState}>
                                  <Alert onClose={() => {
                                      this.setState({
                                          successText: '',
                                          alertState: false,
                                      })
                                  }} severity="success">
                                      <AlertTitle>Success</AlertTitle>
                                      {this.state.successText}
                                  </Alert>
                              </Collapse> */}
                </Grid>
               


                <Grid item md={2} className="mt-2">
                  <FormControl
                    variant="standard"
                    sx={{ width: "19vh" }}
                    style={{ marginLeft: "0px" }}
                  >
                    <InputLabel id="viewInvoiceBilling">Month</InputLabel>
                    <Select
                      labelId="viewInvoiceBilling"
                      id="viewInvoiceBilling2"
                      value={this.state.dateID}
                      onChange={(e) => this.handleChangeDate(e)}
                      label="Month"
                      variant="standard"
                    >
                      <MenuItem value="">
                        <em>Month</em>
                      </MenuItem>

                      {this.state.uniqueDateRanges.map((eachItem, key) => (
                        <MenuItem value={eachItem.title} key={key}>
                          {eachItem.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={2} className="mt-2">
                  <FormControl
                    variant="standard"
                    sx={{ width: "19vh" }}
                    style={{ marginLeft: "0px" }}
                  >
                    <InputLabel id="Location">Location</InputLabel>
                    <Select
                      labelId="Location"
                      id="Location"
                      value={this.state.locationID}
                      onChange={(e) => {
                        this.handleChangeLocation(e);
                      }}
                      label="Location"
                      variant="standard"
                    >
                      <MenuItem value="">
                        <em>Location</em>
                      </MenuItem>

                      {this.state.unique_cities.map((eachItem, key) => (
                        <MenuItem value={eachItem.Locationid} key={key}>
                          {eachItem.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={6} className="mt-2">
                  <FormControl
                    variant="standard"
                    sx={{ width: "19vh" }}
                    style={{ marginLeft: "0px" }}
                  >
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      value={this.state.projectName}
                      options={this.state.unique_project.map(
                        (option) => option.projectName
                      )}
                      disableCloseOnSelect
                      onChange={(e, newValue) => {
                        this.handleProjectChange(e, newValue);
                      }}
                      renderOption={(props, options, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {options}
                        </li>
                      )}
                      required
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Projects"
                          variant="standard"
                          placeholder="Choose Projects..."
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={2} className="mt-1">
                  <Button
                    onClick={(e) => this.handleFilter(e)}
                    size="large"
                    variant="contained"
                    style={{
                      marginTop: "18px",
                      background: "rgb(234 67 68)",
                      color: "white",
                      marginLeft: "20px",
                    }}
                    startIcon={<SearchIcon />}
                  >
                    Filter
                  </Button>
                </Grid>
                <Grid
                  style={{ float: "right", textAlign: "right" }}
                  item
                  md={6}
                ></Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card
            style={{
              display: `${this.state.barCardDisplay}`,
              marginTop: "10px",
            }}
          >
            <CardContent>
              <Grid mt={3} container>
                <Grid item md={12}>
                  {this.state.chartData != null && (
                    <div id="chart">
                      <ReactApexChart
                        options={this.state.options3}
                        series={this.state.series3}
                        type="bar"
                        height={350}
                      />
                    </div>
                  )}
                </Grid>

                {/* <Grid item md={6} className="mt-2">
              {this.state.series != null && (
                <div class="chart-wrap">
                  <div id="chart">
                    <ReactApexChart
                      options={this.state.options}
                      series={this.state.series}
                      type="donut"
                      width={380}
                    />
                  </div>
                </div>
              )}
            </Grid>

            <Grid item md={6} className="mt-2">
              {this.state.series2 != null && (
                <div class="chart-wrap">
                  <div id="chart">
                    <ReactApexChart
                      options={this.state.options2}
                      series={this.state.series2}
                      type="donut"
                      width={380}
                    />
                  </div>
                </div>
              )}
            </Grid> */}
              </Grid>
            </CardContent>
          </Card>

          <Grid mt={3}>
            <Grid item md={12}>
              <Card
                style={{
                  marginTop: "20px",
                }}
              >
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Typography
                        component="h5"
                        style={{
                          lineHeight: 3.5,
                        }}
                      >
                        Employee Stats
                      </Typography>
                    </div>
                    <div>
                      <FormControl
                        variant="standard"
                        sx={{ width: "30vh" }}
                        style={{ marginLeft: "0px" }}
                      >
                        <InputLabel id="viewStatsCategory">
                          Stats Category
                        </InputLabel>
                        <Select
                          labelId="viewStatsCategory"
                          id="viewStatsCategory1"
                          value={this.state.reportID}
                          onChange={(e) => this.handleStatsCategoryDropdown(e)}
                          label="Stats Category"
                          variant="standard"
                        >
                          <MenuItem value="">
                            <em>Stats Category</em>
                          </MenuItem>

                          {this.state.StatsCategoryDropdown.map(
                            (eachItem, key) => (
                              <MenuItem value={eachItem.reportID} key={key}>
                                {eachItem.reporttype}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      {this.state.statsOverAll.map((overall, index) => (
                        <Card key={index}>
                          <CardActionArea
                            style={{
                              cursor: "default",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "antiquewhite",
                              }}
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                  fontWeight: "600",
                                  padding: "5px",
                                  cursor:"pointer",
                                }}
                                onClick={() =>this.dashboardEmployeeStatsOverallDetails()
                                }
                              >
                                {overall.Segment}
                              </p>
                            </div>
                          </CardActionArea>
                          <CardContent>
                            {this.state.reportID !== 3 ? (
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <h1
                                      style={{
                                        fontSize: `${
                                          this.state.reportID === 3
                                            ? "2rem"
                                            : "2rem"
                                        }`,
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          overall.StatusID,
                                          overall.Segment,
                                          1
                                        )
                                      }
                                    >
                                      {overall.OnRoll}
                                    </h1>
                                  </div>
                                </div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    color: "grey",
                                  }}
                                >
                                  On Roll
                                </p>

                                <div
                                  style={{
                                    backgroundColor: "black",
                                    height: "1px",
                                    marginBottom: "20px",
                                  }}
                                ></div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <h1
                                      style={{
                                        fontSize: `${
                                          this.state.reportID === 3
                                            ? "2rem"
                                            : "2rem"
                                        }`,
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          overall.StatusID,
                                          overall.Segment,
                                          0
                                        )
                                      }
                                    >
                                      {overall.OffRoll}
                                    </h1>
                                  </div>
                                </div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    color: "grey",
                                  }}
                                >
                                  Off Roll
                                </p>
                              </div>
                            ) : (
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <h1
                                      style={{
                                        fontSize: `${
                                          this.state.reportID === 3
                                            ? "2rem"
                                            : "2rem"
                                        }`,
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          overall.StatusID,
                                          overall.Segment,
                                          1
                                        )
                                      }
                                    >
                                      {overall.onUpper}
                                    </h1>
                                  </div>
                                </div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    color: "grey",
                                  }}
                                >
                                  On Roll
                                </p>

                                <h1
                                  style={{
                                    fontSize: `${
                                      this.state.reportID === 3
                                        ? "1rem"
                                        : "1rem"
                                    }`,
                                    textAlign: "center",
                                    color: "grey",

                                    fontFamily:
                                      "Roboto,Helvetica,Arial,sans-serif",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.dashboardEmployeeStatsDetails(
                                      overall.StatusID,
                                      overall.Segment,
                                      1
                                    )
                                  }
                                >
                                  ₹ {overall.onLower}
                                </h1>

                                <div
                                  style={{
                                    backgroundColor: "black",
                                    height: "1px",
                                    marginBottom: "20px",
                                  }}
                                ></div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <h1
                                      style={{
                                        fontSize: `${
                                          this.state.reportID === 3
                                            ? "2rem"
                                            : "2rem"
                                        }`,
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          overall.StatusID,
                                          overall.Segment,
                                          0
                                        )
                                      }
                                    >
                                      {overall.offUpper}
                                    </h1>
                                  </div>
                                </div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    color: "grey",
                                  }}
                                >
                                  Off Roll
                                </p>

                                <h1
                                  style={{
                                    fontSize: `${
                                      this.state.reportID === 3
                                        ? "1rem"
                                        : "1rem"
                                    }`,
                                    textAlign: "center",
                                    color: "grey",

                                    fontFamily:
                                      "Roboto,Helvetica,Arial,sans-serif",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.dashboardEmployeeStatsDetails(
                                      overall.StatusID,
                                      overall.Segment,
                                      0
                                    )
                                  }
                                >
                                  ₹ {overall.offLower}
                                </h1>
                              </div>
                            )}
                          </CardContent>
                        </Card>
                      ))}
                    </Grid>
                    <Grid item xs={9}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          flexWrap: "wrap",
                        }}
                      >
                        {this.state.statsOther.map((stats, index) => (
                          <Card
                            style={{
                              width: "30%",
                              marginBottom: "10px",
                            }}
                            key={index}
                          >
                            <CardActionArea
                              style={{
                                cursor: "default",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#e1effb",
                                }}
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "600",
                                    padding: "5px",
                                  }}
                                >
                                  {stats.Segment}
                                </p>
                              </div>
                            </CardActionArea>
                            <CardContent>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                }}
                              >
                                {this.state.reportID !== 3 ? (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <h3
                                          style={{
                                            fontSize: `${
                                              this.state.reportID === 3
                                                ? "12px"
                                                : "1.75rem"
                                            }`,
                                            paddingTop: "0.5rem",
                                            fontFamily:
                                              "Roboto,Helvetica,Arial,sans-serif",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.dashboardEmployeeStatsDetails(
                                              stats.StatusID,
                                              stats.Segment,
                                              1
                                            )
                                          }
                                        >
                                          {stats.OnRoll}
                                        </h3>
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        color: "grey",
                                        fontSize: "11px",
                                      }}
                                    >
                                      On Roll
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <h3
                                          style={{
                                            paddingTop: "0.5rem",
                                            fontFamily:
                                              "Roboto,Helvetica,Arial,sans-serif",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.dashboardEmployeeStatsDetails(
                                              stats.StatusID,
                                              stats.Segment,
                                              1
                                            )
                                          }
                                        >
                                          {stats.onUpper}
                                        </h3>
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        color: "grey",
                                        fontSize: "11px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      On Roll
                                    </p>
                                    <h3
                                      style={{
                                        fontSize: `${
                                          this.state.reportID === 3
                                            ? "12px"
                                            : "1.75rem"
                                        }`,
                                        color: "grey",
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          stats.StatusID,
                                          stats.Segment,
                                          1
                                        )
                                      }
                                    >
                                      ₹ {stats.onLower}
                                    </h3>
                                  </div>
                                )}

                                <div>
                                  <p
                                    style={{
                                      fontSize: "2rem",
                                    }}
                                  >
                                    |
                                  </p>
                                </div>

                                {this.state.reportID !== 3 ? (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <h3
                                          style={{
                                            fontSize: `${
                                              this.state.reportID === 3
                                                ? "12px"
                                                : "1.75rem"
                                            }`,
                                            paddingTop: "0.5rem",
                                            fontFamily:
                                              "Roboto,Helvetica,Arial,sans-serif",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.dashboardEmployeeStatsDetails(
                                              stats.StatusID,
                                              stats.Segment,
                                              0
                                            )
                                          }
                                        >
                                          {stats.OffRoll}
                                        </h3>
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        color: "grey",
                                        fontSize: "11px",
                                      }}
                                    >
                                      Off Roll
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <h3
                                          style={{
                                            paddingTop: "0.5rem",
                                            fontFamily:
                                              "Roboto,Helvetica,Arial,sans-serif",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.dashboardEmployeeStatsDetails(
                                              stats.StatusID,
                                              stats.Segment,
                                              0
                                            )
                                          }
                                        >
                                          {stats.offUpper}
                                        </h3>
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        color: "grey",
                                        fontSize: "11px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      Off Roll
                                    </p>
                                    <h3
                                      style={{
                                        fontSize: `${
                                          this.state.reportID === 3
                                            ? "12px"
                                            : "1.75rem"
                                        }`,
                                        color: "grey",
                                        fontFamily:
                                          "Roboto,Helvetica,Arial,sans-serif",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.dashboardEmployeeStatsDetails(
                                          stats.StatusID,
                                          stats.Segment,
                                          0
                                        )
                                      }
                                    >
                                      ₹ {stats.offLower}
                                    </h3>
                                  </div>
                                )}
                              </div>
                            </CardContent>
                          </Card>
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid mt={3}>
            <Grid item md={12}>
              <MUIDataTable
                title={"Attendance Dashboard"}
                data={this.state.rowData}
                columns={this.state.columns}
                options={options}
              />
            </Grid>
          </Grid>
        </Container>

        <Dialog
          fullScreen
          TransitionComponent={this.Transition}
          onClose={this.handleCloseQuickReports}
          open={this.state.barChartModal}
        >
          <Container
            maxWidth="xl"
            style={{
              maxWidth: "initial",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <AppBar
              style={{
                height: "auto",
                backgroundColor: "white",
                color: "black",
              }}
              sx={{ position: "relative" }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseQuickReports}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      Sound
                    </Typography> */}
                <Button
                  autoFocus
                  color="inherit"
                  onClick={this.handleCloseQuickReports}
                >
                  Close
                </Button>
              </Toolbar>
            </AppBar>

            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                Update Status To: <b>{this.state.statusText}</b>
              </Typography> */}
            <MUIDataTable
              title={"Employee List"}
              data={this.state.barChartData}
              columns={this.state.barChartColumns}
              options={options}
            />
          </Container>
        </Dialog>

        <Dialog
          fullScreen
          TransitionComponent={this.Transition}
          onClose={this.handleCloseStatsReport}
          open={this.state.statsModal}
        >
          <Container
            maxWidth="xl"
            style={{
              maxWidth: "initial",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <AppBar
              style={{
                height: "auto",
                backgroundColor: "white",
                color: "black",
              }}
              sx={{ position: "relative" }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleCloseStatsReport}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      Sound
                    </Typography> */}
                <Button
                  autoFocus
                  color="inherit"
                  onClick={this.handleCloseStatsReport}
                >
                  Close
                </Button>
              </Toolbar>
            </AppBar>

            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                Update Status To: <b>{this.state.statusText}</b>
              </Typography> */}
            <MUIDataTable
              title={"Employee List"}
              data={this.state.statsData}
              columns={this.state.statsColumn}
              options={options}
            />
          </Container>
        </Dialog>

        {/* <Dialog
          open={this.state.firstModal}
          TransitionComponent={this.Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Use Google's location service?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Let Google help apps determine location. This means sending
              anonymous location data to Google, even when no apps are running.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog> */}
      </div>
    );
  }
}

HomePage.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(HomePage);
