import React, {
    Component
    // ,Fragment
} from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/alert.actions";
import Config from "../../../../config";
import './layout.css';
import Select from '@mui/material/Select';
import { Switch, Paper, Card, CardContent, CardMedia, Modal, Backdrop, Fade, Box, Collapse, Alert, AlertTitle, Toolbar, AppBar, Dialog, Slide, Tooltip, IconButton, Grid, Container, Typography, TextField, Button, FormControl, MenuItem, InputLabel } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import XLSX from "xlsx";

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import PlusIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import pdfIcon from "../../../../assets/customImages/pdfIcon.png";
import excelIcon from "../../../../assets/customImages/excel.jpg";
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const style7 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "83%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const style3 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const style4 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "65%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};


const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                },
            },
        },
    },
    palette: {
        primary: {
            light: '#757ce8',
            main: '#f44336',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: "806px",
        },
    },
};

let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();

const today = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;




class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {},
            selectedDate: new Date(),
            rowDataExport: [],
            options: {
                filterType: 'multiselect',
                selectableRows: 'none',
                setRowProps: row => {
                    if (row[0] === "New") {
                        return {
                            style: { background: "snow" }
                        };
                    }
                },
                draggableColumns: { enabled: true },
                responsive: "stacked",
                selectableRows: false,
                pagination: true,

                fixedHeader: false,
                downloadOptions: {
                    filterOptions: {
                        useDisplayedRowsOnly: true,
                    }
                },
                onDownload: (buildHead, buildBody, columns, data) => {

                    this.setState({
                        style: {}
                    });
                    const columns2 = Object.keys(this.state.rowDataExport[0]);
                    const data2 = this.state.rowDataExport.map((row) => {
                        return Object.values(row);
                    });


                    const worksheet = XLSX.utils.aoa_to_sheet([columns2, ...data2]);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, `All-${data2.length}`);

                    XLSX.writeFile(workbook, "Fuel_Intent_Report.xlsx");
                    this.setState({
                        style: {
                            display: "none"
                        }
                    });
                    return false;
                },
            },
            heading: '',
            customStartDate: '',
            customEndDate: '',
            uniqueDateRanges: [],
            dateRangeSelected: '',
            unique_cities: [],
            city: '',
            unique_clients: [],
            clientName: '',
            tripId: '',
            vehicleno: '',
            startDate: '',
            endDate: '',
            successText: '',
            alertState: false,
            severity: '',
            severity_text: '',
            intentno: '',
            modalImagesArray: [],
            openViewIntentModal: false,
            modalImage: '',
            imageModal: false,
            uploadBillLink: '',
            fid: '',
            intentDate: '',
            stationName: "",
            updateVehicleno: "",
            fuelCost: '',
            amount: '',
            updateTripid: "",
            remarks: "",
            oneStatus: '',
            openEditModal: false,
            unique_status: [],
            pageNumber: 1,
            hasMore: true,
            isProject: false,
            locationStats: [],
            locationTotalAmount: 0,
            projectStats: [],
            projectStatsExport: [],
            projectStatsCount: 0,
            projectStatsTotal: 0,
            showProjectStatsMoal: false,
            modal: false,
            fuelIntentData: [],
            openAiModal: false,
            aiData: {},
            rowData: [],
            columns: [
                {
                    name: "city",
                    label: "Location",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "intentno",
                    label: "Intent No",
                    options: {
                        filter: true,
                        sort: true,
                    },
                },
                {
                    name: "intentDate",
                    label: "Intent Date",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "stationName",
                    label: "Station Name",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "VendorName",
                    label: "Vendor",
                },
                {
                    name: "vehicleno",
                    label: "Vehicle No",
                },
                {
                    name: "clientName",
                    label: "Client Name",
                },
                {
                    name: "amount",
                    label: "Amount",
                },
                {
                    name: "createdby",
                    label: "Created By",
                },
                {
                    name: "tripID",
                    label: "Trip Id",
                },
                {
                    name: "Status",
                    label: "Status",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            let StatusColor = "";
                            if (value == "Bill not Uploaded") {
                                StatusColor = "text-danger";
                            } else if (value == "Rejected") {
                                StatusColor = "text-danger";
                            } else {
                                StatusColor = "text-success";
                            }

                            // const text = isVerified === true ? "YES" : "NO";
                            // const StatusColor = (isVerified === true) ? "text-success" : "text-danger";
                            return (
                                <span className={StatusColor} style={{ fontWieght: "bold" }}>
                                    {value}
                                </span>
                            );
                        }
                    }
                },
                {
                    name: "IsVerified",
                    label: "IsVerified",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const isVerified = value;
                            const text = isVerified === true ? "YES" : "NO";
                            const StatusColor =
                                isVerified === true ? "text-success" : "text-danger";
                            return (
                                <span className={StatusColor} style={{ fontWieght: "bold" }}>
                                    {text}
                                </span>
                            );
                        }
                    }
                },

                {
                    name: "isAiVerified",
                    label: "IsAIVerified",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const IsAiVerified = value;
                            const text = IsAiVerified === true ? "YES" : "NO";
                            const StatusColor =
                                IsAiVerified === true ? "text-success" : "text-danger";
                            return (
                                <span className={StatusColor} style={{ fontWieght: "bold" }}>
                                    {text}
                                </span>
                            );
                        }
                    }
                },
                {
                    name: "intentno",
                    label: "AI Verify",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <AutoAwesomeIcon className="pointer" onClick={() => this.handleOpenAIModal(value)} />
                            );
                        }
                    }
                },
                {
                    name: "intentno",
                    label: "View",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <RemoveRedEyeIcon className="pointer" onClick={() => this.handleOpenViewIntentModal(value)} />
                            );
                        }
                    }
                },

                {
                    name: "intentno",
                    label: "Edit",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <EditIcon className="pointer"
                                    onClick={() =>
                                        this.handleOpenEditModal(value)
                                    }
                                />
                            );
                        }
                    }
                },

                {
                    name: "IsVerified",
                    label: "Approve",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {

                            if (value == true) {
                                return (
                                    <div style={{ textAlign: 'center' }}>
                                        <Button
                                            onClick={(e) => { this.handleUpdateApprove('disapprove', 4, tableMeta['rowIndex']) }}
                                            size="large"
                                            variant="contained"
                                            style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }}
                                        >
                                            Disapprove
                                        </Button>
                                    </div>
                                );
                            } else {
                                return (
                                    <div style={{ textAlign: 'center', }}>
                                        <Button
                                            size="large"
                                            variant="contained"
                                            component="label"
                                            onClick={(e) => { this.handleUpdateApprove('approve', 3, tableMeta['rowIndex']) }}
                                            style={{ color: "white", width: '100%' }}
                                            className='uploadFile'
                                        >
                                            Approve
                                        </Button>
                                    </div>
                                );
                            }


                        }
                    }
                },
            ],


        };
        this.validator = new SimpleReactValidator();
    }

    delay = async (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    toggle = async () => {
        await this.setState({
            modal: !this.state.modal,
        });
    };


    exportReport = async () => {

        const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
        const endDate = moment(this.state.endDate).format("YYYY-MM-DD");

        const res = await axios.post(
            `${Config.hostName}/fuelintent/getFuelintentExportReport`,
            {
                city: this.state.city,
                clientName: this.state.clientName,
                startDate: startDate,
                endDate: endDate,
                stationName: '',
                tripId: this.state.tripId,
                vehicleno: this.state.vehicleno,
            }
        )
            .then(async (res) => {
                // console.log(res.data.data, 'project');
                if (res.data.data.length > 0) {
                    await this.setState({
                        rowDataExport: res.data.data,
                    });

                } else {
                    await this.setState({
                        rowDataExport: [],
                    });

                }
            })
            .catch((error) => {
                alert(error);
            });

    }




    getNewRequests = async (pageNumber = null) => {
        await this.setState({
            style: {},
        });
        // if (pageNumber == 'new') {
        //     this.setState({
        //         pageNumber: 1
        //     });
        // }
        // const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
        // const endDate = moment(this.state.endDate).format("YYYY-MM-DD");

        const res = await axios.post(
            `${Config.hostName}/fuelintent/getFuelIntentNew`,
            {
                city: this.state.city,
                clientName: this.state.clientName,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                // endDate: "2024-03-31T23:59:59.000Z",
                // startDate: "2024-03-01T00:00:00.000Z"
                // stationName: '',
                // tripId: this.state.tripId,
                // vehicleno: this.state.vehicleno,
                // pageNumber: this.state.pageNumber
            }
        )
            .then(async (res) => {
                // console.log(res.data.data, 'project');
                if (res.data.data.length > 0) {
                    await this.setState({
                        rowData: res.data.data,
                    });
                    this.exportReport();

                } else {
                    this.setState({
                        rowData: [],
                    })
                }
            })
            .catch((error) => {
                alert(error);
            });

        await this.setState({
            style: { display: "none" },
        })
    }


    getUniqueDateRange = async () => {
        const res = await axios.get(
            `${Config.hostName}/shipment/get/data/dashboard/royaloak/unique/date-ranges`,
            {

            }
        )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    this.setState({
                        uniqueDateRanges: res.data.data,
                        dateRangeSelected: 0,
                        startDate: res.data.data[0].startDate,
                        endDate: res.data.data[0].endDate,
                    });
                } else {
                    this.setState({
                        uniqueDateRanges: [],
                    });
                }
            })
            .catch((error) => {
                alert(error);
            });
    }


    getUniqueCities = async () => {
        const res = await axios.get(
            `${Config.hostName}/unique/locations`,
            {

            }
        )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    this.setState({
                        unique_cities: res.data.data,
                    });
                } else {
                    this.setState({
                        unique_cities: [],
                    });
                }
            })
            .catch((error) => {
                alert(error);
            });
    }


    getStatsByLocation = async () => {
        const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
        const endDate = moment(this.state.endDate).format("YYYY-MM-DD");
        const res = await axios.post(
            `${Config.hostName}/fuelintent/fuelstationstats`,
            {
                startDate: startDate,
                endDate: endDate,
            }
        )
            .then(async (res) => {
                if (res.data.data.length > 0) {
                    let total = 0;
                    const data = res.data.data;
                    await data.map((item) => {
                        total = total + item.issuedAmount;
                    })
                    this.setState({
                        locationStats: res.data.data,
                        locationTotalAmount: total
                    })
                } else {
                    this.setState({
                        locationStats: []
                    })
                }
            })
            .catch((error) => {
                alert(error);
            });
    }

    getStatsByProject = async () => {
        const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
        const endDate = moment(this.state.endDate).format("YYYY-MM-DD");
        const res = await axios.post(
            `${Config.hostName}/fuelintent/fuelstatsByProject`,
            {
                startDate: startDate,
                endDate: endDate,
            }
        )
            .then(async (res) => {
                console.log(res.data.data);
                const data = res.data.data;
                // Group data by unique cities
                const uniqueCities = [...new Set(data.map(item => item.City))];

                // Create a new array with the desired structure
                const result = uniqueCities.map(city => {
                    return {
                        City: city,
                        data: data.filter(item => item.City === city)
                    };
                });

                let total = 0;
                await data.map(async (item) => {
                    total = total + item.issuedAmount;
                })

                if (res.data.data.length > 0) {
                    this.setState({
                        projectStats: result,
                        projectStatsTotal: total,
                        projectStatsCount: data.length,
                        projectStatsExport: data
                    });
                } else {

                }
            })
            .catch((error) => {
                alert(error);
            });
    }



    async componentDidMount() {
        await this.setState({
            style: {}
        });
        if(this.props.auth.user.user_type != 'accounts'){
            window.location.href = '/admin/';
          }
        await this.getUniqueDateRange();
        this.getUniqueCities();
        await this.getNewRequests();


        await this.setState({
            style: {
                display: "none"
            }
        });
        this.getStatsByLocation();
        axios
            .get(`${Config.hostName}/fuelintent/getFuelIndentStatusDropdown`)
            .then((res) => {
                this.setState({
                    unique_status: res.data.data,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleChangeStatsData = async () => {
        await this.setState({
            style: {},
            isProject: !this.state.isProject
        });
        if (this.state.isProject) {
            await this.getStatsByProject();
        } else {
            await this.getStatsByLocation();
        }

        await this.setState({
            style: {
                display: "none"
            },

        });
    }


    handleChangeDateRange = async (e) => {
        let value = e.target.value;

        if (value == 'custom') {
            await this.setState({
                dateRangeSelected: 'custom',
                startDate: "",
                endDate: "",
            });
            await this.toggle();
        } else {
            await this.state.uniqueDateRanges.filter(async (eachDate, i) => {
                if (i == value) {
                    await this.setState({
                        dateRangeSelected: e.target.value,
                        startDate: eachDate.startDate,
                        endDate: eachDate.endDate,
                    });
                }
            });

            if (this.state.isProject) {
                this.getStatsByProject();
            } else {
                this.getStatsByLocation();
            }
        }



    };



    handleChangeLocation = async (e) => {

        await this.setState({
            style: {},
            city: e.target.value,
            clientName: '',

        });

        // await axios
        //     .post(`${Config.hostName}/unique/clients`, {
        //         locationId: e.target.value,
        //         clientName: "",
        //     })
        //     .then(async (res) => {
        //         await this.setState({
        //             unique_clients: res.data.data,
        //         });
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });


        await this.setState({
            style: { display: "none" },
        })
    };

    handleChangeClient = async (e) => {

        await this.setState({
            style: {},
            clientName: e.target.value,
        });

        await this.setState({
            style: { display: "none" },
        })

    }

    // handleChnageTripID = async (e) => {

    //     await this.setState({
    //         style: {},
    //         tripId: e.target.value,
    //     });
    //     await this.getNewRequests();
    //     await this.setState({
    //         style: { display: "none" },
    //     })

    // }


    imageProcessing = async (image, selectedRow) => {
        try {
            const res = await axios.post(
                `${Config.hostName}/fuelintent/imageProcessing`,
                {
                    image: image,
                    selectedRow: selectedRow,
                }
            )
            console.log(res.data.data);
            console.log(res.data.text);
            return res.data.data;
        } catch (error) {
            console.error(error);
        }
    }

    vijayaLakshmi = async (selectedRow, data) => {

        const results = {
            intentDate: false,
            amount: false,
            vehicleNo: false,
            stationName: true
        };


        if (data.includes('DUPLICATE')) {
            const startIndex = data.indexOf('DUPLICATE') + 'DUPLICATE'.length;
            const rawDate = data.substring(startIndex, startIndex + 12);
            const formattedDate = moment(rawDate, "DD-MMM-YYYY").format("YYYY-MM-DD");
            if (formattedDate === selectedRow.intentDate) {
                results.intentDate = true;
            }
        }

        const amountIndex = data.indexOf('AMOUNT:');
        if (amountIndex !== -1) {
            let endIndex = data.indexOf(' ', amountIndex + 8);
            if (endIndex === -1) { // If no space found, try finding the next line
                endIndex = data.indexOf('\n', amountIndex + 8);
            }
            if (endIndex !== -1) {
                const amountValue = data.substring(amountIndex + 8, endIndex).trim();
                if (selectedRow.amount === Math.round(amountValue)) {
                    results.amount = true;
                }
            }
        }

        const vehicleNoIndex = data.indexOf('VEHICLE NO:');
        if (vehicleNoIndex !== -1) {
            let endIndex = data.indexOf(' ', vehicleNoIndex + 12);
            if (endIndex === -1) { // If no space found, try finding the next line
                endIndex = data.indexOf('\n', vehicleNoIndex + 12);
            }
            if (endIndex !== -1) {
                const vehicleNoValue = data.substring(vehicleNoIndex + 12, vehicleNoIndex + 22).trim();
                if (selectedRow.vehicleno.replace(/\s/g, '') === vehicleNoValue) {
                    results.vehicleNo = true;
                }
            }
        }
        return results;
    }

    raipurGD = async (selectedRow, data) => {

        const results = {
            intentDate: false,
            amount: false,
            vehicleNo: false,
            stationName: true
        };

        const welcomeIndex = data.indexOf('Welcomes');
        if (welcomeIndex !== -1) {
            data = data.substring(welcomeIndex);
        } else {
            results.stationName = false;
        }
        console.log(data);
        // if (data.includes('DUPLICATE')) {
        //     const startIndex = data.indexOf('DUPLICATE') + 'DUPLICATE'.length;
        //     const rawDate = data.substring(startIndex, startIndex + 12);
        //     const formattedDate = moment(rawDate, "DD-MMM-YYYY").format("YYYY-MM-DD");
        //     if (formattedDate === selectedRow.intentDate) {
        //         results.intentDate = true;
        //     }
        // }

        // const amountIndex = data.indexOf('AMOUNT:');
        // if (amountIndex !== -1) {
        //     let endIndex = data.indexOf(' ', amountIndex + 8);
        //     if (endIndex === -1) { // If no space found, try finding the next line
        //         endIndex = data.indexOf('\n', amountIndex + 8);
        //     }
        //     if (endIndex !== -1) {
        //         const amountValue = data.substring(amountIndex + 8, endIndex).trim();
        //         if (selectedRow.amount === Math.round(amountValue)) {
        //             results.amount = true;
        //         }
        //     }
        // }

        // const vehicleNoIndex = data.indexOf('VEHICLE NO:');
        // if (vehicleNoIndex !== -1) {
        //     let endIndex = data.indexOf(' ', vehicleNoIndex + 12);
        //     if (endIndex === -1) { // If no space found, try finding the next line
        //         endIndex = data.indexOf('\n', vehicleNoIndex + 12);
        //     }
        //     if (endIndex !== -1) {
        //         const vehicleNoValue = data.substring(vehicleNoIndex + 12, vehicleNoIndex + 22).trim();
        //         if (selectedRow.vehicleno.replace(/\s/g, '') === vehicleNoValue) {
        //             results.vehicleNo = true;
        //         }
        //     }
        // }
        return results;
    }

    handleOpenAIModal = async (intentno) => {
        await this.setState({
            style: {},
            intentno: intentno,
        });

        const viewReqData = [];
        await this.state.rowData.filter((item) => {
            if (item.intentno == intentno) {
                return viewReqData.push(item);
            }
        });

        if (viewReqData.length > 0) {
            const selectedRow = viewReqData[0];
            const billLinks = JSON.parse(selectedRow.Jdata).billLink;
            let aiData = {
                intentDate: false,
                amount: false,
                vehicleNo: false,
                stationName: false,
                amountVal:0
            };

            if (billLinks.length > 0) {
                aiData = await this.imageProcessing(billLinks[0].billLink, selectedRow);

                // if (data.toLowerCase().includes('vijayalakshmi') || data.toLowerCase().includes('vijaya') || data.toLowerCase().includes('vijaya lakshmi')) {
                //     aiData = await this.vijayaLakshmi(selectedRow, data);
                //     aiData.stationName = true;
                // }

                // if (data.toLowerCase().includes('logistics fuels') || data.toLowerCase().includes('logisticsfuels') || data.toLowerCase().includes('g. d.') || data.toLowerCase().includes('g.d.')) {
                //     aiData = await this.raipurGD(selectedRow, data);
                //     aiData.stationName = true;
                //     console.log(aiData.stationName);
                // }
            }


            await this.setState({
                fuelIntentData: selectedRow,
                modalImagesArray: billLinks,
                openAiModal: true,
                aiData: aiData
            });

            if (selectedRow.Status == "Bill not Uploaded") {
                this.setState({ oneStatus: "1" });
            } else if (selectedRow.Status == "Bill Uploaded") {
                this.setState({ oneStatus: "2" });
            } else if (selectedRow.Status == "Verified") {
                this.setState({ oneStatus: "3" });
            } else if (selectedRow.Status == "Rejected") {
                this.setState({ oneStatus: "4" });
            }

            if (aiData.intentDate  && aiData.vehicleNo && aiData.stationName) {
                selectedRow.fuelCost = aiData.amountVal;
                if(aiData.amountVal == selectedRow.amount){
                    aiData.amount = true;
                    this.setState({
                        aiData: aiData,
                        oneStatus: "3"
                    });
                }
            }
           
            await this.setState({
                fid: selectedRow.fid,
                intentDate: selectedRow.intentDate,
                stationName: selectedRow.stationName,
                updateVehicleno: selectedRow.vehicleno,
                fuelCost: selectedRow.fuelCost,
                amount: selectedRow.amount,
                updateTripid: selectedRow.tripID,
                remarks: selectedRow.remarks,
                modalImagesArray: billLinks,
            });

        } else {
            await this.setState({
                fuelIntentData: [],
                modalImagesArray: [],
                openAiModal: false,
                aiData: {},
                fid: '',
                intentDate: '',
                stationName: '',
                updateVehicleno: '',
                fuelCost: '',
                amount: '',
                updateTripid: '',
                remarks: '',
            });
        }

        await this.setState({
            style: { display: "none" },
        })

    }

    handleCloseAiModal = async () => {
        await this.setState({
            fuelIntentData: [],
            modalImagesArray: [],
            openAiModal: false,
            aiData: {},
            fid: '',
            intentDate: '',
            stationName: '',
            updateVehicleno: '',
            fuelCost: '',
            amount: '',
            updateTripid: '',
            remarks: '',
        });
    }

    handleOpenViewIntentModal = async (intentno) => {
        await this.setState({
            style: {},
            intentno: intentno,
        });

        const viewReqData = [];
        await this.state.rowData.filter((item) => {
            if (item.intentno == intentno) {
                return viewReqData.push(item);
            }
        });

        if (viewReqData.length > 0) {
            const selectedRow = viewReqData[0];
            const billLinks = JSON.parse(selectedRow.Jdata).billLink;
            await this.setState({
                modalImagesArray: billLinks,
                openViewIntentModal: true
            });
        } else {
            await this.setState({
                modalImagesArray: [],
                openViewIntentModal: true
            });
        }

        await this.setState({
            style: { display: "none" },
        })

    }

    handleCloseViewIntentModal = async () => {
        await this.setState({
            modalImagesArray: [],
            openViewIntentModal: false,
        });
    }


    handleOpenImageModal = async (url) => {
        await this.setState({ modalImage: url });

        await this.setState({ imageModal: true });
    };

    handleCloseImageModal = async () => {
        await this.setState({ imageModal: false });
    };


    uploadFileAndGetUrl = async (formData) => {
        const fileData = await axios({
            method: "POST",
            url: `${Config.hostName}/unique/upload/any-file-name`,
            timeout: 3 * 60 * 1000,
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*"
            },
            data: formData,
        });
        return fileData;
    }
    billLinkUploadFile = async (file) => {
        const reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = async (e) => {
            await this.setState({
                selectedFile: [reader.result],
            });
        }
        // console.log(url); // Would see a path?
        const fileType = file.type.substring(0, 5);
        await this.setState({ selectedFile: file, selectedFileType: fileType });
        const formData = new FormData();
        formData.append(
            "media",
            this.state.selectedFile,
            `Intent_Attachment_${this.state.selectedFile.name}.${this.state.selectedFile.name.split('.').pop().toLowerCase()}`
        );
        // console.log(this.state.selectedFile, this.state.selectedFile.name, "this.state.selectedFile");
        // console.log(formData, "formData", fileType);
        const res = await this.uploadFileAndGetUrl(formData);
        const urlFromAws = res.data.url;
        return urlFromAws;
        // const urlFromAws = "https://logicarts.s3.ap-south-1.amazonaws.com/clientlogos/capp_truck.png";
        // await this.getPanCardUrl(urlFromAws, file.name);
        // console.log(this.state.attachments, "data")
    }



    onUpdateBillLink = async (event) => {


        event.preventDefault();

        const filesObj = event.target.files;
        const res = await this.billLinkUploadFile(filesObj[0]);

        await this.setState({
            style: {},
        });

        const res2 = await axios
            .post(`${Config.hostName}/fuelintent/modifyFuelIntent`, {
                fid: this.state.intentno,
                contentid: 2,
                content: [{ billLink: res }],
            })
            .then(async (response) => {
                if (response.data.success == false) {
                    alert(response.data.message);
                } else if (response.data.name) {
                    this.setState({
                        successText: response.data.parent.message,
                        alertState: true,
                        severity: 'error',
                        severity_text: 'error',
                        openViewIntentModal: false,
                    })
                    // this.props.setAlert(response.data.parent.message, "danger");
                } else {
                    this.setState({
                        successText: 'Attachment uploaded successfully',
                        alertState: true,
                        severity: 'success',
                        severity_text: 'success',
                        openViewIntentModal: false,
                    })
                    await this.getNewRequests();
                }
            })
            .catch((error) => {
                alert(error.message);
            });

        await this.setState({
            style: { display: "none" },
        })
    };

    handleOpenEditModal = async (intentno) => {
        this.setState({
            intentno: intentno,
        })
        const viewReqData = [];
        await this.state.rowData.filter((item) => {
            if (item.intentno == intentno) {
                return viewReqData.push(item);
            }
        });

        const selectedRow = viewReqData[0];
        const billLinks = JSON.parse(selectedRow.Jdata).billLink;
        if (selectedRow.Status == "Bill not Uploaded") {
            this.setState({ oneStatus: "1" });
        } else if (selectedRow.Status == "Bill Uploaded") {
            this.setState({ oneStatus: "2" });
        } else if (selectedRow.Status == "Verified") {
            this.setState({ oneStatus: "3" });
        } else if (selectedRow.Status == "Rejected") {
            this.setState({ oneStatus: "4" });
        }


        await this.setState({
            fid: selectedRow.fid,
            intentDate: selectedRow.intentDate,
            stationName: selectedRow.stationName,
            updateVehicleno: selectedRow.vehicleno,
            fuelCost: selectedRow.fuelCost,
            amount: selectedRow.amount,
            updateTripid: selectedRow.tripID,
            remarks: selectedRow.remarks,

            modalImagesArray: billLinks,
            openEditModal: true
        });
    }

    handleCloseEditModal = async (intentNo) => {
        await this.setState({
            openEditModal: false
        })
    }

    onSubmitModal = async (e) => {
        e.preventDefault();

        await this.setState({
            style: {},
        });

        const res = await axios
            .post(`${Config.hostName}/fuelintent/modifyFuelIntent`, {
                fid: this.state.fid,
                contentid: 1,
                content: [
                    {
                        intentno: this.state.intentno,
                        intentDate: this.state.intentDate,
                        stationName: this.state.stationName,
                        vehicleno: this.state.updateVehicleno,
                        fuelCost: this.state.fuelCost,
                        amount: this.state.amount,
                        tripID: this.state.updateTripid,
                        statusID: this.state.oneStatus,
                        Remarks: this.state.remarks,
                    },
                ],
            })
            .then(async (response) => {
                if (response.data.name) {
                    this.setState({
                        successText: response.data.parent.message,
                        alertState: true,
                        severity: 'error',
                        severity_text: 'Error',
                        openEditModal: false
                    });

                } else {
                    this.setState({
                        successText: 'Fuel Intent Updated Successfully',
                        alertState: true,
                        severity: 'success',
                        severity_text: 'Success',
                        openEditModal: false
                    });

                    await this.getNewRequests();

                }
            })
            .catch((error) => {
                console.log(error, "error");
            });

        await this.setState({
            style: { display: "none" },
        })
    }

    handleUpdateApprove = async (status, contentid, index) => {
        if (window.confirm(`Are you sure you want to ${status} the status?`)) {

            await this.setState({
                style: {},
            });
            let content = [];
            let contentstring = '';
            if (contentid == 3) {
                content = { "statusID": "3" };
                contentstring = JSON.stringify(content);
            }


            await axios
                .post(`${Config.hostName}/fuelintent/modifyFuelIntent`, {
                    fid: this.state.rowData[index].fid,
                    contentid: contentid,
                    content: contentid == 4 ? this.state.rowData[index].fid : contentstring,
                })
                .then(async (response) => {
                    let statusText = 'Approved';
                    if (contentid == 4) {
                        statusText = 'Disapproved';
                    }
                    this.setState({
                        successText: statusText,
                        alertState: true,
                        severity: 'success',
                        severity_text: 'success',
                    });
                    await this.getNewRequests();
                })
                .catch((error) => {
                    console.log(error, "error");
                });
            await this.getNewRequests();
        } else {

        }
        await this.setState({
            style: { display: "none" },
        })
    }

    handleShowProjectStats = async () => {
        this.setState({
            showProjectStatsMoal: true,
        })
    };

    handleCloseProjectStats = async () => {
        this.setState({
            showProjectStatsMoal: false,
        })
    };

    handleExportStats = async () => {
        this.setState({
            style: {}
        });
        if (this.state.isProject) {
            const columns2 = Object.keys(this.state.projectStatsExport[0]);
            const data2 = this.state.projectStatsExport.map((row) => {
                return Object.values(row);
            });


            const worksheet = XLSX.utils.aoa_to_sheet([columns2, ...data2]);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, `All-${data2.length}`);

            XLSX.writeFile(workbook, "Fuel_Intent_Stats.xlsx");

        } else {
            const columns2 = Object.keys(this.state.locationStats[0]);
            const data2 = this.state.locationStats.map((row) => {
                return Object.values(row);
            });


            const worksheet = XLSX.utils.aoa_to_sheet([columns2, ...data2]);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, `All-${data2.length}`);

            XLSX.writeFile(workbook, "Fuel_Intent_Stats_By_Location.xlsx");
        }
        this.setState({
            style: {
                display: "none"
            }
        });
    };


    toggleCloseModal = async () => {
        await this.toggle();
        await this.setState({
            dateRangeSelected: "",
        });
    };

    toggleConfirmModal = async () => {
        await this.toggle();
        const startDate = moment(this.state.customStartDate).format("YYYY-MM-DD");
        const endDate = moment(this.state.customEndDate).format("YYYY-MM-DD");

        await this.setState({
            startDate: startDate,
            endDate: endDate,
            dateRangeSelected: 'custom',
        });

        if (this.state.isProject) {
            this.getStatsByProject();
        } else {
            this.getStatsByLocation();
        }

        this.getNewRequests('new');
    };


    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    render() {


        return (
            <div>
                <ThemeProvider theme={theme}>

                    <Container pt={3} style={{ maxWidth: "inherit", paddingBottom: "12%", paddingTop: "2%", marginTop: "7%", paddingLeft: "0px", paddingRight: "0px", marginBottom: "2vh", }}>
                        <div id='cover-spin' style={this.state.style}></div>

                        <Card>
                            <CardContent>

                                <Grid pt={3} container>
                                    <Grid item md={12} >
                                        <Collapse in={this.state.alertState}>
                                            <Alert onClose={() => {
                                                this.setState({
                                                    successText: '',
                                                    alertState: false,
                                                    severity: '',
                                                    severity_text: '',
                                                })
                                            }} severity={this.state.severity}>
                                                <AlertTitle>Success</AlertTitle>
                                                {this.state.successText}
                                            </Alert>
                                        </Collapse>
                                    </Grid>


                                    <Grid item md={2} className="mt-2">
                                        <FormControl sx={{ width: "18vh", m: 1 }} style={{ color: "#666666", }} fullWidth>
                                            <InputLabel id="dateRangeSelected">Date Range</InputLabel>
                                            <Select
                                                labelId="dateRangeSelected"
                                                id="dateRangeSelected"

                                                value={this.state.dateRangeSelected}
                                                onChange={(e) => {
                                                    this.handleChangeDateRange(e);
                                                }}
                                                label="Select Date Range"
                                                displayEmpty
                                                style={{ color: "#666666", height: "48px", }}
                                            >
                                                <MenuItem disabled>
                                                    <em>Select Date range</em>
                                                </MenuItem>

                                                {this.state.uniqueDateRanges.map((eachItem, key) => (
                                                    <MenuItem value={key} key={key}>
                                                        {eachItem.title}
                                                    </MenuItem>
                                                ))}
                                                <MenuItem value="custom">
                                                    Custom date
                                                </MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>

                                    <Grid item md={2} className="mt-2">


                                        <FormControl sx={{ width: "18vh", m: 1 }} style={{ color: "#666666", }} fullWidth>
                                            <InputLabel id="Location">Location</InputLabel>
                                            <Select
                                                labelId="Location"
                                                id="Location"
                                                value={this.state.city}
                                                onChange={(e) => {
                                                    this.handleChangeLocation(e);
                                                }}
                                                label="Select Location"
                                                displayEmpty
                                                style={{ color: "#666666", height: "48px", }}
                                            >
                                                <MenuItem disabled>
                                                    <em> Select Location</em>
                                                </MenuItem>

                                                {this.state.unique_cities.map((eachItem, key) => (
                                                    <MenuItem value={eachItem.Locationid} key={key}>
                                                        {eachItem.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </Grid>

                                    {/* <Grid item md={2} className="mt-2">

                                        <FormControl sx={{ width: "18vh", m: 1 }} style={{ color: "#666666", }} fullWidth>
                                            <InputLabel id="Client">Client</InputLabel>
                                            <Select
                                                labelId="Client"
                                                id="Client"
                                                value={this.state.clientName}
                                                onChange={(e) => {
                                                    this.handleChangeClient(e);
                                                }}
                                                label="Select Client"
                                                displayEmpty
                                                style={{ color: "#666666", height: "48px", }}
                                            >
                                                <MenuItem disabled>
                                                    <em>     Select Client</em>
                                                </MenuItem>

                                                {this.state.unique_clients.map((eachItem, key) => (
                                                    <MenuItem value={eachItem.ClientName} key={key}>
                                                        {eachItem.ClientName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid> */}

                                    {/* <Grid item md={2} className="mt-2 mb-2 " style={{ height: "48px", }}>
                                        <FormControl
                                            sx={{ width: "18vh", m: 1 }}
                                            fullWidth
                                            style={{ color: "#666666", marginLeft: "0px", height: "48px" }}
                                        >
                                            <TextField
                                                style={{ color: "#666666", height: "48px" }}
                                                id="vehicle"
                                                onChange={(e) => this.setState({ vehicleno: e.target.value })}
                                                placeholder="Enter Vehicle No"
                                                label="Vehicle No"
                                                variant="outlined"
                                            />
                                        </FormControl>

                                    </Grid> */}

                                    <Grid item md={1}>
                                        <Button onClick={(e) => this.getNewRequests('new')} size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white", marginRight: '0px' }} >
                                            Filter
                                            {/* endIcon={<SearchIcon />} */}
                                        </Button>
                                    </Grid>



                                    <Grid item md={3} style={{ textAlign: 'right', float: 'right' }}>
                                        <FormControl variant="standard" fullWidth style={{ marginRight: '0px', marginLeft: '0px', marginTop: "18px" }}>
                                            <Grid style={{ marginLeft: '45px' }} component="label" container alignItems="center" spacing={1}>
                                                <Grid item>Location</Grid>
                                                <Grid item>
                                                    <Switch
                                                        checked={this.state.isProject}
                                                        onChange={this.handleChangeStatsData}
                                                        value="1"
                                                        color="primary"
                                                    />
                                                </Grid>
                                                <Grid item>Project</Grid>
                                            </Grid>
                                        </FormControl>
                                    </Grid>


                                    {/* <Grid item md={1}>
                                        <Button onClick={(e) => this.imageProcessing()} size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white", marginRight: '0px' }} >
                                            Filter
                                        </Button>
                                    </Grid> */}

                                    {/* <Grid style={{ float: 'right', textAlign: 'right' }} item md={12}>
                            <Button onClick={(e) => this.handleOpenFullModal('add', e)} size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white" }} endIcon={<PlusIcon />}>
                                Filter
                            </Button>
                        </Grid> */}


                                </Grid>

                            </CardContent>
                        </Card>


                        <Grid mt={3}>


                            <Card>
                                <CardContent>
                                    {this.state.isProject == true ?
                                        <Grid item md={12} className="mt-2">
                                            <Typography variant="h6" color="inherit" component="div" style={{ textAlign: 'right', fontSize: '29px' }}>
                                                {this.state.projectStats != 0 &&
                                                    <>
                                                        <Grid container>
                                                            <Grid item md={3} className="mt-2">
                                                            </Grid>

                                                            <Grid item md={3} className="mt-2">
                                                                <Typography variant="h6" color="inherit" component="div" style={{ textAlign: 'center', fontSize: '22px' }}>
                                                                    {this.state.locationStats != 0 && <>
                                                                        Station Count : <b style={{ color: 'red' }}>{this.state.projectStatsCount}</b>
                                                                    </>
                                                                    }
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item md={5} className="mt-2">
                                                                <Typography variant="h6" color="inherit" component="div" style={{ textAlign: 'center', fontSize: '22px' }}>
                                                                    {this.state.locationStats != 0 && <>
                                                                        Total Fuel Amount: <b style={{ color: 'red' }}>₹ {new Intl.NumberFormat("en-IN").format(this.state.projectStatsTotal)}</b>
                                                                    </>
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item md={1} style={{ textAlign: 'center' }} className="">
                                                                <Tooltip title="Full screen">
                                                                    <FullscreenIcon className="projectStatsModalButton" onClick={this.handleShowProjectStats} />
                                                                </Tooltip>
                                                            </Grid>

                                                        </Grid>


                                                    </>
                                                }
                                            </Typography>
                                        </Grid>
                                        :
                                        <Grid item md={12} className="mt-2">
                                            <Grid container>
                                                <Grid item md={3} className="mt-2">
                                                </Grid>

                                                <Grid item md={3} className="mt-2">
                                                    <Typography variant="h6" color="inherit" component="div" style={{ textAlign: 'center', fontSize: '22px' }}>
                                                        {this.state.locationStats != 0 && <>
                                                            Station Count : <b style={{ color: 'red' }}>{this.state.locationStats.length}</b>
                                                        </>
                                                        }
                                                    </Typography>
                                                </Grid>




                                                <Grid item md={5} className="mt-2">
                                                    <Typography variant="h6" color="inherit" component="div" style={{ textAlign: 'center', fontSize: '22px' }}>
                                                        {this.state.locationStats != 0 && <>
                                                            Total Fuel Amount: <b style={{ color: 'red' }}>₹ {new Intl.NumberFormat("en-IN").format(this.state.locationTotalAmount)}</b>
                                                        </>
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={2} className="mt-2">
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                    }



                                    {this.state.isProject == true ?

                                        <Grid item md={12} className="mt-2">
                                            <Grid style={{ display: '-webkit-box', flexWrap: 'nowrap', overflow: 'scroll', height: '235px' }} className="flexGrid" container>


                                                {this.state.projectStats != [] && this.state.projectStats.map((eachItem, key) => (
                                                    <>


                                                        <Grid item md={2} style={{ padding: '5px', }} className="mt-2 mb-2 ">
                                                            <Typography
                                                                mb={2}
                                                                style={{
                                                                    fontSize: "12px",
                                                                    fontWeight: 600,
                                                                    height: '34px',
                                                                    textAlign: 'center',
                                                                    marginBottom: '-6px',

                                                                }}
                                                            >
                                                                {eachItem.City}
                                                            </Typography>
                                                            <div >

                                                                <Grid container>

                                                                    {eachItem.data != null &&
                                                                        eachItem.data.map((each, key) => (
                                                                            <Grid item md={12} className="mb-2" style={{ boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)' }}>


                                                                                <Card
                                                                                    style={{
                                                                                        cursor: "pointer",

                                                                                    }}
                                                                                    className="hoverBoder"
                                                                                >
                                                                                    <CardContent
                                                                                        style={{
                                                                                            textAlign: "center",
                                                                                            padding: '15px'
                                                                                            // maxHeight: "135px",
                                                                                            // minHeight: '130px',
                                                                                        }}
                                                                                    >
                                                                                        <Typography
                                                                                            mb={2}
                                                                                            style={{
                                                                                                fontSize: "12px",
                                                                                                fontWeight: 600,
                                                                                                height: '13px'
                                                                                            }}
                                                                                        >
                                                                                            {each.ProjectName}
                                                                                        </Typography>
                                                                                        <Grid container spacing={1} >
                                                                                            <Grid item xs={12}>
                                                                                                <Typography
                                                                                                    style={{
                                                                                                        fontWeight: 600,
                                                                                                        color: "#ff4700c9",
                                                                                                        fontSize: "12px",
                                                                                                    }}
                                                                                                >
                                                                                                    {" "}
                                                                                                    ₹  {new Intl.NumberFormat("en-IN").format(each.issuedAmount)}
                                                                                                </Typography>

                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </CardContent>

                                                                                </Card>

                                                                            </Grid>
                                                                        ))
                                                                    }



                                                                </Grid>

                                                            </div>
                                                        </Grid>


                                                    </>
                                                )
                                                )}
                                            </Grid>
                                        </Grid> :

                                        <Grid item md={10} className="mt-2">
                                            <Grid pt={1} container spacing={1}>

                                                {this.state.locationStats != [] && this.state.locationStats.map((eachItem, key) => (
                                                    <Grid item md={2} className="mt-2 mb-2 ">
                                                        <Paper elevation={6}>
                                                            <Card
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                className="hoverBoder"
                                                            >
                                                                <CardContent
                                                                    style={{
                                                                        textAlign: "center",
                                                                        maxHeight: "135px",
                                                                        minHeight: '130px',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        mb={2}
                                                                        style={{
                                                                            fontSize: "12px",
                                                                            fontWeight: 600,
                                                                            height: '34px'
                                                                        }}
                                                                    >
                                                                        {eachItem.stationName}
                                                                    </Typography>
                                                                    <Grid container spacing={2} mb={2}>

                                                                        <Grid item xs={12}>
                                                                            <Typography
                                                                                style={{
                                                                                    fontWeight: 600,
                                                                                    color: "#ff4700c9",
                                                                                    fontSize: "14px",
                                                                                }}
                                                                            >
                                                                                ₹ {new Intl.NumberFormat("en-IN").format(eachItem.issuedAmount)}
                                                                            </Typography>
                                                                            <Typography
                                                                                style={{
                                                                                    fontSize: "12px",
                                                                                    fontWeight: 300,
                                                                                }}
                                                                            >
                                                                                {/* Payee Count */}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </CardContent>
                                                            </Card>
                                                        </Paper>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    }

                                </CardContent>
                            </Card>

                            <br />

                            <Grid item md={8}>
                                <Paper elevation={4}>

                                    {/* <InfiniteScroll
                                        dataLength={this.state.rowData.length}
                                        next={this.fetchMoreData}
                                        hasMore={this.state.hasMore}
                                        loader={<h4 style={{ marginLeft: '29px' }}>Loading. ..</h4>}
                                        endMessage={
                                            <p style={{ textAlign: "center" }}>
                                                <br /> <b>Yay! You have seen it all</b>
                                            </p>
                                        }
                                    > */}
                                    <div style={{ height: '100%', width: '100%' }}>
                                        <MUIDataTable
                                            title={"Fuel Intent"}
                                            data={this.state.rowData}
                                            columns={this.state.columns}
                                            options={this.state.options}
                                        />
                                    </div>
                                    {/* </InfiniteScroll> */}
                                </Paper>
                            </Grid>

                        </Grid>

                    </Container>




                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.modal}
                        onClose={this.toggleCloseModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.modal}>
                            <Box sx={style2}>

                                <Grid container>
                                    <Grid className="panText" item md={12}>
                                        Select Start and End date
                                    </Grid>

                                    <Grid
                                        pt={2}
                                        item
                                        md={12}
                                        style={{ float: "left", textAlign: "left" }}
                                    >
                                        <div className="row" style={{ minHeight: "1px" }}>
                                            <div className="col float-left">
                                                <div
                                                    className="cal-datepicker"
                                                    style={{ height: "100%" }}
                                                >
                                                    <div
                                                        className="datepicker-here text-center"
                                                        style={{ height: "100%" }}
                                                        data-language="en"
                                                    >
                                                        <DatePicker
                                                            height="400"
                                                            placeholderText="Select Start Date"
                                                            id="startDateInput"
                                                            inline
                                                            selectsRange
                                                            selected={this.state.customStartDate}
                                                            startDate={this.state.customStartDate}
                                                            onChange={(date) => {
                                                                this.setState({
                                                                    customStartDate: date,
                                                                });
                                                            }}
                                                            dateFormat="yyyy-MM-dd"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col float-left">
                                                <div className="cal-datepicker">
                                                    <div
                                                        className="datepicker-here text-center"
                                                        data-language="en"
                                                    >
                                                        <DatePicker
                                                            height="400"
                                                            id="endDateInput"
                                                            inline
                                                            placeholderText="Select End Date"
                                                            selected={this.state.customEndDate}
                                                            onChange={(date) => {
                                                                this.setState({
                                                                    customEndDate: date,
                                                                });
                                                            }}
                                                            dateFormat="yyyy-MM-dd"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Grid>


                                    <Grid className="panDownload" item md={12}>

                                        <Button
                                            type="button"
                                            size="large"
                                            variant="contained"
                                            style={{
                                                marginTop: "35px",
                                                background: "rgb(234 67 68)",
                                                color: "white",
                                            }}
                                            onClick={this.toggleConfirmModal}

                                        >
                                            Confirm
                                        </Button>
                                    </Grid>

                                </Grid>



                            </Box>
                        </Fade>
                    </Modal>



                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.openViewIntentModal}
                        onClose={this.handleCloseViewIntentModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.openViewIntentModal}>
                            <Box sx={style2}>

                                <Grid container>
                                    <Grid className="panText" item md={12}>
                                        Attachments for Intent No: <b>{this.state.intentno}</b>
                                    </Grid>

                                    <Grid
                                        pt={2}
                                        item
                                        md={12}
                                        style={{ float: "left", textAlign: "left" }}
                                    >
                                        {this.state.modalImagesArray.length > 0 &&
                                            this.state.modalImagesArray.map((imageUrl, i) => {
                                                return (
                                                    <div
                                                        className="bms-open-attachment-modal-image"
                                                        onClick={() =>
                                                            this.handleOpenImageModal(
                                                                imageUrl.billLink
                                                            )
                                                        }
                                                    >
                                                        {imageUrl.billLink &&
                                                            imageUrl.billLink
                                                                .split(".")
                                                                .pop()
                                                                .toLowerCase() != "pdf" &&
                                                            imageUrl.billLink
                                                                .split(".")
                                                                .pop()
                                                                .toLowerCase() != "xlsx" && (
                                                                <img
                                                                    className="bms-view-request-img"
                                                                    src={imageUrl.billLink}
                                                                    alt="No Image"
                                                                    key={i}
                                                                />
                                                            )}
                                                        {imageUrl.billLink &&
                                                            imageUrl.billLink
                                                                .split(".")
                                                                .pop()
                                                                .toLowerCase() == "pdf" && (
                                                                <img
                                                                    className="bms-view-request-img"
                                                                    src={pdfIcon}
                                                                    alt="No Image"
                                                                    key={i}
                                                                />
                                                            )}
                                                        {imageUrl.billLink &&
                                                            imageUrl.billLink
                                                                .split(".")
                                                                .pop()
                                                                .toLowerCase() == "xlsx" && (
                                                                <img
                                                                    className="bms-view-request-img"
                                                                    src={excelIcon}
                                                                    alt="No Image"
                                                                    key={i}
                                                                />
                                                            )}
                                                    </div>
                                                );
                                            })}

                                        {this.state.modalImagesArray.length == 0 &&
                                            <div className="panText2">
                                                No attachments found!
                                            </div>
                                        }
                                    </Grid>
                                    <Grid className="panDownload" item md={12}>

                                        <Button
                                            size="large"
                                            variant="contained"
                                            component="label"
                                            onChange={(e) => { this.onUpdateBillLink(e) }}
                                            style={{ color: "white" }}
                                            className='uploadFile'
                                        >
                                            Upload
                                            <input
                                                type="file"
                                                hidden
                                                accept="image/*,.pdf,.xls,.xlsx"
                                            />
                                        </Button>
                                    </Grid>

                                </Grid>



                            </Box>
                        </Fade>
                    </Modal>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.openAiModal}
                        onClose={this.handleCloseAiModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.openAiModal}>
                            <Box sx={style7}>
                                <form onSubmit={this.onSubmitModal} method="POST">
                                    <Grid container >
                                        <Grid className="panText" style={{ fontWeight: '700' }} item md={12}>
                                            Update Intent No: <b>{this.state.intentno}</b>
                                        </Grid>

                                        <Grid className="aiText" style={{ fontWeight: '700' }} item md={12}>
                                            <b>AI Data:</b>
                                        </Grid>
                                        <Grid item m={1} md={12} style={{ marginTop: '14px' }} >

                                            <Grid container>

                                                <Grid
                                                    pt={2}
                                                    item
                                                    md={2}
                                                    style={{ float: "left", textAlign: "left" }}
                                                >
                                                    {this.state.modalImagesArray.length > 0 &&
                                                        this.state.modalImagesArray.map((imageUrl, i) => {
                                                            return (
                                                                <div
                                                                    className="bms-open-attachment-modal-image"
                                                                    onClick={() =>
                                                                        this.handleOpenImageModal(
                                                                            imageUrl.billLink
                                                                        )
                                                                    }
                                                                >
                                                                    {imageUrl.billLink &&
                                                                        imageUrl.billLink
                                                                            .split(".")
                                                                            .pop()
                                                                            .toLowerCase() != "pdf" &&
                                                                        imageUrl.billLink
                                                                            .split(".")
                                                                            .pop()
                                                                            .toLowerCase() != "xlsx" && (
                                                                            <img
                                                                                className="bms-view-request-img"
                                                                                src={imageUrl.billLink}
                                                                                alt="No Image"
                                                                                key={i}
                                                                            />
                                                                        )}
                                                                    {imageUrl.billLink &&
                                                                        imageUrl.billLink
                                                                            .split(".")
                                                                            .pop()
                                                                            .toLowerCase() == "pdf" && (
                                                                            <img
                                                                                className="bms-view-request-img"
                                                                                src={pdfIcon}
                                                                                alt="No Image"
                                                                                key={i}
                                                                            />
                                                                        )}
                                                                    {imageUrl.billLink &&
                                                                        imageUrl.billLink
                                                                            .split(".")
                                                                            .pop()
                                                                            .toLowerCase() == "xlsx" && (
                                                                            <img
                                                                                className="bms-view-request-img"
                                                                                src={excelIcon}
                                                                                alt="No Image"
                                                                                key={i}
                                                                            />
                                                                        )}
                                                                </div>
                                                            );
                                                        })}

                                                    {this.state.modalImagesArray.length == 0 &&
                                                        <div className="panText2">
                                                            No attachments found!
                                                        </div>
                                                    }
                                                </Grid>

                                                <Grid item m={1} md={8} style={{ marginTop: '14px' }} >
                                                    <Grid container>
                                                        <Grid item m={1} md={2} style={{ fontFamily: 'system-ui', marginTop: '14px' }} >
                                                            <b style={{ fontSize: '15px', fontFamily: 'system-ui', margin: '5px' }}>Station Name :</b>
                                                        </Grid>

                                                        <Grid item m={1} md={3} style={{ fontFamily: 'system-ui', marginTop: '14px' }} >
                                                            <b style={{ fontSize: '15px', fontFamily: 'system-ui', margin: '5px', color: this.state.aiData['stationName'] ? 'green' : 'black' }}>{this.state.aiData['stationName'] ? this.state.aiData['stationNameVal'] : 'Not Verified'}</b>
                                                        </Grid>

                                                        <Grid item m={1} md={2} style={{ fontFamily: 'system-ui', marginTop: '14px' }} >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}>Intent Date :</b>
                                                        </Grid>

                                                        <Grid item m={1} md={3} style={{ marginTop: '14px', fontFamily: 'system-ui' }} >
                                                            <b style={{ fontSize: '15px', fontFamily: 'system-ui', margin: '5px', color: this.state.aiData['intentDate'] ? 'green' : 'black' }}>{this.state.aiData['intentDate'] ? this.state.aiData['intentDateVal'] : this.state.aiData['intentDateVal']}</b>
                                                        </Grid>

                                                        {/* <Grid item m={1} md={2}  >
                                                            <b style={{ fontSize: '15px', margin: '5px' }}></b>
                                                        </Grid> */}

                                                        <Grid item m={1} md={2}  >
                                                            <b style={{ fontSize: '15px', fontFamily: 'system-ui', margin: '5px' }}>Vehicle No. :</b>
                                                        </Grid>

                                                        <Grid item m={1} md={3}  >
                                                            <b style={{ fontSize: '15px', fontFamily: 'system-ui', margin: '5px', color: this.state.aiData['vehicleNo'] ? 'green' : 'black' }}>{this.state.aiData['vehicleNo'] ? this.state.aiData['vehicleNoVal'] : this.state.aiData['vehicleNoVal']}</b>
                                                        </Grid>


                                                        <Grid item m={1} md={2}  >
                                                            <b style={{ fontSize: '15px', fontFamily: 'system-ui', margin: '5px' }}>Amount :</b>
                                                        </Grid>

                                                        <Grid item m={1} md={3}  >
                                                            <b style={{ fontSize: '15px', fontFamily: 'system-ui', margin: '5px', color: this.state.aiData['amount'] ? 'green' : 'black' }}>{this.state.aiData['amount'] ? this.state.aiData['amountVal'] : this.state.aiData['amountVal']}</b>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                        {/* <Grid item m={1} md={12} >
                                        </Grid> */}

                                        <Grid className="aiText" style={{ fontWeight: '700' }} item md={12}>
                                            <b>Manual Data:</b>
                                        </Grid>
                                        <Grid item m={1} md={2} style={{ marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}>Station Name :</b>
                                        </Grid>

                                        <Grid item m={1} md={3} style={{ marginRight: '0px' }} >
                                            <TextField sx={{ width: '22ch' }} id="stationName" label=""
                                                onChange={(e) => {
                                                    this.setState({
                                                        stationName: e.target.value
                                                    })
                                                }}
                                                value={this.state.stationName}
                                                variant="standard"
                                                type="text"
                                                required
                                                placeholder="Enter Station Name"
                                            />
                                        </Grid>

                                        <Grid item m={1} md={2} style={{ marginLeft: '-3px', marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}> Intent Date :</b>
                                        </Grid>

                                        <Grid item m={1} md={3} style={{}} >
                                            <TextField sx={{ width: '22ch' }} id="intentDate" label=""
                                                value={this.state.intentDate}
                                                onChange={(e) => {
                                                    this.setState({
                                                        intentDate: e.target.value
                                                    })
                                                }}
                                                variant="standard"
                                                type="date"
                                                required
                                            />
                                        </Grid>

                                        <Grid item md={1}  >
                                        </Grid>

                                        <Grid item m={1} md={2} style={{ marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}>Vechile No :</b>
                                        </Grid>

                                        <Grid item m={1} md={3} style={{ marginRight: '0px' }} >
                                            <TextField sx={{ width: '22ch' }} id="updateVehcileNO" label=""
                                                onChange={(e) => {
                                                    this.setState({
                                                        updateVehicleno: e.target.value
                                                    })
                                                }}
                                                value={this.state.updateVehicleno}
                                                variant="standard"
                                                type="text"
                                                required
                                                placeholder="Enter Vehicle Number"
                                            />
                                        </Grid>

                                        <Grid item m={1} md={2} style={{ marginLeft: '-3px', marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}>Fuel Cost :</b>
                                        </Grid>

                                        <Grid item m={1} md={3} style={{}} >
                                            <TextField sx={{ width: '22ch' }} id="fuelCost" label=""
                                                value={this.state.fuelCost}
                                                onChange={(e) => {
                                                    this.setState({
                                                        fuelCost: e.target.value
                                                    })
                                                }}
                                                variant="standard"
                                                type="text"
                                                required
                                                placeholder="Enter Fuel Cost"
                                            />
                                        </Grid>

                                        <Grid item md={1}  >
                                        </Grid>

                                        <Grid item m={1} md={2} style={{ marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}>Amount :</b>
                                        </Grid>

                                        <Grid item m={1} md={3} style={{ marginRight: '0px' }} >
                                            <TextField sx={{ width: '22ch' }} id="amount" label=""
                                                onChange={(e) => {
                                                    this.setState({
                                                        amount: e.target.value
                                                    })
                                                }}
                                                value={this.state.amount}
                                                variant="standard"
                                                type="text"
                                                required
                                                placeholder="Enter Amount"
                                            />
                                        </Grid>

                                        <Grid item m={1} md={2} style={{ marginLeft: '-3px', marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}>Trip ID :</b>
                                        </Grid>

                                        <Grid item m={1} md={3} style={{}} >
                                            <TextField sx={{ width: '22ch' }} id="updateTripid" label=""
                                                onChange={(e) => {
                                                    this.setState({
                                                        updateTripid: e.target.value
                                                    })
                                                }}
                                                value={this.state.updateTripid}
                                                variant="standard"
                                                type="text"
                                                required
                                                placeholder="Enter Trip ID"
                                            />
                                        </Grid>

                                        <Grid item md={1} >
                                        </Grid>

                                        <Grid item m={1} md={2} style={{ marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}>Status :</b>
                                        </Grid>

                                        <Grid item m={1} md={3} style={{ marginRight: '0px' }} >
                                            <FormControl variant="standard" fullWidth={true} style={{ marginLeft: '0px' }}>
                                                <Select
                                                    labelId=""
                                                    id="status"
                                                    value={this.state.oneStatus}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            oneStatus:
                                                                e.target.value,
                                                        });
                                                    }}
                                                    label=""
                                                    variant="standard"
                                                    required
                                                >
                                                    {this.state.unique_status.map((eachItem, key) => (
                                                        <MenuItem value={eachItem.statusID} key={key}>
                                                            {eachItem.status}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item m={1} md={12} >
                                        </Grid>

                                        <Grid item m={1} md={2} style={{ marginLeft: '-3px', marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}> Remarks :</b>
                                        </Grid>

                                        <Grid item m={1} md={9} style={{ marginTop: '5px' }} >
                                            <TextField style={{ marginTop: '5px' }} sx={{ mt: 3, width: '35ch' }}
                                                id="standard-multiline-static"
                                                label=""
                                                rows={3}
                                                name='Description'
                                                multiline
                                                defaultValue={this.state.remarks}
                                                variant="outlined"
                                                onChange={(e) => {
                                                    this.setState({
                                                        remarks: e.target.value.replace(
                                                            /[^\w\s]/gi,
                                                            ""
                                                        ),
                                                    });
                                                }}
                                                placeholder="Remarks"
                                            />
                                        </Grid>

                                        <Grid className="panDownload" item md={12}>
                                            <Button
                                                type="submit"
                                                size="large"
                                                variant="contained"
                                                style={{
                                                    marginTop: "35px",
                                                    background: "rgb(234 67 68)",
                                                    color: "white",
                                                }}
                                            >
                                                Update
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </form>
                            </Box>
                        </Fade>
                    </Modal>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.imageModal}
                        onClose={this.handleCloseImageModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.imageModal}>
                            <Box sx={style3}>
                                <Card style={{}} className="pan-image">
                                    <CardMedia
                                        component="img"
                                        height="auto"
                                        image={this.state.modalImage}
                                        alt="Error"
                                    />
                                </Card>
                            </Box>
                        </Fade>
                    </Modal>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.openEditModal}
                        onClose={this.handleCloseEditModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.openEditModal}>
                            <Box sx={style4}>
                                <form onSubmit={this.onSubmitModal} method="POST">

                                    <Grid container >
                                        <Grid className="panText" style={{ fontWeight: '700' }} item md={12}>
                                            Update Intent No: <b>{this.state.intentno}</b>
                                        </Grid>

                                        <Grid item m={1} md={12} >
                                        </Grid>

                                        <Grid item m={1} md={2} style={{ marginLeft: '-3px', marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}> Intent No :</b>
                                        </Grid>

                                        <Grid item m={1} md={3} style={{}} >
                                            <TextField sx={{ width: '22ch' }} id="intentno" label=""
                                                disabled
                                                value={this.state.intentno}
                                                variant="standard"
                                                type="text"

                                            />
                                        </Grid>

                                        <Grid item md={1}  >
                                        </Grid>

                                        <Grid item m={1} md={2} style={{ marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}>Station Name :</b>
                                        </Grid>

                                        <Grid item m={1} md={3} style={{ marginRight: '0px' }} >
                                            <TextField sx={{ width: '22ch' }} id="stationName" label=""
                                                onChange={(e) => {
                                                    this.setState({
                                                        stationName: e.target.value
                                                    })
                                                }}
                                                value={this.state.stationName}
                                                variant="standard"
                                                type="text"
                                                required
                                                placeholder="Enter Station Name"
                                            />
                                        </Grid>




                                        <Grid item m={1} md={2} style={{ marginLeft: '-3px', marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}> Intent Date :</b>
                                        </Grid>
                                        <Grid item m={1} md={3} style={{}} >
                                            <TextField sx={{ width: '22ch' }} id="intentDate" label=""
                                                value={this.state.intentDate}
                                                onChange={(e) => {
                                                    this.setState({
                                                        intentDate: e.target.value
                                                    })
                                                }}
                                                variant="standard"
                                                type="date"
                                                required
                                            />
                                        </Grid>

                                        <Grid item md={1}  >
                                        </Grid>

                                        <Grid item m={1} md={2} style={{ marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}>Vechile No :</b>
                                        </Grid>
                                        <Grid item m={1} md={3} style={{ marginRight: '0px' }} >
                                            <TextField sx={{ width: '22ch' }} id="updateVehcileNO" label=""
                                                onChange={(e) => {
                                                    this.setState({
                                                        updateVehicleno: e.target.value
                                                    })
                                                }}
                                                value={this.state.updateVehicleno}
                                                variant="standard"
                                                type="text"
                                                required
                                                placeholder="Enter Vehicle Number"
                                            />
                                        </Grid>

                                        <Grid item m={1} md={2} style={{ marginLeft: '-3px', marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}>Fuel Cost :</b>
                                        </Grid>
                                        <Grid item m={1} md={3} style={{}} >
                                            <TextField sx={{ width: '22ch' }} id="fuelCost" label=""
                                                value={this.state.fuelCost}
                                                onChange={(e) => {
                                                    this.setState({
                                                        fuelCost: e.target.value
                                                    })
                                                }}
                                                variant="standard"
                                                type="text"
                                                required
                                                placeholder="Enter Fuel Cost"
                                            />
                                        </Grid>

                                        <Grid item md={1}  >
                                        </Grid>

                                        <Grid item m={1} md={2} style={{ marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}>Amount :</b>
                                        </Grid>
                                        <Grid item m={1} md={3} style={{ marginRight: '0px' }} >
                                            <TextField sx={{ width: '22ch' }} id="amount" label=""
                                                onChange={(e) => {
                                                    this.setState({
                                                        amount: e.target.value
                                                    })
                                                }}
                                                value={this.state.amount}
                                                variant="standard"
                                                type="text"
                                                required
                                                placeholder="Enter Amount"
                                            />
                                        </Grid>

                                        <Grid item m={1} md={2} style={{ marginLeft: '-3px', marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}>Trip ID :</b>
                                        </Grid>
                                        <Grid item m={1} md={3} style={{}} >
                                            <TextField sx={{ width: '22ch' }} id="updateTripid" label=""
                                                onChange={(e) => {
                                                    this.setState({
                                                        updateTripid: e.target.value
                                                    })
                                                }}
                                                value={this.state.updateTripid}
                                                variant="standard"
                                                type="text"
                                                required
                                                placeholder="Enter Trip ID"
                                            />
                                        </Grid>

                                        <Grid item md={1}  >
                                        </Grid>

                                        <Grid item m={1} md={2} style={{ marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}>Status :</b>
                                        </Grid>
                                        <Grid item m={1} md={3} style={{ marginRight: '0px' }} >
                                            <FormControl variant="standard" fullWidth={true} style={{ marginLeft: '0px' }}>

                                                <Select
                                                    labelId=""
                                                    id="status"
                                                    value={this.state.oneStatus}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            oneStatus:
                                                                e.target.value,
                                                        });
                                                    }}
                                                    label=""
                                                    variant="standard"
                                                    required
                                                >
                                                    {this.state.unique_status.map((eachItem, key) => (
                                                        <MenuItem value={eachItem.statusID} key={key}>
                                                            {eachItem.status}
                                                        </MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item m={1} md={2} style={{ marginLeft: '-3px', marginTop: '14px' }} >
                                            <b style={{ fontSize: '15px', margin: '5px' }}> Remarks :</b>
                                        </Grid>
                                        <Grid item m={1} md={9} style={{ marginTop: '5px' }} >
                                            <TextField style={{ marginTop: '5px' }} sx={{ mt: 3, width: '35ch' }}
                                                id="standard-multiline-static"
                                                label=""
                                                rows={3}
                                                name='Description'
                                                multiline
                                                defaultValue={this.state.remarks}
                                                variant="outlined"
                                                onChange={(e) => {
                                                    this.setState({
                                                        remarks: e.target.value.replace(
                                                            /[^\w\s]/gi,
                                                            ""
                                                        ),
                                                    });
                                                }}
                                                placeholder="Remarks"
                                            />
                                        </Grid>




                                        <Grid className="panDownload" item md={12}>
                                            <Button
                                                type="submit"
                                                size="large"
                                                variant="contained"
                                                style={{
                                                    marginTop: "35px",
                                                    background: "rgb(234 67 68)",
                                                    color: "white",
                                                }}
                                            >
                                                Update
                                            </Button>
                                        </Grid>



                                    </Grid>
                                </form>


                            </Box>
                        </Fade>
                    </Modal>

                    <Dialog fullScreen
                        TransitionComponent={this.Transition}
                        onClose={this.handleCloseProjectStats}
                        open={this.state.showProjectStatsMoal}
                    // open={true}
                    >
                        <Container maxWidth="xl" style={{ maxWidth: 'inherit', paddingLeft: '0px', paddingRight: '0px' }}>


                            <AppBar style={{ height: 'auto', backgroundColor: 'white', color: 'black' }} sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={this.handleCloseProjectStats}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <Button autoFocus color="inherit" onClick={this.handleCloseProjectStats}>
                                        Close
                                    </Button>
                                </Toolbar>
                            </AppBar>


                            <Grid style={{ background: '#e0e1e0', }} container>
                                <Grid item md={12} style={{ padding: '0px 12px' }}>

                                    <Container pt={3} style={{ maxWidth: 'inherit', background: 'white', paddingBottom: "12%", marginTop: '2%' }} >
                                        <Typography pt={2} style={{ textAlign: 'center', color: '#333333' }} variant="h4"> <b><u>Fuel Usage Stats</u></b></Typography>



                                        <Grid pt={3} container>
                                            <Grid item md={2} className="mt-2">
                                                <FormControl sx={{ width: "18vh", m: 1 }} style={{ color: "#666666", }} fullWidth>
                                                    <InputLabel id="dateRangeSelected">Date Range</InputLabel>
                                                    <Select
                                                        labelId="dateRangeSelected"
                                                        id="dateRangeSelected"

                                                        value={this.state.dateRangeSelected}
                                                        onChange={(e) => {
                                                            this.handleChangeDateRange(e);
                                                        }}
                                                        label="Select Date Range"
                                                        displayEmpty
                                                        style={{ color: "#666666", height: "48px", }}
                                                    >
                                                        <MenuItem disabled>
                                                            <em>Select Date range</em>
                                                        </MenuItem>

                                                        {this.state.uniqueDateRanges.map((eachItem, key) => (
                                                            <MenuItem value={key} key={key}>
                                                                {eachItem.title}
                                                            </MenuItem>
                                                        ))}

                                                        <MenuItem valu="custom">
                                                            Custom date
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={3} style={{ textAlign: 'right', float: 'right' }}>
                                                <FormControl variant="standard" fullWidth style={{ marginRight: '0px', marginLeft: '0px', marginTop: "18px" }}>
                                                    <Grid style={{ marginLeft: '45px' }} component="label" container alignItems="center" spacing={1}>
                                                        <Grid item>Location</Grid>
                                                        <Grid item>
                                                            <Switch
                                                                checked={this.state.isProject}
                                                                onChange={this.handleChangeStatsData}
                                                                value="1"
                                                                color="primary"
                                                            />
                                                        </Grid>
                                                        <Grid item>Project</Grid>
                                                    </Grid>
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={4}>
                                            </Grid>

                                            {this.state.isProject == true ?

                                                <>
                                                    {this.state.projectStats.length > 0 &&

                                                        <Grid item md={1}>
                                                            <Button onClick={(e) => this.handleExportStats()} size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white", marginRight: '0px' }} endIcon={<excelIcon />} >
                                                                Export
                                                            </Button>
                                                        </Grid>}
                                                </>


                                                :

                                                <>
                                                    {this.state.locationStats.length > 0 &&
                                                        <Grid item md={1}>
                                                            <Button onClick={(e) => this.handleExportStats()} size="large" variant="contained" style={{ marginTop: "18px", background: "rgb(234 67 68)", color: "white", marginRight: '0px' }} endIcon={<excelIcon />} >
                                                                Export
                                                            </Button>
                                                        </Grid>}
                                                </>}


                                        </Grid>



                                        <Grid mt={3}>
                                            <Paper elevation={6}>
                                                <Card>
                                                    <CardContent>

                                                        {this.state.isProject == true ?
                                                            <Grid item md={12} className="mt-2">
                                                                <Typography variant="h6" color="inherit" component="div" style={{ textAlign: 'right', fontSize: '29px' }}>
                                                                    {this.state.projectStats != 0 &&
                                                                        <>
                                                                            <Grid container>
                                                                                <Grid item md={3} className="mt-2">
                                                                                </Grid>

                                                                                <Grid item md={3} className="mt-2">
                                                                                    <Typography variant="h6" color="inherit" component="div" style={{ textAlign: 'center', fontSize: '22px' }}>
                                                                                        {this.state.locationStats != 0 && <>
                                                                                            Station Count : <b style={{ color: 'red' }}>{this.state.projectStatsCount}</b>
                                                                                        </>
                                                                                        }
                                                                                    </Typography>
                                                                                </Grid>

                                                                                <Grid item md={5} className="mt-2">
                                                                                    <Typography variant="h6" color="inherit" component="div" style={{ textAlign: 'center', fontSize: '22px' }}>
                                                                                        {this.state.locationStats != 0 && <>
                                                                                            Total Fuel Amount: <b style={{ color: 'red' }}>₹ {new Intl.NumberFormat("en-IN").format(this.state.projectStatsTotal)}</b>
                                                                                        </>
                                                                                        }
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item md={1} className="mt-2">

                                                                                </Grid>

                                                                            </Grid>

                                                                        </>
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            :
                                                            <Grid item md={12} className="mt-2">
                                                                <Grid container>
                                                                    <Grid item md={3} className="mt-2">
                                                                    </Grid>

                                                                    <Grid item md={3} className="mt-2">
                                                                        <Typography variant="h6" color="inherit" component="div" style={{ textAlign: 'center', fontSize: '22px' }}>
                                                                            {this.state.locationStats != 0 && <>
                                                                                Station Count : <b style={{ color: 'red' }}>{this.state.locationStats.length}</b>
                                                                            </>
                                                                            }
                                                                        </Typography>
                                                                    </Grid>

                                                                    <Grid item md={5} className="mt-2">
                                                                        <Typography variant="h6" color="inherit" component="div" style={{ textAlign: 'center', fontSize: '22px' }}>
                                                                            {this.state.locationStats != 0 && <>
                                                                                Total Fuel Amount: <b style={{ color: 'red' }}>₹ {new Intl.NumberFormat("en-IN").format(this.state.locationTotalAmount)}</b>
                                                                            </>
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item md={2} className="mt-2">
                                                                    </Grid>

                                                                </Grid>

                                                            </Grid>
                                                        }



                                                        {this.state.isProject == true ?

                                                            <Grid item md={12} className="mt-2">
                                                                <Grid style={{ display: '-webkit-box', flexWrap: 'nowrap', overflow: 'scroll' }} className="flexGrid" container>


                                                                    {this.state.projectStats != [] && this.state.projectStats.map((eachItem, key) => (
                                                                        <>
                                                                            <Grid item md={2} style={{ padding: '5px' }} className="mt-2 mb-2 ">
                                                                                <Typography
                                                                                    mb={2}
                                                                                    style={{
                                                                                        fontSize: "11px",
                                                                                        fontWeight: 600,
                                                                                        height: '34px',
                                                                                        textAlign: 'center',
                                                                                        marginBottom: '-6px',

                                                                                    }}
                                                                                >
                                                                                    {eachItem.City}
                                                                                </Typography>
                                                                                <div >

                                                                                    <Grid container>

                                                                                        {eachItem.data != null &&
                                                                                            eachItem.data.map((each, key) => (
                                                                                                <Grid item md={12} className="mb-2" style={{ boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)' }}>


                                                                                                    <Card
                                                                                                        style={{
                                                                                                            cursor: "pointer",

                                                                                                        }}
                                                                                                        className="hoverBoder"
                                                                                                    >
                                                                                                        <CardContent
                                                                                                            style={{
                                                                                                                textAlign: "center",
                                                                                                                padding: '15px'
                                                                                                                // maxHeight: "135px",
                                                                                                                // minHeight: '130px',
                                                                                                            }}
                                                                                                        >
                                                                                                            <Typography
                                                                                                                mb={2}
                                                                                                                style={{
                                                                                                                    fontSize: "11px",
                                                                                                                    fontWeight: 600,
                                                                                                                    height: '13px'
                                                                                                                }}
                                                                                                            >
                                                                                                                {each.ProjectName}
                                                                                                            </Typography>
                                                                                                            <Grid container spacing={1} >
                                                                                                                <Grid item xs={12}>
                                                                                                                    <Typography
                                                                                                                        style={{
                                                                                                                            fontWeight: 600,
                                                                                                                            color: "#ff4700c9",
                                                                                                                            fontSize: "12px",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {" "}
                                                                                                                        ₹  {new Intl.NumberFormat("en-IN").format(each.issuedAmount)}
                                                                                                                    </Typography>

                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </CardContent>

                                                                                                    </Card>

                                                                                                </Grid>
                                                                                            ))
                                                                                        }



                                                                                    </Grid>

                                                                                </div>
                                                                            </Grid>


                                                                        </>
                                                                    )
                                                                    )}
                                                                </Grid>
                                                            </Grid> :

                                                            <Grid item md={12} className="mt-2">
                                                                <Grid pt={1} container spacing={1}>

                                                                    {this.state.locationStats != [] && this.state.locationStats.map((eachItem, key) => (
                                                                        <Grid item md={2} className="mt-2 mb-2 ">
                                                                            <Paper elevation={6}>
                                                                                <Card
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                    className="hoverBoder"
                                                                                >
                                                                                    <CardContent
                                                                                        style={{
                                                                                            textAlign: "center",
                                                                                            maxHeight: "135px",
                                                                                            minHeight: '130px',
                                                                                        }}
                                                                                    >
                                                                                        <Typography
                                                                                            mb={2}
                                                                                            style={{
                                                                                                fontSize: "11px",
                                                                                                fontWeight: 600,
                                                                                                height: '34px'
                                                                                            }}
                                                                                        >
                                                                                            {eachItem.stationName}
                                                                                        </Typography>
                                                                                        <Grid container spacing={2} mb={2}>

                                                                                            <Grid item xs={12}>
                                                                                                <Typography
                                                                                                    style={{
                                                                                                        fontWeight: 600,
                                                                                                        color: "#ff4700c9",
                                                                                                        fontSize: "14px",
                                                                                                    }}
                                                                                                >
                                                                                                    ₹ {new Intl.NumberFormat("en-IN").format(eachItem.issuedAmount)}
                                                                                                </Typography>
                                                                                                <Typography
                                                                                                    style={{
                                                                                                        fontSize: "12px",
                                                                                                        fontWeight: 300,
                                                                                                    }}
                                                                                                >
                                                                                                    {/* Payee Count */}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </CardContent>
                                                                                </Card>
                                                                            </Paper>
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </CardContent>
                                                </Card>
                                            </Paper>
                                        </Grid>

                                    </Container>

                                </Grid>
                            </Grid>



                        </Container>



                    </Dialog>



                </ThemeProvider>

            </div >


        );
    }
}

HomePage.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(HomePage);
