/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import CountUp from "react-countup";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/alert.actions";
import totalCustomersBgImg from "../../../../assets/customImages/Total_customers.png";
import totalOrdersBgImg from "../../../../assets/customImages/Total_Orders.png";
import salesReportsBgImg from "../../../../assets/customImages/Sales_Reports.png";
import "../../../../assets/customCssFiles/generalWidgetInvoice.css";
import { Link } from "react-router-dom";

class GeneralWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "",
      modal: false,
      anotherModal: false,
      locations: [],
      selected_date: "",
      style: {},
      userCcId: null,
      ccid: "",
      unique_cities: [],
      totalCustomers: "",
      totalOrders: "",
      statusReport: [],
      startDate: "",
      endDate: "",
      isData: false,
      invoiceStartDate: "",
      invoiceEndDate: ""

    };
  }

  setTimer = async () => {
    console.log(this.props.data, 'this.props.data.isData')
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1; //months from 1-12
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    // eslint-disable-next-line no-useless-concat
    const iDate = year + "-" + month + "-" + "01";
    const mDate = year + "-" + month + "-" + day;
    if (this.props.data.isData) {
      await this.setState({
        totalCustomers: this.props.data.totalCustomers.length > 0 ? this.props.data.totalCustomers[0].CustomerCount : 0,
        totalOrders: this.props.data.totalOrders.length > 0 ? this.props.data.totalOrders[0].orderCount : 0,
        statusReport: this.props.data.statusReport.length > 0 ? this.props.data.statusReport[0] : [{ status: "Orders" }],
        startDate: "",
        endDate: "",
        invoiceStartDate: this.props.data.startDate ? this.props.data.startDate : iDate,
        invoiceEndDate: this.props.data.endDate ? this.props.data.endDate : mDate,
        isData: true
      });
    }
  }

  timerFunction = () => {
    setTimeout(async () => {
      await this.setTimer();
    }, 5000);
  }


  componentWillMount = async () => {
    await this.setState({
      locations: this.props.data
    })
    await this.setTimer()
  }

  cardImageStyle = {
    width: "100px",
    height: "100px",
    position: "absolute",
    top: "-20px",
    left: "10px",
    right: "0",
    borderRadius: "0.25rem",
    boxShadow: "0px 0px 3px 0px #888888",
  };

  cardTextStyle = {
    fontSize: "18px",
    height: "auto",
    marginTop: "65px",
    //marginLeft: "80px",
    // color: "#666666",
  };
  render() {

    const { invoiceStartDate, invoiceEndDate } = this.state;
    return (
      <div>
        {/* <Breadcrumb title="General Widget" parent="Widgets" /> */}
        {/* Container-fluid starts */}
        <div className="container-fluid">
          <div className="row">

            <div className="col-sm-6 col-xl-3 col-lg-6 mt-5">
              <Link to={`/admin/royal-oak/invoice/${invoiceStartDate}/${invoiceEndDate}`}>

                <div
                  className="card custom-card-widget-invoice"
                  style={{ borderRadius: "0.5rem" }}
                >
                  <img src={totalCustomersBgImg} style={this.cardImageStyle} alt="total customers" />

                  <div
                    className="b-r-4 card-body"
                    style={{
                      minHeight: "241px"
                    }}
                  >
                    <div className="media static-top-widget m-1">
                      <div className="align-self-center text-center">
                      </div>
                      <div
                        className="media-body text-center pl-0"
                        style={this.cardTextStyle}
                      >
                        <span
                          className="m-0 font-weight-bold custom-clickhere-button-widget-invoice"
                          style={{
                            color: "#808080"
                          }}
                        >
                          Total Customers
                      </span>
                        <h4
                          className="counter mb-0 mt-1"
                          style={{
                            color: "#4d4d4d",
                            fontSize: "2rem",
                          }}
                        >
                          <CountUp end={this.state.totalCustomers ? this.state.totalCustomers : 0} />
                        </h4>

                      </div>

                    </div>

                  </div>

                </div>

              </Link>

            </div>

            <div className="col-sm-6 col-xl-3 col-lg-6 mt-5" >
              <Link to={`/admin/royal-oak/invoice/${invoiceStartDate}/${invoiceEndDate}`}>

                <div
                  className="card custom-card-widget-invoice"
                  style={{ borderRadius: "0.5rem" }}
                >
                  {/* <div className="card" style={{ borderRadius: "0.5rem" }}> */}
                  <img src={totalOrdersBgImg} style={this.cardImageStyle} alt="totalOrders" />
                  <div className="b-r-4 card-body" style={{ minHeight: "241px" }}>
                    <div className="media static-top-widget m-1">
                      <div className="align-self-center text-center">
                        {/* <i className="icon-package"></i> */}
                      </div>
                      <div
                        className="media-body text-center pl-0"
                        style={this.cardTextStyle}
                      >
                        <span
                          className="m-0 font-weight-bold"
                          style={{ color: "#808080" }}
                        // style={this.hoverStyleInvoices}
                        // hoverStyleInvoices
                        >
                          Total Items
                      </span>
                        <h4
                          className="counter mb-0 mt-1"
                          style={{ color: "#4d4d4d", fontSize: "2rem" }}
                        >
                          <CountUp end={this.state.totalOrders ? this.state.totalOrders : 0} />
                        </h4>
                        {/* <i className="icon-package icon-bg"></i> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className=" col-sm-6 col-xl-6 col-lg-6 mt-5">
              <div className="card" style={{ borderRadius: "0.5rem" }}>
                <img src={salesReportsBgImg} style={this.cardImageStyle} alt="salesReport" />
                {/* <h5>Status</h5> */}

                {/* <div className="card-header">
                                </div> */}
                <div className="b-r-4 card-body" style={{ minHeight: "241px" }}>
                  <div
                    className="m-1"
                    style={{
                      height: "auto",

                      // fontSize: "18px",
                    }}
                  >
                    <h5
                      style={{
                        // float: "right",
                        fontWeight: "bold",
                        fontSize: "18px",
                        height: "auto",
                        marginLeft: "100px",
                        color: "#808080",
                      }}
                    >
                      Status Report
                    </h5>
                    <div className="row mt-5">
                      {this.state.statusReport &&
                        this.state.statusReport.map((status, i) => {
                          // console.log(status);
                          return (
                            <div className="col" key={i}>
                              <span
                                className="m-0 font-weight-bold"
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: "16px",
                                  color: "#808080",
                                }}
                              >
                                {status.status}
                              </span>
                              <h4 className="txt mb-0">
                                <span
                                  className="counter"
                                  style={{
                                    color: "#4d4d4d",
                                    fontFamily: "Open Sans",
                                    fontSize: "2rem",
                                  }}
                                >
                                  <CountUp end={status.orderCount ? status.orderCount : 0} />
                                </span>
                              </h4>
                              {/* <span>|</span> */}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  {/* <div className="crm-overall">

                                        <div className="flot-chart-container-small donut-chart">
                                            <DonutChart className="flot-chart-placeholder"
                                                height={483}
                                                data={[{
                                                    value: 30,
                                                    label: "USA"
                                                },
                                                {
                                                    value: 50,
                                                    label: "India"
                                                },
                                                {
                                                    value: 10,
                                                    label: "Canada"
                                                },
                                                {
                                                    value: 10,
                                                    label: "UK"
                                                }]}
                                                colors={["#26C6DA", "#AB8DE4", "#FF5370", "#4099FF"]} />
                                        </div>
                                    </div>
                               */}
                </div>
              </div>

              {/* <div className="card browser-widget p-0">
                                <div className="card-body">
                                    <div className="media">
                                        <i className="icofont icofont-presentation-alt font-info align-self-center f-70"></i>
                                        <i className="align-self-center mr-3"></i>
                                        <div className="media-body align-self-center">
                                            <div>
                                                <span>Product</span>
                                                <h4><span className="counter"><CountUp end={90} /></span>%</h4>
                                            </div>
                                            <div className="inline-block-sm">
                                                <span>Projects</span>
                                                <h4><span className="counter"><CountUp end={80} /></span>%</h4>
                                            </div>
                                            <div>
                                                <span>Income</span>
                                                <h4><span className="counter"><CountUp end={85} /></span>%</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                      */}
            </div>

          </div>
        </div>
        {/* Container-fluid Ends */}
      </div>
    );
  }
}

GeneralWidget.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(GeneralWidget);

// export default GeneralWidget;
