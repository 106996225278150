/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Breadcrumb from "../../common/breadcrumb.component";
import { setAlert } from "../../../redux/actions/alert.actions";
import { Form, FormGroup, Progress, Button } from "reactstrap";
import Dropzone from "react-dropzone";
import SimpleReactValidator from "simple-react-validator";
import Config from "../../../config";
import "../../../assets/customCssFiles/uploadfile.scss";
// const { saveAs } = 'file-saver';
import { saveAs } from "file-saver";

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      loading: false,
      progressLoader: 0,
      style: {},
      date: "",
      percentage: 66,
      isUploadVisible: true,
      isLoadingVisible: false,
      isDownloadVisible: false,
      pdfBlob: {},
      fileName: "",
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({ style: { display: "none" } });
    }, 1000);
  };

  uploadFileToDb = async (files, name) => {
    let formData = new FormData();
    formData.append("image", files[0]);
    const fileData = await axios({
      method: "POST",
      url: `${Config.hostName}/shipment/file/upload/royal-oak`,
      timeout: 3 * 60 * 1000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    console.log(fileData, "fileData");

    return fileData;
  };

  setFuncton = async (files, name) => {
    console.log(files, name, "files,name");
    const fileName = files[0].name;
    const nameForPdf = fileName.substr(0, fileName.lastIndexOf("."));
    console.log(fileName.substr(0, fileName.lastIndexOf(".")), "fileName");

    await this.setState({
      isLoadingVisible: true,
      isDownloadVisible: false,
      fileName: nameForPdf,
    });
    const response = await this.uploadFileToDb(files, name);
    console.log(response, "response");
    if (response.data.success) {
      await this.downLoadPdf();
    } else {
      await this.props.setAlert(response.data.message, "success");
    }
  };

  onDrop = async ({ files, name }) => {
    await this.setFuncton(files, name);
  };

  downLoadPdf = async () => {
    console.log("hi");

    await axios
      .get(`${Config.hostName}/shipment/create-pdf/${this.state.fileName}`, {
        responseType: "blob",
      })
      .then(async (res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        console.log(pdfBlob, "pdf");
        await this.setState({
          isLoadingVisible: false,
          isDownloadVisible: true,
          pdfBlob: pdfBlob,
        });
        console.log("pdf send to state");
      });
  };

  savePdfToSystem = async () => {
    const { pdfBlob, fileName } = this.state;
    let newFileName = `BARCODE_${fileName}`;
    saveAs(pdfBlob, `${newFileName}.pdf`);
  };

  render() {
    const { loading } = this.state;

    return (
      <Fragment>
        <div
          style={{
            paddingTop: "2rem",
            paddingBottom: "0.5rem",
            textAlign: "center",
            width: "100%",
            marginBottom: "10rem"
          }}
        >

          {/* {this.state.isDownloadVisible && ( */}
          <div
            className="card-wrapper-while-downloading"
            style={{
              width: "80%",
              textAlign: "center",
              margin: "auto",
              paddingBottom: "10rem",
              marginTop: "6rem",
            }}
          >
            <div
              className="card d-flex custom-card-uploadmain float-left mx-3"
              style={{
                backgroundColor: "white",
                boxShadow: "0 0.1rem 0.5rem rgba(0,0,0,.1)",
                borderRadius: "0.5rem",
                width: "42%",
                // height: "100%",
                margin: "auto",
                cursor: "pointer",
                justifyContent: "center",
                boxSizing: "border-box",
              }}
            >
              <Dropzone
                disabled={this.state.isLoadingVisible}
                onDrop={(acceptedFiles) => {
                  this.onDrop({
                    files: acceptedFiles,
                    name: "image",
                  });
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section
                    // onFocus={{ border: "none !important" }}
                    style={{
                      maxWidth: "100%",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        textAlign: "center",
                        maxWidth: "100%",
                        height: "100%",
                      }}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />

                      <div
                        // onFocus={{ border: "none !important" }}
                        style={{
                          margin: "auto",
                          width: "100%",
                          height: "100%",
                          padding: "1rem",
                        }}
                      >
                        <div
                          style={{
                            border: "0.1rem dashed #00000033",
                            borderRadius: "0.5rem",
                            padding: "2rem",
                          }}
                        >
                          <span
                            className="fa fa-cloud-upload w-100 m-auto"
                            style={{ fontSize: "3rem", color: "#808080" }}
                          ></span>
                          <p
                            style={{
                              fontSize: "100%",
                              color: "#808080",
                              marginTop: "0.5rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            Drag & drop files here <br />
                            <b>or</b>
                          </p>
                          <div
                            className="button-drag-comp custom-clickhere-button-uploadmain rounded-pill"
                            style={{
                              textAlign: "center",
                              backgroundColor: "#08b3dd",
                              width: "100%",
                              padding: "0.5rem 1rem",
                              // borderRadius: "50%",
                            }}
                          >
                            <p className="m-0" style={{ fontSize: "100%" }}>
                              <strong>
                                {this.state.isLoadingVisible && (
                                  <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                  />
                                )}
                                {this.state.isLoadingVisible && <span>Processing ...</span>}
                                {!this.state.isLoadingVisible && <span>Click Here</span>}
                                {/* {this.state.isLoadingVisible ? "Processing...." : "Click Here"} */}
                              </strong>
                            </p>

                            {/* <Button
                          type="submit"
                          onClick={e => this.onSubmit(e)}
                          className="btn btn-primary"
                          disabled={loading} */}

                            {/* > */}
                            {/* {loading && (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px" }}
                            />
                          )}
                          {loading && <span>Processing ...</span>}
                          {!loading && <span>Create</span>}
                        </Button> */}

                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            {this.state.isDownloadVisible && <div
              className="card d-flex custom-card-uploadmain float-left mx-3"
              style={{
                backgroundColor: "white",
                boxShadow: "0 0.1rem 0.5rem rgba(0,0,0,.1)",
                borderRadius: "0.5rem",
                width: "42%",
                // height: "100%",
                margin: "auto",
                justifyContent: "center",
                boxSizing: "border-box",
              }}
            >
              <div
                onFocus={{ border: "none !important" }}
                style={{
                  margin: "auto",
                  width: "100%",
                  height: "100%",
                  padding: "1rem",
                }}
              >
                <div
                  style={{
                    border: "0.1rem dashed #00000033",
                    borderRadius: "0.5rem",
                    padding: "2rem",
                  }}
                >

                  <span
                    className="fa fa-download"
                    style={{ fontSize: "3rem", color: "#808080" }}
                  ></span>

                  <p
                    style={{
                      fontSize: "100%",
                      color: "#808080",
                      marginTop: "1rem",
                      marginBottom: "1.5rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "78%",
                      textAlign: "center",
                    }}
                  >
                    Your file is ready
                      <br />
                  </p>
                  <div
                    className="button-drag-comp custom-clickhere-button-uploadmain rounded-pill"
                    onClick={this.savePdfToSystem}
                    style={{
                      textAlign: "center",
                      backgroundColor: "#08b3dd",
                      width: "100%",
                      padding: "0.5rem 1rem",
                    }}
                  >
                    <p className="m-0" style={{ fontSize: "100%" }}>
                      <strong>Download</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>}
          </div>
          {/* )} */}

        </div>
      </Fragment>
    );
  }
}

UploadFile.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  application: state.application,
});

export default connect(mapStateToProps, { setAlert })(UploadFile);
