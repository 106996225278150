import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Button, FormGroup, Input } from "reactstrap";
// import { Link } from "react-router-dom";
// import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Breadcrumb from "../../common/breadcrumb.component";
import Alert from "../../common/Alert";
import { setAlert } from "../../../redux/actions/alert.actions";
import Config from "../../../config";
import moment from "moment";
import { AgGridReact } from 'ag-grid-react';
import Pagination from "react-js-pagination";
import "../../../assets/customCssFiles/loader-spinner.css"


class NoticeAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link: "",
            modal: false,
            locations: [],
            description: "",
            message: "",
            style: {},
            unique_locationids: [],
            unique_clients: [],
            unique_segments: [],
            anotherModal: false,
            unique_dates: [],
            unique_departments: [],
            Locationid: 7,
            selected_date: null,
            Department: "",
            selectedArray: [],
            ClientName: "",
            Segment: "",
            Contact2: "",
            Contact3: "",
            page: 1,
            search: false,
            showText: false,
            columnDefs: [
                {
                    headerName: "Update", field: "Update",
                    cellRendererFramework: (params) => {
                        return <Button
                            color="info"
                            value={params.node.data.EmpAttnid}
                            onClick={this.onSubmit}
                        >
                            Update
                              </Button>

                    }
                },
                {
                    headerName: "AttnDate", field: "AttnDate", sortable: true, filter: true
                },

                {
                    headerName: "EmpAttnid", field: "EmpAttnid", sortable: true, filter: true
                },
                {
                    headerName: "Department", field: "Department", sortable: true, filter: true
                },
                {
                    headerName: "Designation",
                    field: "Designation",
                    sortable: true, filter: true
                },
                {
                    headerName: "City", field: "Locationid", sortable: true, filter: true
                },
                {
                    headerName: "FullName", field: "FullName", sortable: true, filter: true
                },
                {
                    headerName: "mobile", field: "mobile", sortable: true, filter: true
                }, {
                    headerName: "Intime", field: "Intime", sortable: true, filter: true
                }, {
                    headerName: "OutTime", field: "OutTime", sortable: true, filter: true
                },
                // {
                //     headerName: "Remarks", field: "Remarks", sortable: true, filter: true
                // },
                {
                    headerName: "IsApproved", field: "IsApproved", sortable: true, filter: true
                }

            ],

            rowData: [],
            activePage: 1,
            itemsCountPerPage: 50,
            totalItemsCount: 10000,
            pageRangeDisplayed: 5
        };
    }


    handlePageChange = async (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        await this.setState({ activePage: pageNumber, style: {} });
        if (this.state.search === false)
            await this.componentWillMount();
        else
            await this.handleSearchTable();

    }

    changeLocation = async (event) => {
        event.preventDefault();
        await this.setState({
            style: {},
            Locationid: event.target.value
        })
        await this.getUniqueFieldsByLocationId(this.state.Locationid)
        await this.setState({
            style: { display: "none" }
        })
    }

    getUniqueFieldsByLocationId = async (locationId) => {
        await axios.get(`${Config.hostName}/ems/get/unique/table-view/AttnDate/${locationId}`,
        )
            .then(async (res) => {
                // console.log(res.data);
                const index = res.data.data.length - 1
                this.setState({
                    unique_dates: res.data.data,
                    selected_date: res.data.data[index].AttnDate
                })
            })
            .catch((error) => {
                console.log(error)
            });
        await axios.get(`${Config.hostName}/ems/get/unique/table-view/department/${locationId}`)
            .then(async (res) => {
                console.log(res.data);
                this.setState({
                    unique_departments: res.data.data,
                    Department: res.data.data[0].Department
                })
            })
            .catch((error) => {
                console.log(error)
            });
    }

    getData = async () => {
        await axios.get(`${Config.hostName}/unique/locations`)
            .then(res => {
                // console.log(res.data);

                this.setState({
                    unique_locationids: res.data.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    }


    componentWillMount = async () => {
        await this.setState({ search: false })
        await this.getData();
        await this.getUniqueFieldsByLocationId(this.state.Locationid)
        await this.handleSearchTable()
    };

    handleCheckVerify = async index => {
        // console.log(index);

        let selectedArray = this.state.locations;
        selectedArray.forEach((item) => {
            item.selected = false
        }
        )
        selectedArray[index].selected = !this.state.locations[index].selected;
        await this.setState({
            locations: selectedArray
        });
        console.log(selectedArray[index]);
        await this.setState({
            index: index
        })

    };

    handleSearchTable = async () => {
        await this.setState({ search: true, style: {} })
        let { activePage, Locationid, selected_date, Department } = this.state;
        // console.log(activePage);
        await this.setState({ style: {} });
        // console.log(activePage, Locationid, selected_date, Department);

        try {
            let result = await axios.post(
                `${Config.hostName}/ems/table-view/search/${activePage}`,
                {
                    locationId: Locationid,
                    date: moment(selected_date).format("YYYY-MM-DD"),
                    department: Department
                }
            );
            // console.log(result.data);
            await this.setState({
                locations: result.data.data,
                style: { display: 'none' }
            });
            await this.setState({
                rowData: this.state.locations.map(eachItem => {
                    return {
                        IsApproved: eachItem.IsApproved,
                        EmpAttnid: eachItem.EmpAttnid,
                        Locationid: eachItem.city,
                        Department: eachItem.Department,
                        Designation: eachItem.Designation,
                        mobile: eachItem.mobile,
                        Intime: eachItem.inTiming,
                        OutTime: eachItem.outTiming,
                        FullName: eachItem.FullName,
                        Remarks: "",
                        AttnDate: eachItem.AttnDate
                    }
                })
            });
        } catch (e) { }
    };

    onSubmit = async (e) => {
        e.preventDefault();
        console.log(e.target.value);
        let id = e.target.value;
        try {
            await this.setState({
                style: {}
            })
            await axios.post(`${Config.hostName}/ems/table-view/verify`, {
                EmpAttnid: id,
                IsApproved: 1,
                Comments: ""
            });
            await this.props.setAlert(`${id} is approved `, "success");
            await this.handleSearchTable();

        } catch (error) {
            console.log(error);
            const errors = error.response.data.errors;
            if (errors) {
                errors.forEach(error => this.props.setAlert(error.msg, "danger"));
            }
        }

    };

    render() {
        let style = this.state.style;

        const defaultColDef = {
            flex: 1,
            minWidth: 130,
            resizable: true
        }

        if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "user"
        )
            return <Redirect to="/dashboard" />;
        else if (
            this.props.auth.isAuthenticated &&
            this.props.auth.user.userType === "staff"
        )
            return <Redirect to="/staff/notice" />;
        else if (!this.props.auth.isAuthenticated)
            return <Redirect to="/user/login" />;


        return (
            <div>
                <div id='cover-spin' style={style}>
                </div>

                <div className="container-fluid">
                    <div className="edit-profile">
                        <div className="row ">
                            <div className="col-xl-12">
                                <Alert />

                                <div className="col-xl-12">

                                    <nav
                                        className="navbar navbar-light "
                                        style={{ backgroundColor: "none" }}
                                    >
                                        <div className="row" style={{ paddingTop: "8px", width: "100%" }}>

                                            <div className="col-sm-3">
                                                <FormGroup>
                                                    <Input
                                                        type="select"
                                                        name="locationid"
                                                        value={this.state.Locationid}
                                                        id="locationid"
                                                        onChange={this.changeLocation}
                                                    >
                                                        <option value={""} disabled selected>Select Location</option>

                                                        {this.state.unique_locationids.map(locationid => (
                                                            <option value={locationid.Locationid}>
                                                                {locationid.name}

                                                            </option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </div>

                                            <div className="col-sm-3">
                                                <FormGroup>
                                                    <Input
                                                        type="select"
                                                        name="date"
                                                        value={this.state.selected_date}
                                                        id="date"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                selected_date: e.target.value
                                                            })
                                                        }}
                                                    >
                                                        <option value={""} disabled selected>Select date</option>

                                                        {this.state.unique_dates.map(date => (
                                                            <option key={date.AttnDate} value={date.AttnDate}>
                                                                {date.AttnDate}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </div>

                                            <div className="col-sm-3">
                                                <FormGroup>
                                                    <Input
                                                        type="select"
                                                        name="department"
                                                        value={this.state.Department}
                                                        id="department"
                                                        onChange={event => {
                                                            this.setState({ Department: event.target.value });
                                                        }}                                                >
                                                        <option value={""} disabled selected>Select Department</option>

                                                        {this.state.unique_departments.map(department => (
                                                            <option key={department.Department} value={department.Department}>
                                                                {department.Department}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </div>

                                            <div className="col-sm-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-danger w-100 overflow-hidden mb-3 px-0"
                                                    onClick={this.handleSearchTable}
                                                >
                                                    Search
                      </button>
                                            </div>

                                        </div>
                                    </nav>

                                </div>


                                <div className="card-body">

                                    <div
                                        className="ag-theme-balham"
                                        style={{
                                            height: '90vh',
                                            width: '100%'
                                        }}
                                    >
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            rowData={this.state.rowData}
                                            defaultColDef={defaultColDef}
                                            onGridReady={this.onGridReady}
                                            enableCellTextSelection={true}
                                            rowHeight={50}

                                            // rowStyle={{ background: 'yellow' }}
                                            getRowStyle={(params) => {
                                                return {
                                                    background: (params.data.IsApproved === true) ? "#bbf58c" :
                                                        // (params.data.IsApproved === false) ? "#f7a681" :
                                                        "white"
                                                }
                                            }}
                                        >
                                        </AgGridReact>
                                    </div>

                                    <div style={{ margin: "5vh auto", marginLeft: "25%", justifyContent: "center" }}>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.itemsCountPerPage}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                                            onChange={this.handlePageChange.bind(this)}
                                            prevPageText='prev'
                                            nextPageText='next'
                                            firstPageText='first'
                                            lastPageText='last'
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


}

NoticeAdd.propTypes = {
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert })(NoticeAdd);
