require('dotenv').config(); // Load environment variables from .env

// eslint-disable-next-line 
const local = {
    hostName: "http://localhost:2020",
    cookie_expiration: 364,
    hostNameHeroku: "http://localhost:2020",
    hybridHostName: "https://hapi.logicarts.in",
    ptlHostName: "https://ndd.logicarts.in",
    apiKey: "AIzaSyBnWtSBj2X_7ZazYBW2-aR-fXkpTAZnnIE",
    geoCodeApiKey: "AIzaSyA1-I3n0iwCXvvhAaoANLQ_EVWNhoQrPo4",
    sandeepApiKey: "AIzaSyAXld-X2r3YqR6ah2-Hgk_r90nZo07ykXM"
};

// eslint-disable-next-line no-unused-vars
const prod = {
    hostName: "https://dev.logicarts.in",
    hostNameHeroku: "https://tms-ftsd-server.herokuapp.com",
    hybridHostName: "https://hapi.logicarts.in",
    ptlHostName: "https://ndd.logicarts.in",
    cookie_expiration: 364,
    apiKey: "AIzaSyBnWtSBj2X_7ZazYBW2-aR-fXkpTAZnnIE",
    geoCodeApiKey: "AIzaSyA1-I3n0iwCXvvhAaoANLQ_EVWNhoQrPo4",
    sandeepApiKey: "AIzaSyAXld-X2r3YqR6ah2-Hgk_r90nZo07ykXM"
};

const prodAlt = {
    hostName: "https://tms-ftsd.herokuapp.com",
    hostNameHeroku: "https://tms-ftsd-server.herokuapp.com",
    hybridHostName: "https://hapi.logicarts.in",
    cookie_expiration: 364,
    apiKey: "AIzaSyBnWtSBj2X_7ZazYBW2-aR-fXkpTAZnnIE",
    geoCodeApiKey: "AIzaSyA1-I3n0iwCXvvhAaoANLQ_EVWNhoQrPo4",
    sandeepApiKey: "AIzaSyAXld-X2r3YqR6ah2-Hgk_r90nZo07ykXM"
};


const dev = {
    hostName: "https://tms-ftsd-server.herokuapp.com",
    hostNameHeroku: "https://tms-ftsd-server.herokuapp.com",
    hybridHostName: "https://hapi.logicarts.in",
    cookie_expiration: 364,
    apiKey: "AIzaSyBnWtSBj2X_7ZazYBW2-aR-fXkpTAZnnIE",
    geoCodeApiKey: "AIzaSyA1-I3n0iwCXvvhAaoANLQ_EVWNhoQrPo4",
    sandeepApiKey: "AIzaSyAXld-X2r3YqR6ah2-Hgk_r90nZo07ykXM"
};

const localTms = {
    hostName: "http://localhost:2222",
    cookie_expiration: 364,
    hostNameHeroku: "http://localhost:2020",
    hybridHostName: "https://hapi.logicarts.in",
    apiKey: "AIzaSyBnWtSBj2X_7ZazYBW2-aR-fXkpTAZnnIE",
    geoCodeApiKey: "AIzaSyA1-I3n0iwCXvvhAaoANLQ_EVWNhoQrPo4",
    sandeepApiKey: "AIzaSyAXld-X2r3YqR6ah2-Hgk_r90nZo07ykXM"
};

const config = prod;
export default {
    ...config
};
